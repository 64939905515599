import React from "react";
import { Router, Route } from "react-router-dom";
import PropTypes from 'prop-types';

// REDUX
import { Provider } from 'react-redux';

import Layout from "layouts/";

import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";

const Root = ({ store, history }) => (
    <React.StrictMode>
        <Provider store={store}>
            <Router history={history}>
                <Route exact patch="/:params" component={Layout} />
            </Router>
        </Provider>
    </React.StrictMode>
);

Root.propTypes = {
    store: PropTypes.object.isRequired
};

export default Root;
