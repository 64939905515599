//CORE
import React from "react";
import ReactExport from "react-data-export";
import moment from "moment-timezone";

//REDUX
import reduxStore from "store/";

//@MATERIAL
import CustomButton from "components/Buttons/custom"


//FUNCTIONS
import { translate } from "functions/";

function ExportAll(props) {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    const data = props.data

    let dataSet1 = []
    let dataUsers = []

    let total_time = 0
    let total_hours = 0
    let total_minutes = 0
    let total_seconds = 0

    if (data && Object.keys(data).length > 0) {
        Object.keys(data).forEach(user => {
            let userData = []
            if (data[user]._times && Object.keys(data[user]._times > 0))
                Object.keys(data[user]._times).forEach(tm => {
                    let card = reduxStore.getState().db.cards[data[user]._times[tm].cardId]
                    let time = parseInt(data[user]._times[tm].fin) - parseInt(data[user]._times[tm].ini)
                    total_time = total_time + time
                    let diff = time
                    let diffText = ``
                    let hours = 0
                    let minutes = 0
                    let seconds = 0
                    if (time < 60) {
                        diffText = `${diff} ${translate('$__seconds', 1)}`
                    } else if (time > 3600) {
                        hours = Math.trunc(diff / 3600)
                        diff = diff - (hours * 3600)
                        minutes = Math.trunc(diff / 60) > 0 ? Math.trunc(diff / 60) : 0
                        diff = diff - (minutes * 60)
                        seconds = diff > 0 ? diff : 0
                        diffText = `${hours} ${translate('$__hour', 1)}${hours > 1 ? 's' : ''} ${minutes} ${translate('$__minutes', 1)} ${seconds} ${translate('$__seconds', 1)}`
                    } else {
                        minutes = Math.trunc(diff / 60) > 0 ? Math.trunc(diff / 60) : 0
                        diff = diff - (minutes * 60)
                        seconds = diff > 0 ? diff : 0
                        diffText = `${minutes} ${translate('$__minutes', 1)} ${seconds} ${translate('$__seconds', 1)}`
                    }

                    dataSet1.push({
                        name: data[user].name,
                        // email: data[user].email,
                        type: translate(`$__${card.type}`, 1),
                        card: card.name,
                        description: data[user]._times[tm].message,
                        iniData: moment(data[user]._times[tm].ini * 1000).tz('America/Sao_Paulo').format('YYYY/MM/DD HH:mm:ss'),
                        finalData: moment(data[user]._times[tm].fin * 1000).tz('America/Sao_Paulo').format('YYYY/MM/DD HH:mm:ss'),
                        total: diffText,
                        hours: hours,
                        minutes: minutes,
                        seconds: seconds,
                        calc: (parseInt(data[user]._times[tm].fin) - parseInt(data[user]._times[tm].ini)) / 3600
                    })
                    userData.push({
                        name: data[user].name,
                        // email: data[user].email,
                        type: translate(`$__${card.type}`, 1),
                        card: card.name,
                        description: data[user]._times[tm].message,
                        iniData: moment(data[user]._times[tm].ini * 1000).tz('America/Sao_Paulo').format('YYYY/MM/DD HH:mm:ss'),
                        finalData: moment(data[user]._times[tm].fin * 1000).tz('America/Sao_Paulo').format('YYYY/MM/DD HH:mm:ss'),
                        total: diffText,
                        hours: hours,
                        minutes: minutes,
                        seconds: seconds,
                        calc: (parseInt(data[user]._times[tm].fin) - parseInt(data[user]._times[tm].ini)) / 3600
                    })
                })
            userData.push({
                name: '',
                email: '',
                type: '',
                card: '',
                description: '',
                iniData: '',
                finalData: '',
                total: data[user].timerText,
                hours: '',
                minutes: '',
                seconds: '',
                calc: parseInt(data[user].totalTimer) / 3600,
            })
            dataUsers = {
                ...dataUsers,
                [user]: userData
            }

        }
        )
        let total_diff = total_time
        let total_diffText = ``
        if (total_time < 60) {
            total_seconds = total_diff
            total_diffText = `${total_diff} ${translate('$__seconds', 1)}`
        } else if (total_time > 3600) {
            total_hours = Math.trunc(total_diff / 3600)
            total_diff = total_diff - (total_hours * 3600)
            total_minutes = Math.trunc(total_diff / 60) > 0 ? Math.trunc(total_diff / 60) : 0
            total_diff = total_diff - (total_minutes * 60)
            total_seconds = total_diff > 0 ? total_diff : 0
            total_diffText = `${total_hours} ${translate('$__hour', 1)}${total_hours > 1 ? 's' : ''} ${total_minutes} ${translate('$__minutes', 1)} ${total_seconds} ${translate('$__seconds', 1)}`
        } else {
            total_minutes = Math.trunc(total_diff / 60) > 0 ? Math.trunc(total_diff / 60) : 0
            total_diff = total_diff - (total_minutes * 60)
            total_seconds = total_diff > 0 ? total_diff : 0
            total_diffText = `${total_minutes} ${translate('$__minutes', 1)} ${total_seconds} ${translate('$__seconds', 1)}`
        }

        dataSet1.push({
            name: '',
            type: '',
            card: '',
            description: '',
            iniData: '',
            finalData: '',
            total: total_diffText,
            hours: '',
            minutes: '',
            seconds: '',
            calc: ''
        })
    }

    if (dataSet1.length === 0)
        return (
            <div>Sem informações para exportar</div>
        )


    return (
        <ExcelFile
            filename={`${moment(new Date()).tz('America/Sao_Paulo').format('YYYY-MM-DDTHHMM')}_timeSheet`}
            element={
                <CustomButton
                    title={translate("$__export")}
                    text={translate("$__export")}
                    icon={"file_download"}
                    size={"25px"}
                />
            }
        >
            <ExcelSheet data={dataSet1} name="timeSheet">
                <ExcelColumn style={{ alignment: { wrapText: true } }} label={`${translate('$__name', 1)}`} value="name" />
                <ExcelColumn style={{ alignment: { wrapText: true } }} label={`${translate('$__type', 1)}`} value="type" />
                <ExcelColumn style={{ alignment: { wrapText: true } }} label={`${translate('$__card', 1)}`} value="card" />
                <ExcelColumn style={{ alignment: { wrapText: true } }} label={`${translate('$__description', 1)}`} value="description" />
                <ExcelColumn style={{ alignment: { wrapText: true } }} label={`${translate('$__initialTimer', 1)}`} value="iniData" />
                <ExcelColumn style={{ alignment: { wrapText: true } }} label={`${translate('$__finalTimer', 1)}`} value="finalData" />
                <ExcelColumn style={{ alignment: { wrapText: true } }} label={`${translate('$__totalTime', 1)}`} value="total" />
                <ExcelColumn style={{ alignment: { wrapText: true } }} label={`${translate('$__hours', 1)}`} value="hours" />
                <ExcelColumn style={{ alignment: { wrapText: true } }} label={`${translate('$__minutes', 1)}`} value="minutes" />
                <ExcelColumn style={{ alignment: { wrapText: true } }} label={`${translate('$__seconds', 1)}`} value="seconds" />
                <ExcelColumn style={{ alignment: { wrapText: true } }} label={`${translate('$__valueForCalculation', 1)}`} value="calc" />
            </ExcelSheet>
            {dataUsers && Object.keys(dataUsers).length > 0 && Object.keys(dataUsers).map((ds, i) => {
                let userName = dataUsers[ds][0].name
                return (
                    <ExcelSheet data={dataUsers[ds]} name={userName} key={ds}>
                        <ExcelColumn style={{ alignment: { wrapText: true } }} label={`${translate('$__name', 1)}`} value="name" />
                        <ExcelColumn style={{ alignment: { wrapText: true } }} label={`${translate('$__type', 1)}`} value="type" />
                        <ExcelColumn style={{ alignment: { wrapText: true } }} label={`${translate('$__card', 1)}`} value="card" />
                        <ExcelColumn style={{ alignment: { wrapText: true } }} label={`${translate('$__description', 1)}`} value="description" />
                        <ExcelColumn style={{ alignment: { wrapText: true } }} label={`${translate('$__initialTimer', 1)}`} value="iniData" />
                        <ExcelColumn style={{ alignment: { wrapText: true } }} label={`${translate('$__finalTimer', 1)}`} value="finalData" />
                        <ExcelColumn style={{ alignment: { wrapText: true } }} label={`${translate('$__totalTime', 1)}`} value="total" />
                        <ExcelColumn style={{ alignment: { wrapText: true } }} label={`${translate('$__hours', 1)}`} value="hours" />
                        <ExcelColumn style={{ alignment: { wrapText: true } }} label={`${translate('$__minutes', 1)}`} value="minutes" />
                        <ExcelColumn style={{ alignment: { wrapText: true } }} label={`${translate('$__seconds', 1)}`} value="seconds" />
                        <ExcelColumn style={{ alignment: { wrapText: true } }} label={`${translate('$__valueForCalculation', 1)}`} value="calc" />
                    </ExcelSheet>
                )
            })}
        </ExcelFile>
    )
}
export default ExportAll