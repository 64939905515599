import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles({
  grid: {
    padding: "0 15px !important"
  }
});

const GridItem = ({ children, className = "", ...rest }) => {
  const classes = useStyles();
  return (
    <Grid item {...rest} className={`${classes.grid} ${className}`}>
      {children}
    </Grid>
  );
};

GridItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export default GridItem;
