//CORE
import React from "react"
import moment from "moment-timezone"

//REDUX
import * as reduxActions from "store/actions"
import reduxStore from "store/"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import history from "store/history"

//COMPONENTS
import Approve from "components/Card/approval"
import Autocomplete from "components/AutoComplete"
import Card from "components/Card"
import CardTags from "components/Tags/Card"
import ConnectedIn from "components/ConnectedIn"
import CurrencyTextField from 'components/TextField/currency'
import CustomButton from "components/Buttons/custom"
import CustomIconButton from "components/CustomButtons/IconButton"
import DataPicker from "components/DataPicker"
import FormAnswer from "componentsV3/Forms/AnswerForm"
import GroupedButton from "components/Buttons/grouped"
import Icon from "components/Icon"
import ImgCropper from "components/Cropper"
import LinearProgress from "components/Progress/linear"
import QuickUsers from "components/User/quick"
import RecurrenceSettings from "components/Recurrence/settings"
import SideModuleCloseAndSave from "components/SideModule/closeAndSave"
import Switch from "components/Switch"
import TagsInput from "componentsV3/Tags/input"
import TextField from "components/TextField"
import Timer from "components/Timer/timer"

//@MATERIAL
import InputAdornment from "@material-ui/core/InputAdornment"
import { withStyles } from "@material-ui/core/styles"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/sideModule-Card.js"

//FUNCTIONS
import api from "api/"

import {
    cardStatus,
    getUserAccess,
    planMyAccountVerify,
    saveCard,
    setMyDay,
    updateCardStatus,
    verifyTemplate,
} from "functions/cards"

import {
    loadCardsV2
} from "functions/loadData"

import {
    appAlert,
    customApp,
    favoriteNode,
    getAppAccess,
    translate,
} from "functions/"

//ICONS
import MatrixIcon from "assets/icons/Matrix.js"


function Component(props) {
    const { classes, sideModule } = props
    const { db, session } = reduxStore.getState()
    const { planType } = db

    const [data, setData] = React.useState({})
    const [newData, setNewData] = React.useState({})
    const [reqSave, setReqSave] = React.useState(false)
    const [saveLoader, setSaveLoader] = React.useState(false)
    const [setDate, setSetDate] = React.useState(false)
    const [mounted, setMounted] = React.useState(false)
    const [startDate, setStartDate] = React.useState(null)
    const [endDate, setEndDate] = React.useState(null)
    const [miroOption, setMiroOption] = React.useState(false)
    const [dateDays, setDateDays] = React.useState(0)
    const [loadingData, setLoadingData] = React.useState(false)

    let AppAccess = getAppAccess()
    let CardAccess = getUserAccess(sideModule.id)

    React.useEffect(() => {
        if (data._id !== sideModule.data._id) {
            setData(sideModule.data)
            setReqSave(false)
        }
        if (!mounted) {
            if (sideModule.data.startDate)
                setStartDate(sideModule.data && sideModule.data.startDate ? sideModule.data.startDate : null)

            if (sideModule.data.endDate)
                setEndDate(sideModule.data && sideModule.data.endDate ? sideModule.data.endDate : null)
            setData(sideModule.data)
            setReqSave(false)

            if (data.type === "link" && !data.name)
                loadUrlContent()
            setMounted(true)
            reqFullData()
        }
        if (sideModule.data.name && sideModule.data.name.trim() !== "" && sideModule.data.type === "link" && sideModule.data._id === "new") {
            setReqSave(true)
            if (props.reqSave)
                props.reqSave(true)
        }
        // return () => {
        //     setMounted(false)
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sideModule.data, data])

    React.useEffect(() => {
        if (!data.name || (data.name && data.name.trim === "")) {
            setReqSave(false)
        } else {
            setReqSave(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newData])

    React.useEffect(() => {
        if (props.cards[data._id]
            && (
                (
                    props.cards[data._id]._requireApproval
                    && !data._requireApproval
                )
                ||
                (
                    !props.cards[data._id]._requireApproval
                    && data._requireApproval
                )
            )
        )
            setData({
                ...data,
                _requireApproval: !props.cards[data._id]._requireApproval
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.cards])

    const reqFullData = async () => {
        const loadParents = await loadCardsV2({ ...props, ignoreLoader: true }, {
            idRel: [sideModule.data._id],
            cardLoad: "parents"
        })
        if (loadParents)
            setLoadingData(false)
    }

    const changeData = (d, d2, clear = false) => {
        if (
            (
                d.value
                &&
                (
                    !sideModule.data[d.name]
                    &&
                    !data[d.name]
                )
            )
            ||
            (
                sideModule.data[d.name]
                && sideModule.data[d.name] !== d.value
            )
            ||
            clear
        ) {
            // setReqSave(true)
            if (props.reqSave)
                props.reqSave(true)
        }


        let newDataCD = {
            ...data,
            [d.name]: d.value,
        }
        if (d2 && d2.name && d2.value) {
            newDataCD = {
                ...data,
                ...newDataCD,
                [d2.name]: d2.value
            }
        }
        setData(newDataCD)
        setNewData({
            ...newData,
            [d.name]: d.value,
            ...d2 && d2.name && d2.value ? {
                [d2.name]: d2.value
            } : {}
        })
    }

    const close = (cl) => {
        if (reqSave && !cl) {
            appAlert({
                message: translate("$__confirmCloseWithUnsavedData", 1),
                variant: "warning",
                persist: false,
                horizontal: "right",
                confirm: () => {
                    props.reduxFunction("ASYNC", "CLEAR_MODULE")
                }
            })
        } else {
            props.reduxFunction("ASYNC", "CLEAR_MODULE")
        }
    }
    const save = async (cl = false) => {
        if (!saveLoader) {
            setReqSave(false)
            if (props.reqSave)
                props.reqSave(false)
            if (!data.status)
                data.status = "notStarted"
            if (sideModule.id === "new" && sideModule.data) {
                setData({
                    ...data,
                    ...sideModule.data
                })
            }
            props.reduxFunction("ASYNC", "SET_MODULE", {
                ...sideModule,
                data: data
            })

            if (!data.name || (data.name && data.name.trim() === "")) {
                setSaveLoader(false)
                appAlert({
                    message: translate("$__fillName", 1),
                    variant: "warning",
                    persist: false,
                    horizontal: "right",
                    confirm: null
                })
            } else {
                const request = await saveCard({
                    ...props,
                    store: {
                        ...props.store,
                        db: {
                            ...reduxStore.getState().db
                        }
                    }
                }, cl, data)
                if (request) {
                    if (newData && newData.status) {
                        updateCardStatus(
                            {
                                ...props,
                                data: { _id: data._id }
                            }, { status: newData.status })
                        cardStatus(props, data._id, newData.status)
                    }
                    setSaveLoader(false)
                    appAlert({
                        message: translate("$__DataSaved", 1),
                        variant: null,
                        persist: false,
                        horizontal: "right",
                        confirm: null
                    })
                }
                setReqSave(false)
                if (props.reqSave)
                    props.reqSave(false)
                if (cl)
                    close(true)
            }
        }
    }

    const loadUrlContent = async () => {
        const result = await api.post(`/link/content`, {
            url: data.url
        })
        setData({
            ...data,
            content: result.data.article,
        })
        setReqSave(true)
        if (props.reqSave)
            props.reqSave(true)
    }

    const dataType = sideModule.data && sideModule.data.type ? db.dataType.filter(a => a.name === sideModule.data.type)[0] : {}

    let parentData = null
    if (data._parent)
        parentData = reduxStore.getState().db.cards[data._parent]

    if (!data)
        return null

    let myDay = reduxStore.getState().db.cards[data._id] && reduxStore.getState().db.cards[data._id]._users && reduxStore.getState().db.cards[data._id]._users[session._id] && reduxStore.getState().db.cards[data._id]._users[session._id].myDay ? reduxStore.getState().db.cards[data._id]._users[session._id].myDay : null
    let myDayOption = !myDay || (myDay && myDay && moment(new Date(myDay * 1000)).format("YYYY/MM/DD") !== moment().format("YYYY/MM/DD")) ? true : false


    const isTemplate = data.isTemplate ? data.isTemplate : verifyTemplate(data._id)

    let permission = props.permission ? props.permission : false

    if (data && data._planId && (!db.cards[data._planId] || !planMyAccountVerify(data._planId)))
        permission = false

    if (!permission && parseInt(CardAccess) >= 4)
        permission = true

    if (!permission && planMyAccountVerify(data._planId) && AppAccess && AppAccess.admin && AppAccess.plan && (AppAccess.planAdmin || AppAccess.planModerate))
        permission = true

    if (data._requireApproval
        && data._parent
        && (
            (
                reduxStore.getState().db.cards[data._parent]
                && reduxStore.getState().db.cards[data._parent]._users
                && (
                    reduxStore.getState().db.cards[reduxStore.getState().db.cards[data._id]._parent]._users
                    && reduxStore.getState().db.cards[reduxStore.getState().db.cards[data._id]._parent]._users[session._id]
                    && reduxStore.getState().db.cards[reduxStore.getState().db.cards[data._id]._parent]._users[session._id].approvalResponsible

                )
            )
            ||
            (
                data._users
                && (
                    Object.keys(data._users).length === 1
                    && data._users[session._id]
                )
            )
        )
    ) permission = false


    let hasYoutube = false
    let youtube = (<React.Fragment></React.Fragment>)
    if (data && data.url && data.url.indexOf(".youtube.com/watch?") > -1) {
        let ybQuery = data.url.split("?")[1].split("v=")[1].split("&")[0]
        if (ybQuery) {
            youtube = (
                <iframe
                    width="560"
                    height="315"
                    style={{
                        top: 0,
                        width: "100%",
                        maxWidth: "500px",
                        height: "100%",
                        alignSelf: "center",
                        position: "absolute",
                        overflow: "hidden"
                    }}
                    src={`https://www.youtube.com/embed/${ybQuery}`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen >

                </iframe >
            )
            hasYoutube = true
        }
    }

    if (data && data.url && data.url.indexOf("//youtu.be/") > -1) {
        let ybQuery = data.url.split(".be/")[1]
        if (ybQuery) {
            youtube = (
                <iframe
                    width="560"
                    height="315"
                    style={{
                        top: 0,
                        width: "100%",
                        maxWidth: "500px",
                        height: "100%",
                        alignSelf: "center",
                        position: "absolute",
                        overflow: "hidden"
                    }}
                    src={`https://www.youtube.com/embed/${ybQuery}`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen >

                </iframe >
            )
            hasYoutube = true
        }
    }

    const formAsks = data.formId ? JSON.parse(reduxStore.getState().forms[data.formId].elements) : []
    let formAnswers = {}

    formAsks.forEach(x => {
        if (data[x.id])
            formAnswers = {
                ...formAnswers,
                [x.id]: data[x.id]
            }
    })

    return (
        <React.Fragment>
            <SideModuleCloseAndSave
                save={reqSave && permission ? () => {
                    setSaveLoader(true)
                    save()
                } : null}
                saveClose={reqSave && permission ? () => {
                    setSaveLoader(true)
                    save(true)
                } : null}
                saveLoader={saveLoader}
                close={() => {
                    if (props.onClose)
                        props.onClose()
                }}
            />
            <div className={classes.root}>
                {(data._requireApproval
                    && data._parent
                    && (
                        (
                            reduxStore.getState().db.cards[data._parent]
                            && reduxStore.getState().db.cards[data._parent]._users
                            && (
                                reduxStore.getState().db.cards[reduxStore.getState().db.cards[data._id]._parent]._users[session._id]
                                && reduxStore.getState().db.cards[reduxStore.getState().db.cards[data._id]._parent]._users[session._id].approvalResponsible
                            )
                        )
                        ||
                        (
                            data._users
                            && (
                                Object.keys(data._users).length === 1
                                && data._users[session._id]
                            )
                        )
                    )
                ) ?
                    <div style={{ position: "relative", width: "100%", background: customApp("ColumnTitleColor"), padding: 7, display: "grid" }}>
                        <div
                            style={{
                                color: "red",
                                fontWeight: "bold",
                                fontSize: 14
                            }}
                        >{translate("$__attention", 1)}: <span style={{ color: "#FFFFFF" }}>{translate("$__thisActivityRequireApproval", 1)}</span></div>
                        <Approve nodeId={data._id}
                            onAction={() => {
                                props.onClose()
                            }}
                        />
                    </div>
                    : <React.Fragment></React.Fragment>
                }
                {hasYoutube ?
                    <div
                        style={{
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            paddingTop: '48%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative',
                            float: 'left',
                            width: '100%',
                            clear: "both"
                        }}
                    >
                        {youtube}
                    </div>
                    : <React.Fragment></React.Fragment>
                }
                {permission && (!dataType.hideFields || (dataType.hideFields && dataType.hideFields.indexOf("imageUpload") === -1)) &&
                    <ImgCropper img={data ? data.image : null}
                        onChange={(a, file) => {
                            changeData({ name: "image", value: a })
                            changeData({ name: "file", value: file })
                        }}
                        files={data._files ? data._files : {}}
                        maxWidthOrHeight={600}
                        avatar={data.type === "chatGroup" ? true : false}
                        aspectRatio={data.type === "chatGroup" ? null : 16 / 9}
                        onDelete={() => {
                            changeData({ name: "image", value: null }, { name: "file", value: null }, true)
                        }}
                    />
                }
                {!permission && data.image &&
                    <div className={classes.image}
                        style={{
                            backgroundImage: `url(${data.image})`
                        }}
                    ></div>
                }
                {data.type === "plan" && !data._id ?
                    <fieldset style={{ marginBottom: '14px', marginTop: 30 }}>
                        <Autocomplete
                            id="combo-box-demo"
                            options={planType}
                            getOptionLabel={(option) => translate(option.label, 1)}
                            style={{ width: '100%' }}
                            name="planType"
                            disableClearable
                            value={data && data.planType ? planType.filter(a => a.value === data.planType)[0] : planType[0]}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label={translate('$__planType', 1)}
                                    variant={customApp('fieldVariant')}
                                    value={data && data.planType ? planType.filter(a => a.value === data.planType)[0] : planType[0]}
                                />
                            }
                            onChange={
                                (d, v) => {
                                    changeData({ name: 'planType', value: v['value'] })
                                }
                            }
                        />
                        {
                            planType.filter(a =>
                                !data.planType
                                || (data.planType
                                    && a.value === data.planType
                                    && a.swot === true
                                )
                            ).length > 0 ?
                                <div style={{
                                    position: "relative",
                                    width: "100%",
                                }}>
                                    <div style={{
                                        width: "fit-content",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        fontWeight: "bold",
                                        color: customApp("color")
                                    }}>
                                        <Icon icon={MatrixIcon} color={customApp('color')} size={25} /><span style={{ marginLeft: 15 }}>MATRIX SWOT</span>
                                    </div>

                                </div>
                                :
                                <React.Fragment></React.Fragment>
                        }
                        <div>{translate(planType.filter(a =>
                            !data.planType
                            || (data.planType
                                && a.value === data.planType
                            )
                        )[0].description, 1)}</div>
                    </fieldset>
                    : <React.Fragment></React.Fragment>}

                <div className={classes.data}>
                    {(data._parent || data._planId) && loadingData ?
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "auto" }}>
                            <span style={{ color: customApp("menu"), fontSize: "12px", paddingRight: "15px", whiteSpace: "nowrap" }}>{translate("$__loadingdata")}</span>
                            <LinearProgress />
                        </div>
                        : ((data._parent && db.cards[data._parent]) || (data._planId && db.cards[data._planId])) &&
                        <ConnectedIn
                            id={data._parent ? data._parent : data._planId ? data._planId : null}
                            open={(id) => {
                                props.reduxFunction("ASYNC", "SET_MODULE", {
                                    ...reduxStore.getState().sideModule,
                                    db: "cards",
                                    id: id,
                                    ...reduxStore.getState().db.cards[id].type === "step" ? {
                                        module: "columnEdit",
                                        activeModule: "columnEdit"
                                    } : {
                                        module: "cardEdit",
                                        activeModule: "cardEdit"
                                    },

                                    data: reduxStore.getState().db.cards[id],
                                })
                            }}
                        />
                    }

                    <div style={{
                        position: "relative",
                        alignItems: "center",
                        clear: 'both',
                        justifyContent: "space-between",
                        display: "flex",
                    }}>
                        {data._id && db.cards[data._id] && db.cards[data._id]._users &&
                            <QuickUsers _id={data._id} noLimit avatarSize="33px" permission={permission} />
                        }
                        <div
                            style={{
                                display: "flex", alignItems: "center", justifyContent: "flex-end"
                            }}
                        >
                            {
                                data.type === "plan" && data._id && permission ?
                                    <CustomButton
                                        title={translate("$__openPlan", 1)}
                                        icon={"launch"}
                                        color={"lightgray"}
                                        onClick={() => {
                                            history.push(`sp#${data._id}/dashboard`)
                                            close(true)
                                        }
                                        }
                                        size={"25px"}
                                    />
                                    : <React.Fragment></React.Fragment>
                            }
                            {props.card && props.card._id && props.card.type === "plan" ?
                                <CustomButton
                                    title={translate(props.card && props.card._isFavorite ? "$__removeFromFavorite" : "$__addToFavorite", 1)}
                                    icon={props.card && props.card._isFavorite ? "favorite" : "favorite_border"}
                                    color={props.card && props.card._isFavorite ? "red" : "lightgray"}
                                    onClick={() => {
                                        favoriteNode(props, data._id, props.card && props.card._isFavorite ? true : false)
                                        props.reduxFunction("ASYNC", "SET_DB", {
                                            ...reduxStore.getState().db,
                                            cards: {
                                                ...reduxStore.getState().db.cards,
                                                [data._id]: {
                                                    ...reduxStore.getState().db.cards[data._id],
                                                    _isFavorite: props.card && props.card._isFavorite ? false : true
                                                },
                                            }
                                        })
                                    }
                                    }
                                    size={"25px"}
                                />
                                : <React.Fragment></React.Fragment>
                            }
                            {(data._id
                                && data._id.length === 36
                                && data.type !== "chatGroup"
                                && data.type !== "step"
                                && !data.isTemplate
                                && !data.archived
                                && data.status !== 'completed'
                            ) &&
                                <CustomButton
                                    title={translate(myDay && moment(new Date(myDay * 1000)).format("YYYY/MM/DD") === moment().format("YYYY/MM/DD") ? "$__removeOfMyDate" : "$__addOnMyDay", 1)}
                                    color={myDay && moment(new Date(myDay * 1000)).format("YYYY/MM/DD") === moment().format("YYYY/MM/DD") ? "red" : "lightblue"}
                                    icon={myDay && moment(new Date(myDay * 1000)).format("YYYY/MM/DD") === moment().format("YYYY/MM/DD") ? "auto_delete" : "alarm_add"}
                                    onClick={() => {
                                        setMyDay(props, {
                                            _id: data._id,
                                            userId: session._id,
                                            option: myDayOption
                                        })
                                        if (props.myDayClick)
                                            props.myDayClick()
                                    }
                                    }
                                    size={"25px"}
                                />
                            }
                        </div>
                    </div>

                    <fieldset style={{ marginBottom: "14px" }}>
                        <legend>{data.type === 'plan' && data._id ? translate(`$__plan ${data.planType ? planType[1].label : planType[0].label}`, 1) : translate("$__data", 1)}</legend>
                        {data.type !== "plan" && data.type !== "chatGroup" &&
                            (data && data._planId && db.cards[data._planId] && db.cards[data._planId].refs)
                            ?
                            <fieldset style={{ marginBottom: "14px" }}>
                                <legend>{translate("$__references")}</legend>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div style={{ width: "46%" }}>
                                        <TextField
                                            label={(data && data._planId && db.cards[data._planId] && db.cards[data._planId].ref1Title) || translate("$__cardRef1", 1)}
                                            variant={customApp("fieldVariant")}
                                            name={"ref1"}
                                            id={"edit_ref_1"}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                autoComplete: "new-password",
                                                onChange: (d) => {
                                                    changeData(d.target)
                                                },
                                            }}
                                            value={data.ref1 || ""}
                                            placeholder={translate(dataType?.fieldsTitle?.placeholder?.ref1 || "$__cardRef1_placeholder", 1)}
                                            disabled={!permission || data.status === "completed" || (dataType && dataType.disabledFields && dataType.disabledFields.indexOf("ref1") > -1) ? true : false}
                                        />
                                    </div>
                                    <div style={{ width: "8%", textAlign: "center", justifyContent: "center", alignItems: "center", fontSize: "24px", display: "flex", color: customApp("menu") }}>|</div>
                                    <div style={{ width: "46%" }}>
                                        <TextField
                                            label={(data && data._planId && db.cards[data._planId] && db.cards[data._planId].ref2Title) || translate("$__cardRef2", 1)}
                                            variant={customApp("fieldVariant")}
                                            name={"ref2"}
                                            id={"edit_ref_2"}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                autoComplete: "new-password",
                                                onChange: (d) => {
                                                    changeData(d.target)
                                                },
                                            }}
                                            value={data.ref2 || ""}
                                            placeholder={translate(dataType?.fieldsTitle?.placeholder?.ref2 || "$__cardRef2_placeholder", 1)}
                                            disabled={!permission || data.status === "completed" || (dataType && dataType.disabledFields && dataType.disabledFields.indexOf("ref2") > -1) ? true : false}
                                        />
                                    </div>
                                </div>
                            </fieldset>
                            : <></>}
                        <TextField
                            label={translate(dataType && dataType.fieldsTitle && dataType.fieldsTitle.name ? dataType.fieldsTitle.name : "$__titleOfCard", 1)}
                            variant={customApp("fieldVariant")}
                            name={"name"}
                            id={"edit_693"}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                autoComplete: "new-password",
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon icon="view_headline" />
                                    </InputAdornment>
                                ),
                                onChange: (d) => {
                                    changeData(d.target)
                                },
                            }}
                            value={data && data.name ? data.name : ""}
                            placeholder={translate(dataType && dataType.fieldsTitle && dataType.fieldsTitle.placeholder && dataType.fieldsTitle.placeholder.name ? dataType.fieldsTitle.placeholder.name : "$__titleOfCard_placeholder", 1)}
                            disabled={!permission || data.status === "completed" || (dataType && dataType.disabledFields && dataType.disabledFields.indexOf("name") > -1) ? true : false}
                        />
                        {(CardAccess > 3 || data.description || permission)
                            ?
                            <React.Fragment>
                                <TextField
                                    label={translate("$__description", 1)}
                                    variant={customApp("fieldVariant")}
                                    name="description"

                                    InputProps={{
                                        autoComplete: "new-password",
                                        multiline: true,
                                        minRows: 3,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Icon icon="toc" />
                                            </InputAdornment>
                                        ),
                                        onChange: (d) => {
                                            changeData(d.target)
                                        },
                                    }}
                                    value={data && data.description ? data.description : ""}
                                    placeholder={translate("$__descriptionOfCard_placeholder", 1)}
                                    disabled={!permission || data.status === "completed" || (dataType && dataType.disabledFields && dataType.disabledFields.indexOf("description") > -1) ? true : false}
                                />
                            </React.Fragment>
                            :
                            <React.Fragment></React.Fragment>
                        }

                        {/* {data.url ?
                            <React.Fragment> */}
                        {
                            (data.type === "task"
                                || data.type === "iniciative"
                            )
                                ?

                                <CurrencyTextField
                                    label={translate("$__price", 1)}
                                    variant="standard"
                                    currencySymbol="$"
                                    outputFormat="string"
                                    style={{ width: "100%", marginBottom: 10 }}
                                    value={data && data.value ? data.value : ""}
                                    onChange={(event, value) =>
                                        changeData({
                                            name: "value",
                                            value: value
                                        })
                                    }

                                />
                                : <React.Fragment></React.Fragment>}
                        {(CardAccess > 3 || data.url || permission) && data.type !== "chatGroup" ?
                            <TextField
                                label={translate("$__URL", 1)}
                                variant={customApp("fieldVariant")}
                                name={"url"}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    autoComplete: "new-password",
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Icon icon="http" />
                                        </InputAdornment>
                                    ),
                                    onChange: (d) => {
                                        changeData(d.target)
                                    },
                                }}
                                value={data && data.url ? data.url : ""}
                                placeholder={"https://domain.com"}
                                disabled={!permission || data.status === "completed" || (dataType && dataType.disabledFields && dataType.disabledFields.indexOf("name") > -1) ? true : false}
                            />
                            : <React.Fragment></React.Fragment>}
                        {data.url ?
                            <div style={{
                                width: "100%",
                                textAlign: "right"
                            }}>
                                <a href={data.url} target="_blank" rel="noopener noreferrer" style={{ color: customApp("menu"), fontWeight: "bold" }}>{translate("$__openlink", "*")}</a>
                            </div>
                            : <React.Fragment></React.Fragment>
                        }
                    </fieldset>
                    {data.formId ?
                        <fieldset>
                            <legend>{translate('$__formAnswers')}</legend>
                            <FormAnswer
                                formId={data.formId}
                                values={formAnswers}
                                onChange={(values, error) => {
                                    if (values && Object.keys(values).length > 0)
                                        Object.keys(values).forEach(a => {
                                            console.log(a, values[a])
                                            if (values[a] !== formAnswers[a])
                                                changeData({
                                                    name: a,
                                                    value: values[a]
                                                })
                                        })
                                    console.log(formAnswers, values)
                                    // formsRef.current = {
                                    //     ...values,
                                    //     error
                                    // }
                                    // setFormError(null)
                                }}
                                disabled={(CardAccess > 3 || data.url || permission) ? false : true}
                            />
                        </fieldset>
                        : <></>}
                    {
                        (CardAccess > 3 || data.client || permission) &&
                        (
                            !isTemplate
                            && (
                                permission &&
                                (
                                    !dataType.hideFields ||
                                    (
                                        dataType.hideFields &&
                                        dataType.hideFields.indexOf("client") === -1)
                                )
                            ) &&
                            (
                                !data.client ||
                                (
                                    data.client &&
                                    db.users[data.client]
                                )
                            )
                        ) &&
                        <Autocomplete
                            id="combo-box-demo"
                            options={Object.keys(db.users)
                                .filter(a =>
                                    db.users[a].type === "client" ||
                                    db.users[a].type === "group" ||
                                    db.users[a].type === "sic"
                                )
                                .sort((a, b) => {
                                    if (db.users[a].name < db.users[b].name) {
                                        return -1
                                    } else if (db.users[a].name > db.users[b].name) {
                                        return 1
                                    } else {
                                        return 0
                                    }
                                }).map(a => {
                                    return { label: db.users[a].name, value: String(db.users[a]._id) }
                                })
                            }
                            getOptionLabel={(option) => {
                                return translate(option.label, 1)
                            }
                            }
                            style={{ width: "100%" }}
                            name="client"
                            value={
                                (
                                    data
                                    &&
                                    data.client
                                )
                                    ? Object.keys(db.users)
                                        .filter(a =>
                                            data.client
                                            && db.users[a]._id === data.client
                                        ).map(a => {
                                            return {
                                                label: db.users[a].name,
                                                value: String(db.users[a]._id)
                                            }
                                        })[0]
                                    : null
                            }
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label={translate("$__account", 1)}
                                    variant={customApp("fieldVariant")}
                                    value={
                                        (
                                            data
                                            &&
                                            data.client
                                        )
                                            ? Object.keys(db.users)
                                                .filter(a =>
                                                    data.client
                                                    && db.users[a]._id === data.client
                                                ).map(a => {
                                                    return {
                                                        label: db.users[a].name,
                                                        value: String(db.users[a]._id)
                                                    }
                                                })[0]
                                            : null
                                    }
                                />
                            }
                            onChange={
                                (d, v) => {
                                    changeData({ name: "client", value: v && v["value"] ? String(v["value"]) : null })
                                }
                            }
                            disabled={!permission || data.status === "completed" || (dataType && dataType.disabledFields && dataType.disabledFields.indexOf("client") > -1) ? true : false}
                        />
                    }
                    <div>
                        {props.card
                            && data.type !== "plan"
                            && data.type !== "chatGroup"
                            && props.card._id
                            && props.card._users[session._id]
                            && data.status !== "completed"
                            && CardAccess > 0
                            ?
                            <div
                                style={{
                                    position: 'relative',
                                    width: '100%',
                                    float: 'left'
                                }}
                                onClick={(e) => {
                                    e.stopPropagation()
                                }}
                            >
                                <Timer data={props.card} />
                            </div>
                            : <React.Fragment></React.Fragment>
                        }
                    </div>
                    {
                        (
                            ((CardAccess > 2 || data.endDate || data.startDate || permission) && !isTemplate && data.type !== "chatGroup")
                            ||
                            (
                                !(
                                    !permission
                                    &&
                                    (
                                        !data.startDate
                                        && !data.endDate
                                    )
                                )
                                &&
                                (
                                    !isTemplate
                                    &&
                                    (
                                        !dataType.hideFields
                                        || (
                                            dataType.hideFields
                                            && dataType.hideFields.indexOf("deadline") === -1
                                        )
                                    )
                                )
                            )
                        ) &&
                        <fieldset style={{ marginBottom: "14px" }}>
                            <legend>{translate("$__deadline", 1)}</legend>
                            {
                                data.endDate
                                    || data.startDate
                                    || setDate ?
                                    <React.Fragment>
                                        <TextField
                                            label={translate('$__initialDate', 1)}
                                            variant={customApp('fieldVariant', 1)}
                                            name="startDate"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            type='datetime-local'
                                            inputProps={{
                                                min: null,
                                                autoComplete: 'start-date',
                                                onChange: (d) => {
                                                    if (moment(d.target.value).isValid()) {
                                                        setStartDate(moment(d.target.value).unix('X'))
                                                    }
                                                    // if (!endDate || (
                                                    //     endDate
                                                    //     &&
                                                    //     (
                                                    //         moment(d.target.value).format("YYYY-MM-DD") === moment(endDate * 1000).format("YYYY-MM-DD")
                                                    //         || moment(d.target.value).unix("X") > moment(endDate * 1000).unix("X")
                                                    //     )
                                                    // )
                                                    // ) setEndDate(moment(moment(d.target.value).format("YYYY-MM-DDT23:59:59")).unix('X'))

                                                    let newData = {
                                                        startDate: moment(d.target.value).unix('X')
                                                    }
                                                    setStartDate(moment(d.target.value).unix('X'))
                                                    if (!endDate || endDate < moment(d.target.value).unix('X')) {
                                                        newData = {
                                                            ...newData,
                                                            endDate: moment(`${moment(d.target.value).format("YYYY-MM-DD")} 23:59:59`).unix('X')
                                                        }
                                                        setEndDate(moment(`${moment(d.target.value).format("YYYY-MM-DD")} 23:59:59`).unix('X'))
                                                    }
                                                    setData({
                                                        ...data,
                                                        ...newData
                                                    })
                                                    setReqSave(true)
                                                },
                                            }}
                                            value={startDate ? moment(startDate * 1000).format("YYYY-MM-DDTHH:mm") : null}
                                        />
                                        <TextField
                                            label={translate('$__endDate', 1)}
                                            variant={customApp('fieldVariant', 1)}
                                            name="endDate"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            type='datetime-local'
                                            inputProps={{
                                                min: !endDate || startDate ? moment(startDate * 1000).format("YYYY-MM-DDTHH:mm") : null,
                                                autoComplete: 'end-date',
                                                onChange: (d) => {
                                                    let newData = {
                                                        endDate: moment(d.target.value).unix('X')
                                                    }
                                                    setEndDate(moment(d.target.value).unix('X'))
                                                    if (!startDate) {
                                                        newData = {
                                                            ...newData,
                                                            startDate: moment(`${moment(d.target.value).format("YYYY-MM-DD")} 00:00:00`).unix('X')
                                                        }
                                                        setStartDate(moment(`${moment(d.target.value).format("YYYY-MM-DD")} 00:00:00`).unix('X'))
                                                    }
                                                    setData({
                                                        ...data,
                                                        ...newData
                                                    })
                                                    setReqSave(true)
                                                },
                                            }}
                                            value={endDate ? moment(endDate * 1000).format("YYYY-MM-DDTHH:mm") : null}
                                        />
                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            justifyContent: "flex-end"
                                        }}>
                                            <CustomButton
                                                color={customApp('lightgray')}
                                                icon={'clear'}
                                                onClick={() => {
                                                    setStartDate(null)
                                                    setEndDate(null)
                                                    setData({
                                                        ...data,
                                                        startDate: null,
                                                        endDate: null,
                                                        recurrence: null
                                                    })
                                                    setSetDate(false)
                                                    setReqSave(true)
                                                }}
                                                size={'15px'}
                                                {...{

                                                    title: translate(`$__clearDate`, '*'),
                                                    text: translate(`$__clearDate`, '*')
                                                }}
                                            />
                                        </div>
                                        {/* <DataPicker
                                            type="datetime"
                                            clearButtonPosition={"flex-end"}
                                            onChangeStartDate={(d) => {
                                                let newData = {
                                                    startDate: moment(d).unix('X')
                                                }
                                                setStartDate(moment(d).unix('X'))
                                                if (!endDate || endDate < moment(d).unix('X')) {
                                                    newData = {
                                                        ...newData,
                                                        endDate: moment(`${moment(d).format("YYYY-MM-DD")} 23:59:59`).unix('X')
                                                    }
                                                    setEndDate(moment(`${moment(d).format("YYYY-MM-DD")} 23:59:59`).unix('X'))
                                                }
                                                setData({
                                                    ...data,
                                                    ...newData
                                                })
                                                setReqSave(true)
                                            }}
                                            onChangeEndDate={(d) => {
                                                let newData = {
                                                    endDate: moment(d).unix('X')
                                                }
                                                setEndDate(moment(d).unix('X'))
                                                if (!startDate) {
                                                    newData = {
                                                        ...newData,
                                                        startDate: moment(`${moment(d).format("YYYY-MM-DD")} 00:00:00`).unix('X')
                                                    }
                                                    setStartDate(moment(`${moment(d).format("YYYY-MM-DD")} 00:00:00`).unix('X'))
                                                }
                                                setData({
                                                    ...data,
                                                    ...newData
                                                })
                                                setReqSave(true)
                                            }}
                                            range
                                            // horizontal
                                            startDate={startDate ? new Date(startDate * 1000) : null}
                                            endDate={endDate ? new Date(endDate * 1000) : null}
                                            onClear={() => {
                                                setStartDate(null)
                                                setEndDate(null)
                                                setData({
                                                    ...data,
                                                    startDate: null,
                                                    endDate: null,
                                                    recurrence: null
                                                })
                                                setSetDate(false)
                                                setReqSave(true)
                                            }

                                            }
                                        /> */}
                                        <div
                                            style={{ position: "relative", width: "fit-content", float: "right", display: "flex", justifyContent: "right" }}
                                        >
                                            <GroupedButton
                                                options={[
                                                    {
                                                        value: 0,
                                                        label: "Manual",
                                                    },
                                                    {
                                                        value: 7,
                                                        label: `7 ${translate("$__days").toLowerCase()}`,
                                                    },
                                                    {
                                                        value: 15,
                                                        label: `15 ${translate("$__days").toLowerCase()}`,
                                                    },
                                                    {
                                                        value: 30,
                                                        label: `30 ${translate("$__days").toLowerCase()}`,
                                                    },
                                                    {
                                                        value: 60,
                                                        label: `60 ${translate("$__days").toLowerCase()}`,
                                                    },
                                                    {
                                                        value: 90,
                                                        label: `90 ${translate("$__days").toLowerCase()}`,
                                                    },
                                                    {
                                                        value: 180,
                                                        label: `180 ${translate("$__days").toLowerCase()}`,
                                                    },
                                                    {
                                                        value: 365,
                                                        label: `365 ${translate("$__days").toLowerCase()}`,
                                                    },
                                                    ...parentData && parentData.startDate && parentData.endDate ? [
                                                        {
                                                            value: "parent",
                                                            label: `${translate("$__useParentDate")} ${moment(parentData.startDate * 1000).tz("America/Sao_Paulo").format("DD/MM/YYYY")} - ${moment(parentData.endDate * 1000).tz("America/Sao_Paulo").format("DD/MM/YYYY")}`,
                                                        }
                                                    ] : [],
                                                ]}
                                                value={dateDays ? dateDays : ""}
                                                onChange={(v) => {
                                                    if (v !== "parent" && v !== 0) {
                                                        setEndDate(moment(new Date(startDate * 1000)).tz("America/Sao_Paulo").add(v, "days").unix("X"))
                                                        changeData({ name: "endDate", value: moment(new Date(startDate * 1000)).tz("America/Sao_Paulo").add(v, "days").unix("X") }, null, true)
                                                    } else if (v === "parent") {
                                                        if (parentData.startDate) {
                                                            setStartDate(parentData.startDate)
                                                            changeData(
                                                                { name: "startDate", value: parentData.startDate },
                                                                parentData.endDate ?
                                                                    { name: "endDate", value: parentData.endDate }
                                                                    : null, true
                                                            )
                                                            if (parentData.endDate) {
                                                                setEndDate(parentData.endDate)
                                                            }
                                                        }
                                                        if (!parentData.startDate && parentData.endDate) {
                                                            setEndDate(parentData.endDate)
                                                            changeData(
                                                                { name: "endDate", value: parentData.endDate }
                                                                , null
                                                                , true
                                                            )
                                                        }
                                                    }
                                                    setDateDays(v)
                                                }}
                                            />
                                        </div>
                                        <div className={classes.switch}
                                            style={{
                                                position: "relative",
                                                width: "100%",
                                                float: "left",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                            {translate("$__freeChildrenDate", 1)}
                                            <Switch
                                                checked={data.freeChildrenDate ? true : false}
                                                onChange={(d) => {
                                                    changeData({
                                                        name: "freeChildrenDate",
                                                        value: data.freeChildrenDate ? false : true
                                                    })
                                                }}
                                                name="freeChildrenDate"
                                                inputProps={{ "aria-label": "secondary checkbox" }}
                                                disabled={!permission || data.status === "completed" || (dataType && dataType.disabledFields && dataType.disabledFields.indexOf("freeChildrenDate") > -1) ? true : false}
                                            />
                                        </div>
                                        {data.startDate && moment(data.startDate).isValid() &&
                                            (!dataType.hideFields || (dataType.hideFields && dataType.hideFields.indexOf("recurrence") === -1)) &&
                                            <div style={{ width: "311px", position: "relative", float: "left" }}>
                                                {data._recurrenceId ?
                                                    <div
                                                        style={{
                                                            marginTop: "15px",

                                                        }}
                                                    >
                                                        {translate("$__recurrentActivityFrom")}:
                                                        <Card db="cards" cardId={db.cards[data._recurrenceId]._id} Avatar ignoreList onlyOption />
                                                    </div>
                                                    :
                                                    <div>
                                                        <RecurrenceSettings
                                                            data={data}
                                                            onChange={(res) => {
                                                                setData({
                                                                    ...data,
                                                                    ...res,
                                                                    recurrenceNextDate: moment(`${res.recurrenceNextDate} 06:00:00`).tz("America/Sao_Paulo").format('YYYY-MM-DD')
                                                                })
                                                                setReqSave(true)
                                                            }}
                                                        />
                                                        {data.recurrence && data.recurrence !== "0" && data.recurrenceNextDate &&
                                                            <fieldset
                                                                style={{ marginTop: "7px" }}>
                                                                <legend>{translate("$__NextRecurrenceDate")}</legend>
                                                                <DataPicker
                                                                    // type="date"
                                                                    placeholder={translate("$__NextRecurrenceDate")}
                                                                    clearButtonPosition={"flex-end"}
                                                                    onChangeStartDate={(d) => {
                                                                        let newData = {
                                                                            recurrenceNextDate: moment(d).tz("America/Sao_Paulo").format('YYYY-MM-DD')
                                                                        }
                                                                        setData({
                                                                            ...data,
                                                                            ...newData
                                                                        })
                                                                        setReqSave(true)
                                                                    }}

                                                                    startDate={data.recurrenceNextDate && moment(data.recurrenceNextDate).isValid() ? new Date(moment(`${data.recurrenceNextDate} 06:00:00`).tz("America/Sao_Paulo").unix('X') * 1000) : null}

                                                                    onClear={() => {
                                                                        setData({
                                                                            ...data,
                                                                            recurrenceNextDate: null,
                                                                        })
                                                                        setReqSave(true)
                                                                    }

                                                                    }
                                                                />
                                                            </fieldset>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </React.Fragment>
                                    : (permission || ((CardAccess > 2 || data.endDate || data.startDate || permission) && !isTemplate)) ?
                                        <CustomIconButton
                                            text={translate("$__addDeadline", 1)}
                                            icon="timer"
                                            onClick={() => {
                                                setSetDate(true)
                                                setStartDate(moment(`${moment().format("YYYY-MM-DD")} 00:00:00`).unix("X"))
                                                changeData(
                                                    { name: "startDate", value: moment(`${moment().format("YYYY-MM-DD")} 00:00:00`).unix("X") },
                                                    dateDays !== "parent" ?
                                                        { name: "endDate", value: moment(`${moment().format("YYYY-MM-DD")} 23:59:59`).add(dateDays, "days").unix("X") }
                                                        : null
                                                )
                                                if (dateDays !== "parent") {
                                                    setEndDate(moment(`${moment().format("YYYY-MM-DD")} 23:59:59`).add(dateDays, "days").unix("X"))
                                                }
                                            }}
                                        />
                                        : <></>
                            }
                        </fieldset>
                    }
                    {((data.status === "completed" || !permission) && CardAccess < 3) ?
                        <React.Fragment>
                            {data._tags && data._tags.length > 0 ?
                                <CardTags options={data._tags}
                                    expanded={true}
                                />
                                :
                                <React.Fragment></React.Fragment>
                            }
                        </React.Fragment>
                        :
                        <React.Fragment>
                            {(!dataType.hideFields || (dataType.hideFields && dataType.hideFields.indexOf("tags") === -1)) &&
                                <fieldset style={{ marginBottom: "14px" }}>
                                    <legend>{translate("$__tags", 1)}</legend>
                                    <TagsInput
                                        data={{
                                            values: data._tags ? data._tags : [],
                                        }}
                                        callBack={(v) => {
                                            if (permission)
                                                changeData({ name: "_tags", value: v })
                                        }}
                                        disabled={!permission || data.status === "completed" || (dataType && dataType.disabledFields && dataType.disabledFields.indexOf("_tags") > -1) ? true : false}
                                        placeholder={translate("$__selectTheOptions", 1)}
                                    />
                                    {
                                        data
                                            && data._tags
                                            && data._tags.filter(a => a.value).length > 0
                                            ?
                                            <div className={classes.switch}>
                                                <Switch
                                                    checked={
                                                        data.childrenAutoTag
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(d) => {
                                                        if (permission)
                                                            changeData({
                                                                name: "childrenAutoTag",
                                                                value: data.childrenAutoTag ? false : true
                                                            })
                                                    }}
                                                    name="childrenAutoTag"
                                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                                    disabled={!permission || data.status === "completed" || (dataType && dataType.disabledFields && dataType.disabledFields.indexOf("childrenAutoTag") > -1) ? true : false}
                                                />
                                                {translate("$__makeLinksAssumeTheSameLabelsOnAdd", 1)}
                                            </div>
                                            : <React.Fragment></React.Fragment>
                                    }

                                </fieldset>
                            }
                        </React.Fragment>
                    }
                    {(!dataType.hideFields
                        || (dataType.hideFields
                            && (dataType.hideFields.indexOf("priority") === -1
                                || dataType.hideFields.indexOf("status") === -1))) &&
                        <fieldset style={{ marginBottom: "14px" }}>
                            {!isTemplate && (!dataType.hideFields || (dataType.hideFields && dataType.hideFields.indexOf("priority") === -1)) &&
                                <div style={{
                                    position: "relative",
                                    float: "left",
                                    width: "100%"
                                }}>
                                    <div style={{
                                        position: "relative",
                                        float: "left",
                                        width: "calc(50% - 15px)",
                                        margin: 3,
                                        paddingLeft: 7,
                                    }}>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={db.priority}
                                            getOptionLabel={(option) => translate(option.label, 1)}
                                            style={{ width: "100%" }}
                                            name="priority"
                                            disableClearable
                                            value={
                                                data
                                                    && data.priority

                                                    && db.priority

                                                    ? db.priority.filter(a =>
                                                        String(a.value) === String(data.priority)
                                                    )[0]
                                                    : db.priority[0]
                                            }
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    label={translate("$__priority", 1)}
                                                    variant={customApp("fieldVariant")}
                                                    disabled={!permission || data.status === "completed" || (dataType && dataType.disabledFields && dataType.disabledFields.indexOf("priority") > -1) ? true : false}
                                                    inputProps={{
                                                        ...params && params.inputProps ? params.inputProps : {},
                                                        style: {
                                                            ...params && params.inputProps && params.inputProps.style ? params.inputProps.style : {},
                                                            paddingLeft: 7,
                                                            marginTop: 3,
                                                            marginLeft: -5,
                                                            width: "calc(100% - 7px)",
                                                            color: data && data.priority ? db.priority.filter(a =>
                                                                String(a.value) === String(data.priority)
                                                            )[0].color : db.priority[0].color,
                                                            borderLeft: `solid 3.33px ${data && data.priority ? db.priority.filter(a =>
                                                                String(a.value) === String(data.priority)
                                                            )[0].color : db.priority[0].color}`,
                                                        }
                                                    }}
                                                    value={
                                                        data
                                                            && data.priority

                                                            && db.priority

                                                            ? db.priority.filter(a =>
                                                                String(a.value) === String(data.priority)
                                                            )[0]
                                                            : db.priority[0]
                                                    }
                                                />
                                            }
                                            onChange={
                                                (d, v) => {
                                                    if (permission)
                                                        changeData({ name: "priority", value: v["value"] })
                                                }
                                            }
                                            renderOption={(option, { inputValue }) => {
                                                return (
                                                    <span style={{ color: option.color }}>
                                                        {translate(option.label, 1)}
                                                    </span>
                                                )
                                            }}
                                            disabled={!permission || data.status === "completed" || (dataType && dataType.disabledFields && dataType.disabledFields.indexOf("priority") > -1) ? true : false}
                                        />
                                    </div>
                                    <div style={{
                                        position: "relative",
                                        float: "left",
                                        width: "calc(50% - 15px)",
                                        margin: 3,
                                        paddingLeft: 7,
                                    }}>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={db.priority}
                                            getOptionLabel={(option) => translate(option.label, 1)}
                                            style={{ width: "100%" }}
                                            name="risk"
                                            disableClearable
                                            value={
                                                data
                                                    && data.risk

                                                    && db.priority

                                                    ? db.priority.filter(a =>
                                                        String(a.value) === String(data.risk)
                                                    )[0]
                                                    : db.priority[0]
                                            }
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    label={translate("$__risk", 1)}
                                                    variant={customApp("fieldVariant")}
                                                    disabled={!permission || data.status === "completed" || (dataType && dataType.disabledFields && dataType.disabledFields.indexOf("priority") > -1) ? true : false}
                                                    inputProps={{
                                                        ...params && params.inputProps ? params.inputProps : {},
                                                        style: {
                                                            ...params && params.inputProps && params.inputProps.style ? params.inputProps.style : {},
                                                            paddingLeft: 7,
                                                            marginTop: 3,
                                                            marginLeft: -5,
                                                            width: "calc(100% - 7px)",
                                                            color: data && data.risk ? db.priority.filter(a =>
                                                                String(a.value) === String(data.risk)
                                                            )[0].color : db.priority[0].color,
                                                            borderLeft: `solid 3.33px ${data && data.risk ? db.priority.filter(a =>
                                                                String(a.value) === String(data.risk)
                                                            )[0].color : db.priority[0].color}`,
                                                        }
                                                    }}
                                                    value={
                                                        data
                                                            && data.risk

                                                            && db.priority

                                                            ? db.priority.filter(a =>
                                                                String(a.value) === String(data.risk)
                                                            )[0]
                                                            : db.priority[0]
                                                    }

                                                />
                                            }
                                            onChange={
                                                (d, v) => {
                                                    if (permission)
                                                        changeData({ name: "risk", value: v["value"] })
                                                }
                                            }
                                            renderOption={(option, { inputValue }) => {
                                                return (
                                                    <span style={{ color: option.color }}>
                                                        {translate(option.label, 1)}
                                                    </span>
                                                )
                                            }}
                                            disabled={!permission || data.status === "completed" || (dataType && dataType.disabledFields && dataType.disabledFields.indexOf("priority") > -1) ? true : false}
                                        />
                                    </div>
                                    <div style={{
                                        position: "relative",
                                        float: "left",
                                        width: "calc(50% - 15px)",
                                        margin: 3,
                                        paddingLeft: 7,
                                    }}>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={db.priority}
                                            getOptionLabel={(option) => translate(option.label, 1)}
                                            style={{ width: "100%" }}
                                            name="complexity"
                                            disableClearable
                                            value={
                                                data
                                                    && data.complexity

                                                    && db.priority

                                                    ? db.priority.filter(a =>
                                                        String(a.value) === String(data.complexity)
                                                    )[0]
                                                    : db.priority[0]
                                            }
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    label={translate("$__complexity", 1)}
                                                    variant={customApp("fieldVariant")}
                                                    disabled={!permission || data.status === "completed" || (dataType && dataType.disabledFields && dataType.disabledFields.indexOf("priority") > -1) ? true : false}
                                                    inputProps={{
                                                        ...params && params.inputProps ? params.inputProps : {},
                                                        style: {
                                                            ...params && params.inputProps && params.inputProps.style ? params.inputProps.style : {},
                                                            paddingLeft: 7,
                                                            marginTop: 3,
                                                            marginLeft: -5,
                                                            width: "calc(100% - 7px)",
                                                            color: data && data.complexity ? db.priority.filter(a =>
                                                                String(a.value) === String(data.complexity)
                                                            )[0].color : db.priority[0].color,
                                                            borderLeft: `solid 3.33px ${data && data.complexity ? db.priority.filter(a =>
                                                                String(a.value) === String(data.complexity)
                                                            )[0].color : db.priority[0].color}`,
                                                        }
                                                    }}
                                                    value={
                                                        data
                                                            && data.complexity

                                                            && db.priority

                                                            ? db.priority.filter(a =>
                                                                String(a.value) === String(data.complexity)
                                                            )[0]
                                                            : db.priority[0]
                                                    }
                                                />
                                            }
                                            onChange={
                                                (d, v) => {
                                                    if (permission)
                                                        changeData({ name: "complexity", value: v["value"] })
                                                }
                                            }
                                            renderOption={(option, { inputValue }) => {
                                                return (
                                                    <span style={{ color: option.color }}>
                                                        {translate(option.label, 1)}
                                                    </span>
                                                )
                                            }}
                                            disabled={!permission || data.status === "completed" || (dataType && dataType.disabledFields && dataType.disabledFields.indexOf("priority") > -1) ? true : false}
                                        />
                                    </div>
                                    <div style={{
                                        position: "relative",
                                        float: "left",
                                        width: "calc(50% - 15px)",
                                        margin: 3,
                                        paddingLeft: 7,
                                    }}>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={db.priority}
                                            getOptionLabel={(option) => translate(option.label, 1)}
                                            style={{ width: "100%" }}
                                            name="impact"
                                            disableClearable
                                            value={
                                                data
                                                    && data.impact

                                                    && db.priority

                                                    ? db.priority.filter(a =>
                                                        String(a.value) === String(data.impact)
                                                    )[0]
                                                    : db.priority[0]
                                            }
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    label={translate("$__impact", 1)}
                                                    variant={customApp("fieldVariant")}
                                                    disabled={!permission || data.status === "completed" || (dataType && dataType.disabledFields && dataType.disabledFields.indexOf("priority") > -1) ? true : false}
                                                    inputProps={{
                                                        ...params && params.inputProps ? params.inputProps : {},
                                                        style: {
                                                            ...params && params.inputProps && params.inputProps.style ? params.inputProps.style : {},
                                                            paddingLeft: 7,
                                                            marginTop: 3,
                                                            marginLeft: -5,
                                                            width: "calc(100% - 7px)",
                                                            color: data && data.impact ? db.priority.filter(a =>
                                                                String(a.value) === String(data.impact)
                                                            )[0].color : db.priority[0].color,
                                                            borderLeft: `solid 3.33px ${data && data.impact ? db.priority.filter(a =>
                                                                String(a.value) === String(data.impact)
                                                            )[0].color : db.priority[0].color}`,
                                                        }
                                                    }}
                                                    value={
                                                        data
                                                            && data.impact

                                                            && db.priority

                                                            ? db.priority.filter(a =>
                                                                String(a.value) === String(data.impact)
                                                            )[0]
                                                            : db.priority[0]
                                                    }
                                                />
                                            }
                                            onChange={
                                                (d, v) => {
                                                    if (permission)
                                                        changeData({ name: "impact", value: v["value"] })
                                                }
                                            }
                                            renderOption={(option, { inputValue }) => {
                                                return (
                                                    <span style={{ color: option.color }}>
                                                        {translate(option.label, 1)}
                                                    </span>
                                                )
                                            }}
                                            disabled={!permission || data.status === "completed" || (dataType && dataType.disabledFields && dataType.disabledFields.indexOf("priority") > -1) ? true : false}
                                        />
                                    </div>
                                </div>
                            }
                            {!isTemplate && (!dataType.hideFields || (dataType.hideFields && dataType.hideFields.indexOf("status") === -1)) &&
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={db.status}
                                    getOptionLabel={(option) => translate(option.label, 1)}
                                    style={{ width: "100%" }}
                                    name="status"
                                    disableClearable
                                    value={
                                        data
                                            && data.status
                                            && db.status
                                            ? db.status.filter(a =>
                                                String(a.value) === String(data.status)
                                            )[0]
                                            : db.status[0]
                                    }
                                    renderInput={(params) =>
                                        <div > <TextField
                                            {...params}
                                            label={translate("$__status", 1)}
                                            variant={customApp("fieldVariant")}
                                            value={
                                                data
                                                    && data.status
                                                    && db.status
                                                    ? db.status.filter(a =>
                                                        String(a.value) === String(data.status)
                                                    )[0]
                                                    : db.status[0]
                                            }
                                        />
                                        </div>
                                    }
                                    onChange={
                                        (d, v) => {
                                            if (permission || String(CardAccess) !== "0")
                                                changeData({ name: "status", value: v["value"] })
                                        }
                                    }
                                    disabled={!permission || (dataType && dataType.disabledFields && dataType.disabledFields.indexOf("status") > -1) ? String(CardAccess) === "0" ? true : false : false}
                                />
                            }

                        </fieldset>
                    }
                    {((CardAccess > 4 || (AppAccess && AppAccess.plan && AppAccess.planAdmin)) && data.type === 'plan' && data.status !== "completed") &&
                        <React.Fragment>
                            {!miroOption && !data.miroBoard ?
                                <fieldset style={{ marginBottom: "14px" }}>
                                    <legend>{translate("$__MiroBoard", 1)}</legend>
                                    <CustomIconButton
                                        text={translate("$__addBoard", 1)}
                                        icon={"developer_board"}
                                        onClick={() => {
                                            setMiroOption(true)
                                        }}
                                    />
                                </fieldset>
                                : miroOption ?
                                    <fieldset style={{ marginBottom: "14px" }}>
                                        <legend>{translate("$__MiroBoard", 1)}</legend>
                                        <TextField
                                            label={translate("$__MiroBoardCode")}
                                            variant={customApp("fieldVariant")}
                                            name={"boardCode"}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                autoComplete: "new-password",
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Icon icon={"save"} color="green" onClick={() => setMiroOption(false)} />
                                                    </InputAdornment>
                                                ),
                                                onChange: (d, v) => {
                                                    changeData({ name: "miroBoard", value: d.target.value })
                                                },
                                            }}
                                            value={data && data.miroBoard ? data.miroBoard : ""}
                                            placeholder={translate("$__MiroBoardCodePlaceholder", 1)}
                                            disabled={!permission || data.status === "completed" || (dataType && dataType.disabledFields && dataType.disabledFields.indexOf("miroBoard") > -1) ? true : false}
                                        />
                                    </fieldset>
                                    : data.miroBoard &&
                                    <fieldset style={{ marginBottom: "14px" }}>
                                        <legend>{translate("$__MiroBoard", 1)}</legend>
                                        <CustomIconButton
                                            text={translate("$__openMiroBoard", 1)}
                                            icon={"developer_board"}
                                            onClick={() => {
                                                props.reduxFunction("ASYNC", "LIGHTBOX", {
                                                    miro: true,
                                                    miroBoard: data.miroBoard
                                                })
                                            }}
                                        />
                                        <CustomIconButton
                                            text={translate("$__editBoard", 1)}
                                            icon={"insert_link"}
                                            onClick={() => {
                                                setMiroOption(true)
                                            }}
                                        />
                                    </fieldset>
                            }
                        </React.Fragment>
                    }
                    {
                        (!data._id
                            ||
                            (
                                (
                                    data
                                    && data._users
                                    && session
                                    && session._id
                                    && data._users[session._id]
                                    && data._users[session._id].access
                                    && parseInt(data._users[session._id].access) >= 3
                                )
                                && (
                                    !dataType.hideFields
                                    ||
                                    (
                                        dataType.hideFields
                                        && dataType.hideFields.indexOf("private") === -1
                                    )
                                )
                            )
                        ) &&
                        <fieldset style={{ marginBottom: "14px" }}>
                            <div className={classes.switch}>
                                <Switch
                                    checked={
                                        data.private
                                            ? true
                                            : false
                                    }
                                    onChange={(d) => {
                                        if (permission)
                                            changeData({
                                                name: "private",
                                                value: data.private ? false : true
                                            })
                                    }}
                                    name="private"
                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                    disabled={!permission || data.status === "completed" || (dataType && dataType.disabledFields && dataType.disabledFields.indexOf("private") > -1) ? true : false}
                                />
                                {translate("$__private", 1)}
                            </div>
                        </fieldset>
                    }
                    {(!dataType.hideFields || (dataType.hideFields && dataType.hideFields.indexOf("private") === -1)) && data.private &&
                        <fieldset style={{ marginBottom: "14px" }}>
                            <div className={classes.switch}>
                                <Switch
                                    checked={
                                        data.privateChildrens
                                            ? true
                                            : false
                                    }
                                    onChange={(d) => {
                                        if (permission)
                                            changeData({
                                                name: "privateChildrens",
                                                value: data.privateChildrens ? false : true
                                            })
                                    }}
                                    name="privateChildrens"
                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                    disabled={!permission || data.status === "completed" || (dataType && dataType.disabledFields && dataType.disabledFields.indexOf("private") > -1) ? true : false}
                                />
                                {translate("$__privateChildrens", 1)}
                            </div>
                        </fieldset>
                    }
                    {permission && data.type === "chatGroup" &&
                        <React.Fragment>
                            <fieldset style={{ marginBottom: "14px" }}>
                                <div className={classes.switch}>
                                    <Switch
                                        checked={
                                            data.onlyAdminsSendMessages
                                                ? true
                                                : false
                                        }
                                        onChange={(d) => {
                                            if (permission)
                                                changeData({
                                                    name: "onlyAdminsSendMessages",
                                                    value: data.onlyAdminsSendMessages ? false : true
                                                })
                                        }}
                                        name="onlyAdmins"
                                        inputProps={{ "aria-label": "secondary checkbox" }}
                                    />
                                    {translate("$__onlyAdminsCanSendMessage", 1)}
                                </div>
                            </fieldset>
                            {(
                                data.broadcast
                                ||
                                (
                                    reduxStore.getState().timeline
                                    &&
                                    (
                                        !reduxStore.getState().timeline[data._id]
                                        ||
                                        (
                                            reduxStore.getState().timeline[data._id]
                                            && Object.keys(reduxStore.getState().timeline[data._id]).length === 0
                                        )
                                    )
                                )
                            ) ?
                                <fieldset style={{ marginBottom: "14px" }}>
                                    <div className={classes.switch}>
                                        <Switch
                                            checked={
                                                data.broadcast
                                                    ? true
                                                    : false
                                            }
                                            onChange={(d) => {
                                                if (permission)
                                                    changeData({
                                                        name: "broadcast",
                                                        value: data.broadcast ? false : true
                                                    })
                                            }}
                                            name="broadcast"
                                            inputProps={{ "aria-label": "secondary checkbox" }}
                                        // disabled={!permission || data.status === "completed" || (dataType && dataType.disabledFields && dataType.disabledFields.indexOf("private") > -1) ? true : false}
                                        />
                                        {translate("$__workAsABroadcastList", 1)}
                                    </div>
                                </fieldset>
                                : <React.Fragment></React.Fragment>}
                        </React.Fragment>
                    }
                    {AppAccess
                        && AppAccess.plan
                        && AppAccess.planAdmin
                        && isTemplate
                        && data.type === "plan"
                        && session._access
                        && session._access.userAdmin
                        && session._access.machenAdmin
                        ?
                        <fieldset style={{ marginBottom: "14px" }}>
                            <Autocomplete
                                id="combo-box-demo"
                                options={db.plans}
                                getOptionLabel={(option) => translate(option.label, 1)}
                                style={{ width: "100%" }}
                                name="plans"
                                disableClearable
                                value={
                                    data
                                        && data.plans
                                        && db.plans
                                        ? db.plans.filter(a =>
                                            String(a.value) === String(data.plans)
                                        )[0]
                                        : {
                                            label: "$__notAvaiable",
                                            value: null,
                                            settings: {}
                                        }
                                }
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label={translate("$__avaiablefromplan", 1)}
                                        variant={customApp("fieldVariant")}
                                        value={
                                            data
                                                && data.plans
                                                && db.plans
                                                ? db.plans.filter(a =>
                                                    String(a.value) === String(data.plans)
                                                )[0]
                                                : {
                                                    label: "$__notAvaiable",
                                                    value: null,
                                                    settings: {}
                                                }
                                        }
                                    />
                                }
                                onChange={
                                    (d, v) => {
                                        if (permission)
                                            changeData({ name: "plans", value: v["value"] })
                                    }
                                }
                            />
                        </fieldset>
                        : <React.Fragment></React.Fragment>
                    }
                </div>
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = (store) => ({
    sideModule: store.sideModule,
    card: store.db.cards[store.sideModule.id],
    cards: store.db.cards,
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component))