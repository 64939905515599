import React, { useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import LinearProgress from "components/Progress/linear";
import { withStyles } from "@material-ui/core/styles";
import { translate, customApp } from "functions/";

function LoadingIndicator({ loaders, classes, loaderIds }) {
    // Garante que loaderIds seja um array
    const ids = Array.isArray(loaderIds) ? loaderIds : [];

    const isLoading = useMemo(() => {
        if (!loaders) return false;

        // Se não houver nenhum loader, não mostra
        const allKeys = Object.keys(loaders);
        if (allKeys.length === 0) return false;

        // Se não foi passado nenhum loaderId, comporta-se como antes (considera todos)
        if (ids.length === 0) {
            return allKeys.some((key) => loaders[key] !== "completed");
        }

        // Se houve loaderIds, filtra apenas IDs existentes em loaders
        const validKeys = ids.filter((id) => Object.prototype.hasOwnProperty.call(loaders, id));

        // Se nenhuma key foi encontrada em loaders, não há nada para considerar
        if (validKeys.length === 0) return false;

        // Verifica se pelo menos uma das validKeys ainda não está "completed"
        return validKeys.some((key) => loaders[key] !== "completed");
    }, [loaders, ids]);

    if (!isLoading) {
        return null;
    }

    return (
        <div className={classes.loadingContainer} role="status" aria-live="polite">
            <span id="loadBar" className={classes.loadingText}>
                {translate("$__loadingData")}
            </span>
            <LinearProgress />
        </div>
    );
}

const mapStateToProps = (store) => ({
    loaders: store.sessionLoads.cards.load,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(reduxActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withStyles(() => ({
        loadingContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
        },
        loadingText: {
            color: customApp("menu"),
            fontSize: "12px",
            paddingRight: "15px",
            whiteSpace: "nowrap",
        },
    }))(LoadingIndicator)
);
