//CORE
import React from "react"
import CryptoJS from "crypto-js"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import history from "store/history"
import reduxStore from "store"

//COMPONENTS
import Badge from "components/Icon/Badge"
import Card from "./card"
import HeaderWithIcon from "components/Header/card"

//DEPENDENCIES
import FeedFakeCard from "./FeedFakeCard"


//@MATERIAL
import { withStyles } from "@material-ui/core/styles"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/BrightAndConnected-card.js"

//FUNCTIONS
import api from "api/"
import {
    translate,
    customApp,
    getAppAccess,
    appAlert
} from "functions/"

function Component(props) {
    const { db, session } = reduxStore.getState()
    let data = props.data

    let AppAccess = getAppAccess()

    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace("#", "")

    if (!data)
        return null

    const setTitle = async (title) => {
        let id = hashs[0] && hashs[0].length === 36 ? data._parent[hashs[0]].idRel : data._parent[session.account].idRel
        if (
            data._parent
            && Object.keys(data._parent).length === 1
            && Object.keys(data._parent).filter(caid => reduxStore.getState().db.cards[caid]).length === 0
            && data._parent[Object.keys(data._parent)[0]].idRel
        ) {
            id = data._parent[Object.keys(data._parent)[0]].idRel
        }
        api.put("/rel/update", {
            _id: id,
            customTitle: title
        })
        let feeds = reduxStore.getState().db.feeds

        if (
            data._parent
            && Object.keys(data._parent).length === 1
            && Object.keys(data._parent).filter(caid => reduxStore.getState().db.cards[caid]).length === 0
            && data._parent[Object.keys(data._parent)[0]].idRel
        ) {
            feeds[data._id] = {
                ...feeds[data._id],
                _parent: {
                    ...feeds[data._id]._parent,
                    [Object.keys(data._parent)[0]]: {
                        ...feeds[data._id]._parent[Object.keys(data._parent)[0]],
                        customTitle: title,
                    }
                }
            }
        } else {
            feeds[data._id] = {
                ...feeds[data._id],
                _parent: {
                    ...feeds[data._id]._parent,
                    [hashs[0]]: {
                        ...feeds[data._id]._parent[hashs[0]],
                        customTitle: title,
                    }
                }
            }
        }
        props.reduxFunction("ASYNC", "SET_DB", {
            ...db,
            feeds: feeds
        })
        if (props.onChange)
            props.onChange()
    }
    const deleteChannel = async (pid) => {
        appAlert({
            message: translate("$__confirmDeleteThis", 1),
            variant: "warning",
            persist: false,
            horizontal: "right",
            confirm: () => {
                confirmDeleteChannel(pid)
            }
        })
    }
    const confirmDeleteChannel = async (pid) => {
        let deleteId = pid ? pid : hashs[0]
        api.delete(`feed/unfollow/${deleteId}__${data._id}`)

        let feeds = {
            ...reduxStore.getState().db.feeds
        }
        if (feeds[data._id] && feeds[data._id]._parent && feeds[data._id]._parent[deleteId]) {
            delete feeds[data._id]._parent[deleteId]
        }


        if (pid && feeds[data._id]) {
            delete feeds[data._id]
        }

        props.reduxFunction("ASYNC", "SET_DB", {
            ...db,
            feeds: {
                ...feeds
            }
        })

        if (props.onChange)
            setTimeout(() => {
                props.onChange()
            }, 400)
    }

    const changeVisible = async (parent, visible = false) => {
        api.put(`feed/visible/`, {
            feedId: data._id,
            visible: data && data.hide ? false : true,
        })
        let feed = data
        feed = {
            ...feed,
            hide: data && data.hide ? false : true,
        }
        props.reduxFunction("ASYNC", "SET_DB", {
            ...db,
            feeds: {
                ...db.feeds,
                [data._id]: {
                    ...db.feeds[data._id],
                    ...feed
                }
            }
        })
        // }
    }


    const ConnectedIn = () => {
        if (props.data._parent) {

            let options = []

            Object.keys(props.data._parent).forEach(pid => {
                if (reduxStore.getState().db.cards[pid] && reduxStore.getState().db.cards[pid]._users && reduxStore.getState().db.cards[pid]._users[session._id]) {
                    let parent = reduxStore.getState().db.cards[pid]
                    options = [...options, {
                        name: parent.name,
                        icon: "view_comfy",
                        render: (
                            <React.Fragment>
                                <div style={{ marginRight: "10px", width: "100%", display: "flex", color: customApp("color") }}>
                                    <div style={{ width: "33px" }}>
                                        <Badge
                                            overlap="rectangular"
                                            icon={parent.type && db && db.dataType ? db.dataType.filter(a => a.name === parent.type)[0].icon : "topic"}
                                            title={`$__${parent.type}`}
                                            size="22px"
                                            color={customApp("menu")}
                                        />
                                    </div>
                                    <div style={{ width: "calc(100% - 7px)", display: "flex", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
                                        {parent.name}
                                    </div>
                                </div>
                            </React.Fragment>
                        ),
                        onClick: () => {
                            history.push(`sp#${pid}/bright-and-connected`)
                        }
                    }
                    ]
                } else if (Object.keys(props.data._parent).length === 1) {
                    options = [...options, {
                        name: translate("$__globalAccount"),
                        icon: "rss_feed",
                        render: (
                            <React.Fragment>
                                <div style={{ marginRight: "10px", width: "100%", display: "flex" }}>
                                    <div style={{ width: "33px" }}>
                                        <Badge
                                            overlap="rectangular"
                                            icon={"rss_feed"}
                                            title={translate(`$__globalAccount`)}
                                            size="22px"
                                            color={customApp("menu")}
                                        />
                                    </div>
                                    <div style={{ width: "calc(100% - 7px)", display: "flex", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
                                        {translate(`$__globalAccount}`)}
                                    </div>
                                    <div style={{ width: "16px" }}>
                                        <Badge
                                            overlap="rectangular"
                                            icon={"delete_forever"}
                                            title={translate("$__delete")}
                                            size="22px"
                                            color={"red"}
                                            onClick={() => {
                                                deleteChannel(pid)
                                            }}
                                        />
                                    </div>
                                </div>
                            </React.Fragment>
                        ),
                        onClick: () => {
                            deleteChannel(pid)
                        }
                    }
                    ]
                }
            })
            return options
        }
    }

    if (data._parent)
        Object.keys(data._parent).forEach(a => {
            if (hashs[0] && hashs[0].length === 36 && hashs[0] === a) {
                data.customTitle = data._parent[a].customTitle
            } else if (Object.keys(data._parent).length > 0) {
                data.customTitle = data._parent[Object.keys(data._parent)[0]].customTitle
            }
        })

    return (
        <div
            style={{
                position: "relative",
                height: 'auto',
                cursor: 'pointer',
                width: "100%"
            }}
        >

            <HeaderWithIcon
                title={data.customTitle ? data.customTitle : data.name}
                icon={'chrome_reader_mode'}
                fontSize='20px'
                onEdit={(text, id) => setTitle(text)}
                color={customApp("color")}
                editable={
                    (
                        (

                            hashs[0] && hashs[0].length === 36 && AppAccess && AppAccess.plan
                            && (
                                AppAccess.planAdmin
                                || AppAccess.planModerate
                            )
                        )
                        ||
                        (
                            data._parent
                            && Object.keys(data._parent).length === 1
                            && Object.keys(data._parent).filter(caid => reduxStore.getState().db.cards[caid]).length === 0
                        )
                    )
                        ? true
                        : false
                }
                moreButton={true}
                {...(hashs[0]
                    && hashs[0] === 'tab'
                    && AppAccess && AppAccess.plan
                    && (
                        AppAccess.planAdmin
                        || AppAccess.planModerate
                    )
                )
                    ?
                    {
                        ...data._parent && Object.keys(data._parent).length > 0 && {
                            options: [
                                ...[{
                                    name: data.hide ? translate("$__folowRSS") : translate("$__hideRSS"),
                                    icon: data.hide ? "visibility" : "visibility_off",
                                    onClick: () => {
                                        changeVisible()
                                    }
                                },

                                ],
                                ...AppAccess && AppAccess.feed && (
                                    AppAccess.feedAdmin
                                    || AppAccess.feedModerate
                                ) ?
                                    [{
                                        name: translate("$__edit"),
                                        icon: 'settings_suggest',
                                        onClick: () => {
                                            props.reduxFunction("ASYNC", "SET_MODULE", {
                                                ...reduxStore.getState().sideModule,
                                                db: 'feeds',
                                                id: data._id,
                                                module: "feedEdit",
                                                activeModule: "feedEdit",
                                                data: {
                                                    ...data,
                                                    _lock: true,
                                                    _admin: props.admin ? true : false
                                                },
                                            })
                                        }
                                    }] : [],
                                ...ConnectedIn()
                            ]
                        }
                    } : (AppAccess && AppAccess.plan
                        && (
                            AppAccess.planAdmin
                            || AppAccess.planModerate
                        )) ? {
                        options: [
                            {
                                name: translate("$__deleteRSS"),
                                icon: "delete_forever",
                                onClick: () => {
                                    deleteChannel()
                                }
                            }
                        ]
                    } : {}}
            />
            <div
                style={{
                    padding: '0',
                    display: 'flex',
                    marginBottom: '15px',
                    flexFlow: 'wrap',
                    marginTop: '15px',
                }}
            >
                {data.feed && Object.keys(data.feed).length > 0 ?
                    Object.keys(data.feed).sort((a, b) => {
                        if (data.feed[a].isoDate && data.feed[b].isoDate && data.feed[a].isoDate < data.feed[b].isoDate)
                            return 1
                        return -1
                    })
                        .filter((lk, ii) => {
                            if (data)
                                if (ii === 0)
                                    return true
                            return false
                        })
                        .map((lk) => {
                            let link = data.feed[lk]
                            let id = CryptoJS.MD5(link.link)
                            return (
                                <Card
                                    key={id}
                                    data={link}
                                    _tags={data._tags}
                                    clickChannel={() => {
                                        history.push(`${window.location.pathname}${window.location.hash}/${data._id}`)
                                    }}
                                />
                            )
                        })
                    :
                    <FeedFakeCard
                        data={data}
                        clickChannel={() => {
                            history.push(`${window.location.pathname}${window.location.hash}/${data._id}`)
                        }}
                    />
                }
            </div>
        </div >
    )
}
const mapStateToProps = store => ({
    store
})
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component))
