//REDUX
import reduxStore from "store/"
import MD5 from "crypto-js/md5"

//FUNCTIONS
import api from "api"
import apims from "apims"
import { upload } from "functions/upload.js"
import moment from "moment-timezone"

import { loadCardsV2 } from "./loadData"
import session from "store/db"

import { removeSeats } from "./index"
import { v4 as uuid } from "uuid"

import { appAlert, translate } from "./index"


const saveCard_V2 = async () => {
    let hashs = window.location.hash.split('/')
    hashs[0] = hashs[0].replace('#', '')
    if (hashs[0].length !== 36) {
        hashs[0] = null
    }
    // console.log(data)
}

const saveCard = async (props, close = false, data, idRel = null) => {


    let hashs = window.location.hash.split('/')
    hashs[0] = hashs[0].replace('#', '')
    if (hashs[0].length !== 36) {
        hashs[0] = null
    }
    let newData = { ...data }
    const oldData = newData._id ? { ...reduxStore.getState().db['cards'][newData._id] } : {}
    let file = null

    if (newData.order && String(newData.order.low))
        newData.order = newData.order.low

    if (newData.type === 'chatGroup' && newData._planId) {
        delete newData._planId
        delete newData._parent
        delete newData.idRel
    }

    Object.keys(newData).map(f => {
        if (f === 'file') {
            file = newData[f]
            delete newData.file
        }
        if ((newData[f] && newData[f].low) || (newData[f] && newData[f].low && String(newData[f].low) === '0'))
            newData[f] = newData[f].low
        return newData
    })

    if (!newData._id || (newData._id && newData._id.length !== 36)) {
        if (idRel) {
            newData.idRel = idRel
            newData._parent = idRel
            if (reduxStore.getState().db.cards[idRel]?.type) {
                if (reduxStore.getState().db.cards[idRel].type === "plan") {
                    newData._planId = idRel
                }
                if (reduxStore.getState().db.cards[idRel].type !== "plan" && reduxStore.getState().db.cards[idRel]._planId) {
                    newData._planId = reduxStore.getState().db.cards[idRel]._planId
                }
            }
            // console.log(newData)
        } else {
            if (window.location.pathname.indexOf('sp') > -1 && !newData._parent)
                newData._parent = newData._parent ? newData._parent : hashs[0] ? hashs[0] : null
            if (newData._parent)
                newData.idRel = idRel ? idRel : newData._parent
            if (window.location.pathname.indexOf('sp') > -1 && hashs[0] && hashs[0].length === 36)
                newData._planId = hashs[0]
            // console.log(newData)
        }

        const responseAdd = await addCard(props, {
            ...newData,
            oldData: oldData,
        }, file, close)
        return responseAdd
    } else {
        const responseUPd = await updateCard(props, {
            ...newData,
            oldData: oldData,
        }, file, close)

        //Save ASPECTS
        if (newData
            && newData.type
            && newData.type.indexOf('guideline') > -1
            && newData.type !== 'guideline'
            && Object.keys(reduxStore.getState().db.cards).filter(a =>
                (
                    reduxStore.getState().db.cards[a]._planId === hashs[0]
                    || reduxStore.getState().db.cards[a]._parent === hashs[0]
                )
                && reduxStore.getState().db.cards[a]._selected === true
            ).length > 0)
            await saveMATRIX(newData._id)

        return responseUPd
    }
}

const kpiTimerStart = async (props, cardId) => {
    const response = await api.post("timer/start", {
        idRel: cardId,
        startTime: String(moment().unix("X")),
        type: "start"
    })
    if (response) {

        dbUpdateCardTimer(props, {
            ...response.data,
        })
        return {
            ...response.data,
            created_at: String(moment().unix("X"))
        }
    }
}

const kpiTimerStop = async (props, cardId) => {
    const response = await api.put("timer/stop", {
        idRel: cardId,
    })

    if (response) {
        return response.data
    }
}
const kpiTimerDelete = async (props, cardId, timerId) => {
    const response = await api.delete(`timer/run/${timerId}`, {
        idRel: cardId,
    })

    let kpiTimer = reduxStore.getState().db.cards[cardId]._kpiTimer
    if (kpiTimer[timerId])
        delete kpiTimer[timerId]

    if (response) {
        props.reduxFunction("ASYNC", "SET_DB", {
            ...reduxStore.getState().db,
            cards: {
                ...reduxStore.getState().db.cards,
                [cardId]: {
                    ...reduxStore.getState().db.cards[cardId],
                    _kpiTimer: kpiTimer
                }
            }
        })
        return true
    }
}

const saveMATRIX = async (props) => {
    let hashs = window.location.hash.split('/')
    hashs[0] = hashs[0].replace('#', '')
    let id = Object.keys(reduxStore.getState().db.cards).filter(a => {
        if (
            (
                reduxStore.getState().db.cards[a]._planId === hashs[0]
                || reduxStore.getState().db.cards[a]._parent === hashs[0]
            )
            && reduxStore.getState().db.cards[a]._selected === true
            && reduxStore.getState().db.cards[a].type
            && reduxStore.getState().db.cards[a].type.indexOf('guideline') > -1) {
            return true
        }
        return false
    })[0]
    let aspects = Object.keys(reduxStore.getState().db.cards).filter(a =>
        (
            reduxStore.getState().db.cards[a]._planId === hashs[0]
            || reduxStore.getState().db.cards[a]._parent === hashs[0]
        )
        && reduxStore.getState().db.cards[a]._selected === true
        && reduxStore.getState().db.cards[a].type
        && reduxStore.getState().db.cards[a].type.indexOf('guideline') === -1
    )
    api.put("matrix/aspects", {
        _id: id,
        _parent: hashs[0],
        aspects: aspects
    })
    if (props && props.reduxFunction)
        props.reduxFunction("ASYNC", "SET_DB", {
            ...reduxStore.getState().db,
            cards: {
                ...reduxStore.getState().db.cards,
                [id]: {
                    ...reduxStore.getState().db.cards[id],
                    _aspects: aspects
                }
            }
        })
    return true
}

const addCard = async (props, newData, file, close = false) => {
    let socket
    if (props && props.store && reduxStore.getState().functions && reduxStore.getState().functions.socket) {
        socket = reduxStore.getState().functions.socket
    } else {
        socket = reduxStore.getState().functions.socket
    }
    const { session, db } = reduxStore.getState()
    let hashs = window.location.hash.split('/')
    hashs[0] = hashs[0].replace('#', '')

    const response = new Promise(async (resolve, reject) => {
        try {
            let action = 'add'
            if (newData.type === 'myTask' || newData.type === 'chatGroup') {
                newData.myTask = true
            }
            if (!newData.status)
                newData.status = "notStarted"
            socket.emit("data", {
                module: "cards",
                method: "post",
                action
            }, newData, async (response) => {
                newData._users = {
                    [session._id]: {
                        name: db.users[session._id].name ? db.users[session._id].name : null,
                        displayName: db.users[session._id].displayName ? db.users[session._id].displayName : null,
                        image: db.users[session._id].image ? db.users[session._id].image : null,
                        access: 5,
                        _id: session._id
                    }
                }

                if (newData.type && newData.type.indexOf('guideline') > -1)
                    await saveMATRIX(response.id)

                if (newData.myTask)
                    delete newData.myTask

                newData._loaded = true
                newData._loadTimeline = true
                if (response.id) {
                    if (!close)
                        props.reduxFunction("ASYNC", "SET_MODULE", {
                            ...reduxStore.getState().sideModule,
                            id: response.id,
                            data: {
                                ...reduxStore.getState().sideModule.data,
                                ...newData,
                                _id: response.id,
                            }
                        })

                    newData._id = response.id

                    if (parseInt(newData.order) >= 0)
                        newData.order = { low: newData.order }

                    newData = {
                        ...newData,
                        ...response.data,
                    }
                    props.reduxFunction("ASYNC", "SET_DB", {
                        ...reduxStore.getState().db,
                        cards: {
                            ...reduxStore.getState().db.cards,
                            [newData._id]: {
                                ...newData,
                                //_loaded: true,
                                created_at: moment().tz('America/Sao_Paulo').unix('x'),
                                updated_at: {
                                    low: moment().tz('America/Sao_Paulo').unix('x')
                                },
                            },
                        }
                    })
                    timelineAdd(props, newData._id, {
                        [response.log._id]: {
                            ...response.log,
                            _parent: newData._id,
                        }
                    })
                    if (file) {
                        updateCard(props, {
                            ...newData,
                        }, file, close)
                    } else if ((hashs[0] === 'tab' && hashs[1] === 'myDay')) {
                        setMyDay(props, { _id: response.id, userId: session._id, option: true }, {
                            new: true,
                            user: session._id,
                            cardId: newData._id,
                            cardData: {
                                ...newData,
                            },
                        })
                        timelineAdd(props, newData._id, {
                            [response.log._id]: {
                                ...response.log,
                                _parent: newData._id,
                            }
                        })
                    }
                }
                resolve({
                    ...newData,
                    //_loaded: true,
                    created_at: moment().tz('America/Sao_Paulo').unix('x'),
                    updated_at: {
                        low: moment().tz('America/Sao_Paulo').unix('x')
                    },
                })
            })
        } catch (e) {
            console.log(e)
        }
    })

    const result = await response
    return result
}

const massUpdate = async (props, data) => {
    if (data.ids.length > 0) {
        let socket
        if (props && props.store && reduxStore.getState().functions && reduxStore.getState().functions.socket) {
            socket = reduxStore.getState().functions.socket
        } else {
            socket = reduxStore.getState().functions.socket
        }
        socket.emit("data", {
            module: "cards",
            method: "put",
            action: "massUpdate"
        }, data, async (response) => {
            let cards = reduxStore.getState().db.cards
            data.ids.map(async id => {
                cards[id] = {
                    ...cards[id],
                    ...data.data,
                }
                if (response.log)
                    await timelineAdd(props, id, {
                        [uuid()]: {
                            ...response.log,
                            _parent: [id],
                        }
                    })
            })

            props.reduxFunction("ASYNC", "SET_DB", {
                ...reduxStore.getState().db,
                cards: {
                    ...reduxStore.getState().cards,
                    ...cards,
                }
            })
        })
    }
}
const updateCard = async (props, nData, file) => {


    let newData = {
        ...nData,
    }
    let imageResult

    let socket
    if (props && props.store && reduxStore.getState().functions && reduxStore.getState().functions.socket) {
        socket = reduxStore.getState().functions.socket
    } else {
        socket = reduxStore.getState().functions.socket
    }

    if (file) {
        imageResult = await upload(newData._id, file)
        newData.image = imageResult
    }
    if (!file || (file && imageResult)) {
        props.reduxFunction("ASYNC", "SET_DB", {
            ...reduxStore.getState().db,
            cards: {
                ...reduxStore.getState().db.cards,
                [newData._id]: {
                    ...reduxStore.getState().db.cards[newData._id],
                    ...newData,
                    _users: {
                        ...reduxStore.getState().db.cards[newData._id]._users,
                    }
                }
            }
        })

        Object.keys(newData).forEach(d => {
            if (d && d.substr(0, 1) && d.substr(0, 1) !== "_" && !isNaN(d.substr(0, 1))) {
                newData = {
                    ...newData,
                    [`f${d}`]: newData[d]
                }
                delete newData[d]
            }
        }
        )

        socket.emit("data", {
            module: "cards",
            method: "put",
            action: "update220527"
        }, newData, async (response) => {

            if (newData.order && parseInt(newData.order) >= 0)
                newData.order = { low: newData.order }

            if (response && response.log && response.log._id)
                timelineAdd(props, newData._id, {
                    [response.log._id]: {
                        ...response.log,
                        _parent: [newData._id],
                    }
                })
            const { db } = reduxStore.getState()

            if (
                newData.type
                && newData._id
                && newData.type.indexOf('guideline') > -1
                && newData.type !== 'guideline'
                && Object.keys(db.cards).filter(card =>
                    db.cards[card]._parent === newData._id
                    && db.dataType.filter(a => a.name === db.cards[card].type && a.module === 'swot').length > 0
                )
            ) {
                await saveMATRIX(newData._id)
            }
        })
    }

    return true
}

const updateCardv2 = async (props, nData, file) => {
    return new Promise((resolve, reject) => {
        let newCards = {}

        nData.forEach(async element => {
            let newData = {
                ...element,
            }
            let imageResult

            let socket
            if (props && props.store && reduxStore.getState().functions && reduxStore.getState().functions.socket) {
                socket = reduxStore.getState().functions.socket
            } else {
                socket = reduxStore.getState().functions.socket
            }

            if (file) {
                imageResult = await upload(newData._id, file)
                newData.image = imageResult
            }
            if (!file || (file && imageResult)) {

                newCards = {
                    ...newCards,
                    [newData._id]: {
                        ...reduxStore.getState().db.cards[newData._id],
                        ...newData,
                        _users: {
                            ...reduxStore.getState().db.cards[newData._id]._users,
                        }
                    }
                }

                socket.emit("data", {
                    module: "cards",
                    method: "put",
                    action: "update220527"
                }, newData, async (response) => {

                    if (!response) {
                        reject(new Error(`error update`));
                    }

                    if (newData.order && parseInt(newData.order) >= 0)
                        newData.order = { low: newData.order }

                    if (response && response.log && response.log._id)
                        timelineAdd(props, newData._id, {
                            [response.log._id]: {
                                ...response.log,
                                _parent: [newData._id],
                            }
                        })
                    resolve(true);
                })

            }

        });
        props.reduxFunction("ASYNC", "SET_DB", {
            ...reduxStore.getState().db,
            cards: {
                ...reduxStore.getState().db.cards,
                ...newCards
            }
        })


    });
}

const timelineAdd = (props, nodeId, data) => {
    let newTimeline = reduxStore.getState().timeline[nodeId] ? reduxStore.getState().timeline[nodeId] : {}
    newTimeline = {
        ...newTimeline,
        ...data
    }
    props.reduxFunction("ASYNC", "SET_TIMELINE", {
        ...reduxStore.getState().timeline,
        [nodeId]: newTimeline
    })
}

const restoreCard = async (props, data) => {
    let socket
    if (props && props.store && reduxStore.getState().functions && reduxStore.getState().functions.socket) {
        socket = reduxStore.getState().functions.socket
    } else {
        socket = reduxStore.getState().functions.socket
    }
    socket.emit("data", {
        module: "cards",
        method: "put",
        action: "restore"
    }, {
        _id: data._id,
    }, async (response) => {
        dbCardsDelete(props, {
            cardId: data._id,
        }, false)
    })
}

const deleteCard = async (props, data, onlyRedux, childrensDelete = false) => {
    let { db } = reduxStore.getState()
    if (!onlyRedux) {
        let socket
        if (props && props.store && reduxStore.getState().functions && reduxStore.getState().functions.socket) {
            socket = reduxStore.getState().functions.socket
        } else {
            socket = reduxStore.getState().functions.socket
        }
        socket.emit("data", {
            module: "cards",
            method: "put",
            action: "delete"
        }, {
            _id: data._id,
            deleted: true,
            childrensDelete,
            client: db.cards[data._id] && db.cards[data._id].client ? db.cards[data._id].client : db.cards[data._id] && db.cards[data._id]._planId && db.cards[db.cards[data._id]._planId] && db.cards[db.cards[data._id]._planId].client ? db.cards[db.cards[data._id]._planId].client : null
        }, async (response) => {
            dbCardsDelete(props, {
                cardId: data._id,
                childrens: childrensDelete
            }, true)
        })
    } else {
        dbCardsDelete(props, {
            cardId: data._id,
            childrens: data.childrensDelete
        }, true)
    }
}

const dbCardsDelete = (props, data, deleted = false) => {
    let cards = { ...reduxStore.getState().db.cards }
    cards[data.cardId] = {
        ...cards[data.cardId],
        deleted: deleted,
        ...cards[data.cardId] && cards[data.cardId]._selected ? { _selected: false } : {}
    }
    if (data.childrens) {
        let childrens = getChildrens(data.cardId)
        Object.keys(childrens).forEach(c => {
            cards[c] = {
                ...cards[c],
                deleted: deleted,
            }
        })
    }
    props.reduxFunction("ASYNC", "SET_DB", {
        ...reduxStore.getState().db,
        cards: {
            ...reduxStore.getState().db.cards,
            ...cards
        }
    })
}
const dbUpdateCardSWOT = (props, data) => {
    if (reduxStore.getState().db.cards[data._id] && !reduxStore.getState().db.cards[data._id]._selected && data && data._selected) {
        delete data._selected
    }
    props.reduxFunction("ASYNC", "SET_DB", {
        ...reduxStore.getState().db,
        cards: {
            ...reduxStore.getState().db.cards,
            [data._id]: {
                ...reduxStore.getState().db.cards[data._id],
                _aspects: data.aspects,
            }
        }
    })
    props.reduxFunction("ASYNC", "preLoader", {
        ...reduxStore.getState().preLoader,
        autoSave: true,
    })
}
export const dbUpdateCard = async (props, data) => {
    let forceUpdate = {}
    let card = await new Promise(async (resolve, reject) => {
        if (data && data.cardData && data.cardData._parent && !reduxStore.getState().db.cards[data.cardData._parent]) {
            const lp = await loadCardsV2(props, {
                idRel: data.cardData._parent,
                cardLoad: "fullCatenation",
                ignoreLoader: true
            })
            if (lp && lp[data.cardData._parent])
                forceUpdate = {
                    _requireApproval: lp[data.cardData._parent].requireApproval ? true : null
                }
        } else if (data?.cardData?._parent && reduxStore.getState().db.cards[data.cardData._parent]) {
            let cardParent = reduxStore.getState().db.cards[data.cardData._parent]
            forceUpdate = {
                _requireApproval: !cardParent.requireApproval ? null : true
            }
        }
        if (!reduxStore.getState().db.cards[data.cardId]) {
            const loadNewCard = await loadCardsV2(props, {
                idRel: data.cardId,
                cardLoad: "fullCatenation",
                ignoreLoader: true
            })
            if (loadNewCard && loadNewCard.cards && loadNewCard.cards[data.cardId]) {
                resolve(loadNewCard.cards[data.cardId])
            }
        } else {
            resolve(reduxStore.getState().db.cards[data.cardId])
        }

    })
    if (card) {
        let newTags = reduxStore.getState().db.tag
        if (reduxStore.getState().db.cards && card) {
            if (data.cardData && data.cardData._tags && data.cardData._tags.length > 0) {
                data.cardData._tags.forEach(tag => {
                    if (card
                        && card._tags
                        && card._tags.filter(fil => fil.value === tag.value).length === 0
                    )
                        newTags.filter(fil => fil.value === tag.value).forEach(a => {
                            a.count = a.count + 1
                        })
                })
            }
            if (card._tags) {
                card._tags.forEach(tag => {
                    if (data.cardData._tags && data.cardData._tags.filter(fil => fil.value === tag.value).length === 0)
                        newTags.filter(fil => fil.value === tag.value).forEach(a => {
                            a.count = a.count - 1
                        })
                })
            }
        }

        if (
            !data.new
            ||
            (
                data.new
                && data.user !== session._id
            )) {
            props.reduxFunction("ASYNC", "SET_DB", {
                ...reduxStore.getState().db,
                cards: {
                    ...reduxStore.getState().db.cards,
                    [data.cardId]: {
                        ...card ? card : {},
                        ...data.cardData,
                        _notifications: reduxStore.getState().db.cards && card && card._notifications ? card._notifications : {
                            comment: 0,
                            log: 0,
                            timer: 0,
                            kpi: 0
                        },
                        createdBy: data.user ? data.user : null,
                        ...forceUpdate,
                    }
                },
                tag: newTags
            })
        }
    }
}

const dbUpdateCardTimer = (props, data) => {

    let card = reduxStore.getState().db.cards[data.cardId] || null
    if (card) {
        props.reduxFunction("ASYNC", "SET_DB", {
            ...reduxStore.getState().db,
            cards: {
                ...reduxStore.getState().db.cards,
                [data.cardId]: {
                    ...card,
                    _kpiTimer: {
                        ...data._kpiTimer,
                        ...data.startTime ? { created_at: data.startTime } : {}
                    }
                }
            },
        })
    }
}

const dbUpdateCard_V2 = async (props, data) => {
    props.reduxFunction("ASYNC", "SET_DB", {
        ...reduxStore.getState().db,
        cards: {
            ...reduxStore.getState().db.cards,
            [data._id]: {
                ...reduxStore.getState().db.cards[data._id] ? reduxStore.getState().db.cards[data._id] : {},
                ...data
            }
        },
    })
}

const selectSWOT = async (props, idCard) => {
    const { db } = reduxStore.getState()
    const { cards } = db
    let selectedCard = cards[idCard]

    let hashs = window.location.hash.split('/')
    hashs[0] = hashs[0].replace('#', '')
    if (hashs[0].length !== 36) {
        hashs[0] = null
    }

    let activeCards = {}

    Object.keys(db.cards).filter(a =>
        db.cards[a]
        && !db.cards[a].deleted
        && (
            db.cards[a]._planId === hashs[0]
            || db.cards[a]._parent === hashs[0]
        )
    ).forEach(a => {
        activeCards = {
            ...activeCards,
            [a]: db.cards[a]
        }
    })

    Object.keys(activeCards).forEach(card => {
        if (activeCards[card]
            && activeCards[card].type
            && activeCards[card].type.indexOf('guideline') === -1
            && activeCards[card].type.indexOf('objective') === -1
            && activeCards[card].type.indexOf('goal') === -1
        ) {
            activeCards[card] = {
                ...activeCards[card],
                _selected: false
            }
        }
    })
    if (selectedCard && selectedCard._aspects && selectedCard._aspects.length > 0) {
        selectedCard._aspects.forEach(as => {
            if (activeCards[as])
                activeCards[as] = {
                    ...activeCards[as],
                    _selected: true
                }
        })
    }
    props.reduxFunction("ASYNC", "SET_DB", {
        ...reduxStore.getState().db,
        cards: {
            ...cards,
            ...activeCards
        }
    })
}

const updateColumnCards = (props, data) => {
    if (reduxStore.getState().db.cards[data._cardColumn]) {
        let columnData = {
            ...reduxStore.getState().db.cards[data._cardColumn].boards[data._board].columns[data._columnId],
            ...data._columnData,
        }
        let columnOrder = [...reduxStore.getState().db.cards[data._cardColumn].boards[data._board].columnOrder]
        if (data._columnsOrder)
            columnOrder = data._columnsOrder

        props.reduxFunction("ASYNC", "SET_DB", {
            ...reduxStore.getState().db,
            cards: {
                ...reduxStore.getState().db.cards,
                [data._cardColumn]: {
                    ...reduxStore.getState().db.cards[data._cardColumn],
                    boards: {
                        ...reduxStore.getState().db.cards[data._cardColumn].boards,
                        [data._board]: {
                            ...reduxStore.getState().db.cards[data._cardColumn].boards[data._board],
                            columnOrder,
                            columns: {
                                ...reduxStore.getState().db.cards[data._cardColumn].boards[data._board].columns,
                                [data._columnId]: columnData
                            }
                        }
                    }
                }
            }
        })
    }
    selectSWOT(props)
}

const deleteColumn = (props, data) => {
    if (reduxStore.getState().db.cards[data._parent]) {
        let columnOrder = [...reduxStore.getState().db.cards[data._parent].boards[data._board].columnOrder]
        let columns = { ...reduxStore.getState().db.cards[data._parent].boards[data._board].columns }

        delete columns[data._deleted]
        columnOrder.splice(columnOrder.indexOf(data._deleted), 1)

        props.reduxFunction("ASYNC", "SET_DB", {
            ...reduxStore.getState().db,
            cards: {
                ...reduxStore.getState().db.cards,
                [data._parent]: {
                    ...reduxStore.getState().db.cards[data._parent],
                    boards: {
                        ...reduxStore.getState().db.cards[data._parent].boards,
                        [data._board]: {
                            ...reduxStore.getState().db.cards[data._parent].boards[data._board],
                            columnOrder,
                            columns
                        }
                    }
                }
            }
        })
    }
}

const reorderCard = (props, data) => {
    let cards = reduxStore.getState().db.cards
    let cardsToReorder = data.cards
    if (cardsToReorder && cardsToReorder.length > 0) {
        cardsToReorder.forEach((a, i) => {
            cards = {
                ...cards,
                [a]: {
                    ...cards[a],
                    order: `${i + 1}`
                }
            }
        })
        props.reduxFunction("ASYNC", "SET_DB", {
            ...reduxStore.getState().db,
            cards: {
                ...reduxStore.getState().db.cards,
                ...cards
            }
        })
    }
    return
}

const reorderColumns = (props, data) => {
    if (reduxStore.getState().db.cards[data._parent]) {
        let columnOrder = data._order

        props.reduxFunction("ASYNC", "SET_DB", {
            ...reduxStore.getState().db,
            cards: {
                ...reduxStore.getState().db.cards,
                [data._parent]: {
                    ...reduxStore.getState().db.cards[data._parent],
                    boards: {
                        ...reduxStore.getState().db.cards[data._parent].boards,
                        [data._board]: {
                            ...reduxStore.getState().db.cards[data._parent].boards[data._board],
                            columnOrder,
                        }
                    }
                }
            }
        })
    }
    return
}

const columnCards = (props, data) => {
    const { db } = reduxStore.getState()
    const newState = {
        [data.cardId]: {
            ...db.cards[data.cardId],
            boards: {
                ...db.cards[data.cardId].boards,
                [data.boardId]: {
                    ...db.cards[data.cardId].boards[data.boardId],
                    columns: data.columns
                }
            }
        }
    }
    props.reduxFunction("ASYNC", "SET_DB", {
        ...db,
        cards: {
            ...db.cards,
            ...newState
        }
    })
    return
}

const localAddCard = async (props, data, newCard) => {
    let createDate = moment().tz('America/Sao_Paulo').unix('x')

    let user = {
        ...reduxStore.getState().db.cards[data._id] && reduxStore.getState().db.cards[data._id]._users && reduxStore.getState().db.cards[data._id]._users[data.userId] ? reduxStore.getState().db.cards[data._id]._users[data.userId] : {},
        ...newCard && newCard.data && newCard.data._users && newCard.data._users[data.userId] ? newCard.data._users[data.userId] : {},
        myDay: !data.checklist && data.option ? createDate : null
    }

    props.reduxFunction("ASYNC", "SET_DB", {
        ...reduxStore.getState().db,
        cards: {
            ...reduxStore.getState().db.cards,
            [data._id]: {
                ...newCard && newCard.data ? newCard.data : {},
                ...reduxStore.getState().db.cards[data._id],
                _users: {
                    ...reduxStore.getState().db.cards[data._id] && reduxStore.getState().db.cards[data._id]._users ? reduxStore.getState().db.cards[data._id]._users : {},
                    ...newCard && newCard.data && newCard.data._users ? newCard.data._users : {},
                    [data.userId]: user
                },
            }
        }
    })
}

const myDayUpd = async (props, data) => {
    props.reduxFunction("ASYNC", "SET_DB", {
        ...reduxStore.getState().db,
        cards: {
            ...reduxStore.getState().db.cards,
            [data._id]: {
                ...reduxStore.getState().db.cards[data._id],
                _users: {
                    ...reduxStore.getState().db.cards[data._id] && reduxStore.getState().db.cards[data._id]._users ? reduxStore.getState().db.cards[data._id]._users : {},
                    [data.userId]: {
                        ...reduxStore.getState().db.cards[data._id] && reduxStore.getState().db.cards[data._id]._users && reduxStore.getState().db.cards[data._id]._users[data.userId] ? reduxStore.getState().db.cards[data._id]._users[data.userId] : {},
                        myDay: data.option ? data.createDate : null,
                    }
                }
            }
        }
    })
}

const setMyDay = async (props, data, newCard) => {

    let createDate = moment().unix('x')
    localAddCard(props, data, newCard)

    try {
        api.post('/cards/setMyDay', {
            ...data,
            createDate: createDate
        })

        if (reduxStore.getState().sideModule.id && reduxStore.getState().sideModule.id === data._id)
            props.reduxFunction("ASYNC", "SET_MODULE", {
                ...reduxStore.getState().sideModule,
                data: {
                    ...reduxStore.getState().sideModule.data ? reduxStore.getState().sideModule.data : {},
                    _users: {
                        ...reduxStore.getState().db.cards[data._id] && reduxStore.getState().db.cards[data._id]._users ? reduxStore.getState().db.cards[data._id]._users : {},
                        [data.userId]: {
                            ...reduxStore.getState().db.cards[data._id] && reduxStore.getState().db.cards[data._id]._users && reduxStore.getState().db.cards[data._id]._users[data.userId] ? reduxStore.getState().db.cards[data._id]._users[data.userId] : {},
                            myDay: data.option ? createDate : null
                        }
                    }
                }
            })
    } catch (e) {
        console.log(e)
    }
}

const cardCopyTo = async (props, data) => {
    try {
        const response = await api.put('/cards/copyTo', {
            ...data
        })

        if (response) {
            changeParentId(props, data)
            return true
        }
    } catch (e) {
        console.log(e)
        appAlert({
            message: `${translate("$__tryAgainOrContactSupport", 1)}. ERROR: cMoveTo->API~887`,
            variant: 'error',
            persist: false,
            horizontal: "right",
        })
    }
}

const cardMoveTo = async (props, data) => {

    try {
        const response = await api.put('/cards/moveTo', {
            ...data
        })

        if (response) {
            changeParentId(props, data)
            return true
        }
    } catch (e) {
        console.log(e)
        appAlert({
            message: `${translate("$__tryAgainOrContactSupport", 1)}. ERROR: cMoveTo->API~887`,
            variant: 'error',
            persist: false,
            horizontal: "right",
        })
    }
}

const changeParentId = async (props, data) => {
    let cards = reduxStore.getState().db.cards
    let changeId = await new Promise(async (resolve, reject) => {
        let rcc = await reqChildrenCards(data.cardId)
        console.log(rcc)
        if (data?.childrens?.length > 0) {
            rcc = [...rcc, ...data.childrens]
        }
        if (rcc && rcc.length > 0) {
            rcc.forEach((c, ci) => {
                if (cards[c]) {
                    cards[c] = {
                        ...cards[c],
                        _parent: data.newRel,
                        ...data.planId ? { _planId: data.planId } : {},
                    }
                }
                if (ci + 1 === rcc.length)
                    resolve(true)
            })
        } else {
            resolve(true)
        }

    })

    if (changeId) {
        if (cards[data.cardId])
            cards[data.cardId] = {
                ...cards[data.cardId],
                _parent: data.newRel,
            }
        props.reduxFunction("ASYNC", "SET_DB", {
            ...reduxStore.getState().db,
            cards: {
                ...reduxStore.getState().db.cards,
                ...cards
            }
        })
    }
}

const cardAccessUpdate = async (props, data) => {
    // ;.log(data)
    if (!reduxStore.getState().db.cards[data.cardId]) {
        const loadNewCard = await loadCardsV2(props, {
            idRel: data.cardId,
            cardLoad: "fullCatenation",
            ignoreLoader: true
        })
        if (loadNewCard) {
            let users = reduxStore.getState().db.cards[data.cardId] && reduxStore.getState().db.cards[data.cardId]._users ? reduxStore.getState().db.cards[data.cardId]._users : {}
            if (data.isResponsible)
                Object.keys(users).forEach(u => {
                    if (u !== data.userId && users[u].isResponsible)
                        delete users[u].isResponsible
                })

            let newCard = reduxStore.getState().db.cards[data.cardId] ? reduxStore.getState().db.cards[data.cardId] : {}
            if (loadNewCard && loadNewCard.cards && loadNewCard.cards[data.cardId])
                newCard = loadNewCard.cards[data.cardId]
            props.reduxFunction("ASYNC", "SET_DB", {
                ...reduxStore.getState().db,
                cards: {
                    ...reduxStore.getState().db.cards,
                    [data.cardId]: {
                        ...newCard,
                        _users: {
                            ...users,
                            [data.userId]: {
                                ...data.userData ? data.userData : {},
                                ...reduxStore.getState().db.users && reduxStore.getState().db.users[data.userId] ? reduxStore.getState().db.users[data.userId] : {},
                                ...newCard && newCard._users && newCard._users[data.userId] ? newCard._users[data.userId] : {},
                                ...data.isResponsible ? { isResponsible: true } : {},
                                ...String(data.approvalResponsible) ? { approvalResponsible: data.approvalResponsible } : { approvalResponsible: data.approvalResponsible },
                                ...data.access ? { access: data.access } : {},
                            }
                        }
                    }
                }
            })
        }
    } else {
        let users = reduxStore.getState().db.cards[data.cardId] && reduxStore.getState().db.cards[data.cardId]._users ? reduxStore.getState().db.cards[data.cardId]._users : {}
        if (data.isResponsible)
            Object.keys(users).forEach(u => {
                if (u !== data.userId && users[u].isResponsible)
                    delete users[u].isResponsible
            })

        props.reduxFunction("ASYNC", "SET_DB", {
            ...reduxStore.getState().db,
            cards: {
                ...reduxStore.getState().db.cards,
                [data.cardId]: {
                    ...reduxStore.getState().db.cards[data.cardId] ? reduxStore.getState().db.cards[data.cardId] : {},
                    _users: {
                        ...users,
                        [data.userId]: {
                            ...reduxStore.getState().db.cards[data.cardId] && reduxStore.getState().db.cards[data.cardId]._users && reduxStore.getState().db.cards[data.cardId]._users[data.userId] ? reduxStore.getState().db.cards[data.cardId]._users[data.userId] : {},
                            ...users[data.userId] ? users[data.userId] : {},
                            ...data.userData ? data.userData : {},
                            ...data.isResponsible ? { isResponsible: true } : {},
                            ...String(data.approvalResponsible) ? { approvalResponsible: data.approvalResponsible } : { approvalResponsible: data.approvalResponsible },
                            ...data.access ? { access: data.access } : {},
                        }
                    }
                }
            }
        })
    }
}

const cardAccessDelete = (props, data) => {
    if (!reduxStore.getState().db.cards[data.cardId]) {
        return true
    } else {
        let newCard = { ...reduxStore.getState().db.cards[data.cardId] }
        if (newCard._users[data.userId])
            delete newCard._users[data.userId]

        props.reduxFunction("ASYNC", "SET_DB", {
            ...reduxStore.getState().db,
            cards: {
                ...reduxStore.getState().db.cards,
                [data.cardId]: {
                    ...newCard,
                }
            }
        })
    }
}
const cardAccessRemove = (props, data) => {
    if (!reduxStore.getState().db.cards[data.cardId]) {
        return true
    } else {
        let newCard = { ...reduxStore.getState().db.cards[data.cardId] }
        if (data.userIds && data.userIds.length > 0)
            data.userIds.forEach(userId => {
                if (newCard._users[userId])
                    delete newCard._users[userId]
            })


        props.reduxFunction("ASYNC", "SET_DB", {
            ...reduxStore.getState().db,
            cards: {
                ...reduxStore.getState().db.cards,
                [data.cardId]: {
                    ...newCard,
                }
            }
        })
    }
}

const unlinkChildrens = async (props, data) => {
    try {
        api.put("cards/unlinkChildrens", {
            cardId: data.cardId,
            planId: data._planId
        })
        // socketUnLinkChildrens(props, {
        //     cardId: data.cardId,
        //     planId: data._planId
        // })
    } catch (e) {
        console.log(e)
    }
    return true
}
const socketUnLinkChildrens = async (props, data) => {
    let cards = reduxStore.getState().db.cards
    Object.keys(cards).filter(card =>
        cards[card]._parent === data.cardId
        && cards[card].type !== "step"
        && (
            !cards[card].checklist
            ||
            (
                cards[card].checklist
                && cards[card].showCard
            )
        )).forEach(card => {
            cards[card]._parent = data.planId
        }
        )
    if (data.cards && data.cards.length > 0)
        data.cards.forEach(cardId => {
            if (cards[cardId])
                cards[cardId] = {
                    ...cards[cardId],
                    _parent: data.planId
                }
        })

    if (cards && cards[data.cardId])
        cards[data.cardId] = {
            ...cards[data.cardId],
            _parent: data.planId
        }
    props.reduxFunction("ASYNC", "SET_DB", {
        ...reduxStore.getState().db,
        cards: cards
    })
}

const getCardColor = (data, children) => {
    let color = data && data.color ? data.color : null
    let parent = data.color && children ? true : false
    if (data._parent && reduxStore.getState().db.cards[data._parent]) {
        let parentColor = getCardColor(reduxStore.getState().db.cards[data._parent], true)
        if (parentColor.color)
            return parentColor
    }
    return {
        parent,
        color,
        parentId: data.color ? data._id : null
    }
}
const getCardParents = (parent) => {
    let parents = []
    if (parent && reduxStore.getState().db.cards[parent]) {
        let req = getCardParents(parent)
        req.forEach(x => {
            if (parents.indexOf(x) === -1)
                parents.push(x)
        })
        if (parents.indexOf(parent) === -1)
            parents.push(parent)
    }
    return parents
}
const connectInMount = (parentId) => {
    const { db, session } = reduxStore.getState()
    let parent = reduxStore.getState().db.cards[parentId]
    if (reduxStore.getState().db.cards[parentId]) {
        let result = {}
        if (
            !reduxStore.getState().db.cards[parentId].private
            ||
            (
                reduxStore.getState().db.cards[parentId].private
                && reduxStore.getState().db.cards[parentId]._users
                && reduxStore.getState().db.cards[parentId]._users[session._id]
            )
        )
            result = {
                [parentId]: {
                    _id: parent._id,
                    type: parent.type,
                    name: parent.name,
                    icon: parent.type && db && db.dataType ? db.dataType.filter(a => a.name === parent.type)[0].icon : "topic",
                },
            }
        if (parent._parent) {
            result = { ...connectInMount(parent._parent), ...result }
        }
        return result
    } else {
        return {}
    }
}

const linkCard = async (props, data) => {
    let socket
    if (props && props.store && reduxStore.getState().functions && reduxStore.getState().functions.socket) {
        socket = reduxStore.getState().functions.socket
    } else {
        socket = reduxStore.getState().functions.socket
    }
    socket.emit("data", {
        module: "cards",
        method: "put",
        action: "link"
    }, data, async (response) => {
    })
}

const sortList = (a, b) => {
    if (reduxStore.getState().db.orderByTitle
        && reduxStore.getState().db.orderByTitlePosition === 'ASC') {
        if (removeSeats(a.name.toLowerCase().trim()) < removeSeats(b.name.toLowerCase().trim()))
            return -1
        if (removeSeats(a.name.toLowerCase().trim()) > removeSeats(b.name.toLowerCase().trim()))
            return 1
        return 0
    }
    if (reduxStore.getState().db.orderByTitle
        && reduxStore.getState().db.orderByTitlePosition === 'DESC') {
        if (removeSeats(a.name.toLowerCase().trim()) < removeSeats(b.name.toLowerCase().trim()))
            return 1
        if (removeSeats(a.name.toLowerCase().trim()) > removeSeats(b.name.toLowerCase().trim()))
            return -1
        return 0
    }
    if (reduxStore.getState().db.orderByTitle) {
        return 0
    } else if (
        !reduxStore.getState().db.orderByTitle
        && a.order
        && b.order
        && a.order.low
        && b.order.low
        && String(a.order)
        && String(b.order)
        && String(a.order.low)
        && String(b.order.low)
        && parseInt(a.order.low) < parseInt(b.order.low)
    ) {
        return -1
    } else if (
        !reduxStore.getState().db.orderByTitle
        && a.order
        && b.order
        && String(a.order)
        && String(b.order)
        && a.order.low
        && b.order.low
        && String(a.order.low)
        && String(b.order.low)
        && parseInt(a.order.low) > parseInt(b.order.low)
    ) {
        return 1
    } else {
        return 0
    }
}

const getUserAccess = (id) => {
    const { db, session } = reduxStore.getState()
    let data = { ...db.cards[id] }
    if (db.cards[id]) {
        let cardAccess = 0
        if (data
            && data._users
            && Object.keys(data._users).length > 0
            && data._users[Object.keys(data._users).find(a => a.indexOf(session._id) > -1)]
            && data._users[Object.keys(data._users).find(a => a.indexOf(session._id) > -1)].access) {
            cardAccess = parseInt(data._users[Object.keys(data._users).find(a => a.indexOf(session._id) > -1)].access)
        }

        if (data && data._parent
            && db.cards[data._parent]
            && db.cards[data._parent]._users
            && Object.keys(db.cards[data._parent]._users).length > 0
            && db.cards[data._parent]._users[Object.keys(db.cards[data._parent]._users).find(a => a.indexOf(session._id) > -1)]
            && db.cards[data._parent]._users[Object.keys(db.cards[data._parent]._users).find(a => a.indexOf(session._id) > -1)].access
            && parseInt(db.cards[data._parent]._users[Object.keys(db.cards[data._parent]._users).find(a => a.indexOf(session._id) > -1)].access) > parseInt(cardAccess)
        ) {
            cardAccess = parseInt(db.cards[data._parent]._users[Object.keys(db.cards[data._parent]._users).find(a => a.indexOf(session._id) > -1)].access)
        }

        if (
            db.cards[id]._planId
            && db.cards[db.cards[id]._planId]
            && db.cards[db.cards[id]._planId]._users
            && Object.keys(db.cards[db.cards[id]._planId]._users).length > 0
            && db.cards[db.cards[id]._planId]._users[Object.keys(db.cards[db.cards[id]._planId]._users).find(a => a.indexOf(session._id) > -1)]
            && parseInt(db.cards[db.cards[id]._planId]._users[Object.keys(db.cards[db.cards[id]._planId]._users).find(a => a.indexOf(session._id) > -1)].access) > parseInt(cardAccess)
        ) {
            cardAccess = parseInt(db.cards[db.cards[id]._planId]._users[Object.keys(db.cards[db.cards[id]._planId]._users).find(a => a.indexOf(session._id) > -1)].access)
        }
        return cardAccess
    }
    return 0
}

const getUserList = (cardId, options = {}) => {
    const { db, session } = reduxStore.getState()
    let data = {
        ...cardId ? db.cards[cardId] : {}
    }

    let userList = {}

    if (options.allPlataform) {
        Object.keys(db.users).filter(u => !db.users[u].deleted && db.users[u].type === "user").forEach(u => {
            let type = "user"
            if (
                !db.users[u]
                ||
                (
                    db.users[u]
                    && db.users[u]._parent
                    && db.users[db.users[u]._parent]
                    && db.users[u]._parent !== session.account
                )
            ) type = "external"

            if (
                !db.users[u]
                ||
                (
                    db.users[u]
                    && db.users[u]._parent
                    && !db.users[db.users[u]._parent]
                )
            ) type = "consultant"

            userList = {
                ...userList,
                [u]: {
                    ...db.users[u] || {},
                    _id: u,
                    type: type,
                }
            }
        })
        return userList
    }
    if (!options?.noInternal)
        Object.keys(db.users).filter(u => db.users[u].type === "user" && db.users[u]._parent === session._account && !db.users[u].deleted).forEach(u => {
            userList = {
                ...userList,
                [u]: {
                    ...db.users[u],
                    _id: u,
                    type: "user",
                }
            }
        })
    if (data?.client && db.users[data.client])
        Object.keys(db.users).filter(u =>
            db.users[u]._parent === data.client
            && !db.users[u].deleted
        ).forEach(u => {
            userList = {
                ...userList,
                [u]: {
                    ...db.users[u],
                    _id: u,
                    type: "external",
                    ...db.users[u]._parent ? { _parent: db.users[u]._parent } : {},
                }
            }
        })

    if (data?._planId && db.cards[data._planId]?.client && db.users[db.cards[data._planId].client])
        Object.keys(db.users).filter(u =>
            db.users[u]._parent === db.cards[data._planId]?.client
            && !db.users[u].deleted
        ).forEach(u => {
            userList = {
                ...userList,
                [u]: {
                    ...db.users[u],
                    _id: u,
                    type: "external",
                    ...db.users[u]._parent ? { _parent: db.users[u]._parent } : {},
                }
            }
        })
    if (data?._users)
        Object.keys(data._users).forEach(u => {
            if (u !== session._id) {
                let type = "user"
                if (
                    !db.users[u]
                    ||
                    (
                        db.users[u]
                        && db.users[u]._parent
                        && db.users[db.users[u]._parent]
                        && db.users[u]._parent !== session.account
                    )
                ) type = "external"

                if (
                    !db.users[u]
                    ||
                    (
                        db.users[u]
                        && db.users[u]._parent
                        && !db.users[db.users[u]._parent]
                    )
                ) type = "consultant"

                userList = {
                    ...userList,
                    [u]: {
                        ...data._users[u],
                        _id: u,
                        type: type,
                        ...data._users[u]._parent ? { _parent: data._users[u]._parent } : {}
                    }
                }
            }
        })

    if (options && !options.onlyCard) {
        if (
            !options.noPlan
            && data._planId
            && db.cards[data._planId]?._users[session._id]
        ) {
            userList = {
                ...userList,
                ...getUserList(data._planId, { noPlan: true, noInternal: true }),
            }
        }
        if (
            data._parent
            && db.cards[data._parent]?._users[session._id]
        ) {
            userList = {
                ...userList,
                ...getUserList(data._parent, { noPlan: true, noInternal: true }),
            }
        }
    }
    if (options?.isArray) {
        let result = []
        Object.keys(userList).forEach(a => {
            if (result.filter(us => us._id === a).length === 0)
                result.push(userList[a])
        })
        return result
    }
    return userList
}

const verifyTemplate = (id, firstId = null) => {
    if (id === firstId)
        return false
    if (!id)
        return false

    let isTemplate = false

    let card = reduxStore.getState().db.cards[id] ? reduxStore.getState().db.cards[id] : null

    if (!card)
        return false
    if (card && card.isTemplate) {
        isTemplate = true
    } else if (card && card._planId && card._planId !== card._id && reduxStore.getState().db.cards[card._planId]) {
        isTemplate = verifyTemplate(card._planId, firstId ? firstId : id)
    } else if (card && card._parent && reduxStore.getState().db.cards[card._parent]) {
        isTemplate = verifyTemplate(card._parent, firstId ? firstId : id)
    }
    return isTemplate
}

const copyCards = async (from, to, options = {}) => {
    const response = new Promise(async (resolve, reject) => {
        const apiConn = await api.post('/cards/copy', {
            from,
            to,
            options,
        })
        if (apiConn)
            resolve(true)
    })

    const result = await response
    return result
}

const getChildrens = (id) => {
    const { cards } = reduxStore.getState().db
    let result = {
        [id]: true
    }
    Object.keys(cards).filter(c => cards[c]._parent === id).map(async (c, ci) => {
        let child = getChildrens(c)
        if (child) {
            result = {
                ...result,
                ...child,
            }
        }
    })
    return result
}

const updateCardStatus = async (props, d) => {
    let data = props.data && props.data._id ? reduxStore.getState().db.cards[props.data._id] : {}

    let socket
    if (props && props.store && reduxStore.getState().functions && reduxStore.getState().functions.socket) {
        socket = reduxStore.getState().functions.socket
    } else {
        socket = reduxStore.getState().functions.socket
    }

    let dataUpdate = d
    let localUpdate = dataUpdate
    if (dataUpdate.status === "completed") {
        localUpdate = {
            ...localUpdate,
            completed_at: {
                low: moment().unix("X")
            }
        }
    } else {
        dataUpdate = {
            ...localUpdate,
            completed_at: null
        }
    }

    try {
        socket.emit("data", {
            module: "cards",
            method: "put",
            action: "status"
        }, {
            _id: data._id,
            ...dataUpdate
        }, async () => {
            props.reduxFunction("ASYNC", "SET_DB", {
                ...reduxStore.getState().db,
                cards: {
                    ...reduxStore.getState().db.cards,
                    [data._id]: {
                        ...reduxStore.getState().db.cards[data._id],
                        ...localUpdate,
                    },
                }
            })

        })
    } catch (e) {
        console.log(e)
    }
}

//2022Ago
export const updateCardData = async (props, d) => {
    let data = props.data && props.data._id ? reduxStore.getState().db.cards[props.data._id] : {}

    let socket
    if (props && props.store && reduxStore.getState().functions && reduxStore.getState().functions.socket) {
        socket = reduxStore.getState().functions.socket
    } else {
        socket = reduxStore.getState().functions.socket
    }

    let dataUpdate = d
    let localUpdate = dataUpdate
    if (dataUpdate.status === "completed") {
        localUpdate = {
            ...localUpdate,
            completed_at: {
                low: moment().unix("X")
            }
        }
    } else if (dataUpdate.status) {
        localUpdate = {
            ...localUpdate,
            completed_at: {
                low: null
            }
        }
    }
    props.reduxFunction("ASYNC", "SET_DB", {
        ...reduxStore.getState().db,
        cards: {
            ...reduxStore.getState().db.cards,
            [data._id]: {
                ...reduxStore.getState().db.cards[data._id],
                ...localUpdate,
            },
        }
    })
    try {
        socket.emit("data", {
            module: "cards",
            method: "put",
            action: "update220527"
        }, {
            _id: data._id,
            ...dataUpdate
        })
    } catch (e) {
        console.log(e)
    }
}

//2023Fev @@@REVIEW_CODE
export const saveCardData = async (props, d) => {
    let data = props.data && props.data._id ? reduxStore.getState().db.cards[props.data._id] : {}

    let dataUpdate = d
    let localUpdate = dataUpdate
    if (dataUpdate.status === "completed") {
        localUpdate = {
            ...localUpdate,
            completed_at: {
                low: moment().unix("X")
            }
        }
    } else if (dataUpdate.status) {
        localUpdate = {
            ...localUpdate,
            completed_at: {
                low: null
            }
        }
    }
    props.reduxFunction("ASYNC", "SET_DB", {
        ...reduxStore.getState().db,
        cards: {
            ...reduxStore.getState().db.cards,
            [data._id]: {
                ...reduxStore.getState().db.cards[data._id],
                ...localUpdate,
            },
        }
    })
    try {
        api.put("/cards/update", dataUpdate)
    } catch (e) {
        console.log(e)
    }
}

const reqChildrenCards = async (cardId, status = null, fid) => {
    let cards = reduxStore.getState().db.cards
    let childrenCards = []
    let chields = Object.keys(cards)
        .filter(a =>
            cards[a]
            && cards[a]._parent
            && (
                cards[a]._parent === cardId
                ||
                cards[a]._parent === fid
            )
            && (
                !status
                ||
                (
                    status === 'completed'
                    && cards[a].status !== "completed"
                )
                ||
                (
                    status !== 'completed'
                    && cards[a]._parentCompleted
                    && cards[a]._parentCompleted.cardId
                    && (
                        cards[a]._parentCompleted.cardId === cardId
                        ||
                        cards[a]._parentCompleted.cardId === fid
                    )
                )
            )
        )
    if (chields.length > 0) {
        let load = await new Promise((resolve, reject) => {
            chields.forEach(async (a, ai) => {
                let ch = await reqChildrenCards(a, status, cardId)
                if (childrenCards.indexOf(a) === -1) {
                    childrenCards.push(a)
                }
                if (ch && ch.length > 0) {
                    ch.forEach((xx, xxi) => {
                        if (childrenCards.indexOf(xx) === -1) {
                            childrenCards.push(xx)
                        }
                        if (xxi + 1 === ch.length && ai + 1 === chields.length)
                            resolve(true)
                    })
                } else {
                    resolve(true)
                }

            })
        })
        if (load) {
            return childrenCards
        }
    } else {
        return []
    }
}

const cardStatus = async (props, parentId, status) => {
    let childrens = await reqChildrenCards(parentId, status)
    let cards = reduxStore.getState().db.cards
    if (childrens && childrens.length > 0)
        childrens.forEach(cc => {
            cards[cc] = {
                ...cards[cc],
                status: status,
                completed_at: status === 'completed' ? {
                    low:
                        moment().unix("X")
                } : null,
                ...status === 'completed' ? {
                    _parentCompleted: {
                        cardId: parentId,
                        completed_at: {
                            low: moment().unix("X")
                        }
                    }
                } : { _parentCompleted: null }
            }
        })

    cards[parentId] = {
        ...cards[parentId],
        status: status,
        completed_at: status === 'completed' ? {
            low:
                moment().unix("X")
        } : null,
    }

    props.reduxFunction("ASYNC", "SET_DB", {
        ...reduxStore.getState().db,
        cards: cards
    })

}

const countNotifications = (nodeId, debug = false, my = false) => {
    const { session } = reduxStore.getState()
    let notifications = reduxStore.getState().db.cards[nodeId] && reduxStore.getState().db.cards[nodeId]._notifications ? reduxStore.getState().db.cards[nodeId]._notifications : {}

    Object.keys(notifications).map(t => notifications[t] = parseInt(notifications[t]))
    let newTotal = 0

    Object.keys(notifications).filter(t => t !== 'total').forEach(t => {
        if (
            reduxStore.getState().db.cards[nodeId]
            && reduxStore.getState().db.cards[nodeId]._users
            && reduxStore.getState().db.cards[nodeId]._users[session._id]
            && reduxStore.getState().timeline[nodeId]
            && parseInt(notifications[t])
            < Object.keys(reduxStore.getState().timeline[nodeId]).filter(m =>
                reduxStore.getState().timeline[nodeId][m]
                && !reduxStore.getState().timeline[nodeId][m].deleted
                && reduxStore.getState().timeline[nodeId][m].type
                && reduxStore.getState().timeline[nodeId][m].type === t
            ).length
        ) {
            notifications[t] = reduxStore.getState().timeline &&
                reduxStore.getState().timeline[nodeId] &&
                Object.keys(reduxStore.getState().timeline[nodeId]).length > 0 ?
                Object.keys(reduxStore.getState().timeline[nodeId]).filter(tm => {
                    if (session &&
                        session._id &&
                        parseInt(reduxStore.getState().timeline[nodeId][tm].created_at) > 1650666142
                        && !reduxStore.getState().timeline[nodeId][tm].deleted
                        && reduxStore.getState().timeline[nodeId][tm].type === t
                        && reduxStore.getState().timeline[nodeId][tm].user
                        && reduxStore.getState().timeline[nodeId][tm].user._id !== session._id
                        &&
                        (
                            !reduxStore.getState().timeline[nodeId][tm].readedBy
                            ||
                            (
                                reduxStore.getState().timeline[nodeId][tm].readedBy &&
                                !reduxStore.getState().timeline[nodeId][tm].readedBy[session._id]
                            )
                        )
                    )
                        return true
                    return false
                }
                ).length : 0
            newTotal = newTotal + parseInt(notifications[t])
        } else {
            notifications[t] = parseInt(notifications[t])
            newTotal = newTotal + parseInt(notifications[t])
        }
    })

    return {
        ...notifications,
        total: newTotal
    }
}

const planAccountVerify = (plan) => {
    const { session, db } = reduxStore.getState()
    if (
        plan
        && plan.length === 36
        && db.cards[plan]
        && !db.cards[plan].isTemplate
        && (
            db.cards[plan]._users
            && (
                !db.cards[plan]._users[session._id]
                && (
                    db.cards[plan]._account
                    && db.cards[plan]._account !== MD5(session.account).toString()
                )
            )
        )
    )
        return false
    return true
}

const planMyAccountVerify = (plan) => {
    const { session, db } = reduxStore.getState()
    if (
        plan
        && plan.length === 36
        && db.cards[plan]
        && !db.cards[plan].isTemplate
        && (
            db.cards[plan]._account
            && db.cards[plan]._account !== MD5(session.account).toString()
        )
    )
        return false
    return true
}

const setCompleted = async (props, data) => {
    const countChildrens = await apims.post("/Card_Count", { id: data._id })
    if (countChildrens && parseInt(countChildrens.data) > 0 && data.status !== 'completed') {
        appAlert({
            message: translate('$__concludeWithChildrensNotConcludedConfirm', 1),
            autoClose: 50000,
            buttons: [
                {
                    shadow: true,
                    title: translate("$__concludeAll"),
                    text: translate("$__concludeAll"),
                    color: "green",
                    icon: "close",
                    size: "25px",
                    onClick: () => {
                        updateCardData(
                            {
                                ...props,
                                data: { _id: data._id }
                            }, { status: data.status === 'completed' ? 'inProgress' : 'completed' })
                        if (countChildrens && parseInt(countChildrens.data) > 0)
                            cardStatus(props, data._id, data.status === 'completed' ? 'inProgress' : 'completed')
                    },
                    style: { marginLeft: 15 }
                },
                {
                    shadow: true,
                    title: translate("$__cancel"),
                    text: translate("$__cancel"),
                    color: "red",
                    icon: "close",
                    size: "25px",
                    onClick: () => {
                        // toast.dismiss(key)
                    },
                    style: { marginLeft: 15 }
                }],
            variant: 'warning',
            persist: false,
            horizontal: 'right',
        })
    } else {
        updateCardData(
            {
                ...props,
                data: { _id: data._id }
            }, { status: data.status === 'completed' ? 'inProgress' : 'completed' })
        if (countChildrens && parseInt(countChildrens.data) > 0) {
            cardStatus(props, data._id, data.status === 'completed' ? 'inProgress' : 'completed')
        }
    }
}

export const privateVerify = (id) => {
    const { session, db } = reduxStore.getState()
    const card = db.cards[id] || {}
    if (card && card.private && card._users[session._id])
        return true
    return false
}

export const setCompletedStep = async (id) => {
    return false
}

export const filterCards = (data, search) => {
    let filtered = Array.from(data)
    const { db } = reduxStore.getState()

    if (search) {
        if (search.startDate || search.endDate)
            filtered = filtered.filter(fil => {
                if (
                    fil

                    && (
                        (
                            search.startDate
                            && fil.startDate
                            && fil.startDate >= search.startDate
                        )
                        && (
                            search.endDate
                            && fil.endDate
                            && fil.endDate <= search.endDate
                        )
                    ))
                    return true
                return false
            }).map(res => res)

        if (search.text)
            filtered = filtered.filter(fil => {
                if (fil && fil.name && fil.name.toLowerCase().indexOf(search.text.toLowerCase()) > -1)
                    return true
                return false
            }).map(res => res)

        if (search.status)
            filtered = filtered.filter(fil => {
                if (!fil.status || (fil && fil.status && search.status && search.status[fil.status]))
                    return true
                return false
            }).map(res => res)

        if (search.priority)
            filtered = filtered.filter(fil => {
                if ((fil && fil.priority && search.priority[fil.priority]) || (search.priority['null'] && !fil.priority))
                    return true
                return false
            }).map(res => res)

        if (search.users && search.users.length > 0)
            filtered = filtered.filter(fil => {
                if (fil && fil._users && Object.keys(fil._users).filter(uf => search.users.indexOf(uf) > -1).length > 0)
                    return true
                return false
            }).map(res => res)

        if (search.responsible && search.responsible.length > 0)
            filtered = filtered.filter(fil => {
                if (fil && fil._users && Object.keys(fil._users).filter(uf => {
                    if (
                        (fil._users[uf].isResponsible
                            && search.responsible.indexOf(uf) > -1
                        )
                        ||
                        (
                            search.responsible.indexOf(uf) > -1
                            && !fil._users[uf].isResponsible
                            && Object.keys(fil._users).length === 1
                        )

                    ) return true
                    return false
                }).length > 0)
                    return true
                return false
            }).map(res => res)

        if (search.units && search.units.length > 0) {
            if (search.units.indexOf("all") > -1) {
                filtered = filtered.filter(fil => {
                    if (!fil._planId || (
                        fil._planId
                        && db.cards[fil._planId]
                        && !db.cards[fil._planId].client
                    ))
                        return true
                    return false
                }).map(res => res)
            } else {
                filtered = filtered.filter(fil => {
                    let result = false
                    Object.keys(db.cards).filter(plan => {
                        if (db.cards[plan].client && search.units.indexOf(db.cards[plan].client) > -1)
                            return true
                        return false
                    }).forEach(plan => {
                        if (fil._planId && fil._planId === db.cards[plan]._id)
                            result = true
                    })
                    return result
                }).map(res => res)
            }
        }

        if (search.groups && search.groups.length > 0) {
            if (search.groups.indexOf("ALL") > -1) {
                filtered = filtered.filter(fil => {
                    if (!fil._planId || (
                        fil._planId
                        && db.cards[fil._planId]
                        && !db.cards[fil._planId].client
                    ))
                        return true
                    return false
                }).map(res => res)
            } else {
                filtered = filtered.filter(fil => {
                    let result = false
                    Object.keys(db.cards).filter(plan => {
                        if (db.cards[plan].client && search.groups.indexOf(db.cards[plan].client) > -1)
                            return true
                        return false
                    }).forEach(plan => {
                        if (fil._planId && fil._planId === db.cards[plan]._id)
                            result = true
                    })
                    return result
                }).map(res => res)
            }
        }

        if (search.plans && search.plans.length > 0) {
            filtered = filtered.filter(fil => {
                if (search.plans.indexOf(fil._planId) > -1) return true
                return false
            }).map(res => res)
        }

        if (search.tags && search.tags.length > 0) {
            filtered = filtered.filter(fil =>
                fil._tags && fil._tags.length > 0 && fil._tags.filter(tag => {
                    let result = false
                    search.tags.forEach(tagFilter => {
                        if (tagFilter.value === tag.value)
                            result = true
                    })
                    return result
                }
                ).length > 0
            ).map(res => res)
        }

        if (search && search.tagsByText) {
            filtered = filtered.filter(fil =>
                fil._tags && fil._tags.length > 0 && fil._tags.filter(tag =>
                    tag.label
                    && tag.label.indexOf(search.tagsByText) > -1
                ).length > 0
            ).map(res => res)
        }

    }//FILTER

    return filtered
}

export {
    cardAccessDelete,
    cardAccessRemove,
    cardAccessUpdate,
    cardCopyTo,
    cardMoveTo,
    cardStatus,
    changeParentId,
    columnCards,
    connectInMount,
    copyCards,
    countNotifications,
    dbUpdateCardSWOT,
    dbUpdateCardTimer,
    dbUpdateCard_V2,
    deleteCard,
    deleteColumn,
    getCardColor,
    getCardParents,
    getChildrens,
    getUserAccess,
    getUserList,
    kpiTimerDelete,
    kpiTimerStart,
    kpiTimerStop,
    linkCard,
    localAddCard,
    massUpdate,
    myDayUpd,
    planAccountVerify,
    planMyAccountVerify,
    reorderCard,
    reorderColumns,
    restoreCard,
    saveCard,
    saveCard_V2,
    saveMATRIX,
    selectSWOT,
    setCompleted,
    setMyDay,
    socketUnLinkChildrens,
    sortList,
    unlinkChildrens,
    updateCard,
    updateCardv2,
    updateCardStatus,
    updateColumnCards,
    verifyTemplate,
}