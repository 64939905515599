import React, { useEffect, useState } from "react";
import styled from "styled-components"

//COMPONENTES
import Icon from "components/Icon";
import Progress from "components/Progress/circular";

//FUNCTIONS
import { customApp, translate, getDate } from "functions";

//APIS
import apims from "apims"

const Historic = () => {
  const [loader, setLoader] = useState(true)
  const [approvalList, setApprovalList] = useState([])

  useEffect(() => {
    const init = async () => {
      const Approval = await apims.post('/Approval_List')
      if (Approval) {
        setLoader(false)
        setApprovalList(Approval.data)
      }
    }
    init()
    // eslint-disable-next-line
  }, []);


  if (loader)
    return (
      <div style={{
        position: "relative",
        float: "left",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        paddintTop: "40%"
      }}>
        <Progress />
      </div>
    )

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "auto",
          boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
          backgroundColor: "#fff",
          borderRadius: "3px",
          fontFamily: "Soleto, Roboto, Helvetica, Arial, sans-serif",
          marginBottom: "50px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "5px",
            justifyContent: "center",
            borderBottom: "#e8e8e8 1px solid",
            marginBottom: "15px",
            padding: "7px 0px",
            color: customApp("colorText"),
            background: customApp("ColumnTitleColor"),
          }}
        >
          <Icon style={{ color: customApp('color') }}>history</Icon>
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            {translate('$__approvalHistory')}
          </span>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ width: "100%", grid: "15px", padding: 15 }}>
            {approvalList
              .sort((a, b) => {
                if (a.date > b.date)
                  return -1
                if (a.date < b.date)
                  return 1
                return 0
              })
              .map((al, ali) => {
                console.log(al)
                return (<ApprovalItem key={al.timeline._id} approved={al.timeline.message.indexOf("$__approved") > -1 ? true : false}>

                  <div className={"cardTitle"}><div className={"cardApprovalDate"}>{getDate(al.date)}</div>{al.card} <div className={"item"}>(#{al.code})</div></div>
                  <div className={"local"}>
                    {al.local.reverse().map((lc, i) => <div className="cardApprovalLocal" key={`lc${lc.code}`}>{lc.name}{i + 1 < al.local.length ? ` / ` : ``}</div>)}
                  </div>
                  <div className={"cardApprovalStep"}>
                    <div>{translate("$__step", 1)}: {al.approvedIn}</div>
                    <div className={"cardApprovalReason"}>
                      <div style={{ position: "relative", width: "100%", display: "flex" }}>
                        {al.timeline.message.indexOf("$__approved") > -1 ?
                          <Icon icon="verified" color={"green"} />
                          : al.timeline.message.indexOf("$__rejected") > -1 ?
                            <Icon icon="gpp_bad" color={"red"} />
                            : <React.Fragment></React.Fragment>
                        }
                        <div style={{ wordBreak: "break-word", width: "100%", clear: "both", marginRight: 15 }}>
                          {translate(al.timeline.message)}
                        </div>
                        <div style={{ clear: "both" }}></div>
                      </div>
                    </div>
                  </div>

                </ApprovalItem>)
              })}

          </div>
          {/* <div
            style={{
              height: "100%",
              borderLeft: "1px solid #b9b9b9",
              padding: "0px 20px",
            }}
          >
            <TextField
              id={`search`}
              label={translate("$__search", 1)}
              variant={customApp("fieldVariant")}
              name={"search"}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon>search</Icon>
                  </InputAdornment>
                ),
              }}
              placeholder={translate("$__search", 1)}
              autoFocus
            />
            {plans?.map((plan) => (
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                }}
                key={plan}
              >
                <Avatar>{plan.slice(0, 2)}</Avatar>
                <FormControlLabel
                  required
                  control={<Checkbox color="default" />}
                  label={plan}
                />
              </div>
            ))}
          </div> */}
        </div>
      </div>
    </>
  );
};

const ApprovalItem = styled.div`
  position:relative;
  float:left;
  width:100%;  
  border-radius:7px;
  box-shadow:rgba(0,0,0,0.1) 0px 0px 3px 1px;
  padding:7px;
  grid:15px;
  align-items:center;
  margin-bottom:15px;
  ${props => `
  
  `}
  .cardApprovalDate{
    position:relative;
    float:left;
    font-size:12px;
    font-weight:regular;
    color:#979797;
    display:flex;
    margin-right:15px;
  }
  .cardTitle{
    position:relative;
    float:left;
    font-size:16px;
    font-weight:bold;
    display:flex;
  }
  .cardApprovalStep{
    position:relative;
    float:right;
    font-size:14px;
    font-weight:bold;
    text-align:right;
    ${props => `
    color:${props.approved ? `green` : `red`}
    `}
  }
  .cardApprovalLocal{
    position:relative;
    float:left;
    font-size:12px;
    color:#979797;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-left:15px;
  }
  .cardApprovalReason{
    position:relative;
    float:left;
    clear:both;
    width:100%;
    font-size:13px;
    color:#979797;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-left:15px;
  }
  
`;

export default Historic;
