import MD5 from "crypto-js/md5";
import moment from "moment-timezone";

// REDUX
import reduxStore from "store/";

// API
import apims from "apims";

/**
 * Atualiza o status de load no Redux sem duplicar código.
 * @param {Object} props - Propriedades do componente (inclui reduxFunction).
 * @param {string} key - Chave identificadora (geralmente um MD5 do request).
 * @param {string} status - Status a ser definido ("loading", "completed", "errored", etc.).
 */
function setLoadStatus(props, key, status) {
    const { sessionLoads } = reduxStore.getState();
    const updatedLoads = { ...sessionLoads.cards.load, [key]: status };

    props.reduxFunction("ASYNC", "SET_SESSION_LOADS", {
        ...sessionLoads,
        cards: {
            ...sessionLoads.cards,
            load: updatedLoads,
        },
    });
}

/**
 * Prefixa chaves numéricas de um objeto para evitar conflitos.
 * @param {Object} obj - Objeto a ser processado.
 * @returns {Object} Novo objeto com chaves numéricas prefixadas.
 */
function prefixNumericKeys(obj = {}) {
    const result = { ...obj };
    Object.keys(result).forEach((key) => {
        if (!isNaN(key[0])) {
            result[`f${key}`] = result[key];
            delete result[key];
        }
    });
    return result;
}

/**
 * Carrega os cards via API e os monta no Redux.
 * @param {Object} props - Propriedades do componente (inclui reduxFunction).
 * @param {Object} data - Parâmetros do request.
 * @returns {Object|boolean} Objeto contendo total e cards ou true se já carregado; false em caso de falha.
 */
const loadCardsV2 = async (props, data) => {
    const {
        idRel,
        adminView = false,
        callback = null,
        cardLoad = "my",
        completed = false,
        status,
        deleted = false,
        archived = false,
        templates = false,
        allStatus = false,
        search = "",
        completedDays = 7,
        limit = 0,
        skip = 0,
        startDate = null,
        endDate = null,
        searchParams = null,
        ignoreLoader: customIgnoreLoader = false,
    } = data;

    // Obtemos dados relevantes do Redux
    const {
        sessionLoads,
        session: {
            dashboard: { period },
        },
        preLoader,
    } = reduxStore.getState();

    const loads = { ...sessionLoads.cards.load };
    const reqMd5 = MD5(JSON.stringify(data)).toString();

    // Define se deve ignorar o loader
    const ignoreLoader = cardLoad === "search" || props.ignoreLoader || customIgnoreLoader;

    // Se a requisição ainda não foi iniciada e não ignoramos o loader, marca como "loading"
    if (!loads[reqMd5]) {
        if (!ignoreLoader) {
            setLoadStatus(props, reqMd5, "loading");
        }
    } else if (loads[reqMd5] === "completed") {
        // Se já foi concluída, não recarrega
        // console.log(`loadCardsV2::already completed for reqMd5: ${reqMd5}`);
        return true;
    }

    // Ajusta o período para completedDays se necessário
    const subtractDays = String(period).replace("$__last", "").replace("Days", "");

    // Monta os dados do request para a API
    const requestData = {
        idRel,
        adminView,
        load: cardLoad,
        completed,
        archived,
        templates,
        status,
        allStatus,
        limit,
        skip,
        startDate,
        endDate,
        ...(completed && { completedDays: completedDays || subtractDays }),
        ...(search && { search }),
        ...(deleted && { deleted: true }),
        searchParams,
    };

    try {
        const response = await apims.post("/Card_List/", requestData);

        if (response?.data) {
            // Monta os cards localmente
            const mountedCards = await mountCards(props, response.data, cardLoad);
            if (mountedCards) {
                setLoadStatus(props, reqMd5, "completed");
                if (callback) callback();

                props.reduxFunction("ASYNC", "preLoader", {
                    ...preLoader,
                    cards: false,
                });

                return { total: response.data.length, cards: mountedCards };
            }
        }
    } catch (error) {
        console.error("loadCardsV2::ERR::", error);
        setLoadStatus(props, reqMd5, "errored");
    } finally {
        // Garante que o preLoader seja desativado
        props.reduxFunction("ASYNC", "preLoader", {
            ...preLoader,
            cards: false,
        });
    }
};

/**
 * Solicita cards via API e atualiza o Redux.
 * @param {Object} props - Propriedades do componente.
 * @param {Object} data - Parâmetros do request.
 * @returns {Object|boolean|null} Cards montados ou true se duplicado; null em caso de erro.
 */
const reqCards = async (props, data) => {
    const {
        idRel,
        adminView = false,
        callback = null,
        cardLoad = "my",
        completed = false,
        status,
        deleted = false,
        archived = false,
        templates = false,
        search = props.search || "",
    } = data;

    const ignoreLoader = cardLoad === "search" || props.ignoreLoader;
    const { sessionLoads, db, preLoader } = reduxStore.getState();
    const loads = { ...sessionLoads.cards.load };
    const reqMd5 = MD5(JSON.stringify(data)).toString();

    // Evita requisições duplicadas
    if (loads[reqMd5]) return true;

    if (!ignoreLoader) {
        props.reduxFunction("ASYNC", "SET_SESSION_LOADS", {
            ...sessionLoads,
            cards: {
                ...sessionLoads.cards,
                load: {
                    ...loads,
                    [reqMd5]: "loading",
                },
            },
        });
    }

    try {
        const response = await apims.post("/Card_List/", {
            idRel,
            adminView,
            load: cardLoad,
            completed,
            archived,
            templates,
            status,
            ...(search && { search }),
            ...(deleted && { deleted: true }),
        });

        if (response?.data) {
            const mountedCards = await mountCards(props, response.data);
            if (mountedCards) {
                // Atualiza os cards no Redux
                const newCards = { ...mountedCards };
                props.reduxFunction("ASYNC", "SET_DB", {
                    ...db,
                    cards: {
                        ...db.cards,
                        ...newCards,
                    },
                });

                props.reduxFunction("ASYNC", "SET_SESSION_LOADS", {
                    ...sessionLoads,
                    cards: {
                        ...sessionLoads.cards,
                        load: {
                            ...loads,
                            [reqMd5]: "completed",
                        },
                    },
                });

                if (callback) callback();
                return mountedCards;
            }
        }
    } catch (error) {
        console.error("reqCards::Error::", error);
    } finally {
        props.reduxFunction("ASYNC", "preLoader", {
            ...preLoader,
            cards: false,
        });
    }

    return null;
};

/**
 * Processa os dados dos cards, atualiza o Redux e retorna os cards montados.
 * @param {Object} props - Propriedades do componente.
 * @param {Array} ndata - Array de dados retornados da API.
 * @returns {Object} Objeto com os cards formatados ou objeto vazio.
 */
function mountCards(props, ndata = []) {
    const { session, db } = reduxStore.getState();

    if (!ndata?.length) {
        return {};
    }

    // Filtra cards que possuem _id e que não sejam privados (ou o usuário tem permissão)
    const filterCards = (card) =>
        card?.data?._id &&
        (!card.data.private || card.users.some((user) => user._id === session._id));

    const prepareUsers = (users, myDayUsers) => {
        const userMap = {};
        if (users) {
            users.forEach((user) => {
                if (user._id) {
                    const myDay =
                        myDayUsers?.find((myDayUser) => myDayUser._id === user._id)?.date || null;
                    userMap[user._id] = { ...user, ...(myDay && { myDay }) };
                }
            });
        }
        return userMap;
    };

    const prepareGroups = (groups) => {
        const groupMap = {};
        if (groups) {
            groups.forEach((group) => {
                if (group._id) {
                    groupMap[group._id] = { ...group };
                }
            });
        }
        return groupMap;
    };

    const formatCardData = (card, users, groups, showGroups) => {
        const existingCard = db.cards[card.data._id] || {};
        const newCardData = {
            ...existingCard,
            ...(existingCard._selected && { _selected: true }),
            ...card.data,
            _cardCode: card.cardCode,
            _requireApproval: card.requireApproval || card.data.requireApproval || false,
            _isFavorite: String(card.favorite) === "false",
            ...(card.account && { _account: MD5(card.account).toString() }),
            ...(card.createdBy?.[0] && { _createdBy: card.createdBy[0] }),
            ...(card.myDay && { _myDay: card.myDay }),
            ...(card.parent && { _parent: card.parent }),
            ...(card.plan && { _planId: card.plan }),
            ...(users && { _users: users }),
            ...(groups && { _groups: groups }),
            ...(card.tags && { _tags: card.tags }),
            ...(card.aspects && { _aspects: card.aspects }),
            ...(card.inFlow && { _hasStep: card.inFlow }),
            status: card.data.status || "notStarted",
            ...(showGroups?.length > 0 && { showGroups }),
            _notifications: card.notifications,
            ...(card.parentCompleted?.card && {
                _parentCompleted: card.parentCompleted,
                status: "completed",
                completed_at: {
                    low: card.parentCompleted.date || card.updated_at?.low || null,
                },
            }),
            ...(card.timer && {
                _kpiTimer: {
                    [card.timer._id]: { ...card.timer, userId: session._id },
                },
            }),
            ...(card.parentPrivate && { private: true }),
            type: card.data.type || "task",
        };

        return prefixNumericKeys(newCardData);
    };

    const newCards = ndata
        .filter(filterCards)
        .reduce((acc, card) => {
            const users = prepareUsers(card.users, card.myDayUsers);
            const groups = prepareGroups(card.groups);
            const showGroups =
                card.data.type === "step"
                    ? card.showGroups?.filter((g) => g.value) ?? []
                    : [];
            const formattedCard = formatCardData(card, users, groups, showGroups);
            return { ...acc, [card.data._id]: formattedCard };
        }, {});

    if (Object.keys(newCards).length > 0) {
        props.reduxFunction("ASYNC", "SET_DB", {
            ...db,
            cards: {
                ...db.cards,
                ...newCards,
            },
        });
        return newCards;
    }
    return {};
}

/**
 * Função wrapper para loadCardsV2, facilitando sua chamada com parâmetros individuais.
 * @param {Object} props - Propriedades do componente.
 * @param {string} idRel - Identificador relacional.
 * @param {boolean} adminView - Visualização de administrador.
 * @param {Function|null} callback - Função callback após carregamento.
 * @param {string} cardLoad - Tipo de carregamento dos cards.
 * @param {boolean} completed - Se deve buscar cards completos.
 * @param {string} status - Status do card.
 * @param {boolean} ignoreLoader - Se deve ignorar o loader.
 * @returns {Object|boolean} Resultado do loadCardsV2 ou false.
 */
const loadCards = async (
    props,
    idRel,
    adminView = false,
    callback = null,
    cardLoad = "my",
    completed = false,
    status,
    ignoreLoader = false
) => {
    const res = await loadCardsV2(props, {
        idRel,
        adminView,
        callback,
        cardLoad,
        completed,
        status,
        ignoreLoader,
    });
    return res || false;
};

/**
 * Carrega arquivos associados aos cards e atualiza o Redux.
 * @param {Object} props - Propriedades do componente.
 * @param {string|Array} nodeId - ID do nó ou array de IDs.
 * @param {string} dbR - Nome do banco de dados (padrão "cards").
 * @param {string} searchText - Texto de pesquisa.
 * @param {number|null} skip - Quantidade a pular.
 * @param {number|null} limit - Limite de registros.
 * @returns {boolean} True se carregado com sucesso; false em caso de erro.
 */
const loadFiles = async (props, nodeId, dbR = "cards", searchText, skip = null, limit = null) => {
    const db = dbR;

    const updateStoreWithFiles = (data) => {
        const updatedCards = Object.keys(data).reduce((acc, id) => {
            return {
                ...acc,
                [id]: {
                    ...reduxStore.getState().db[db]?.[id],
                    _files: {
                        ...(reduxStore.getState().db[db]?.[id]?._files || {}),
                        ...data[id],
                    },
                    _loadedFiles: true,
                },
            };
        }, {});

        props.reduxFunction("ASYNC", "SET_DB", {
            ...reduxStore.getState().db,
            [db]: {
                ...reduxStore.getState().db[db],
                ...updatedCards,
            },
        });
    };

    try {
        const requestData =
            typeof nodeId === "string"
                ? { nodeId, skip, limit, searchText }
                : { nodesIds: nodeId };

        const reqFiles = await apims.post("/M_Files_List/", requestData);

        if (reqFiles?.data) {
            updateStoreWithFiles(reqFiles.data);
            return true;
        }
    } catch (error) {
        console.group("loadFiles::ERR::");
        console.error(error);
        console.groupEnd();
        return false;
    }
    return true;
};

/**
 * Carrega os cards de acordo com a rota da página e atualiza o Redux.
 * @param {Object} props - Propriedades do componente.
 * @param {number} days - Número de dias para filtrar cards completos (padrão 7).
 * @returns {boolean} True quando finalizado.
 */
const pageLoadCards = async (props, days = 7) => {
    const pathname = window.location.pathname;
    const [firstHash, secondHash] = window.location.hash
        .split("/")
        .map((part) => part.replace("#", ""));
    const isPlanId = firstHash?.length === 36;

    const resolveLoad = async (loadConfig) => {
        await loadCardsV2(props, loadConfig);
    };

    const resolvePlanLoad = async (configs) => {
        for (const config of configs) {
            await loadCardsV2(props, config);
        }
    };

    switch (pathname) {
        case "/calendar":
            await resolveLoad({
                cardLoad: "calendar",
                iniDate: moment().startOf("month").unix("x"),
                endDate: moment().endOf("month").unix("x"),
            });
            return true;

        case "/sp":
            if (!isPlanId) {
                if (secondHash === "archived") {
                    await resolvePlanLoad([
                        { cardLoad: "plans", archived: true },
                        { cardLoad: "plans", archived: true, completed: true, completedDays: days },
                    ]);
                } else if (secondHash === "completed") {
                    await resolveLoad({
                        cardLoad: "plans",
                        completed: true,
                        completedDays: days,
                    });
                } else if (secondHash === "templates") {
                    await resolveLoad({
                        cardLoad: "templates",
                        templates: true,
                    });
                } else if (secondHash === "admin") {
                    await resolveLoad({
                        cardLoad: "plans",
                        adminView: true,
                    });
                } else if (secondHash === "deleted") {
                    await resolveLoad({
                        cardLoad: "plans",
                        deleted: true,
                    });
                }
            } else {
                await resolvePlanLoad([
                    { idRel: firstHash, cardLoad: "plan" },
                    { idRel: firstHash, cardLoad: "plan", completed: true },
                ]);
            }
            return true;

        case "/d":
            if (secondHash === "dayTasks" || secondHash === "ourTasks") {
                await resolveLoad({
                    adminView: true,
                    cardLoad: "myDay",
                });
            } else {
                await resolvePlanLoad([
                    { cardLoad: "dashboard" },
                    { cardLoad: "showIn" },
                    { cardLoad: "channel" },
                ]);
            }
            return true;

        case "/forms":
        case "/p":
        case "/acc":
        case "/login":
        case "/subscriptionUpgrade":
        case "/masterAdmin":
        case "/plataformAdministrator":
            return true;

        case "/library":
            await resolveLoad({ cardLoad: "plans" });
            return true;

        case "/tags":
            if (isPlanId || reduxStore.getState().searchs["tagsView"]) {
                await resolveLoad({
                    cardLoad: "tags",
                    idRel: firstHash,
                    searchParams: reduxStore.getState().searchs["tagsView"],
                });
            }
            return true;

        case "/t":
            if (!secondHash || secondHash === "myTasks") {
                await resolvePlanLoad([
                    { cardLoad: "myResponsible" },
                    { cardLoad: "myResponsible", completed: true, completedDays: days },
                ]);
            } else if (secondHash === "myDay" || secondHash === "ourDay") {
                await resolvePlanLoad([
                    { cardLoad: "myDay" },
                    { cardLoad: "myDay", completed: true, completedDays: 180 },
                ]);
            } else {
                await resolvePlanLoad([
                    { cardLoad: "in" },
                    { cardLoad: "in", completed: true, completedDays: days },
                    { cardLoad: "myResponsible" },
                ]);
            }
            return true;

        default:
            return true;
    }
};

export {
    reqCards,
    loadCards,
    loadCardsV2,
    loadFiles,
    mountCards,
    pageLoadCards,
};