import React, { useState } from 'react';

// @Material
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge';

// @MATERIAL
import { makeStyles, withStyles } from '@material-ui/core/styles';

// FUNCTIONS
import { translate, customApp } from 'functions/';

const useStyles = makeStyles(() => ({
    customColor: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiBadge-badge': {
            backgroundColor: customApp('color'),
            color: customApp('light'),
            display: 'flex',
            alignItems: 'center',
            zIndex: '1500 !important',
        },
    },
    root: {
        '& .MuiBadge-badge': {
            display: 'flex',
            alignItems: 'center',
            zIndex: 20,
        },
    },
}));

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        borderRadius: '7px',
        boxShadow: '0 0 3px 3px rgba(0,0,0,0.03)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '1000 !important',
    },
}))(Tooltip);

export default function BadgeComponent(props) {
    const {
        tooltipprops,
        title,
        onClose,
        count,
        max,
        icon,
        onClick,
        className = "",
        size = "22px",
        color,
        style = {},
        disabled,
    } = props;

    const classes = useStyles();
    const [internalBadgeVisible, setInternalBadgeVisible] = useState(false);
    const isBadgeVisible = internalBadgeVisible;
    const tooltipOpen = tooltipprops?.open === 'false' ? false : tooltipprops?.open;

    const handleMouseEnter = () => {
        setInternalBadgeVisible(true);
    };

    const handleMouseLeave = () => {
        setInternalBadgeVisible(false);
    };

    const renderTooltipTitle = () => {
        if (!title) return '';
        if (typeof title === 'string') {
            return (
                <div
                    style={{
                        color: customApp('darkText'),
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        height: 'auto',
                    }}
                >
                    {translate(title, 1)}
                </div>
            );
        }
        return (
            <div className={classes.customColor}>
                <div style={{ position: 'relative' }}>
                    {title}
                    {onClose && (
                        <Icon
                            style={{
                                position: 'absolute',
                                left: '5px',
                                top: '0px',
                                zIndex: 10,
                                cursor: 'pointer',
                            }}
                            onClick={onClose}
                            title="close"
                        >
                            close
                        </Icon>
                    )}
                </div>
            </div>
        );
    };

    const renderIcon = () => {
        const commonProps = {
            onMouseEnter: handleMouseEnter,
            onMouseLeave: handleMouseLeave,
            onClick: (e) => {
                if (onClick) {
                    onClick();
                    e.stopPropagation();
                }
            },
            className,
            style: {
                width: size,
                height: size,
                fontSize: size,
                color: color || customApp('medium'),
                opacity: !disabled || isBadgeVisible ? '1' : '0.5',
                cursor: onClick ? 'pointer' : '',
                ...style,
            },
        };

        if (typeof icon === 'string') {
            return <Icon {...commonProps}>{icon}</Icon>;
        }
        return React.createElement(icon, commonProps);
    };

    return (
        <div className={classes.root}>
            <HtmlTooltip
                title={renderTooltipTitle()}
                open={tooltipOpen}
                disableFocusListener={tooltipprops?.disableFocusListener || false}
                disableHoverListener={tooltipprops?.disableHoverListener || false}
                disableTouchListener={tooltipprops?.disableTouchListener || false}
                onClose={() => tooltipprops?.onClose && tooltipprops.onClose()}
                interactive={typeof title !== 'string' && tooltipprops?.interactive ? tooltipprops.interactive : false}
            >
                <Badge
                    overlap="rectangular"
                    badgeContent={count}
                    style={{ opacity: 1, fontSize: '5px' }}
                    max={max || 9}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    invisible={count && !isBadgeVisible}
                >
                    {renderIcon()}
                </Badge>
            </HtmlTooltip>
        </div>
    );
}
