// ID_CREATED*************************

//CORE
import React from 'react'

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import reduxStore from "store/";

//COMPONENTS
import Icon from "components/Icon";
import TextField from "components/TextField";

//@MATERIAL
import LinearProgress from "components/Progress/linear"
import InputAdornment from "@material-ui/core/InputAdornment";

//DEPENDENCIES
import File from "./file_V2";

//FUNCTIONS
import { loadFiles } from "functions/files";
import {
    customApp,
    translate,
    removeSeats
} from 'functions';

function FileList(props) {
    const { db, session } = reduxStore.getState()
    const [files, setFiles] = React.useState([])
    const [endLoad, setEndLoad] = React.useState(false)
    const [loadingFiles, setLoadingFiles] = React.useState(false)
    const [searchValue, setSearchValue] = React.useState('')
    const [page, setPage] = React.useState(0)

    let lightBoxfiles = [];

    React.useEffect(() => {
        setLoadingFiles(true)
        return () => {
            setFiles([])
        }
    }, [props.nodeId])

    React.useEffect(() => {
        setLoadingFiles(true)
    }, [page])

    React.useEffect(() => {
        if (loadingFiles)
            init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingFiles])

    const init = async () => {
        if (!endLoad) {
            let lf = await loadFiles({ ids: [props.nodeId], type: db.cards[props.nodeId] ? 'cards' : 'users', page })
            if (lf) {
                if (lf.length < 20)
                    setEndLoad(true)
                let newFiles = Array.from(files)
                lf.forEach(x => {
                    if (newFiles.filter(fl => fl && fl.data && x && x.data && fl.data._id === x.data._id).length === 0)
                        newFiles.push(x)
                })
                setFiles(newFiles)
                setLoadingFiles(false)
            }
        }
    }

    const openLightBox = (f) => {
        props.reduxFunction("ASYNC", "LIGHTBOX", {
            open: true,
            files: lightBoxfiles,
            file: f
        })
    }

    const scrollFunction = (e) => {
        var scrollDiv = document.getElementById(`filesList_${props.nodeId}`);
        if (scrollDiv.scrollTop + window.innerHeight + 150 > scrollDiv.scrollHeight && !loadingFiles)
            setPage(page + 1)
    }

    return (
        <div style={{
            position: 'relative',
            width: "100%",
            height: "100%"
        }}
            id="sideModule_files"
        >
            {((!searchValue && files.length > 10) || searchValue) &&
                <div style={{ position: 'relative', padding: '10px' }}>
                    <TextField
                        label={translate('$__fileNameSearch', 1)}
                        variant={customApp('fieldVariant')}
                        name={'name'}
                        id="sideModule_files_search"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            autoComplete: 'new-password',
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Icon icon='find_in_page' />
                                </InputAdornment>
                            ),
                            onChange: (d) => {
                                setSearchValue(d.target.value)
                            },
                        }}
                        value={removeSeats(searchValue).toLowerCase()}
                        placeholder={translate("$__fileNameSearch")}
                    />
                </div>
            }
            <div
                style={{
                    position: "absolute",
                    bottom: 0,
                    top: files && files.length > 10 ? 63 : 0,
                    padding: 15,
                    left: 0,
                    right: 0
                }}
                id="sideModule_files_list"
            >
                <legend style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: props.dark ? customApp('color') : customApp('menu'),
                    fontSize: 14
                }}><Icon
                        color={customApp('color')}
                        icon={'folder_open'}
                        title={translate('$__files', '*')}
                        size={'20px'}
                        style={{ marginRight: '15px' }}
                    />{translate('$__files', '*')}</legend>


                <ul
                    id={`filesList_${props.nodeId}`}
                    style={{
                        listStyle: 'none',
                        padding: '0px',
                        position: "absolute",
                        bottom: 0,
                        top: 33,
                        overflowY: "auto",
                        overflowX: "hidden",
                        left: 15,
                        right: 15
                    }}
                    onScroll={!props.horizontal ? (e) => {
                        scrollFunction(e)
                    } : null}
                >

                    {files && files.length > 0 &&
                        files
                            .filter(f =>
                            (
                                (
                                    !searchValue
                                    || searchValue === ''
                                    ||
                                    (
                                        searchValue
                                        && f.data.name.toLowerCase().indexOf(searchValue.toLocaleLowerCase()) > -1
                                    )
                                )
                            )
                            )
                            .sort((a, b) => parseInt(a.data.created_at) < parseInt(b.data.created_at))
                            .map((f, i) => {
                                lightBoxfiles.push(f.data)
                                let file = {
                                    ...f.data,
                                    createdBy: f.createdBy
                                }

                                let permission = false
                                if (
                                    (
                                        file.createdBy
                                        && file.createdBy._id === session._id
                                    )
                                    ||
                                    (
                                        props.nodeId
                                        && db.cards[props.nodeId]
                                        && db.cards[props.nodeId]._users
                                        && db.cards[props.nodeId]._users[session._id]
                                        && db.cards[props.nodeId]._users[session._id].access > 2
                                    )
                                ) permission = true


                                return (
                                    <li
                                        key={f.data._id}
                                        onClick={!permission ? (e) => {
                                            openLightBox(f.data._id)
                                            e.preventDefault();
                                        } : null}
                                        id={`sideModule_files_file_${f.data._id}`}
                                    >
                                        <File
                                            data={file}
                                            resume
                                            dark={props.dark ? true : false}
                                            admin={permission}
                                            searchText={searchValue}
                                            openLightBox={() => {
                                                openLightBox(f.data._id)
                                            }}
                                        />
                                    </li>
                                )
                            })}
                    {((files.length > 0 || loadingFiles) && !endLoad) ?
                        <li style={{
                            width: '100%',
                            textAlign: 'center',
                            position: "relative",
                            float: "left",
                            color: props.dark ? customApp('color') : customApp('menu')
                        }}><LinearProgress />{translate('$__checkingFiles', '*')}</li>
                        : files.length === 0 && !loadingFiles ?
                            translate('$__hasNoFiles', '*')
                            : <React.Fragment></React.Fragment>
                    }
                </ul>
            </div>
        </div>
    )
}
const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(FileList)