// ID_CREATED*************************

import React from "react"
import moment from "moment-timezone"
import { useCookies } from 'react-cookie'

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"
import history from "store/history"

//COMPONENTS
import Button from "@material-ui/core/Button"
import CustomButton from "components/Buttons/custom"
import Card from "components/Card"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Icon from "components/Icon"
import Checklist from "components/Checklist"

//DEPENDENCIES
import ShowIn from "./myChannels"

// @MATERIAL
import { withStyles } from "@material-ui/core/styles"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/views/dashboard"


//FUNCTIONS
import {
    translate,
    customApp,
    nl2br
} from "functions/"

//ICONS
import MockupApp from "assets/img/dashboard/Mockup_App_WEB.png"
import MockupSupport from "assets/img/dashboard/supportMockup.png"

import { setMyDay } from "functions/cards"
import ErrorBoundary from "components/ErrorBoundary"
import SelectCardTypes from "componentsV3/SelectCardTypes"

//VARIABLES
function Dashboard(props) {
    const { db, session } = reduxStore.getState()
    const { users } = db
    const [viewSuggestions, setViewSuggestions] = React.useState(false)
    const [nextTasksOrderBy, setNextTasksOrderBy] = React.useState("EndDate")
    const [disabledTypes, setDisabledTypes] = React.useState(["guideline", "guidelineD", "guidelineR", "guidelineC", "guidelineE", "objective", "goal", "link", "insight"])
    const [cookies, setCookie] = useCookies(['banner']);
    const [myCards, setMyCards] = React.useState([]);


    React.useEffect(() => {
        let s = Object.keys(reduxStore.getState().db.cards)
            .filter(a =>
                reduxStore.getState().db.cards[a]
                && !reduxStore.getState().db.cards[a].checklist
                && !reduxStore.getState().db.cards[a].deleted
                && (
                    !props.hidePrivate
                    || (
                        props.hidePrivate
                        && !reduxStore.getState().db.cards[a].private
                    )
                )
            ).map(c => reduxStore.getState().db.cards[c])
        if (JSON.stringify(s) !== JSON.stringify(myCards))
            setMyCards(s)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.cards])

    const hideSupportAndAppBanner = async () => {
        const { socket } = reduxStore.getState().functions

        setCookie('banner', true, { path: '/' });
        try {
            socket.emit("data", {
                module: "user",
                method: "post",
                action: "preffers"
            }, {
                hideSupportAndAppBanner: true
            }, data => {
                if (data.error)
                    console.log("hideSupportAndAppBanner", data.error)
            })
        } catch (e) {
            console.log(e)
        }
    }

    const today = moment().format("YYYY-MM-DD")

    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace("#", "")

    let myDayTasksSuggestionsContent = myCards
        .filter(a => disabledTypes.length === 0 || (disabledTypes.length > 0 && disabledTypes.indexOf(a.type) === -1))
        .filter(a => {
            if (
                a
                && a._users
                && a._users[session._id]
                && a.status !== "completed"
                && a.type !== "chatGroup"
                && a.type !== "plan"
                &&
                (
                    (
                        a._users[session._id].myDay
                        && moment(a._users[session._id].myDay * 1000).format("YYYY-MM-DD") !== today
                    )
                    ||
                    (
                        a._users[session._id].isResponsible
                        && (
                            (a.endDate
                                && moment(a.endDate * 1000).unix('x') < moment().unix('x')
                            )
                            ||
                            (a.startDate
                                && moment(a.startDate * 1000).unix('x') > moment(new Date(today)).subtract(30, "days").unix('x')
                            )
                        )
                        && (
                            (
                                a._users[session._id]
                                && !a._users[session._id].myDay
                            )
                            || (
                                a._users[session._id].myDay
                                && moment(a._users[session._id].myDay * 1000).format("YYYY-MM-DD") !== today
                            )
                        )
                    )
                )
            ) return true
            return false
        }
        ).sort((a, b) => {
            let compareDateA = a._users[session._id].myDay || a.endDate || a.startDate || 0
            let compareDateB = b._users[session._id].myDay || b.endDate || b.startDate || 0
            if (compareDateA > compareDateB)
                return -1
            if (compareDateA > compareDateB)
                return 1
            return 0
        }
        ).map((a, i) => {
            return (
                <div style={{ width: "calc(100%)", display: "flex", alignItems: "center", justifyContent: "center" }} key={a._id}>
                    <Card db="cards" data={a} noShadow suggestion />
                    <Button
                        onClick={() => {
                            setMyDay(props, {
                                _id: a._id,
                                userId: session._id,
                                option: true
                            })
                        }}
                    >
                        <Icon
                            icon="add"
                            color={customApp("menu")}
                            title={translate("$__addOnMyDay")}
                        />
                    </Button>
                </div>
            )
        }
        )

    let nextDaysContent = myCards.filter(a =>
        a.status !== "completed"
        && a.name
        && !a.deleted
        && !a.archived
        && a._users
        && a._users[session._id]
        &&
        (
            !a._users[session._id].myDay
            ||
            (
                a._users[session._id].myDay
                && moment(new Date(a._users[session._id].myDay * 1000)).format("YYYY-MM-DD") !== today
            )
        )
        &&
        (
            (a.endDate
                && moment(new Date(a.endDate * 1000)).format("YYYY-MM-DD") >= moment().format("YYYY-MM-DD")
                && moment(new Date(a.endDate * 1000)).format("YYYY-MM-DD") <= moment(new Date(today)).add(10, "days").format("YYYY-MM-DD")
            )
            ||
            (a.startDate
                && moment(new Date(a.startDate * 1000)).format("YYYY-MM-DD") >= moment().format("YYYY-MM-DD")
                && moment(new Date(a.startDate * 1000)).format("YYYY-MM-DD") <= moment(new Date(today)).add(10, "days").format("YYYY-MM-DD")
            )
        )
    ).sort((a, b) => {
        let paramA = a.name.toLowerCase().trim(), paramB = b.name.toLowerCase().trim()
        if (nextTasksOrderBy === 'IniDate') {
            paramA = parseInt(a.startDate)
            paramB = parseInt(b.startDate)
        }
        if (nextTasksOrderBy === 'EndDate') {
            paramA = parseInt(a.endDate)
            paramB = parseInt(b.endDate)
        }

        if (paramA < paramB)
            return -1
        if (paramA > paramB)
            return 1
        return 0
    }
    ).map((a, i) => <Card db="cards" key={a._id} data={a} noShadow />)

    const user = users && Object.keys(users).length > 0 ? users[Object.keys(users).find(a => session && session._id && users[a] && users[a]._id && users[a]._id.indexOf(session._id) > -1)] : {}

    const now = new Date()
    const timeMessage =
        (now.getHours() >= 0 && now.getHours() < 5)
            ? "$__goodNight"
            : (now.getHours() >= 5 && now.getHours() < 12)
                ? "$__goodMorning"
                : (now.getHours() >= 12 && now.getHours() < 18)
                    ? "$__goodAfternoon"
                    : "$__goodNight"

    let FavoritePlans = Object.keys(db.cards)
        .filter(fil =>
            db.cards[fil]
            && !db.cards[fil].deleted
            && !db.cards[fil].archived
            && db.cards[fil].type === "plan"
            && db.cards[fil]._users
            && db.cards[fil]._users[session._id]
            && db.cards[fil].status !== "completed"
            && db.cards[fil]._isFavorite
            && (
                !props.hidePrivate
                || (
                    props.hidePrivate
                    && !db.cards[fil].private
                )
            )
        )

    return (
        <div style={{ width: "100%" }}>
            <GridContainer
            >
                {!cookies.banner && !session.hideSupportAndAppBanner ?
                    <React.Fragment>
                        <GridItem
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    marginBottom: "33px"
                                }}
                                id={'view_dashboard_welcomeMessage'}
                            >
                                <div style={{
                                    fontSize: "16px",
                                    padding: "15px 0",
                                    fontWeight: "bold",
                                    width: "100%",
                                    textAlign: "left",
                                    color: customApp("menu"),
                                    paddingLeft: "15px"
                                }}>
                                    {translate(timeMessage, 1)}, {user && user.displayName ? user.displayName : user.name ? user.name : ""}!
                                </div>
                                <GridContainer
                                    style={{ width: "100%" }}
                                >

                                    <GridItem
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                    >
                                        <img alt="MACHEN_IMAGE" src={MockupSupport} width={800} height={610} style={{ width: "100%", height: "auto", borderRadius: 17, boxShadow: "0 0 7px 1px rgba(0,0,0,0.1)" }} />
                                    </GridItem>
                                    <GridItem
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                    >
                                        <div style={{ position: "relative", paddingBottom: 33, height: "100%" }}>
                                            <div style={{
                                                fontSize: "16px",
                                                color: customApp("medium"),
                                                paddingRight: 15
                                            }} dangerouslySetInnerHTML={{
                                                __html: nl2br(translate("$__supportWelcomeMessage", 1))
                                            }}
                                            />
                                            <div style={{ position: "absolute", bottom: 0, left: 0 }}>
                                                <CustomButton
                                                    title={translate(`$__clickToaccessSupportPage`, '*')}
                                                    text={translate("$__clickToaccessSupportPage")}
                                                    color={customApp('menu')}
                                                    icon={'help_outline'}
                                                    // transparent
                                                    onClick={() => {
                                                        window.open("https://support.machen.ai/", "_blank")
                                                    }}
                                                    size={'17px'}
                                                />
                                            </div>

                                        </div>
                                    </GridItem>

                                </GridContainer>
                            </div>
                        </GridItem>
                        <GridItem
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                        >
                            <div
                                style={{ position: "relative" }}
                            >
                                <img alt="MACHEN_IMAGE" src={MockupApp} width={800} height={640} style={{ width: "100%", height: "auto" }} />
                                <div style={{
                                    position: "absolute",
                                    bottom: -33,
                                    right: 15,
                                    zIndex: 10
                                }}>
                                    <CustomButton
                                        title={translate(`$__hideSupportAndAppBanner`, '*')}
                                        text={translate("$__hideSupportAndAppBanner")}
                                        color={customApp('menu')}
                                        icon={'close'}
                                        // transparent
                                        onClick={() => {
                                            hideSupportAndAppBanner()

                                        }}
                                        size={'12px'}
                                    />
                                </div>
                            </div>
                        </GridItem>
                    </React.Fragment>
                    : <React.Fragment></React.Fragment>
                }

            </GridContainer>

            {FavoritePlans.length > 0 ?
                <GridContainer
                    style={{ marginTop: "33px", marginBottom: "33px", }}
                >
                    <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        id={"view_dashboard_favorites"}
                        style={{ boxShadow: "0 5px 7px 1px rgba(0,0,0,0.03)", backgroundColor: "rgba(250,250,250,0.07)", paddingBottom: 33 }}
                    >
                        <div
                            style={{
                                position: "relative",
                                float: "left",
                                width: "100%",
                                // padding: "0 16px",
                                // paddingBottom: 33,
                                marginBottom: 33
                            }}
                        >
                            <div
                                style={{
                                    position: "relative",
                                    float: "left",
                                    width: "100%",
                                    color: customApp("menu"),
                                    fontWeight: "bold",
                                    fontSize: "16px",
                                    marginTop: "16px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between"
                                }}
                            >
                                {translate("$__favoritePlans")}{FavoritePlans.length > 3 ?
                                    <CustomButton
                                        title={`${translate(`$__viewAllFavorites`, '*')} (${FavoritePlans.length})`}
                                        text={`${translate(`$__viewAllFavorites`, '*')} (${FavoritePlans.length})`}
                                        color={customApp('menu')}
                                        icon={'favorite'}
                                        transparent
                                        onClick={() => {
                                            history.push(`sp#tab/home`)
                                        }}
                                        size={'25px'}
                                    />
                                    : <React.Fragment></React.Fragment>}
                            </div>

                            <GridContainer
                                style={{
                                    flexDirection: "row",
                                    overflowY: "auto",
                                    flexWrap: "nowrap",
                                    display: "-webkit-inline-box"
                                }}
                            >
                                {FavoritePlans
                                    .sort((a, b) => {
                                        let updA = db.cards[a].updated_at && db.cards[a].updated_at.low ? db.cards[a].updated_at.low : 0
                                        let updB = db.cards[b].updated_at && db.cards[b].updated_at.low ? db.cards[b].updated_at.low : 0
                                        if (updA > updB)
                                            return -1
                                        if (updA < updB)
                                            return 1
                                        return 0
                                    })
                                    .filter((a, i) => i < 6)
                                    .map((a, i) => {
                                        return (
                                            <GridItem
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                lg={3}
                                                xl={2}
                                                key={db.cards[a]._id}
                                                style={{ marginTop: "33px", cursor: "pointer" }}
                                                onClick={() => {
                                                    history.push(`sp#${db.cards[a]._id}/dashboard`)
                                                }}
                                            >
                                                <Card data={db.cards[a]}
                                                    expanded
                                                    Avatar
                                                    ignoreList
                                                />
                                            </GridItem>
                                        )
                                    })}
                            </GridContainer>
                        </div>
                        <div style={{ clear: "both" }}></div>
                    </GridItem>
                </GridContainer>
                : <React.Fragment></React.Fragment>
            }
            <ErrorBoundary>
                <ShowIn />
            </ErrorBoundary>
            <GridContainer style={{
                paddingBottom: 33,
                marginTop: 33
            }}>
                <GridItem
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    style={{
                        borderRight: `2px solid ${customApp("menu")}`
                    }}
                    id={'view_dashboard_myDay'}
                >
                    <div
                        style={{
                            padding: "0 16px",
                            marginTop: 15
                        }}
                    >
                        {!viewSuggestions ?
                            <React.Fragment>
                                <div
                                    style={{
                                        color: customApp("menu"),
                                        fontWeight: "bold",
                                        fontSize: "16px",
                                        marginBottom: "16px",
                                    }}
                                >
                                    {translate("$__myDay")}
                                    {myDayTasksSuggestionsContent.length > 0 &&
                                        <div style={{
                                            fontSize: "16px",
                                            marginTop: "7px",
                                            cursor: "pointer",
                                            textAlign: "left",
                                            padding: "7px",
                                            color: "#f50057",
                                            display: "flex",
                                            alignItems: "center"
                                        }}
                                            onClick={() => {
                                                setViewSuggestions(true)
                                            }}
                                            id={'view_dashboard_myDaySuggestionsOptions'}
                                        >

                                            <Icon icon={"info"} color={customApp("medium")} style={{ marginRight: "15px" }} />
                                            {translate("$__clickHereToCheckTaskSuggestions")}
                                        </div>
                                    }
                                </div>
                                <Checklist
                                    noChecklist
                                    noDelete
                                    showCard
                                    myDay
                                    db={"cards"}
                                />

                            </React.Fragment>
                            :
                            <div >
                                <div
                                    style={{
                                        color: customApp("menu"),
                                        fontWeight: "bold",
                                        fontSize: "16px",
                                        marginBottom: "16px",
                                        display: "flex",
                                        alignItems: "center"
                                    }}
                                >
                                    <Button
                                        onClick={() => {
                                            setViewSuggestions(false)
                                        }}
                                    >
                                        <Icon
                                            icon="undo"
                                            color={customApp("menu")}
                                            title={translate("$__backToMyDay")}
                                        />
                                    </Button>
                                    {translate("$__taskSuggestions")}
                                </div>
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between"
                                }}>
                                    <SelectCardTypes options={disabledTypes} onChange={(d) => { setDisabledTypes(d) }} />
                                    <div
                                        style={{
                                            color: customApp("medium"),
                                            fontWeight: "bold",
                                            fontSize: "12px",
                                        }}
                                        id={"view_dashboardSuggestionsAddButton"}
                                    >
                                        {/* {translate("$__clickIn+ToAddInMyDay")} */}
                                        Clique no + para adicionar as tarefas ao seu dia.
                                    </div>
                                </div>
                                <div>
                                    {myDayTasksSuggestionsContent}
                                </div>
                            </div>
                        }
                    </div>
                </GridItem>
                <GridItem
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    style={{
                        borderRight: `2px solid ${customApp("menu")}`,
                    }}
                    id={"view_dashboard_nextTasks"}
                >
                    <div
                        style={{
                            position: "relative",
                            float: "left",
                            width: "100%",
                            padding: "0 16px",
                            marginTop: 15
                        }}
                    >
                        <div
                            style={{
                                position: "relative",
                                float: "left",
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between"
                            }}
                        >
                            <div
                                style={{
                                    color: customApp("menu"),
                                    fontWeight: "bold",
                                    fontSize: "16px",
                                    marginBottom: "16px"
                                }}
                            >
                                {translate("$__nextTasks")}
                            </div>
                            <div>
                                <CustomButton
                                    title={translate(`$__orderBy${nextTasksOrderBy}`)}
                                    color={'lightgray'}
                                    // load={loadingDeleted}
                                    icon={nextTasksOrderBy === 'IniDate' ? "today" : nextTasksOrderBy === 'EndDate' ? "event" : "text_rotate_vertical"}
                                    onClick={() => {
                                        if (nextTasksOrderBy === 'IniDate')
                                            setNextTasksOrderBy("EndDate")
                                        if (nextTasksOrderBy === 'EndDate')
                                            setNextTasksOrderBy("name")
                                        if (nextTasksOrderBy === 'name')
                                            setNextTasksOrderBy("IniDate")
                                    }}
                                    size={'21px'}
                                    style={{ padding: "0px !important" }}
                                />
                            </div>
                        </div>
                        <div>
                            {nextDaysContent}
                        </div>
                    </div>
                </GridItem>

            </GridContainer>
        </div >
    )
}

const mapStateToProps = (store) => {
    return {
        cards: store.db.cards,
        hidePrivate: store.controls.hidePrivate,
    }
}
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

//EXPORT
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Dashboard))