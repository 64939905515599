import React, { useState, useMemo, useRef } from "react";
import moment from "moment-timezone";

// REDUX
import * as reduxActions from "store/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// COMPONENTS
import Checklist from "components/Checklist";
import Icon from "components/Icon";

// @MATERIAL
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";

// FUNCTIONS
import { translate, customApp } from "functions/";

function MyDay(props) {
    const { db, session } = props;
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const today = useMemo(
        () => moment().tz("America/Sao_Paulo").format("YYYY/MM/DD"),
        []
    );

    const cards = useMemo(() => {
        return Object.keys(db.cards)
            .filter((cardId) => {
                const card = db.cards[cardId];
                if (!card || card.deleted) return false;
                if (card.checklist && !card.showCard) return false;
                if (!card._users || !card._users[session._id]) return false;
                if (!card._users[session._id].myDay) return false;
                const cardDay = moment(
                    new Date(card._users[session._id].myDay * 1000)
                )
                    .tz("America/Sao_Paulo")
                    .format("YYYY/MM/DD");
                return cardDay === today;
            })
            .map((cardId) => db.cards[cardId]);
    }, [db.cards, session._id, today]);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    if (cards.length === 0) return null;

    return (
        <div id="notificationsBt" style={{ zIndex: "100 !important" }}>
            <Button
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <Badge
                    overlap="rectangular"
                    badgeContent={cards.filter((card) => card.status !== "completed").length}
                    color="secondary"
                >
                    <Icon icon="hourglass_empty" color="parent" title="$__myDay" />
                </Badge>
            </Button>
            {cards.length > 0 && (
                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                    style={{ width: "400px" }}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === "bottom" ? "center top" : "center bottom",
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <div
                                        style={{
                                            height: "auto",
                                            maxHeight: "calc(90vh - 80px)",
                                            width: "100%",
                                            overflowY: "auto",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                padding: "7px",
                                            }}
                                        >
                                            <p
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    padding: '7px',
                                                    fontSize: 15,
                                                    color: customApp("menu"),
                                                    fontWeight: "bold",
                                                    paddingLeft: 10
                                                }}
                                            >
                                                {translate("$__myDay")}
                                            </p>
                                        </div>
                                        <Checklist noChecklist noDelete showCard myDay db="cards" />
                                    </div>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            )}
        </div>
    );
}

const mapStateToProps = (store) => ({
    db: store.db,
    session: store.session,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(reduxActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MyDay);
