// CORE
import React from "react";
import styled from "styled-components";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import moment from "moment-timezone";

// REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import reduxStore from "store/";

// COMPONENTS
import CustomButton from "components/Buttons/custom";
import SimpleCard from "./Card";
import Switch from "components/Switch";

// @MATERIAL
import CustomIconButton from "components/CustomButtons/IconButton";

// FUNCTIONS
import { translate, customApp } from "functions/";
import { updateCardData } from "functions/cards";

const Container = styled.div`
  background-color: none;
  ${props =>
        props.isDragDisable
            ? ""
            : props.isDragging
                ? `opacity:0.88`
                : ``
    }
`;

function CheckList(props) {
    // Desestruturação das props
    const {
        myDay,
        checklist,
        noChecklist,
        nodeId,
        permission,
        showProgress,
        reduxFunction,
        noDelete,
        showCard
    } = props;

    // Obtendo dados do Redux Store
    const { db, session } = reduxStore.getState();
    const { socket } = reduxStore.getState().functions;

    // Estados locais
    const [dragStatus, setDragStatus] = React.useState(false);
    const [showCompleted, setShowCompleted] = React.useState(false);
    const today = moment().tz("America/Sao_Paulo").format("YYYY/MM/DD");

    // Processamento da URL para hash
    let hashs = window.location.hash.split("/");
    hashs[0] = hashs[0].replace("#", "");
    if (hashs[0].length !== 36) {
        hashs[0] = null;
    }

    // Obter objeto "parent" do estado, se disponível
    let parent = {};
    if (reduxStore.getState().db[props.db] && reduxStore.getState().db[props.db][nodeId]) {
        parent = reduxStore.getState().db[props.db][nodeId];
    }

    // Filtrar os cards (tasksCards) conforme as condições
    const tasksCards = Object.keys(db.cards)
        .filter(cardKey => {
            const card = db.cards[cardKey];
            if (
                !myDay &&
                (!checklist || (checklist && card.checklist)) &&
                card.type !== "step" &&
                (
                    (!noChecklist && card && !card.deleted && card._parent === nodeId) ||
                    (noChecklist && !card.deleted && card._parent === nodeId)
                )
            ) {
                return true;
            }
            if (
                myDay &&
                (!checklist || (checklist && card.checklist)) &&
                card._users &&
                card._users[session._id] &&
                card._users[session._id].myDay &&
                moment(new Date(card._users[session._id].myDay * 1000))
                    .tz("America/Sao_Paulo")
                    .format("YYYY/MM/DD") === today &&
                card &&
                !card.deleted
            ) {
                return true;
            }
            return false;
        })
        .sort((a, b) => {
            const cardA = db.cards[a];
            const cardB = db.cards[b];
            let orderA = cardA && cardA.order && !cardA.order.low
                ? parseInt(cardA.order)
                : cardA.order && cardA.order.low
                    ? parseInt(cardA.order.low)
                    : 0;
            let orderB = cardB && cardB.order && !cardB.order.low
                ? parseInt(cardB.order)
                : cardB.order && cardB.order.low
                    ? parseInt(cardB.order.low)
                    : 0;
            if (orderA < orderB) return -1;
            if (orderA > orderB) return 1;
            return 0;
        })
        .map(card => card);

    // Helper para aguardar com Promise (mantém a funcionalidade original)
    const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const onDragEnd = async result => {
        setDragStatus(false);
        const { destination, source, draggableId } = result;

        if (!destination) return false;
        if (destination.droppableId === source.droppableId && destination.id === source.index)
            return false;

        let itemOrderIds = Array.from(
            tasksCards
                .filter(cardKey => {
                    const card = db.cards[cardKey];
                    return card.status !== "completed" && (!checklist || (checklist && card.checklist));
                })
                .sort((a, b) => {
                    const cardA = db.cards[a];
                    const cardB = db.cards[b];
                    let orderA = cardA.order || 0;
                    if (String(cardA.order.low)) orderA = parseInt(cardA.order.low);
                    let orderB = cardB.order || 0;
                    if (String(cardB.order.low)) orderB = parseInt(cardB.order.low);
                    if (orderA < orderB) return -1;
                    if (orderA > orderB) return 1;
                    return 0;
                })
                .map(card => card)
        );

        itemOrderIds.splice(source.index, 1);
        await wait(500);
        itemOrderIds.splice(destination.index, 0, draggableId);
        await wait(500);

        socket.emit("data", {
            module: "cards",
            method: "put",
            action: "reorder"
        }, {
            cards: itemOrderIds
        });

        let newCards = { ...db.cards };
        let reorderAwait = await new Promise((resolve, reject) => {
            itemOrderIds.forEach((cardId, i) => {
                newCards = {
                    ...newCards,
                    [cardId]: {
                        ...newCards[cardId],
                        order: `${i + 1}`
                    }
                };
                if (i + 1 === itemOrderIds.length) resolve(true);
            });
        });
        if (reorderAwait) {
            reduxFunction("IMMEDIATE", "SET_DB", {
                ...db,
                cards: {
                    ...db.cards,
                    ...newCards
                }
            });
        }
        return;
    };

    const onDragStart = async result => {
        setDragStatus(true);
    };

    const onDragUpdate = async result => { };

    // Ordena os cards abertos (não completados)
    let openCards = tasksCards
        .filter(cardKey => db.cards[cardKey].status !== "completed")
        .sort((a, b) => {
            const cardA = db.cards[a];
            const cardB = db.cards[b];
            let orderA = cardA && cardA.order && !cardA.order.low
                ? parseInt(cardA.order)
                : cardA.order && cardA.order.low
                    ? parseInt(cardA.order.low)
                    : 0;
            let orderB = cardB && cardB.order && !cardB.order.low
                ? parseInt(cardB.order)
                : cardB.order && cardB.order.low
                    ? parseInt(cardB.order.low)
                    : 0;
            if (orderA < orderB) return -1;
            if (orderA > orderB) return 1;
            return 0;
        });

    // Verifica se há board existente
    const hasBoard = Object.keys(db.cards).filter(cardKey => {
        const card = db.cards[cardKey];
        return card._parent === nodeId && !card.deleted && card.type === "step";
    }).length > 0;

    return (
        <div style={{ width: "100%" }} onClick={(e) => e.stopPropagation()}>
            {permission ? (
                <>
                    <div
                        style={{
                            width: "96%",
                            position: "relative",
                            margin: "2%",
                            padding: "3px 10px",
                            border: "solid 1px rgba(0,0,0,0.1)",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column"
                        }}
                    >
                        <div style={{ width: "100%", color: "lightgray", textAlign: "center", padding: 3 }}>
                            {translate(hasBoard ? "$__thisActivitieHasABoardExplain" : "$__createActivitieABoardExplain")}
                        </div>
                        <CustomButton
                            title={translate(hasBoard ? "$__openThisBoard" : "$__createNewBoard")}
                            text={translate(hasBoard ? "$__openThisBoard" : "$__createNewBoard")}
                            color={customApp("menu")}
                            textColor={customApp("menu")}
                            icon={"schema"}
                            onClick={() => {
                                reduxFunction("ASYNC", "SET_CONTROL", {
                                    ...reduxStore.getState().control,
                                    board: nodeId
                                });
                                reduxFunction("ASYNC", "CLEAR_MODULE");
                            }}
                            size={"25px"}
                        />
                    </div>
                    <div
                        style={{
                            width: "100%",
                            position: "relative",
                            display: "flex",
                            alignItems: "center",
                            padding: "3px 10px",
                            justifyContent: "center"
                        }}
                    >
                        <div style={{ marginRight: 15 }}>
                            {translate("$__requireConcludedByStep")}
                        </div>
                        <Switch
                            checked={parent.listStepLock ? true : false}
                            onChange={() => {
                                updateCardData(
                                    {
                                        ...props,
                                        data: { _id: parent._id }
                                    },
                                    {
                                        listStepLock: parent.listStepLock ? false : true
                                    }
                                );
                            }}
                            name="listStepLock"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                    </div>
                </>
            ) : (
                <React.Fragment></React.Fragment>
            )}

            {showProgress && tasksCards.length > 0 ? (
                <div
                    style={{
                        position: "relative",
                        width: "100%"
                    }}
                >
                    <div
                        style={{
                            position: "absolute",
                            right: 7,
                            bottom: 0,
                            fontSize: 9,
                            color: customApp("menu"),
                            fontWeight: "bold"
                        }}
                    >
                        {tasksCards.filter(cardKey => db.cards[cardKey].status === "completed").length}/
                        <span style={{ fontWeight: "normal" }}>
                            {tasksCards.length}
                        </span>
                    </div>
                    <div
                        style={{
                            position: "relative",
                            width: "100%",
                            height: 5,
                            backgroundColor: "lightGray"
                        }}
                    >
                        <div
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                bottom: 0,
                                backgroundColor: customApp("menu"),
                                width: `${(100 / tasksCards.length) * tasksCards.filter(cardKey => db.cards[cardKey].status === "completed").length}%`
                            }}
                        ></div>
                    </div>
                </div>
            ) : (
                <React.Fragment></React.Fragment>
            )}

            {tasksCards.filter(cardKey => db.cards[cardKey].status !== "completed").length > 0 ? (
                <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart} onDragUpdate={onDragUpdate}>
                    <Droppable droppableId={`Drop_tasks`} type="column" direction={"vertical"}>
                        {(provided, snapshot) => (
                            <Container
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                isDragging={snapshot.isDraggingOver}
                            >
                                {openCards.map((cardKey, index) => (
                                    <Draggable draggableId={cardKey} index={index} key={`${cardKey}${index}`}>
                                        {(provided, snapshot) => (
                                            <SimpleCard
                                                dragStatus={dragStatus}
                                                provided={provided}
                                                snapshot={snapshot}
                                                data={db.cards[cardKey]}
                                                db={"cards"}
                                                selectable
                                                noDelete={noDelete ? noDelete : false}
                                                showCard={
                                                    (!db.cards[cardKey].checklist ||
                                                        (db.cards[cardKey].checklist &&
                                                            (showCard || db.cards[cardKey].showCard)))
                                                        ? true
                                                        : false
                                                }
                                                permission={permission}
                                                locked={
                                                    parent.listStepLock &&
                                                    index > 0 &&
                                                    db.cards[openCards[index - 1]].status !== "completed"
                                                }
                                            />
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </Container>
                        )}
                    </Droppable>
                </DragDropContext>
            ) : (
                <React.Fragment></React.Fragment>
            )}

            {(permission || myDay) && (
                <div
                    style={{
                        position: "relative",
                        float: "right",
                        width: "100%",
                        padding: "7px",
                        display: "flex",
                        alignContent: "flex-end",
                        justifyContent: "center"
                    }}
                >
                    {/* <div> */}
                    <CustomIconButton
                        // reverse
                        text={translate("$__new", "*")}
                        icon="add_box"
                        iconColor={customApp("color")}
                        onClick={() => {
                            reduxFunction("ASYNC", "SET_CONTROL", {
                                ...reduxStore.getState().control,
                                myDay: myDay ? true : false,
                                addCard: {
                                    myDay: myDay ? true : false,
                                    _parent: parent?._id || null,
                                    rows: tasksCards.length,
                                    type:
                                        parent &&
                                            parent.type &&
                                            parent.type.indexOf("guideline") > -1
                                            ? "objective"
                                            : parent?.type === "objective"
                                                ? "goal"
                                                : parent?.type === "goal"
                                                    ? "task"
                                                    : "task"
                                }
                            });
                        }}
                        style={{ padding: 7 }}
                    />
                    {!myDay ? (
                        <CustomIconButton
                            // reverse
                            text={translate("$__newSimpleTask", "*")}
                            icon="playlist_add"
                            iconColor={customApp("medium")}
                            onClick={() => {
                                reduxFunction("ASYNC", "SET_CONTROL", {
                                    ...reduxStore.getState().control,
                                    myDay: myDay || false,
                                    checklist: true,
                                    addCard: {
                                        _parent: parent._id,
                                        rows: tasksCards.length,
                                        type:
                                            parent.type.indexOf("guideline") > -1
                                                ? "objective"
                                                : parent.type === "objective"
                                                    ? "goal"
                                                    : parent.type === "goal"
                                                        ? "task"
                                                        : "task",
                                        checklist: true
                                    }
                                });
                            }}
                            style={{ padding: 7 }}
                        />
                    ) : (
                        <React.Fragment></React.Fragment>
                    )}
                </div>
            )}

            {tasksCards.filter(cardKey => db.cards[cardKey].status === "completed").length > 0 ? (
                <div
                    style={{
                        width: "96%",
                        position: "relative",
                        margin: "2%",
                        padding: "3px 10px",
                        border: "solid 1px rgba(0,0,0,0.1)",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "column"
                    }}
                >
                    <CustomButton
                        title={`${translate(showCompleted ? "$__hideCompletedActivities" : "$__showCompletedActivities")} - (${tasksCards.filter(cardKey => db.cards[cardKey].status === "completed").length})`}
                        text={`${translate(showCompleted ? "$__hideCompletedActivities" : "$__showCompletedActivities")} - (${tasksCards.filter(cardKey => db.cards[cardKey].status === "completed").length})`}
                        color={customApp("menu")}
                        textColor={customApp("menu")}
                        icon={"done_all"}
                        onClick={() => {
                            setShowCompleted(prev => !prev);
                        }}
                        size={"25px"}
                    />
                </div>
            ) : (
                <></>
            )}

            {showCompleted && tasksCards.filter(cardKey => db.cards[cardKey].status === "completed").length > 0 ? (
                <>
                    {tasksCards
                        .filter(cardKey => db.cards[cardKey].status === "completed")
                        .sort((a, b) => {
                            const cardA = db.cards[a];
                            const cardB = db.cards[b];
                            let dataA = 0;
                            let dataB = 0;
                            if (cardA.completed_at && cardA.completed_at.low)
                                dataA = parseInt(cardA.completed_at.low);
                            if (cardB.completed_at && cardB.completed_at.low)
                                dataB = parseInt(cardB.completed_at.low);
                            if (dataA > dataB) return -1;
                            if (dataA < dataB) return 1;
                            return 0;
                        })
                        .map((cardKey, index) => (
                            <div key={`${cardKey}${index}`}>
                                <SimpleCard
                                    dragStatus={dragStatus}
                                    data={db.cards[cardKey]}
                                    db={"cards"}
                                    selectable
                                    noDelete={noDelete ? noDelete : false}
                                    showCard={
                                        (!db.cards[cardKey].checklist ||
                                            (db.cards[cardKey].checklist &&
                                                (showCard || db.cards[cardKey].showCard)))
                                            ? true
                                            : false
                                    }
                                    permission={permission}
                                    locked={
                                        parent.listStepLock &&
                                        index > 0 &&
                                        openCards[index - 1] &&
                                        db.cards[openCards[index - 1]] &&
                                        db.cards[openCards[index - 1]].status !== "completed"
                                    }
                                />
                            </div>
                        ))}
                </>
            ) : (
                <React.Fragment></React.Fragment>
            )}
        </div>
    );
}

const mapStateToProps = store => ({
    cards: store.db.cards,
});
const mapDispatchToProps = dispatch => bindActionCreators(reduxActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CheckList);