import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import history from "store/history";

// COMPONENTES
import ChatNotifications from "components/Chat/notifications";
import CustomButton from "components/Buttons/custom";
import ErrorBoundary from "components/ErrorBoundary";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Icon from "components/Icon";
import MoreVert from "components/MoreMenu/";
import MyDayNotifications from "componentsV3/Notifications/myDay";
import MyRequireApproval from "componentsV3/Notifications/requireApproval";
import Notifications from "componentsV3/Notifications";
import QuickAdd from "components/QuickAdd/";
import TextField from "components/TextField";
import TimersNotifications from "components/Timer/notifications";
import Favorites from "componentsV3/Favorites";

// @MATERIAL-UI
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { withStyles } from "@material-ui/core/styles";

// DEPENDÊNCIAS
import SearchCards from "components/TopSearch/cards";
import SearchFiles from "components/TopSearch/files";
import User from "./User";

// STYLES
import styles from "assets/jss/material-dashboard-pro-react/views/search.js";

// ROTAS
import routes from "routes";

// FUNÇÕES
import { customApp, getAppAccess, translate } from "functions/";

function SearchComponent(props) {
  const {
    expanded,
    menuExpand,
    loaders,
    reduxFunction,
    controls,
    db,
    session,
    chat,
  } = props;

  // Estados do componente
  const [searchValue, setSearchValue] = useState("");
  const [cardsLoader, setCardsLoader] = useState(0);
  const [filesLoader, setFilesLoader] = useState(0);
  const [searchCardsLoading, setSearchCardsLoading] = useState(false);
  const [searchFilesLoading, setSearchFilesLoading] = useState(false);
  const [cardsStatusSearch, setCardsStatusSearch] = useState("asset");
  const [properties, setProperties] = useState({
    title: null,
    icon: null,
    sTitle: null,
    sIcon: null,
  });
  const anchorRef = useRef(null);

  // Extrai informações da URL
  const { pathname, hash } = window.location;
  const hashParts = useMemo(() => {
    const parts = hash.split("/");
    parts[0] = parts[0].replace("#", "");
    return parts;
  }, [hash]);

  // Atualiza as propriedades da página com base na rota e dados do redux
  useEffect(() => {
    const route = routes.find((fil) => fil.path === pathname) || {};
    let title = translate(`$__${route.alias}`, 1);
    let icon = route.icon;
    let sTitle = "";
    let sIcon = null;

    if (route.alias === "plan" && hashParts[0] && db.cards && db.cards[hashParts[0]]) {
      title = `${translate(`$__${route.alias}`, 1)}: ${db.cards[hashParts[0]].name}`;
    }
    if (route.tabs && route.tabs.length > 0) {
      const tab = route.tabs.find((fil) => fil.path === hashParts[1]);
      if (tab) {
        sTitle = translate(`$__${tab.alias}`, 1);
        icon = tab.icon;
      }
    }
    setProperties({ title, icon, sTitle, sIcon });
  }, [pathname, hashParts, db]);

  // Manipulador de fechamento do popper de busca
  const handleClose = useCallback((event) => {
    if (event?.target?.className?.indexOf("ignoreClickAway") > -1) return;
    if (anchorRef.current && anchorRef.current.contains(event.target)) return;
    setTimeout(() => {
      setSearchValue("");
      setSearchCardsLoading(false);
      setSearchFilesLoading(false);
    }, 100);
  }, []);

  // Manipulador para alterar o valor de busca
  const handleInputChange = useCallback((e) => {
    setSearchValue(e.target.value);
    setSearchCardsLoading(true);
    setSearchFilesLoading(true);
  }, []);

  // Manipulador para limpar a busca
  const handleClearSearch = useCallback(() => {
    setSearchValue("");
    setSearchCardsLoading(false);
    setSearchFilesLoading(false);
  }, []);

  const appAccess = getAppAccess();

  // Memoiza as opções do MoreVert para evitar recriação em cada render
  const moreVertOptions = useMemo(() => {
    const unlockedCount = Object.keys(controls.unlockedPrivates || {}).filter(
      (key) => controls.unlockedPrivates[key]
    ).length;

    const options = [
      ...(appAccess &&
        appAccess.user &&
        (appAccess.userAdmin || appAccess.userModerate)
        ? [
          {
            name: "$__usersAndContacts",
            icon: "account_box",
            onClick: () => history.push("c#tab/users"),
          },
          {
            name: "$__customersCompaniesAndDivisions",
            icon: "business",
            onClick: () => history.push("c#tab/units"),
          },
        ]
        : []),
      ...(appAccess &&
        appAccess.user &&
        appAccess.userAdmin &&
        appAccess.plan &&
        appAccess.planAdmin
        ? [
          {
            name: "$__forms",
            icon: "ballot",
            onClick: () => history.push("forms"),
          },
        ]
        : []),
      {
        name: "$__support",
        icon: "help_outline",
        onClick: () => window.open("https://support.machen.ai", "_blank"),
      },
      (session &&
        ((session._access && session._access.master) || session.isAdmin)) && {
        name: "$__APPSettings",
        icon: "engineering",
        onClick: () => window.open("/masterAdmin#tab/languages", "_self"),
        show: "hover",
      },
      (session && session._id === "701bbca1-e1e4-4ea0-ada8-30f74ae74894") && {
        name: "DEV",
        icon: "code",
        onClick: () => history.push("plataformAdministrator"),
        show: "hover",
      },
      ...(!controls.hidePrivate
        ? [
          {
            name: translate(
              !controls.lockPrivate
                ? `$__unlockMyPrivateActivities`
                : `$__lockMyPrivateActivities`,
              1
            ),
            icon: !controls.lockPrivate ? `lock_open` : `private_connectivity`,
            color: controls.lockPrivate ? "lightgray" : null,
            onClick: () => {
              reduxFunction("ASYNC", "SET_CONTROL", {
                ...controls,
                lockPrivate: !controls.lockPrivate,
                ...(!controls.lockPrivate ? { unlockedPrivates: {} } : {}),
              });
            },
            show: "hover",
          },
        ]
        : []),
      {
        name: translate(
          !chat.notifications ? `$__chatNotificationsOff` : `$__chatNotificationsActive`,
          1
        ),
        icon: !chat.notifications ? `notifications_off` : `notifications_active`,
        color: !chat.notifications ? "lightgray" : "green",
        onClick: () => {
          reduxFunction("ASYNC", "SET_CHAT", {
            ...chat,
            notifications: !chat.notifications,
          });
        },
        show: "hover",
      },
      ...(unlockedCount > 0
        ? [
          {
            name: translate(`$__lockMyPrivateActivities`, 1),
            icon: `private_connectivity`,
            color: "red",
            count: unlockedCount,
            onClick: () => {
              reduxFunction("ASYNC", "SET_CONTROL", {
                ...controls,
                unlockedPrivates: unlockedCount,
                lockPrivate: false,
              });
            },
            show: "hover",
          },
        ]
        : []),
      ...(session && session._access && session._access["masterAdmin"] > 0
        ? [
          {
            name: translate(`$__accountSettings`, 1),
            icon: `settings_applications`,
            color: "lightgray",
            onClick: () => history.push("acc#tab/resume"),
            show: "hover",
          },
        ]
        : []),
    ];
    return options.filter(Boolean);
  }, [appAccess, controls, session, chat, reduxFunction]);

  // Memoriza o estilo do container principal para evitar recriações
  const topContainerStyle = useMemo(() => ({
    position: "absolute",
    top: "-4px",
    right: 0,
    left: expanded ? 30 : 0,
    display: "flex",
    alignItems: "center",
    zIndex: "10 !important",
  }), [expanded]);

  return (
    <div style={topContainerStyle} id="top_">
      <GridContainer>
        <GridItem
          xs={10}
          sm={4}
          md={4}
          lg={4}
          xl={4}
          style={{ display: "flex", alignItems: "center" }}
          id="top_menuHideShow"
        >
          {!expanded && (
            <Button style={{ width: "50px" }} onClick={menuExpand}>
              <Icon
                icon="menu"
                title="$__showMenu"
                size="27px"
                color={customApp("menu")}
              />
            </Button>
          )}
          <div
            id="top_pageTitle"
            style={{
              color: customApp("menu"),
              marginLeft: "22px",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            {properties.sTitle
              ? `${properties.title} - ${properties.sTitle.toLowerCase() === "matrix" ? "MATRIX SWOT" : properties.sTitle
              }`
              : properties.title}
          </div>
        </GridItem>
        <ErrorBoundary>
          <GridItem
            xs={12}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              zIndex: "10 !important",
            }}
            id="top_search"
          >
            <div
              style={{ position: "relative", width: "calc(100% - 15px)", zIndex: "10 !important" }}
            >
              <TextField
                ref={anchorRef}
                label={translate("$__search", 1)}
                variant={customApp("fieldVariant")}
                name="name"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  autoComplete: "new-password",
                  endAdornment:
                    searchValue !== "" ? (
                      <InputAdornment position="end">
                        <Icon icon="close" onClick={handleClearSearch} />
                      </InputAdornment>
                    ) : null,
                  onChange: handleInputChange,
                }}
                value={searchValue}
                placeholder={translate("$__searchInMachen")}
              />
              {searchValue !== "" && searchValue.length > 1 && (
                <ClickAwayListener onClickAway={handleClose}>
                  <Popper
                    open={!!searchValue}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                    style={{
                      position: "absolute",
                      zIndex: 10,
                      bottom: 0,
                      left: "5px",
                      right: "5px",
                      width: "100%",
                      minWidth: "400px",
                      maxWidth: "800px",
                    }}
                  >
                    {({ TransitionProps }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: "center bottom",
                          zIndex: "3",
                          maxHeight: "calc(100vh - 177px)",
                          overflowY: "auto",
                        }}
                      >
                        <Paper style={{ zIndex: "3", boxShadow: "0 7px 15px rgba(0,0,0,0.33) !important" }}>
                          <div
                            style={{
                              display: "flex",
                              borderBottom: "solid 2px rgba(0,0,0,0.22)",
                              backgroundColor: "#f1f1f1",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: cardsStatusSearch === "asset" ? "rgba(0,0,0,0.08)" : "",
                                padding: "0 3px",
                              }}
                            >
                              <CustomButton
                                title={translate(`$__asset`, "*")}
                                text={translate(`$__asset`, "*")}
                                color={cardsStatusSearch === "asset" ? customApp("menu") : "lightGray"}
                                icon="play_circle_outline"
                                onClick={() => {
                                  setCardsStatusSearch("asset");
                                  setSearchCardsLoading(true);
                                  setSearchFilesLoading(true);
                                  setCardsLoader(0);
                                  setFilesLoader(0);
                                }}
                                size="17px"
                                style={{ padding: "0px 3px !important" }}
                                transparent
                              />
                            </div>
                            <div
                              style={{
                                backgroundColor: cardsStatusSearch === "completed" ? "rgba(0,0,0,0.08)" : "",
                                padding: "0 3px",
                              }}
                            >
                              <CustomButton
                                title={translate(`$__completed`, "*")}
                                text={translate(`$__completed`, "*")}
                                color={cardsStatusSearch === "completed" ? customApp("menu") : "lightGray"}
                                icon="verified"
                                onClick={() => {
                                  setCardsStatusSearch("completed");
                                  setSearchCardsLoading(true);
                                  setSearchFilesLoading(true);
                                  setCardsLoader(0);
                                  setFilesLoader(0);
                                }}
                                size="17px"
                                style={{ padding: "0px 3px !important" }}
                                transparent
                              />
                            </div>
                            <div
                              style={{
                                backgroundColor: cardsStatusSearch === "deleted" ? "rgba(0,0,0,0.08)" : "",
                                padding: "0 3px",
                              }}
                            >
                              <CustomButton
                                title={translate(`$__deleted`, "*")}
                                text={translate(`$__deleted`, "*")}
                                color={cardsStatusSearch === "deleted" ? customApp("menu") : "lightGray"}
                                icon="delete_forever"
                                onClick={() => {
                                  setCardsStatusSearch("deleted");
                                  setSearchCardsLoading(true);
                                  setSearchFilesLoading(true);
                                  setCardsLoader(0);
                                  setFilesLoader(0);
                                }}
                                size="17px"
                                style={{ padding: "0px 3px !important" }}
                                transparent
                              />
                            </div>
                          </div>
                          {String(cardsLoader) === "0" &&
                            String(filesLoader) === "0" &&
                            !searchCardsLoading &&
                            !searchFilesLoading ? (
                            <div
                              style={{
                                textAlign: "center",
                                fontSize: "16px",
                                color: "red",
                                marginTop: 33,
                                marginBottom: 33,
                              }}
                            >
                              {translate("$__noResultsFound")}
                            </div>
                          ) : null}
                          {searchValue && (
                            <>
                              <ErrorBoundary>
                                <SearchCards
                                  text={searchValue}
                                  onFinish={(n) => {
                                    setCardsLoader(n);
                                    setSearchCardsLoading(false);
                                  }}
                                  onAction={() => {
                                    setSearchValue("");
                                    setSearchCardsLoading(false);
                                    setCardsLoader(0);
                                    setFilesLoader(0);
                                  }}
                                  completed={cardsStatusSearch === "completed"}
                                  deleted={cardsStatusSearch === "deleted"}
                                />
                              </ErrorBoundary>
                              <ErrorBoundary>
                                <SearchFiles
                                  text={searchValue}
                                  onFinish={(n) => {
                                    setFilesLoader(n);
                                    setSearchFilesLoading(false);
                                  }}
                                  onAction={() => {
                                    setSearchValue("");
                                    setCardsLoader(0);
                                    setFilesLoader(0);
                                  }}
                                />
                              </ErrorBoundary>
                            </>
                          )}
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </ClickAwayListener>
              )}
            </div>
          </GridItem>
        </ErrorBoundary>
        <GridItem
          xs={5}
          sm={4}
          md={4}
          lg={4}
          xl={4}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginBottom: "15px",
            marginTop: "15px",
          }}
        >
          <div id="top_quickAdd">
            <ErrorBoundary>
              <QuickAdd hashs={hashParts} />
            </ErrorBoundary>
          </div>
          <ErrorBoundary>
            <ChatNotifications />
          </ErrorBoundary>
          <div id="top_favorites">
            <ErrorBoundary>
              <Favorites button />
            </ErrorBoundary>
          </div>
          <div id="top_notifications">
            <ErrorBoundary>
              <Notifications loader={loaders && loaders.notifications ? true : false} />
            </ErrorBoundary>
          </div>
          <div id="top_timers">
            <ErrorBoundary>
              <TimersNotifications />
            </ErrorBoundary>
          </div>
          <div id="top_requireApproval">
            <ErrorBoundary>
              <MyRequireApproval />
            </ErrorBoundary>
          </div>
          <div id="top_myDay">
            <ErrorBoundary>
              <MyDayNotifications />
            </ErrorBoundary>
          </div>
          <div id="top_moreOptions">
            <Badge
              overlap="rectangular"
              badgeContent={
                Object.keys(controls.unlockedPrivates || {}).filter(
                  (key) => controls.unlockedPrivates[key]
                ).length
              }
              color="secondary"
            >
              <MoreVert
                transparent
                count={50}
                size="27px"
                icon="settings"
                title={translate("$__settings")}
                btColor="lightgray"
                options={moreVertOptions}
              />
            </Badge>
          </div>
          <div
            style={{
              width: "33px",
              height: "33px",
              marginLeft: "15px",
              backgroundColor: "#cccccc",
              minWidth: "33px",
              borderRadius: "50%",
            }}
            id="top_user"
          >
            <User />
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = (store) => ({
  controls: store.controls,
  chatNotifications: store.chat.notifications,
  db: store.db,
  session: store.session,
  chat: store.chat,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(reduxActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SearchComponent));