//CORE
import React from "react"
import { FixedSizeList } from "react-window"
import AutoSizer from "react-virtualized-auto-sizer"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"

//COMPONENTS
import HtmlTooltip from "components/Tooltip/html"
import UserBadge from "components/Badge/user"

//@MATERIAL
import Avatar from '@material-ui/core/Avatar'
import Badge from '@material-ui/core/Badge'
import { withStyles } from "@material-ui/core/styles"


//STYLES
import styles from "./chatsStyles.js"

//FUNCTIONS
import {
    getAppAccess,
    customApp
} from "functions/"

import {
    countUnReadsMessages
} from "functionsV3/chat"

function Component(props) {
    const { classes } = props
    const { chat, session, timeline, usersStatus } = props.store
    const [listChats, setListChats] = React.useState([])
    const expanded = props.expanded ? true : false

    let AppAccess = getAppAccess()



    React.useEffect(() => {
        let users = { ...reduxStore.getState().db.users }
        let chats = {}
        const mountChats = async () => {
            users = Object.keys(users).filter(a => a !== session._id && users[a].type === 'user')
                .map(a => props.store.users[a])
                .sort((a, b) => {
                    if (a.name < b.name) {
                        return -1
                    } else if (a.name > b.name) {
                        return 1
                    } else {
                        return 0
                    }
                })

            users = users.map(us => {
                us.unReadedCount = 0
                let myStories = {
                    // ...timeline[session._id] || {}
                }
                myStories = Object.keys(myStories).filter(a => myStories[a] && myStories[a].user && myStories[a].user._id === us._id && myStories[a].type === 'comment').map(a => myStories[a])

                let userStories = {
                    ...props.store.users[us._id] && timeline[us._id] ? props.store.users[us._id] && timeline[us._id] : {}
                }
                userStories = Object.keys(userStories).filter(a => userStories[a] && userStories[a].type === 'comment').map(a => userStories[a])
                let stories = [
                    ...myStories,
                    ...userStories
                ]

                stories = stories.sort((a, b) => {
                    if (Math.ceil(a.created_at) < Math.ceil(b.created_at))
                        return -1
                    return 1
                }).map(a => a)
                if (typeof us !== 'boolean') {
                    chats = {
                        ...chats,
                        [us._id]: {
                            ...props.store.users[us._id],
                            type: "user",
                            _lastMessage: stories[stories.length - 1] ? stories[stories.length - 1] : null,
                            unReadedCount: myStories.filter(a => a.user._id !== session._id && a.type === "comment" && !a.deleted && (!a.readedBy || (a.readedBy && !a.readedBy[session._id]))).length
                        }
                    }
                    us._lastMessage = stories[stories.length - 1] ? stories[stories.length - 1] : null
                    us.unReadedCount = myStories.filter(a => a.user._id !== session._id && a.type === "comment" && !a.deleted && (!a.readedBy || (a.readedBy && !a.readedBy[session._id]))).length
                } else {
                    us._lastMessage = null
                    us.unReadedCount = 0
                }
                return us
            })

            let groups = Object.keys(reduxStore.getState().db.cards)
                .filter(a =>
                    reduxStore.getState().db.cards[a].type === 'chatGroup'
                    && !reduxStore.getState().db.cards[a].deleted
                    && (
                        (
                            reduxStore.getState().db.cards[a]._users
                            && reduxStore.getState().db.cards[a]._users[session._id]
                        )
                        ||
                        (
                            !reduxStore.getState().db.cards[a].private
                            && props.store.adminView
                            && AppAccess
                            && (
                                AppAccess.owner
                                || AppAccess.admin
                            )
                        )
                    )
                )
                .map(a => reduxStore.getState().db.cards[a])



            if (groups && groups.length > 0)
                groups
                    .filter(fil => {
                        if (
                            fil._users
                            && fil._users[session._id]
                        )
                            return true
                        return false
                    }
                    )
                    .forEach(gr => {
                        let lastMessage
                        if (timeline[gr._id] && Object.keys(timeline[gr._id]).length > 0)
                            lastMessage = Object.keys(timeline[gr._id])
                                .filter(tm => timeline[gr._id]
                                    && timeline[gr._id][tm]
                                    && timeline[gr._id][tm].type === 'comment'
                                )
                                .sort((a, b) => {
                                    if (timeline[gr._id][a]
                                        && timeline[gr._id][b]
                                        && String(timeline[gr._id][a].created_at)
                                        && String(timeline[gr._id][b].created_at)
                                        && parseInt(timeline[gr._id][a].created_at) > parseInt(timeline[gr._id][b].created_at)) {
                                        return -1
                                    } else if (timeline[gr._id][a]
                                        && timeline[gr._id][b]
                                        && String(timeline[gr._id][a].created_at)
                                        && String(timeline[gr._id][b].created_at)
                                        && parseInt(timeline[gr._id][a].created_at) < parseInt(timeline[gr._id][b].created_at)) {
                                        return 1
                                    } else {
                                        return 0
                                    }
                                }
                                )
                        if (gr
                            && gr._id
                            && lastMessage
                            && lastMessage.length > 0
                            && timeline[gr._id]
                            && timeline[gr._id][lastMessage[0]]
                            && timeline[gr._id][lastMessage[0]].created_at
                        ) {
                            chats = {
                                ...chats,
                                [gr._id]: {
                                    ...reduxStore.getState().db.cards[gr._id],
                                    type: "group",
                                    _lastMessage: timeline[gr._id][lastMessage[0]],
                                }
                            }
                        } else {
                            chats = {
                                ...chats,
                                [gr._id]: {
                                    ...reduxStore.getState().db.cards[gr._id],
                                    type: "group",
                                }
                            }
                        }
                    })

            const newListChats = Object.keys(chats).filter(ch =>
                (!reduxStore.getState().db.cards[ch]
                    ||
                    (
                        reduxStore.getState().db.cards[ch]
                        && !reduxStore.getState().db.cards[ch].deleted
                    )
                ) &&
                (
                    (!props.find || props.find === '')
                    || (
                        props.find
                        && (
                            chats[ch].name.toLowerCase().indexOf(props.find.toLowerCase()) > -1
                            ||
                            (
                                chats[ch]._parent
                                && reduxStore.getState().db.users[chats[ch]._parent]
                                && reduxStore.getState().db.users[chats[ch]._parent].name
                                && reduxStore.getState().db.users[chats[ch]._parent].name.toLowerCase().indexOf(props.find.toLowerCase()) > -1
                            )
                        )
                    )
                )
                &&
                (
                    expanded
                    ||
                    (
                        !expanded
                        && chats[ch]
                        && chats[ch]._lastMessage
                    )
                )
                &&
                (
                    !props.onLine
                    || (
                        props.onLine
                        && usersStatus.users && usersStatus.users[ch]
                    )
                )
            ).sort((a, b) => {
                let lastMessageA = chats[a]._lastMessage && String(chats[a]._lastMessage.created_at) ? Math.ceil(chats[a]._lastMessage.created_at) : 0
                let lastMessageB = chats[b]._lastMessage && String(chats[b]._lastMessage.created_at) ? Math.ceil(chats[b]._lastMessage.created_at) : 0
                if (!expanded) {
                    if (lastMessageA > lastMessageB) {
                        return -1
                    } else if (lastMessageA < lastMessageB) {
                        return 1
                    } else {
                        return 0
                    }
                } else {
                    if (chats[a].name.toLowerCase() < chats[b].name.toLowerCase())
                        return -1
                    if (chats[a].name.toLowerCase() > chats[b].name.toLowerCase())
                        return 1
                    return 0
                }
            }).map((ch, i) => {
                let u = chats[ch]
                return ({
                    _id: ch,
                    name: u.name,
                    image: u.image || null,
                    lastMessage: u.lastMessage || null,
                    deleted: u.deleted || false,
                    _parent: u._parent || null,
                })
            })

            setListChats(newListChats)
            if (!chat.nodeId && newListChats.length > 0)
                props.reduxFunction("ASYNC", "SET_CHAT", {
                    ...chat,
                    nodeId: newListChats[0]._id,
                })

        }
        mountChats()
        // eslint-disable-next-line
    }, [usersStatus, timeline, props.expanded, props.find, props.onLine, props.chat])


    const Row = ({ index, style }) => {
        let u = listChats[index]
        return (
            <div style={style}>
                <div
                    className={chat && chat.nodeId === u._id ? expanded ? classes.userActiveExtended : classes.userActive : expanded ? classes.userExtended : classes.user}
                    style={{
                        height: 55,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "auto",
                        cursor: "pointer",
                        ...chat && chat.nodeId === u._id ? {
                            backgroundColor: "rgba(250,250,250,0.1)"
                        } : {}
                    }}
                    onClick={() => {
                        props.openChat(u._id)
                    }}
                >
                    <HtmlTooltip
                        title={(
                            <React.Fragment>
                                {u.name
                                    ? u.name
                                    : u.name
                                        ? u.name.split(' ').lenth > 0 ? u.name.split(' ')[0]
                                            : ''
                                        : ''
                                }
                                {!u.name && u.name
                                    ? u.name.split(' ').lenth > 0 ? u.name.split(' ')[0]
                                        : ''
                                    : ''
                                }
                            </React.Fragment>
                        )
                        } >
                        <div style={{ width: "100%" }}>
                            {!expanded ?
                                <Badge
                                    overlap="rectangular"
                                    badgeContent={countUnReadsMessages(u._id)}
                                    color="secondary"
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: 0,
                                        width: "80%",
                                        marginTop: "16px",
                                    }}
                                >
                                    <UserBadge
                                        overlap="circular"
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        userId={u._id}
                                    >
                                        <React.Fragment>
                                            <div
                                                style={{
                                                    width: 33,
                                                    height: 33,
                                                    marginTop: "-10px",
                                                    ...u.deleted ? { border: "solid 2px red" } : {},
                                                    borderRadius: "50%",
                                                    backgroundColor: u.image ? "none" : "lightgray",
                                                    backgroundImage: `url("${u.image}")`,
                                                    backgroundSize: "cover",
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundPosition: "center center",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            ><span style={{
                                                color: customApp("menu"),
                                                fontWeight: "bold",
                                                letterSpacing: "-2px !important"
                                            }}>{!u.image ? `${u.name.substr(0, 1).toUpperCase()}${u.name.split(" ").length > 1 ? ` ${u.name.split(" ")[1].substr(0, 1).toUpperCase()}` : ``}` : ``}</span>
                                            </div>
                                            {u._parent &&
                                                props.store.users[u._parent] &&
                                                props.store.users[u._parent].image ?
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        bottom: 4,
                                                        right: -7,
                                                        width: 15,
                                                        height: 15,
                                                        borderRadius: "50%",
                                                        backgroundImage: `url(${props.store.users[u._parent].image})`,
                                                        backgroundSize: "cover",
                                                        backgroundRepeat: "no-repeat"
                                                    }}></div>
                                                : <React.Fragment></React.Fragment>}
                                        </React.Fragment>
                                    </UserBadge>
                                </Badge>
                                :
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'left',
                                        alignItems: 'center',
                                        marginTop: "10px"
                                    }
                                    }>
                                    <Badge
                                        overlap="rectangular"
                                        badgeContent={countUnReadsMessages(u._id)}
                                        color="secondary"
                                        style={{
                                            width: '37px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            // marginTop: '-10px',
                                        }}
                                    >
                                        <UserBadge
                                            overlap="circular"
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            userId={u._id}
                                        >
                                            <React.Fragment>
                                                <Avatar alt={u.displayName ? u.displayName : u.name} src={`${u.image}`}
                                                    style={{
                                                        width: '29px',
                                                        height: '29px',
                                                        marginTop: '-10px',
                                                    }} />
                                                {u._parent &&
                                                    props.store.users[u._parent] &&
                                                    props.store.users[u._parent].image ?
                                                    <div
                                                        style={{
                                                            position: "absolute",
                                                            bottom: 4,
                                                            right: -7,
                                                            width: 15,
                                                            height: 15,
                                                            borderRadius: "50%",
                                                            backgroundImage: `url(${props.store.users[u._parent].image})`,
                                                            backgroundSize: "cover",
                                                            backgroundRepeat: "no-repeat"
                                                        }}></div>
                                                    : <React.Fragment></React.Fragment>}
                                            </React.Fragment>
                                        </UserBadge>
                                    </Badge>
                                    <div
                                        style={{
                                            fontSize: '14px',
                                            maxWidth: "calc(100% - 30px)",
                                            paddingLeft: "7px",
                                            color: 'gray',
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis",
                                            overflow: "hidden"
                                        }}>
                                        {u.name
                                            ? u.name
                                            : u.name
                                                ? u.name.split(' ').lenth > 0 ? u.name.split(' ')[0]
                                                    : ''
                                                : ''
                                        }
                                        {!u.name && u.name
                                            ? u.name.split(' ').lenth > 0 ? u.name.split(' ')[0]
                                                : ''
                                            : ''}
                                        {u._parent && props.store.users[u._parent] ?
                                            <div
                                                style={{
                                                    fontSize: 10,
                                                    color: "lightgray",
                                                    marginTop: "-7px",
                                                }}>{props.store.users[u._parent].name}</div>
                                            : <React.Fragment></React.Fragment>}
                                    </div>
                                </div>
                            }
                        </div>
                    </HtmlTooltip>
                </div>
            </div>
        )
    }
    return (
        <AutoSizer
            style={{
                width: "100%",
                height: "100%"
            }}
        >
            {({ height, width }) => (
                <FixedSizeList
                    height={height}
                    itemCount={listChats.length}
                    itemSize={55}
                    width={expanded ? 295 : 58}
                    itemData={listChats}
                    style={{
                        height: "100%",
                    }}
                >
                    {Row}
                </FixedSizeList>
            )}
        </AutoSizer>
    )
}
const mapStateToProps = (store) => ({
    store: {
        chat: store.chat,
        timeline: store.timeline,
        cards: store.db.cards,
        users: store.db.users,
        adminView: store.db.adminView,
        session: store.session,
        usersStatus: store.usersStatus
    }
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component))
