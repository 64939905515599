//CORE
import React, { useState, useRef, useMemo, useEffect, useCallback } from "react";

//REDUX
import * as reduxActions from "store/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

//COMPONENTS
import Icon from "components/Icon";
import Notification from "componentsV3/Notifications/card";
import CustomButton from "components/Buttons/custom";

//@MATERIAL
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";

//FUNCTIONS
import {
    translate,
    customApp,
    appAlert
} from "functions/";

function Notifications(props) {
    const { session, timeline, cards, users, functions, reduxFunction } = props;
    const { socket } = functions || {};

    const [open, setOpen] = useState(false);
    const [limit, setLimit] = useState({});
    const [actualNotificationType, setNotificationType] = useState("comment");
    const anchorRef = useRef();

    // Memoiza os dados do usuário
    const userData = useMemo(() => {
        return session && users && users[session._id] ? users[session._id] : {};
    }, [session, users]);

    // Calcula os contadores de notificações
    const { ntCount, xCount } = useMemo(() => {
        let ntCountLocal = 0;
        let xCountLocal = {
            comment: 0,
            log: 0,
            mentions: 0,
            deleted: 0,
            kpi: 0,
            timer: 0,
            completed: 0
        };
        if (!cards || !timeline || !session || !session._id) {
            return { ntCount: 0, xCount: xCountLocal };
        }
        Object.keys(cards)
            .filter(n => {
                const card = cards[n];
                if (
                    card.type !== 'chatGroup' &&
                    card.type !== 'step' &&
                    (
                        (card._users && card._users[session._id]) ||
                        (
                            (!card._users || !card._users[session._id]) &&
                            card._groups &&
                            userData.groups &&
                            userData.groups.length > 0 &&
                            Object.keys(card._groups).length > 0 &&
                            Object.keys(card._groups).some(gr =>
                                userData.groups.some(xg => xg.value === gr)
                            )
                        )
                    ) &&
                    timeline[n] &&
                    Object.keys(timeline[n]).some(a => {
                        const note = timeline[n][a];
                        return (
                            !note.deleted &&
                            note.user &&
                            note.user._id &&
                            note.user._id !== session._id &&
                            (
                                !note.readedBy ||
                                (note.readedBy && !note.readedBy[session._id])
                            )
                        );
                    })
                ) {
                    return true;
                }
                return false;
            })
            .forEach(n => {
                const card = cards[n];
                if (!timeline[n]) return;
                Object.keys(timeline[n]).forEach(a => {
                    const note = timeline[n][a];
                    if (
                        !note.deleted &&
                        note.user &&
                        note.user._id &&
                        note.user._id !== session._id &&
                        (
                            !note.readedBy ||
                            (note.readedBy && !note.readedBy[session._id])
                        )
                    ) {
                        if (card.deleted) {
                            xCountLocal.deleted += 1;
                        }
                        if (card.status === "completed") {
                            xCountLocal.completed += 1;
                        }
                        if (note.message && note.message.indexOf(session._id) > -1 && !card.deleted) {
                            xCountLocal.mentions += 1;
                        }
                        if (note.type && xCountLocal.hasOwnProperty(note.type)) {
                            xCountLocal[note.type] = (xCountLocal[note.type] || 0) + 1;
                        }
                        ntCountLocal += 1;
                    }
                });
            });
        return { ntCount: ntCountLocal, xCount: xCountLocal };
    }, [cards, timeline, session, userData]);

    // Fecha o menu de notificações se não houver notificações
    useEffect(() => {
        if (ntCount === 0 && open) {
            setOpen(false);
        }
    }, [ntCount, open]);

    // Define o tipo de notificação a exibir
    const notificationType = useMemo(() => {
        let type = actualNotificationType;
        if (type === "comment" && !xCount.comment)
            type = "mentions";
        if (type === "mentions" && !xCount.mentions)
            type = "timer";
        if (type === "timer" && !xCount.timer)
            type = "kpi";
        if (type === "kpi" && !xCount.kpi)
            type = "log";
        if (type === "log" && !xCount.log)
            type = "completed";
        if (type === "completed" && !xCount.completed)
            type = "deleted";
        if (type === "deleted" && !xCount.deleted)
            type = "all";
        return type;
    }, [actualNotificationType, xCount]);

    // Filtra e ordena os cards para renderização conforme o tipo de notificação
    const renderCards = useMemo(() => {
        if (!cards || !timeline || !session || !session._id) return [];
        return Object.keys(cards)
            .filter(n => {
                const card = cards[n];
                if (
                    card.type !== 'chatGroup' &&
                    session &&
                    session._id &&
                    (
                        (card._users && card._users[session._id]) ||
                        (
                            card._groups &&
                            userData.groups &&
                            userData.groups.length > 0 &&
                            Object.keys(card._groups).some(gr =>
                                userData.groups.some(xg => xg.value === gr)
                            )
                        )
                    ) &&
                    timeline[n] &&
                    Object.keys(timeline[n]).some(a => {
                        const note = timeline[n][a];
                        return (
                            !note.deleted &&
                            note.user &&
                            note.user._id &&
                            note.user._id !== session._id &&
                            (
                                !note.readedBy ||
                                (note.readedBy && !note.readedBy[session._id])
                            )
                        );
                    })
                ) {
                    return true;
                }
                return false;
            })
            .filter(n =>
                notificationType === "all" ||
                (
                    (notificationType === "deleted" && cards[n]?.deleted) ||
                    (notificationType !== "deleted" &&
                        cards[n] &&
                        !cards[n].deleted &&
                        (
                            (notificationType === "comment" &&
                                Object.keys(timeline[n]).some(a => timeline[n][a]?.type === "comment")
                            ) ||
                            (notificationType === "log" &&
                                Object.keys(timeline[n]).some(a => timeline[n][a]?.type === "log")
                            ) ||
                            (notificationType === "kpi" &&
                                Object.keys(timeline[n]).some(a => timeline[n][a]?.type === "kpi")
                            ) ||
                            (notificationType === "timer" &&
                                Object.keys(timeline[n]).some(a => timeline[n][a]?.type === "timer")
                            ) ||
                            (notificationType === "mentions" &&
                                Object.keys(timeline[n]).some(a => timeline[n][a]?.message?.indexOf(session._id) > -1)
                            ) ||
                            (notificationType === "completed" &&
                                cards[n].status === "completed")
                        )
                    )
                )
            )
            .sort((na, nb) => {
                let lna = 0, lnb = 0;
                if (timeline[na]) {
                    Object.keys(timeline[na]).forEach(a => {
                        const note = timeline[na][a];
                        if (
                            note.user &&
                            note.user._id !== session._id &&
                            (
                                !note.readedBy ||
                                (note.readedBy && !note.readedBy[session._id])
                            )
                        ) {
                            const createdAt = parseInt(note.created_at) || 0;
                            if (createdAt > lna) lna = createdAt;
                        }
                    });
                }
                if (timeline[nb]) {
                    Object.keys(timeline[nb]).forEach(a => {
                        const note = timeline[nb][a];
                        if (
                            note.user &&
                            note.user._id !== session._id &&
                            (
                                !note.readedBy ||
                                (note.readedBy && !note.readedBy[session._id])
                            )
                        ) {
                            const createdAt = parseInt(note.created_at) || 0;
                            if (createdAt > lnb) lnb = createdAt;
                        }
                    });
                }
                return lna > lnb ? -1 : lna < lnb ? 1 : 0;
            });
    }, [cards, timeline, session, userData, notificationType]);

    // Função para limpar notificações
    const clearNotifications = useCallback(() => {
        appAlert({
            message: translate("$__confirmClearAllNotifications", 1),
            variant: "warning",
            persist: false,
            horizontal: "right",
            confirm: () => {
                setOpen(false);
                let newTimeline = { ...timeline };
                Object.keys(newTimeline).forEach(t => {
                    if (newTimeline[t]) {
                        Object.keys(newTimeline[t]).forEach(ms => {
                            const note = newTimeline[t][ms];
                            if (
                                cards &&
                                cards[t] &&
                                cards[t].type !== 'chatGroup' &&
                                (
                                    !note.readedBy ||
                                    (note.readedBy && !note.readedBy[session._id])
                                )
                            ) {
                                delete newTimeline[t][ms];
                            }
                        });
                    }
                });
                reduxFunction("ASYNC", "SET_TIMELINE", {
                    timeline: newTimeline
                });
                reduxFunction("ASYNC", "SET_NOTIFICATIONS", {
                    total: 0,
                    lastNotification: 0,
                    firstNotification: 0,
                });
                try {
                    socket && socket.emit("data", {
                        module: "notifications",
                        method: "put",
                        action: "readedAll"
                    });
                } catch (e) {
                    console.log(e);
                }
            }
        });
    }, [reduxFunction, timeline, session, socket, cards]);

    const handleToggle = useCallback(() => {
        setOpen(prevOpen => !prevOpen);
    }, []);

    const handleClose = useCallback((event) => {
        if (event?.target?.className?.indexOf("ignoreClickAway") > -1)
            return;
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setTimeout(() => {
            setOpen(false);
        }, 100);
    }, []);

    if (ntCount === 0) {
        return null;
    }

    return (
        <div id="notificationsBt" style={{ zIndex: "1000 !important" }}>
            <Button
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <Badge overlap="rectangular" badgeContent={ntCount} color="secondary">
                    <Icon
                        icon={ntCount === 0 ? "notifications_none" : "notifications_active"}
                        color={"parent"}
                        title="$__notifications"
                    />
                </Badge>
            </Button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <div style={{ width: "800px", overflowY: "auto" }}>
                                    <div style={{ position: "relative", display: "flex" }}>
                                        <div style={{ height: "auto", width: "300px", overflowY: "auto", display: "table" }}>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                padding: "7px",
                                                width: "100%",
                                                fontSize: 18,
                                                color: customApp("color"),
                                                fontWeight: "bold"
                                            }}>
                                                <div>{translate("$__notifications")}</div>
                                            </div>
                                            <ul style={{ listStyle: "none", padding: 0 }}>
                                                {xCount.comment ? (
                                                    <li style={{ padding: "15px 0px" }}>
                                                        <CustomButton
                                                            title={translate(`$__timeline`, '*')}
                                                            text={translate(`$__timeline`, '*')}
                                                            color={notificationType === "comment" ? customApp("menu") : 'lightGray'}
                                                            icon={'timeline'}
                                                            onClick={() => setNotificationType("comment")}
                                                            size={'22px'}
                                                            style={{ padding: "0px 3px !important", width: "100%" }}
                                                            transparent
                                                            badgeContent={xCount.comment}
                                                        />
                                                    </li>
                                                ) : null}
                                                {xCount.mentions ? (
                                                    <li style={{ padding: "15px 0px" }}>
                                                        <CustomButton
                                                            title={translate(`$__mentioned`, '*')}
                                                            text={translate(`$__mentioned`, '*')}
                                                            color={notificationType === "mentions" ? customApp("menu") : 'lightGray'}
                                                            icon={'person_pin_circle'}
                                                            onClick={() => setNotificationType("mentions")}
                                                            size={'22px'}
                                                            style={{ padding: "0px 3px !important", width: "100%" }}
                                                            transparent
                                                            badgeContent={xCount.mentions}
                                                        />
                                                    </li>
                                                ) : null}
                                                {xCount.kpi ? (
                                                    <li style={{ padding: "15px 0px" }}>
                                                        <CustomButton
                                                            title={translate(`$__kpi`, '*')}
                                                            text={translate(`$__kpi`, '*')}
                                                            color={notificationType === "kpi" ? customApp("menu") : 'lightGray'}
                                                            icon={'analytics'}
                                                            onClick={() => setNotificationType("kpi")}
                                                            size={'22px'}
                                                            style={{ padding: "0px 3px !important", width: "100%" }}
                                                            transparent
                                                            badgeContent={xCount.kpi}
                                                        />
                                                    </li>
                                                ) : null}
                                                {xCount.timer ? (
                                                    <li style={{ padding: "15px 0px" }}>
                                                        <CustomButton
                                                            title={translate(`$__timeSheet`, '*')}
                                                            text={translate(`$__timeSheet`, '*')}
                                                            color={notificationType === "timer" ? customApp("menu") : 'lightGray'}
                                                            icon={'timer'}
                                                            onClick={() => setNotificationType("timer")}
                                                            size={'22px'}
                                                            style={{ padding: "0px 3px !important", width: "100%" }}
                                                            transparent
                                                            badgeContent={xCount.timer}
                                                        />
                                                    </li>
                                                ) : null}
                                                {xCount.log ? (
                                                    <li style={{ padding: "15px 0px" }}>
                                                        <CustomButton
                                                            title={translate(`$__update`, '*')}
                                                            text={translate(`$__update`, '*')}
                                                            color={notificationType === "log" ? customApp("menu") : 'lightGray'}
                                                            icon={'update'}
                                                            onClick={() => setNotificationType("log")}
                                                            size={'22px'}
                                                            style={{ padding: "0px 3px !important", width: "100%" }}
                                                            transparent
                                                            badgeContent={xCount.log}
                                                        />
                                                    </li>
                                                ) : null}
                                                {xCount.completed ? (
                                                    <li style={{ padding: "15px 0px" }}>
                                                        <CustomButton
                                                            title={translate(`$__completed`, '*')}
                                                            text={translate(`$__completed`, '*')}
                                                            color={notificationType === "completed" ? customApp("menu") : 'lightGray'}
                                                            icon={'verified'}
                                                            onClick={() => setNotificationType("completed")}
                                                            size={'22px'}
                                                            style={{ padding: "0px 3px !important", width: "100%" }}
                                                            transparent
                                                            badgeContent={xCount.completed}
                                                        />
                                                    </li>
                                                ) : null}
                                                {xCount.deleted ? (
                                                    <li style={{ padding: "15px 0px" }}>
                                                        <CustomButton
                                                            title={translate(`$__deletedItems`, '*')}
                                                            text={translate(`$__deletedItems`, '*')}
                                                            color={notificationType === "deleted" ? customApp("menu") : 'lightGray'}
                                                            icon={'delete_sweep'}
                                                            onClick={() => setNotificationType("deleted")}
                                                            size={'22px'}
                                                            style={{ padding: "0px 3px !important", width: "100%" }}
                                                            transparent
                                                            badgeContent={xCount.deleted}
                                                        />
                                                    </li>
                                                ) : null}
                                                <li style={{ padding: "15px 0px" }}>
                                                    <CustomButton
                                                        title={translate(`$__all`, '*')}
                                                        text={translate(`$__all`, '*')}
                                                        color={notificationType === "all" ? customApp("menu") : 'lightGray'}
                                                        icon={'all_inbox'}
                                                        onClick={() => setNotificationType("all")}
                                                        size={'22px'}
                                                        style={{ padding: "0px 3px !important", width: "100%" }}
                                                        transparent
                                                    />
                                                </li>
                                            </ul>
                                            <div>
                                                <div style={{ position: "absolute", bottom: 0, left: 0 }}>
                                                    <CustomButton
                                                        title={translate(`$__clearNotifications`, 1)}
                                                        text={translate(`$__clearNotifications`, 1)}
                                                        color={customApp('color')}
                                                        icon={'clear_all'}
                                                        onClick={clearNotifications}
                                                        size={'21px'}
                                                        style={{ padding: "0px !important" }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{
                                            height: "auto",
                                            maxHeight: "calc(90vh - 80px)",
                                            width: "600px",
                                            backgroundColor: "rgba(247,247,247,1)",
                                            overflowY: "auto"
                                        }}>
                                            {renderCards.slice(0, limit[actualNotificationType] || 6).map(n => (
                                                <div key={n}>
                                                    <Notification
                                                        type={notificationType}
                                                        data={{ cardId: n }}
                                                        onClick={() => setOpen(false)}
                                                    />
                                                </div>
                                            ))}
                                            {(limit[actualNotificationType] || 6) < renderCards.length && (
                                                <div style={{ textAlign: "center", marginTop: "10px" }}>
                                                    <button
                                                        onClick={() => setLimit(prev => ({
                                                            ...prev,
                                                            [actualNotificationType]: (prev[actualNotificationType] || 6) + 6
                                                        }))}
                                                        style={{
                                                            padding: "7px 10px",
                                                            backgroundColor: "#232323",
                                                            color: "#fff",
                                                            border: "none",
                                                            borderRadius: "5px",
                                                            cursor: "pointer",
                                                            width: "100%",
                                                            zIndex: 10
                                                        }}
                                                    >
                                                        Exibir mais
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
}

const mapStateToProps = (store) => ({
    notifications: store.notifications,
    timeline: store.timeline,
    session: store.session,
    cards: store.db.cards,
    users: store.db.users,
    functions: store.functions
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);