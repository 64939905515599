import React from "react";

// REDUX
import reduxStore from "store/";

// COMPONENTS
import ColorPicker from "components/ColorPicker";
import CustomButton from "components/Buttons/custom";
import Icon from "components/Icon";
import MoreMenu from "components/MoreMenu/";
import NotificationCount from "componentsV3/Notifications/count";
import TextField from "components/TextField";

// @MATERIAL
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Badge from "components/Icon/Badge";
import Tooltip from "@material-ui/core/Tooltip";

// FUNCTIONS
import { translate, customApp, Highlighted } from "functions/";

const useStyles = makeStyles((theme) => ({
    header: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between"
    },
    content: {
        position: "relative",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center"
    },
    contentWithExpand: {
        position: "relative",
        width: "calc(100% - 7px)",
        height: "100%",
        display: "flex",
        paddingLeft: "5px",
        paddingTop: "5px",
        alignItems: "center",
        justifyContent: "center"
    },
    info: {
        display: "flex",
        width: "100%",
        alignItems: "center"
    },
    headerIcon: {
        display: "contents",
        alignItems: "center"
    },
    headerTitle: {
        width: "100%",
        overflowX: "hidden",
        overflowY: "hidden",
        fontWeight: "bold"
    },
    actionButtons: {
        position: "absolute",
        height: "24px",
        right: "3px",
        display: "flex"
    },
    cancelButton: {
        right: "4px",
        position: "absolute",
        cursor: "pointer"
    },
    saveButton: {
        right: "33px",
        position: "absolute",
        cursor: "pointer"
    },
    button: {
        padding: "3px auto",
        cursor: "pointer",
        alignItems: "center",
        display: "flex",
        opacity: 1
    },
    parent: {
        width: "100%",
        padding: "0 5px",
        "& .title": {
            color: customApp("medium"),
            fontSize: "10px",
            textTransform: "uppercase"
        },
        "& .info": {
            marginTop: "-8px",
            color: customApp("colorText"),
            fontWeight: "500",
            alignItems: "center"
        }
    },
    iconButtonStyle: {
        margin: 0,
        backgroundColor: "#FFFFFF",
        "&:hover": {
            backgroundColor: "rgba(250,250,250,0.9)"
        },
        padding: "3px"
    }
}));

function HeaderComponent(props) {
    const {
        noExpanding,
        onExpanding,
        isExpanded,
        options,
        title,
        editable,
        unlockedPrivates,
        style,
        backClick,
        private: isPrivate,
        onEdit,
        _id: id,
        fontSize,
        iconTitle,
        privateTitle,
        color,
        optionsProps,
        setPrivate,
        colorPicker,
        moreButton,
        showOrder,
        value,
        viewAllName,
        expanded,
        tooltipText,
        actionsSize,
        onSave,
        onCancel,
        multiline,
        icon,
        searchText,
        searchTextStyle,
        dark,
        noAutoFocus,
        fieldName,
        inputStyle,
        textStyle
    } = props;

    const { functions, db } = reduxStore.getState();
    const { socket } = functions;
    const classes = useStyles();

    const [name, setName] = React.useState(title?.trim() || null);
    const [editing, setEditing] = React.useState(false);
    const [showPrivateCard, setShowPrivateCard] = React.useState(false);

    React.useEffect(() => {
        if (unlockedPrivates) {
            setShowPrivateCard(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unlockedPrivates]);

    React.useEffect(() => {
        if (!title && editable) {
            setEditing(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title]);

    const stripHtml = (html) => {
        const tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    };

    const saveData = async (newData) => {
        try {
            if (id) {
                socket.emit(
                    "data",
                    {
                        module: "node",
                        method: "put",
                        action: "update",
                        noLog: true
                    },
                    {
                        _id: id,
                        ...newData
                    }
                );
            }
            if (onEdit) onEdit(name, id);
        } catch (e) {
            console.log(e);
        }
        setEditing(false);
    };

    const hasOptions = options && options.length > 0;

    return (
        <div
            className={classes.header}
            style={{
                minHeight: style?.minHeight || "30px",
                ...style,
                ...(backClick ? { cursor: "pointer" } : {})
            }}
        >
            <div className={!onExpanding || editing ? classes.content : classes.contentWithExpand}>
                <div className={classes.info}>
                    {isPrivate && !reduxStore.getState().controls.lockPrivate && !showPrivateCard ? (
                        <>
                            <div className={classes.headerIcon} style={{ color: "lightgray" }}>
                                <Badge
                                    overlap="rectangular"
                                    size={`calc(${fontSize} + 4px)` || "22px"}
                                    icon={"private_connectivity"}
                                    color={"parent"}
                                    title={
                                        iconTitle
                                            ? iconTitle.charAt(0).toUpperCase() + iconTitle.slice(1)
                                            : ""
                                    }
                                />
                            </div>
                            <div style={{ marginLeft: "7px", fontSize: 12, color: "lightgray" }}>
                                {privateTitle ? privateTitle : translate("$__privateActivity")}
                            </div>
                        </>
                    ) : (
                        <>
                            {icon && (
                                <div
                                    className={classes.headerIcon}
                                    style={{
                                        color: color ? color : customApp("colorText"),
                                        ...(backClick ? { cursor: "pointer" } : {})
                                    }}
                                >
                                    <Badge
                                        overlap="rectangular"
                                        size={`calc(${fontSize} + 4px)` || "22px"}
                                        icon={icon}
                                        color={"parent"}
                                        title={
                                            iconTitle
                                                ? iconTitle.charAt(0).toUpperCase() + iconTitle.slice(1)
                                                : ""
                                        }
                                    />
                                </div>
                            )}
                            {searchText ? (
                                <div style={{ marginLeft: "7px", ...searchTextStyle }}>
                                    {Highlighted(title ? title : "", searchText, dark ? true : false)}
                                </div>
                            ) : (
                                <div
                                    className={classes.headerTitle}
                                    style={{
                                        fontSize: fontSize || "14px",
                                        color: color || customApp("colorText"),
                                        marginLeft: icon ? "7px" : "none",
                                        ...textStyle,
                                        ...(backClick ? { cursor: "pointer" } : {})
                                    }}
                                    onClick={
                                        backClick
                                            ? (e) => {
                                                backClick();
                                                e.stopPropagation();
                                            }
                                            : null
                                    }
                                >
                                    {editing ? (
                                        <div
                                            style={{
                                                position: "relative",
                                                float: "left",
                                                width: "100%",
                                                paddingTop: "10px",
                                                paddingLeft: "7px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                ...inputStyle
                                            }}
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            <TextField
                                                style={{ width: "calc(100% - 60px)" }}
                                                label={fieldName ? fieldName : translate("$__nameOfCard", 1)}
                                                variant={customApp("fieldVariant")}
                                                name="name"
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{
                                                    autoComplete: "new-password",
                                                    onChange: (d) => setName(d.target.value),
                                                    onKeyDown: (e) => {
                                                        if (e.keyCode === 13 && name) {
                                                            saveData({ name });
                                                        }
                                                        if (e.keyCode === 27) {
                                                            setEditing(false);
                                                            setName(value ? value : title ? title.trim() : "");
                                                        }
                                                    }
                                                }}
                                                value={name}
                                                placeholder={translate("$__titleOfCard_placeholder", 1)}
                                                autoFocus={noAutoFocus ? false : true}
                                                multiline={multiline ? true : false}
                                            />
                                            <Badge
                                                color={customApp("medium")}
                                                onClick={() =>
                                                    editing ? setEditing(false) : onCancel()
                                                }
                                                icon={"cancel"}
                                                title={translate("$__close", "*")}
                                                size={actionsSize || "25px"}
                                            />
                                            <Badge
                                                overlap="rectangular"
                                                color="green"
                                                icon={"save"}
                                                onClick={() => {
                                                    if (editing) {
                                                        saveData({ name });
                                                    } else {
                                                        onSave();
                                                    }
                                                }}
                                                title={translate("$__save", " * ")}
                                                size={actionsSize || "25px"}
                                            />
                                        </div>
                                    ) : (
                                        <Tooltip title={tooltipText ? tooltipText : title ? title : ""}>
                                            <div
                                                style={
                                                    !expanded
                                                        ? {
                                                            width: "100%",
                                                            textOverflow: "ellipsis",
                                                            overflow: "hidden",
                                                            whiteSpace:
                                                                viewAllName === true ? undefined : "nowrap"
                                                        }
                                                        : { width: "100%" }
                                                }
                                                onDoubleClick={() => {
                                                    if (onEdit && editable) {
                                                        setName(value ? value : title ? title.trim() : "");
                                                        setEditing(true);
                                                    }
                                                }}
                                            >
                                                {`${showOrder ? `${showOrder}. ` : ""}${title && title.indexOf("$__") > -1
                                                    ? translate(title, 1)
                                                    : title
                                                        ? stripHtml(title)
                                                        : ""
                                                    }`}
                                            </div>
                                        </Tooltip>
                                    )}
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
            {!editing && (
                <div
                    style={{
                        position: "absolute",
                        right: 7,
                        padding: "3px 0px",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: 7,
                        backgroundColor: "rgba(250,250,250,0.7)"
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                >
                    {!moreButton ? (
                        <NotificationCount nodeId={id} />
                    ) : (
                        <div
                            style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                display: "flex",
                                alignItems: "center"
                            }}
                            onClick={(e) => e.stopPropagation()}
                        >
                            {isPrivate &&
                                !reduxStore.getState().controls.lockPrivate &&
                                !showPrivateCard ? (
                                <>
                                    <CustomButton
                                        title={translate(`$__showPrivateActivity`, "*")}
                                        color="lightGray"
                                        icon="lock_open"
                                        onClick={() => {
                                            setShowPrivateCard((prev) => !prev);
                                            if (setPrivate) setPrivate(!showPrivateCard);
                                        }}
                                        size="21px"
                                        style={{ padding: "0px !important" }}
                                    />
                                </>
                            ) : (
                                <>
                                    {colorPicker && (
                                        <div style={{ zIndex: 1000 }}>
                                            <ColorPicker
                                                color={
                                                    id && db.cards[id] && db.cards[id].color
                                                        ? db.cards[id].color
                                                        : null
                                                }
                                                _id={id}
                                                onChange={(c) => {
                                                    colorPicker(c);
                                                }}
                                                style={{ zIndex: 5000 }}
                                            />
                                        </div>
                                    )}
                                    {hasOptions &&
                                        options
                                            .filter(
                                                (a) =>
                                                    a &&
                                                    a.name &&
                                                    (a.name === "$__removeSelection" || a.name === "$__selectThis")
                                            )
                                            .length > 0 && (
                                            <Tooltip
                                                title={translate(
                                                    options.filter(
                                                        (a) =>
                                                            a &&
                                                            a.name &&
                                                            (a.name === "$__removeSelection" || a.name === "$__selectThis")
                                                    )[0].name
                                                )}
                                            >
                                                <IconButton
                                                    onClick={(e) => {
                                                        options
                                                            .filter(
                                                                (a) =>
                                                                    a &&
                                                                    a.name &&
                                                                    (a.name === "$__removeSelection" || a.name === "$__selectThis")
                                                            )[0].onClick();
                                                    }}
                                                    className={classes.iconButtonStyle}
                                                >
                                                    <Icon
                                                        size="22px"
                                                        color={customApp("menu")}
                                                        icon={
                                                            options.filter(
                                                                (a) =>
                                                                    a &&
                                                                    a.name &&
                                                                    (a.name === "$__removeSelection" || a.name === "$__selectThis")
                                                            )[0].icon
                                                        }
                                                        usePropagation
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    {options &&
                                        options.filter((a) => a && a.show === "fixed").length > 0 ? (
                                        options
                                            .filter((a) => a && a.show === "fixed")
                                            .map((b, i) => (
                                                <Tooltip key={`opt${i}`} title={translate(b.name)}>
                                                    <IconButton
                                                        onClick={() => b.onClick()}
                                                        className={classes.iconButtonStyle}
                                                    >
                                                        <Icon
                                                            size="22px"
                                                            color={b.color || customApp("menu")}
                                                            icon={b.icon}
                                                            usePropagation
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            ))
                                    ) : (
                                        <React.Fragment></React.Fragment>
                                    )}
                                    {!noExpanding && onExpanding && (
                                        <Tooltip title={translate(!isExpanded ? `$__expand` : `$__collapse`)}>
                                            <IconButton
                                                onClick={(e) => onExpanding()}
                                                className={classes.iconButtonStyle}
                                            >
                                                <Icon
                                                    size="22px"
                                                    color={customApp("menu")}
                                                    icon={!isExpanded ? "expand_more" : "expand_less"}
                                                    usePropagation
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {hasOptions && (
                                        <MoreMenu
                                            noButton
                                            options={[
                                                ...options,
                                                ...(isPrivate && showPrivateCard
                                                    ? [
                                                        {
                                                            color: "lightblue",
                                                            icon: "lock",
                                                            name: "$__lockThisPrivateCard",
                                                            onClick: () => {
                                                                setShowPrivateCard((prev) => !prev);
                                                                if (setPrivate) setPrivate(!showPrivateCard);
                                                            },
                                                            show: "hover"
                                                        }
                                                    ]
                                                    : [])
                                            ]}
                                            {...(optionsProps || {})}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default HeaderComponent;
