import React, { useEffect, useState } from "react";

//REDUX
import reduxStore from "store/";

//COMPONENTES
import Icon from "components/Icon";
import TextField from "components/TextField";
import Checkbox from "components/Checkbox";

//@MATERIAL
import { InputAdornment, Avatar, FormControlLabel } from "@material-ui/core";

//FUNCTIONS
import { customApp, translate } from "functions";

const Historic = () => {
  const { db } = reduxStore.getState();
  const { cards } = db;
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    const cardsPlans = Object.values(cards)
      .filter((value) => value.type === "plan")
      .map((value) => value.name);
    setPlans([...new Set(cardsPlans)]);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "auto",
          boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
          backgroundColor: "#fff",
          borderRadius: "3px",
          fontFamily: "Soleto, Roboto, Helvetica, Arial, sans-serif",
          marginBottom: "50px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "5px",
            justifyContent: "center",
            borderBottom: "#e8e8e8 1px solid",
            marginBottom: "15px",
            padding: "7px 0px",
            color: customApp("colorText"),
            background: customApp("ColumnTitleColor"),
          }}
        >
          <Icon style={{ color: customApp('color') }}>history</Icon>
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            {translate('$__activityHistory')}
          </span>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ width: "70%" }}></div>
          <div
            style={{
              height: "100%",
              borderLeft: "1px solid #b9b9b9",
              padding: "0px 20px",
            }}
          >
            <TextField
              id={`search`}
              label={translate("$__search", 1)}
              variant={customApp("fieldVariant")}
              name={"search"}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon>search</Icon>
                  </InputAdornment>
                ),
              }}
              placeholder={translate("$__search", 1)}
              autoFocus
            />
            {plans?.map((plan) => (
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                }}
                key={plan}
              >
                <Avatar>{plan.slice(0, 2)}</Avatar>
                <FormControlLabel
                  required
                  control={<Checkbox color="default" />}
                  label={plan}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Historic;
