import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import MD5 from "crypto-js/md5";

// REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import reduxStore from "store";

// COMPONENTS
import HeaderWithIcon from "components/Header/card";
import CustomButton from "components/Buttons/custom";
import Card from "components/Card";
import Icon from "components/Icon";
import SelectedMenu from "componentsV3/SelectedMenu/SelectedMenu";

// @MATERIAL
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";

// DEPENDENCIES
import Column from "./Column";

// FUNCTIONS
import { linkCard, updateCard, getChildrens } from "functions/cards";
import { translate, appAlert, customApp } from "functions";
import { countNotifications } from "functions/cards";

// Helper: delay wrapper
const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

// Helper: recalculate tree of children – utilizando dbCards passado como parâmetro
const trackingTreeChildrens = (id, dbCards, selectedTree) => {
    if (!selectedTree.includes(id)) {
        selectedTree.push(id);
    }
    Object.keys(dbCards).forEach(c => {
        const card = dbCards[c];
        if (card._parent === id && !card.checklist && !selectedTree.includes(c)) {
            trackingTreeChildrens(c, dbCards, selectedTree);
            // Não é necessário empurrar c novamente, pois a recursão já cuida disso
        }
    });
};

const trackingTreeParents = (id, dbCards, selectedTree) => {
    if (!selectedTree.includes(id)) {
        selectedTree.push(id);
    }
    Object.keys(dbCards).forEach(c => {
        // Se o card c for igual a id ou for pai de id e não for checklist
        if (
            id &&
            dbCards[id] &&
            (c === id || dbCards[id]._parent === c) &&
            !dbCards[c].checklist &&
            !selectedTree.includes(c)
        ) {
            if (dbCards[c] && dbCards[c]._parent) {
                trackingTreeParents(dbCards[c]._parent, dbCards, selectedTree);
            }
            selectedTree.push(c);
        }
    });
};

function Overview(props) {
    const { db, session } = reduxStore.getState();
    const { dataType } = db;
    const [Migrator, setMigrator] = useState(false);
    const [cardsUpdate, setCardsUpdate] = useState(true);
    const [MigratorData, setMigratorData] = useState({});
    const [selectedCardState, setSelectedCardState] = useState({
        checkSelectedCards: [],
        childrenChecked: []
    });
    const [cards, setCards] = useState({});

    const searchID = MD5(`${window.location.pathname}-${window.location.hash}`).toString();

    // Processamento da URL
    let hashs = window.location.hash.split("/");
    hashs[0] = hashs[0].replace("#", "");
    if (hashs[0].length !== 36) {
        hashs[0] = null;
    }

    let activeCards = {};
    let selectedTree = [];

    const selectedGuideline = props.searchs[searchID]?.selectedCards?.guideline || null;
    const selectedObjective = props.searchs[searchID]?.selectedCards?.objective || null;
    const selectedGoal = props.searchs[searchID]?.selectedCards?.goal || null;
    const selectedTasks = props.searchs[searchID]?.selectedCards?.action || null;

    const overview = [
        { value: "guidelines", type: "guideline", label: "$__guidelines", icon: "alt_route", viewButton: true, selectedParent: null },
        { value: "objectives", type: "objective", label: "$__objectives", icon: dataType.find(a => a.name === "objective").icon, viewButton: true, selectedParent: selectedGuideline },
        { value: "goals", type: "goal", label: "$__goals", icon: dataType.find(a => a.name === "goal").icon, viewButton: true, selectedParent: selectedObjective || selectedGuideline },
        { value: "actions", type: "iniciative", label: "$__actions", icon: "double_arrow", viewButton: true, selectedParent: selectedGoal || selectedObjective || selectedGuideline }
    ];

    // Reinicia o estado dos cards selecionados quando showCheck mudar
    useEffect(() => {
        if (!props.showCheck) {
            setSelectedCardState({
                checkSelectedCards: [],
                childrenChecked: []
            });
        }
    }, [props.showCheck]);

    // Atualiza os cards quando houver mudanças
    useEffect(() => {
        if (cardsUpdate) {
            const dbCards = db.cards;
            // Constrói activeCards com base em critérios de filtro
            Object.keys(dbCards).forEach(a => {
                let notifications = countNotifications(a);
                const card = dbCards[a];
                if (
                    card &&
                    (
                        !card.private ||
                        (card.private && card._users && card._users[session._id])
                    ) &&
                    (card._planId === hashs[0] || card._parent === hashs[0]) &&
                    (props.showCompleted || (!props.showCompleted && card.status !== "completed")) &&
                    (!props.onlyUnReaded || (props.onlyUnReaded && notifications.total > 0))
                ) {
                    activeCards[a] = card;
                }
            });

            // Se houver um card selecionado, atualiza o selectedTree
            if (selectedTasks) {
                trackingTreeChildrens(selectedTasks, dbCards, selectedTree);
                trackingTreeParents(selectedTasks, dbCards, selectedTree);
            } else if (selectedGoal) {
                trackingTreeChildrens(selectedGoal, dbCards, selectedTree);
                trackingTreeParents(selectedGoal, dbCards, selectedTree);
            } else if (selectedObjective) {
                trackingTreeChildrens(selectedObjective, dbCards, selectedTree);
                trackingTreeParents(selectedObjective, dbCards, selectedTree);
            } else if (selectedGuideline) {
                trackingTreeChildrens(selectedGuideline, dbCards, selectedTree);
                trackingTreeParents(selectedGuideline, dbCards, selectedTree);
            }

            // Processa os cards para cada coluna
            const computedCards = {};

            computedCards["guidelines"] = Object.keys(activeCards).filter(a => {
                const card = activeCards[a];
                return (
                    (card._parent === hashs[0] || card._planId === hashs[0]) &&
                    card.type &&
                    card.type.indexOf("guideline") > -1 &&
                    (selectedTree.length === 0 || selectedTree.includes(a))
                );
            });

            computedCards["objectives"] = Object.keys(activeCards).filter(a => {
                const card = activeCards[a];
                return (card.type === "objective" && !card.checklist && (selectedTree.length === 0 || selectedTree.includes(a)));
            });

            computedCards["objectives2"] = Object.keys(activeCards).filter(a => {
                const card = activeCards[a];
                return (
                    !selectedObjective &&
                    card.type === "objective" &&
                    card._parent !== selectedGuideline &&
                    !card.checklist &&
                    computedCards["objectives"].includes(card._parent) &&
                    (selectedTree.length === 0 || selectedTree.includes(a))
                );
            });
            computedCards["objectives2"].forEach(a => {
                if (!computedCards["objectives"].includes(a)) {
                    computedCards["objectives"].push(a);
                }
            });

            computedCards["goals"] = Object.keys(activeCards).filter(a => {
                const card = activeCards[a];
                return (card.type === "goal" && (selectedTree.length === 0 || selectedTree.includes(a)));
            });

            computedCards["goals2"] = Object.keys(activeCards).filter(a => {
                const card = activeCards[a];
                return (
                    card.type === "goal" &&
                    !card.checklist &&
                    computedCards["goals"].includes(card._parent) &&
                    (selectedTree.length === 0 || selectedTree.includes(a))
                );
            });
            computedCards["goals2"].forEach(a => {
                if (!computedCards["goals"].includes(a)) {
                    computedCards["goals"].push(a);
                }
            });

            computedCards["actions"] = Object.keys(activeCards).filter(a => {
                const card = activeCards[a];
                return (
                    card.type &&
                    !card.checklist &&
                    card.type !== "plan" &&
                    card.type !== "objective" &&
                    card.type !== "goal" &&
                    card.type !== "feed" &&
                    card.type !== "link" &&
                    card.type !== "insight" &&
                    card.type !== "chatGroup" &&
                    card.type.indexOf("guideline") === -1 &&
                    dataType.filter(dt => dt.name === card.type).length > 0 &&
                    dataType.filter(dt => dt.name === card.type)[0].module !== "swot" &&
                    (selectedTree.length === 0 || selectedTree.includes(a))
                );
            });

            // Mapeia para os objetos reais
            computedCards["guidelines"] = computedCards["guidelines"].map(a => activeCards[a]);
            computedCards["objectives"] = computedCards["objectives"].map(a => activeCards[a]);
            computedCards["goals"] = computedCards["goals"].map(a => activeCards[a]);
            computedCards["actions"] = computedCards["actions"].map(a => activeCards[a]);

            setCards(computedCards);
        }
        // eslint-disable-next-line
    }, [db.cards, props.cards, props.searchs, cardsUpdate, session, dataType, props.showCompleted, props.onlyUnReaded]);

    const onDragEnd = async result => {
        const { destination, source, draggableId } = result;
        if (result.combine) {
            appAlert({
                message: `${translate("$__confirmLink", 1)}: ${reduxStore.getState().db.cards[draggableId].name} >>> ${reduxStore.getState().db.cards[result.combine.draggableId] ? reduxStore.getState().db.cards[result.combine.draggableId].name : ""}`,
                variant: "warning",
                persist: true,
                horizontal: "right",
                confirm: () => {
                    if (result.combine.draggableId)
                        linkCard(props, {
                            cardId: draggableId,
                            cardToLink: result.combine.draggableId
                        });
                }
            });
            return;
        }
        if (!destination) return false;
        if (destination.droppableId === source.droppableId && destination.id === source.index) return false;

        const sourceColumn = source.droppableId;
        const destinationColumn = destination.droppableId;
        const dbCards = reduxStore.getState().db.cards;
        // Cria uma cópia de activeCards a partir do estado atual
        let activeCardsTemp = { ...dbCards };

        const sourceCardIds = Object.keys(activeCardsTemp)
            .filter(c => activeCardsTemp[c].status === sourceColumn)
            .sort((a, b) => {
                const orderA = activeCardsTemp[a].order && activeCardsTemp[a].order.low ? parseInt(activeCardsTemp[a].order.low) : 0;
                const orderB = activeCardsTemp[b].order && activeCardsTemp[b].order.low ? parseInt(activeCardsTemp[b].order.low) : 0;
                return orderA - orderB;
            })
            .map(c => activeCardsTemp[c]._id);

        if (sourceColumn === destinationColumn && reduxStore.getState().db.orderByTitle) return;

        if (sourceColumn === destinationColumn && !reduxStore.getState().db.orderByTitle) {
            const { socket } = reduxStore.getState().functions;
            let columnName = sourceColumn === "iniciative" ? "action" : sourceColumn;
            let itemOrderIds = cards[`${columnName}s`]
                .filter(card => card.status && card.status !== "completed")
                .sort((a, b) => {
                    const orderA = a?.order?.low || (!isNaN(a?.order) ? parseInt(a.order) : a.order ? parseInt(a.order) : 0);
                    const orderB = b?.order?.low || (!isNaN(b?.order) ? parseInt(b.order) : b.order ? parseInt(b.order) : 0);
                    return orderA - orderB;
                })
                .map(card => card._id);

            itemOrderIds.splice(source.index, 1);
            await wait(500);
            itemOrderIds.splice(destination.index, 0, draggableId);
            await wait(500);

            socket.emit("data", {
                module: "cards",
                method: "put",
                action: "reorder"
            }, { cards: itemOrderIds });

            let newCards = { ...reduxStore.getState().db.cards };
            await new Promise(resolve => {
                itemOrderIds.forEach((a, i) => {
                    newCards = {
                        ...newCards,
                        [a]: {
                            ...newCards[a],
                            order: `${i + 1}`
                        }
                    };
                    if (i + 1 === itemOrderIds.length) resolve(true);
                });
            });
            props.reduxFunction("IMMEDIATE", "SET_DB", {
                ...reduxStore.getState().db,
                cards: {
                    ...reduxStore.getState().db.cards,
                    ...newCards
                }
            });
            return;
        }

        // Movendo card entre colunas
        sourceCardIds.splice(source.index, 1);
        const destinationCardIds = Object.keys(activeCardsTemp)
            .filter(c => activeCardsTemp[c].status === destinationColumn)
            .sort((a, b) => {
                const orderA = activeCardsTemp[a].order && activeCardsTemp[a].order.low ? parseInt(activeCardsTemp[a].order.low) : 0;
                const orderB = activeCardsTemp[b].order && activeCardsTemp[b].order.low ? parseInt(activeCardsTemp[b].order.low) : 0;
                return orderA - orderB;
            })
            .map(c => activeCardsTemp[c]._id);
        destinationCardIds.splice(destination.index, 0, draggableId);

        // Atualiza ordem dos cards no db
        sourceCardIds.forEach((a, i) => {
            if (!dbCards[a].order || !dbCards[a].order.low) {
                dbCards[a] = { ...dbCards[a], order: { low: parseInt(i) } };
            } else {
                dbCards[a].order.low = parseInt(i);
            }
        });
        destinationCardIds.forEach((a, i) => {
            if (!dbCards[a].order || !dbCards[a].order.low) {
                dbCards[a] = { ...dbCards[a], order: { low: parseInt(i) } };
            } else {
                dbCards[a].order.low = parseInt(i);
            }
        });

        const childrens = getChildrens(draggableId);
        const data = {
            _id: draggableId,
            type: destination.droppableId,
            _childrens: childrens,
            _objectives: cards["objectives"].filter(fil => childrens[fil._id]).map(a => a),
            _goals: cards["goals"].filter(fil => childrens[fil._id]).map(a => a)
        };

        if (dbCards[draggableId].type === "goal" && destination.droppableId === "guideline") {
            appAlert({
                message: translate('$__YouCannotMoveThisItemToThisColumnMoveFirstToTheObjectiveColumn', 1),
                variant: "error",
                persist: false,
                horizontal: "center"
            });
            setMigratorData({});
            return;
        }
        if (
            dbCards[draggableId].type.indexOf("guideline") === -1 &&
            dbCards[draggableId].type !== "objective" &&
            dbCards[draggableId].type !== "goal" &&
            (destination.droppableId === "guideline" || destination.droppableId === "objective")
        ) {
            appAlert({
                message: translate('$__YouCannotMoveThisItemToThisColumnMoveFirstToTheGoalsColumn', 1),
                variant: "error",
                persist: false,
                horizontal: "center"
            });
            setMigratorData({});
            return;
        }
        if (dbCards[draggableId].type.indexOf("guideline") > -1 && dbCards[draggableId].type !== "guideline") {
            appAlert({
                message: translate('$__youCantMoveGuidelineWithSwotLinked', 1),
                variant: "error",
                persist: false,
                horizontal: "center"
            });
        } else if (
            dbCards[draggableId].type === "guideline" &&
            destination.droppableId !== "guideline" &&
            (cards["objectives"].filter(fil => fil._parent === draggableId).length > 0 ||
                cards["goals"].filter(fil => fil._parent === draggableId).length > 0)
        ) {
            setMigratorData(data);
            setMigrator(true);
        } else if (
            dbCards[draggableId].type.indexOf("objective") > -1 &&
            (destination.droppableId !== "guideline" && destination.droppableId !== "objective") &&
            (cards["goals"].filter(fil => fil._parent === draggableId).length > 0)
        ) {
            setMigratorData(data);
            setMigrator(true);
        } else {
            appAlert({
                message: `${translate("$__confirmTransformIn", 1)}: ${translate(`$__${destination.droppableId}`)}`,
                variant: "warning",
                persist: true,
                horizontal: "right",
                confirm: () => {
                    updateType(data);
                    if (dbCards[dbCards[draggableId]._parent].type.indexOf(destination.droppableId) > -1) {
                        linkCard(props, {
                            cardId: draggableId,
                            cardToLink: dbCards[dbCards[draggableId]._parent]._parent
                        });
                    }
                },
                cancel: () => {
                    setMigratorData({});
                }
            });
        }
    };

    const updateType = async (d) => {
        let data = d;
        if (MigratorData && MigratorData._id) data = MigratorData;
        await updateCard(props, data);
        props.reduxFunction("IMMEDIATE", "SET_DB", {
            ...reduxStore.getState().db,
            cards: {
                ...reduxStore.getState().db.cards,
                [data._id]: {
                    ...reduxStore.getState().db.cards[data._id],
                    type: data.type
                }
            }
        });
        setMigratorData({});
    };

    const iniMigration = async (type) => {
        const MCards = type === "goals" ? MigratorData._goals : MigratorData._objectives;
        cards[type]
            .filter(fil => MigratorData._childrens[fil._id])
            .forEach(async (a) => {
                MCards.filter(c => c._id === a._id).forEach(aItem => {
                    aItem.type = type === "goals" ? "task" : "goal";
                });
                await updateCard(props, {
                    _id: a._id,
                    type: type === "goals" ? "task" : "goal"
                });
            });
        setMigratorData({
            ...MigratorData,
            ...(type === "goals" ? { _goals: MCards } : { _objectives: MCards })
        });
    };

    const finishMigration = async () => {
        await updateCard(props, {
            _id: MigratorData._id,
            type: MigratorData.type
        });
        setMigrator(false);
        setMigratorData({});
    };

    const selectCards = (selectedCardState, cardID, cardArr) => {
        let newData = { ...selectedCardState };
        const parent = reduxStore.getState().db.cards[cardID]._parent;

        if (
            !selectedCardState.checkSelectedCards.includes(cardID) &&
            !selectedCardState.childrenChecked.includes(cardID) &&
            !selectedCardState.checkSelectedCards.includes(parent)
        ) {
            newData.checkSelectedCards = [...selectedCardState.checkSelectedCards, cardID];
        } else if (selectedCardState.checkSelectedCards.includes(parent)) {
            if (!selectedCardState.childrenChecked.includes(cardID)) {
                newData.childrenChecked = [...selectedCardState.childrenChecked, cardID];
            } else {
                newData.childrenChecked = selectedCardState.childrenChecked.filter(Id => Id !== cardID);
            }
        } else {
            newData.checkSelectedCards = selectedCardState.checkSelectedCards.filter(Id => Id !== cardID);
            newData.childrenChecked = selectedCardState.childrenChecked.filter(Id => Id !== cardID);
        }

        let children = Object.keys(getChildrens(cardID)) || [];
        children.filter(Id => Id !== cardID).forEach(item => {
            if (newData.childrenChecked.includes(item)) {
                newData.childrenChecked = newData.childrenChecked.filter(Id => Id !== item);
            } else if (newData.childrenChecked.includes(cardID) || newData.checkSelectedCards.includes(cardID)) {
                newData.childrenChecked = [...newData.childrenChecked, item];
            }
        });

        setSelectedCardState(newData);
    };

    return (
        <div>
            <DragDropContext onDragEnd={onDragEnd}>
                <SelectedMenu
                    selectCards={selectedCardState}
                    handleCheckBoxState={() => props.handleCheckBoxState()}
                    handleCardsUpdate={value => setCardsUpdate(value)}
                    handleState={() =>
                        setSelectedCardState({
                            checkSelectedCards: [],
                            childrenChecked: []
                        })
                    }
                />
                <Droppable
                    droppableId="ALL-COLUMNS-TYPE"
                    type="column"
                    direction={props.inLine ? "vertical" : "horizontal"}
                    isDropDisabled={true}
                >
                    {provided => (
                        <Container {...props} ref={provided.innerRef}>
                            {overview.map((st, index) => {
                                const theColumn = {
                                    name: st.label,
                                    icon: st.icon,
                                    cards: [],
                                    emptyHide: false
                                };
                                return (
                                    <Column
                                        {...props}
                                        key={st.value}
                                        columnId={st.type}
                                        isDropDisabled
                                        column={theColumn}
                                        setSelectedCards={(cardID, cardArr) => selectCards(selectedCardState, cardID, cardArr)}
                                        selectedCards={[...selectedCardState.checkSelectedCards, ...selectedCardState.childrenChecked]}
                                        showCheck={props.showCheck}
                                        showCatenation={props.showCatenation || false}
                                        cards={cards && cards[st.value] ? cards[st.value].map(a => a._id) : []}
                                        index={index}
                                        columnsSettings={false}
                                        locked={false}
                                        columnStyle={{ minWidth: "200px" }}
                                        viewButton={st.viewButton}
                                        isCombineEnabled
                                        inLineAdd
                                        typeAdd={st.type}
                                        selectedParent={st.selectedParent}
                                        cardColorPicker={props.showColors ? true : false}
                                    />
                                );
                            })}
                            {provided.placeholder}
                        </Container>
                    )}
                </Droppable>
            </DragDropContext>
            <Dialog fullWidth aria-labelledby="simple-dialog-title" open={Migrator}>
                <div
                    style={{
                        position: "relative",
                        marginLeft: "10px",
                        marginTop: "5px",
                        width: "calc(100% - 10px)"
                    }}
                >
                    <HeaderWithIcon
                        title={translate("$__attention", 1)}
                        icon="label"
                        onCancel={() => {
                            setMigrator(false);
                            setMigratorData({});
                        }}
                    />
                </div>
                <div style={{ color: "#CCCCCC", padding: "15px" }}>
                    {MigratorData._childrens &&
                        !MigratorData._skipGoals &&
                        MigratorData._goals.length > 0 &&
                        MigratorData._goals.filter(
                            a =>
                                reduxStore.getState().db.cards[a._id] &&
                                reduxStore.getState().db.cards[a._id].type === a.type
                        ).length > 0 && (
                            <>
                                <span style={{ color: "gray" }}>
                                    {translate(`$__YouHaveLinkedGoals`)}
                                    <p style={{ color: "red" }}>
                                        {translate(`$__ThisActionCanOnlyBeUndoneManually`)}
                                    </p>
                                </span>
                                {MigratorData._goals
                                    .filter(
                                        a =>
                                            reduxStore.getState().db.cards[a._id] &&
                                            reduxStore.getState().db.cards[a._id].type === a.type
                                    )
                                    .sort((a, b) => {
                                        const orderByTitle = reduxStore.getState().db.orderByTitle;
                                        const orderByTitlePosition = reduxStore.getState().db.orderByTitlePosition;
                                        if (
                                            orderByTitle &&
                                            orderByTitlePosition === "ASC" &&
                                            a.name.toLowerCase().trim() < b.name.toLowerCase().trim()
                                        ) {
                                            return -1;
                                        } else if (
                                            orderByTitle &&
                                            orderByTitlePosition === "DESC" &&
                                            a.name.toLowerCase().trim() > b.name.toLowerCase().trim()
                                        ) {
                                            return -1;
                                        } else if (orderByTitle) {
                                            return 0;
                                        } else if (
                                            !orderByTitle &&
                                            String(a.order) &&
                                            String(b.order) &&
                                            String(a.order.low) &&
                                            String(b.order.low) &&
                                            parseInt(a.order.low) < parseInt(b.order.low)
                                        ) {
                                            return -1;
                                        } else {
                                            return 1;
                                        }
                                    })
                                    .map((a) => (
                                        <div
                                            style={{
                                                width: "calc(100%)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                            key={a._id}
                                        >
                                            <Card db="cards" cardId={a._id} noShadow />
                                            <Button
                                                onClick={() => {
                                                    updateCard(props, {
                                                        _id: a._id,
                                                        type: "task"
                                                    });
                                                }}
                                            >
                                                <Icon
                                                    icon="change_circle"
                                                    color={customApp("menu")}
                                                    title={translate("$__transformInTask")}
                                                />
                                            </Button>
                                        </div>
                                    ))}
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                        padding: "15px"
                                    }}
                                >
                                    <CustomButton
                                        shadow
                                        title={translate("$__skipAll")}
                                        text={translate("$__skipAll")}
                                        color="lightgrey"
                                        icon={"skip_next"}
                                        size={"25px"}
                                        onClick={() => {
                                            setMigratorData({
                                                ...MigratorData,
                                                _skipGoals: true
                                            });
                                        }}
                                    />
                                    <div style={{ marginLeft: "15px" }}>
                                        <CustomButton
                                            shadow
                                            title={translate("$__changeAllInTasks")}
                                            text={translate("$__changeAllInTasks")}
                                            icon={"change_circle"}
                                            size={"25px"}
                                            onClick={() => {
                                                iniMigration("goals");
                                                setMigratorData({
                                                    ...MigratorData,
                                                    _skipGoals: true
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    {MigratorData._childrens &&
                        !MigratorData._skipObjectives &&
                        MigratorData._objectives.length > 0 &&
                        MigratorData._objectives.filter(
                            a =>
                                reduxStore.getState().db.cards[a._id] &&
                                reduxStore.getState().db.cards[a._id].type === a.type
                        ).length > 0 && (
                            <>
                                <span style={{ color: "gray" }}>
                                    {translate(`$__YouHaveLinkedObjectives`)}
                                    <p style={{ color: "red" }}>
                                        {translate(`$__ThisActionCanOnlyBeUndoneManually`)}
                                    </p>
                                </span>
                                {MigratorData._objectives
                                    .filter(
                                        a =>
                                            reduxStore.getState().db.cards[a._id] &&
                                            reduxStore.getState().db.cards[a._id].type === a.type
                                    )
                                    .sort((a, b) => {
                                        const orderByTitle = reduxStore.getState().db.orderByTitle;
                                        const orderByTitlePosition = reduxStore.getState().db.orderByTitlePosition;
                                        if (
                                            orderByTitle &&
                                            orderByTitlePosition === "ASC" &&
                                            a.name.toLowerCase().trim() < b.name.toLowerCase().trim()
                                        ) {
                                            return -1;
                                        } else if (
                                            orderByTitle &&
                                            orderByTitlePosition === "DESC" &&
                                            a.name.toLowerCase().trim() > b.name.toLowerCase().trim()
                                        ) {
                                            return -1;
                                        } else if (orderByTitle) {
                                            return 0;
                                        } else if (
                                            !orderByTitle &&
                                            String(a.order) &&
                                            String(b.order) &&
                                            String(a.order.low) &&
                                            String(b.order.low) &&
                                            parseInt(a.order.low) < parseInt(b.order.low)
                                        ) {
                                            return -1;
                                        } else {
                                            return 1;
                                        }
                                    })
                                    .map((a) => (
                                        <div
                                            style={{
                                                width: "calc(100%)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                            key={a._id}
                                        >
                                            <Card db="cards" cardId={a._id} noShadow />
                                            <Button
                                                onClick={() => {
                                                    updateCard(props, {
                                                        _id: a._id,
                                                        type: "goal"
                                                    });
                                                }}
                                            >
                                                <Icon
                                                    icon="change_circle"
                                                    color={customApp("menu")}
                                                    title={translate("$__transformInGoal")}
                                                />
                                            </Button>
                                        </div>
                                    ))}
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                        padding: "15px"
                                    }}
                                >
                                    <CustomButton
                                        shadow
                                        title={translate("$__skipAll")}
                                        text={translate("$__skipAll")}
                                        color="lightgrey"
                                        icon={"skip_next"}
                                        size={"25px"}
                                        onClick={() => {
                                            setMigratorData({
                                                ...MigratorData,
                                                _skipObjectives: true
                                            });
                                        }}
                                    />
                                    <div style={{ marginLeft: "15px" }}>
                                        <CustomButton
                                            shadow
                                            title={translate("$__changeAllInGoal")}
                                            text={translate("$__changeAllInGoal")}
                                            icon={"change_circle"}
                                            size={"25px"}
                                            onClick={() => {
                                                iniMigration("objectives");
                                                setMigratorData({
                                                    ...MigratorData,
                                                    _skipObjectives: true
                                                });
                                            }}
                                        />
                                    </div>
                                    <div style={{ marginLeft: "15px" }}>
                                        <CustomButton
                                            shadow
                                            title={translate("$__cancel")}
                                            text={translate("$__cancel")}
                                            icon={"cancel"}
                                            size={"25px"}
                                            onClick={() => {
                                                setMigrator(false);
                                                setMigratorData({});
                                            }}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    {((MigratorData &&
                        MigratorData._childrens &&
                        Object.keys(MigratorData._childrens).length > 0 &&
                        MigratorData._goals.filter(
                            a =>
                                reduxStore.getState().db.cards[a._id] &&
                                reduxStore.getState().db.cards[a._id].type === a.type
                        ).length === 0 &&
                        MigratorData._objectives.filter(
                            a =>
                                reduxStore.getState().db.cards[a._id] &&
                                reduxStore.getState().db.cards[a._id].type === a.type
                        ).length === 0) ||
                        (((MigratorData._skipGoals && MigratorData._goals.length > 0) ||
                            (MigratorData && MigratorData._goals && MigratorData._goals.length === 0)) &&
                            ((MigratorData._skipObjectives && MigratorData._objectives.length > 0) ||
                                (MigratorData && MigratorData._objectives && MigratorData._objectives.length === 0))))
                        && (
                            <>
                                <span style={{ color: "red" }}>
                                    {translate("$__clickInSaveToFinishMigration")}
                                </span>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                        padding: "15px"
                                    }}
                                >
                                    <CustomButton
                                        shadow
                                        title={translate("$__cancel")}
                                        text={translate("$__cancel")}
                                        color="lightgrey"
                                        icon={"close"}
                                        size={"25px"}
                                        onClick={() => {
                                            setMigrator(false);
                                            setMigratorData({});
                                        }}
                                    />
                                    <div style={{ marginLeft: "15px" }}>
                                        <CustomButton
                                            shadow
                                            title={translate("$__save")}
                                            text={translate("$__save")}
                                            icon={"done_all"}
                                            size={"25px"}
                                            onClick={() => {
                                                finishMigration();
                                            }}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                </div>
            </Dialog>
        </div>
    );
}

const Container = styled.div`
  position: absolute;
  height: 100%;
  padding: 7px;
  ${props =>
        !props.inLine &&
        props.styles &&
        props.styles.discountHeight &&
        `height:calc(100vh - ${props.styles.discountHeight}px);`}
  ${props =>
        props.inLine
            ? `display: block;
         width: -webkit-fill-available;
         ${props.style}`
            : `display: flex;
         width: 100%;
         ${props.style}
         @media(min-width: 960px) {
           display: flex;
         }`}
  @media(max-width: 959px) {
    display: contents !important;
    width: -webkit-fill-available;
    height: auto;
  }
`;

const mapStateToProps = (store, props) => ({
    cards: store.db.cards,
    searchs: store.searchs,
    controls: store.controls
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Overview);