import React, { useEffect, useState, useRef } from 'react'
import moment from "moment-timezone"


//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"


//COMPONENTS
import Legend from '../Legend';
import { ResponsiveBar } from '@nivo/bar'
import Header from 'components/Dashboards/Header'
import './Loader.css';

//DEPENDENCIES
import Modal from './Modal';

//FUNCTIONS
import { translate } from 'functions';
import { loadTimeline } from "functions/chat"


const TaskDoneBar = (props) => {
    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace('#', '')
    const mounted = useRef(true)
    const similarColorsRef = useRef({})
    const colorSelectRef = useRef(0)

    React.useEffect(() => {
        const init = async () => {
            try {
                await loadTimeline(
                    {
                        ...props,
                        fnIdentification: "Components/Dashboard/Widgers/TaskDoneBar/init()"
                    },
                    {
                        ids: [hashs[0]],
                        db: "cards",
                        type: "timer",
                        reqChildrens: true
                    })
            } catch (e) {
                console.log(e)
            }
        }
        init()
        mounted.current = true
        return () => {
            mounted.current = false
        }
        // eslint-disable-next-line
    }, [])


    const { db } = reduxStore.getState()
    const [filterChart, setFilter] = useState({
        index: 0,
        showQuantity: 12,
        number: 12,
        finalIndex: 12,
        dataStructure: 'month',
        status: {
            "all": false,
            "notStarted": false,
            "awaitAction": false,
            "paused": false,
            "inProgress": false,
            "completed": true
        }
    })
    const [dashFilterChart, setDashFilterChart] = useState({})
    const [data, setData] = useState({
        "total": {
            "value": 0,
            "label": "completed",
            "color": "#5A7F71",
            "id": "completed",
        }
    })
    const [table, setTable] = useState({})
    const keysLabel = useRef([{ label: 'completed', color: '#5A7F71' }])
    const filterRef = useRef([])

    const generateColor = (name) => {
        let hash = 0;
        for (let i = 0; i < name.length; i++) {
            hash += name.charCodeAt(i);
        }
        const hexColor = "#" + ((hash % 0xFFFFFF) + 0x1000000).toString(16).slice(1, 7);
        return hexColor;
    }

    function generateSimilarColors(color, tag, last) {

        let newColor = props?.dashboardConfig?.colorsArr[colorSelectRef.current]?.color;
        let result = '#';

        let adjustmentFactor = 0.25;

        for (let i = 1; i < newColor.length; i += 2) {
            let temp = parseInt(newColor.slice(i, i + 2), 16);
            temp = Math.round(temp + adjustmentFactor * (255 - temp));
            result += temp.toString(16).padStart(2, '0');
        }



        if (filterChart?.tagsColors && filterChart?.tagsColors?.mainColor === color) {
            Object.values(filterChart?.tagsColors).forEach((item) => {
                if (item.name === tag) {
                    similarColorsRef.current = {
                        ...similarColorsRef.current,
                        [item.name]: {
                            color: item.color,
                            name: item.name
                        }
                    }
                }
            })
        } else if (!similarColorsRef.current?.[tag] || newColor !== similarColorsRef.current?.mainColor) {
            similarColorsRef.current = {
                ...similarColorsRef.current,
                [tag]: {
                    color: result,
                    name: tag
                }
            }
        }

        if (!similarColorsRef.current?.[tag]) {
            similarColorsRef.current = {
                ...similarColorsRef.current,
                [tag]: {
                    color: result,
                    name: tag
                }
            }
        }


        if (Object.values(similarColorsRef.current).length > 0) {
            Object.values(similarColorsRef.current).forEach((item) => {
                if (item.name === tag) {
                    result = item.color
                }
            })
        }


        return result;
    }



    const checkWeekStructure = (start, end) => {
        if (start === end)
            return true
        else
            return false
    }

    const getNames = (name) => {
        if (typeof name === 'string') {
            let nameLength = name.split(" ")
            let newName = name
            if (data.length > 5 && data.length < 10) {
                newName = ''
                if (!nameLength[0].startsWith("#")) {
                    nameLength.forEach((item) => {
                        if (nameLength.length === 1) {
                            newName = item.substring(0, 3);
                        } else {
                            newName = newName + item.charAt(0)
                        }
                    })
                } else {
                    nameLength.forEach((item, index) => {
                        if (index !== 0) {
                            if (nameLength.length === 2) {
                                newName = item.substring(0, 3);
                            } else {
                                newName = newName + item.charAt(0)
                            }
                        }
                    })
                    return nameLength[0] + newName
                }
                return newName
            } else if (data.length > 10 && !filterChart?.mode) {
                return ''
            }
            if (newName.split('').length > 10) {
                return newName.substring(0, 10) + '...';
            } else {
                return newName
            }
        }
    }


    const getLeftValue = () => {
        if (filterChart?.mode) {
            let hihgherLabel = 0
            data.forEach((item) => {
                if (item && item?.label && item?.label.split("").length > hihgherLabel)
                    hihgherLabel = item.label.split("").length
            })
            return (hihgherLabel > 10 ? 10 : hihgherLabel) * 11
        } else {
            return 40
        }
    }




    useEffect(() => {
        if (Object.keys(props.filter).length > 0) {
            setFilter({ ...filterChart, ...props.filter })
        }
        if (mounted.current && Object.keys(props?.dashboardConfig?.filter).length > 0) {
            setDashFilterChart({
                ...filterChart,
                ...props?.dashboardConfig?.filter,
                colorsArr: props.dashboardConfig?.colorsArr
            })
        }
        // eslint-disable-next-line
    }, [props.filter, props.dashboardConfig]);

    useEffect(() => {
        if (filterChart.tags && mounted.current) {
            filterRef.current = []
            filterChart.tags.forEach((item) => {
                filterRef.current = [...filterRef.current, item.label];
            })

        }
        similarColorsRef.current = {}

    }, [filterChart]);


    useEffect(() => {
        let timeTasks = {};
        let modalData = {}
        let keysLabelArr = []
        colorSelectRef.current = 0
        let count = 0
        const startDate = (dashFilterChart.startDate ? dashFilterChart : filterChart).startDate ? moment((dashFilterChart.startDate ? dashFilterChart : filterChart).startDate * 1000) : moment().startOf('year');
        const endDate = (dashFilterChart.startDate ? dashFilterChart : filterChart).endDate ? moment((dashFilterChart.startDate ? dashFilterChart : filterChart).endDate * 1000) : moment().endOf('year');
        const weekStructure = checkWeekStructure(startDate.format('MMMM'), endDate.format('MMMM'))

        const getWeeks = (date, label) => {
            count = count + 1
            let firstWeek = date.startOf("month").week()
            let lastWeek = date.endOf("month").week()
            for (let i = firstWeek; i <= lastWeek; i++) {
                modalData = {
                    ...modalData,
                    [label]: {
                        ...modalData[label] || {},
                        [i]: (modalData[label]?.[i] || 0),
                    }
                }
            }
        }

        const updateTaskInfo = (dateCard, item, status, last) => {
            if (colorSelectRef.current < 5) {
                colorSelectRef.current = colorSelectRef.current + 1
            } else {
                colorSelectRef.current = 0
            }
            const label = item.label
            const cardDate = moment(dateCard.date).clone()
            const monthKey = dateCard.date.clone().format('MMMM');
            const startOfMonth = cardDate.clone().startOf('month');
            const endOfMonth = cardDate.clone().endOf('month');
            const weekStart = cardDate.clone().startOf("week")
            const weekEnd = cardDate.clone().endOf("week")
            const weekKey = dateCard.date.clone().week();
            if (!modalData.label && weekStructure)
                getWeeks(dateCard.date.clone(), label)
            timeTasks = {
                ...timeTasks,
                [label]: {
                    ...timeTasks[label] || {},
                    "id": label,
                    "color": props?.dashboardConfig?.colorsArr ?
                        generateSimilarColors(props?.dashboardConfig?.colorsArr[0]?.color, label, last)
                        : (item?.color || generateColor(label)),
                    "label": label,
                    "value": (timeTasks[label]?.["value"] || 0) + 1,
                }
            };
            let cellRef = weekStructure ? weekKey : monthKey

            modalData = {
                ...modalData,
                [label]: {
                    ...modalData[label] || {},
                    "value": (modalData[label]?.["value"] || 0) + 1,
                    tag: item.value,
                    "label": window.location.hostname.indexOf('eneva') > -1 ? label.substring(3) : label,
                    [status]: {
                        ...modalData[label]?.[status],
                        [cellRef]: (modalData[label]?.[status]?.[cellRef] || 0) + 1,
                    },
                    color: generateSimilarColors(props?.dashboardConfig?.colorsArr[0]?.color, label),
                    [cellRef]: (modalData[label]?.[cellRef] || 0) + 1,
                    ["Period__" + cellRef]: weekStructure ? {
                        start: weekStart.startOf("day").unix("X"),
                        end: weekEnd.endOf("day").unix("X")
                    } : {
                        start: startOfMonth.startOf("day").unix("X"),
                        end: endOfMonth.endOf("day").unix("X")
                    }
                }
            }
            keysLabelArr = {
                ...keysLabelArr,
                [label]: {
                    label: label,
                    color: item?.color || generateColor(label)
                }
            }
        };

        if (props.planCards) {
            keysLabel.current = [];
            let arr = filterChart.status ? Object.keys(filterChart.status).filter(key => (filterChart.status[key])) : ["completed"]
            Object.keys(props.planCards).filter((fil) =>
                !db.cards[fil].deleted
                &&
                (arr).includes(db.cards[fil].status)
            )
                .forEach((planCard, index) => {
                    let result = filterChart?.users?.length > 0 ? false : true
                    if (filterChart.users && Object.keys(db.cards[planCard]?._users).length > 0) {
                        Object.values(db.cards[planCard]?._users).forEach((user) => {
                            filterChart.users.forEach((filterUser) => {
                                if (user._id === filterUser)
                                    result = true
                            })
                        })
                    }
                    if (result) {
                        const dateCard = {
                            date: db.cards[planCard].completed_at ? moment(db.cards[planCard].completed_at?.low * 1000) : moment(db.cards[planCard].created_at * 1000),
                            tags: db.cards[planCard]._tags
                        };

                        if (dateCard.date.isBetween(startDate, endDate, null, '[]')) {
                            // const monthKey = dateCard.date.format('MMMM');
                            if (filterChart?.tags?.length > 0 || filterChart.tagsByText) {
                                dateCard.tags
                                    .filter(item =>
                                        (
                                            filterChart.tags
                                            && filterChart.tags.length > 0
                                            && item.label
                                            && filterRef.current.includes(item.label)
                                        )
                                        ||
                                        (
                                            filterChart.tagsByText
                                            && item.label
                                            && item.label.toLowerCase().indexOf(filterChart.tagsByText.toLowerCase()) > -1
                                        )
                                    )
                                    .forEach((item) => {
                                        updateTaskInfo(dateCard, item, db.cards[planCard].status, index === Object.keys(props.planCards).length - 1);
                                    });

                                keysLabel.current = Object.values(keysLabelArr)
                            } else {
                                timeTasks = {
                                    ...timeTasks,
                                    [db.cards[planCard].status]: {
                                        ...timeTasks["total"] || {},
                                        "value": timeTasks && timeTasks[db.cards[planCard].status] && timeTasks[db.cards[planCard].status]["value"] ? timeTasks[db.cards[planCard].status]["value"] + 1 : 1,
                                        "label": db.cards[planCard].status,
                                        "color": db.status.filter(fil => fil.value === db.cards[planCard].status)[0].color,
                                        "id": translate(`$__${db.cards[planCard].status}`),
                                        // "id":trans
                                    }
                                }
                                modalData = {}

                                const newLabel = {
                                    label: db.cards[planCard].status,
                                    color: db.status.filter(fil => fil.value === db.cards[planCard].status)[0].color
                                };

                                if (!keysLabel.current.some(item => item.label === newLabel.label)) {
                                    keysLabel.current = [...keysLabel.current, newLabel];
                                }
                            }
                        }
                    }
                })



            if (data !== timeTasks && mounted.current) {
                let newData = Object.values(timeTasks)
                if (filterChart?.colorsArr) {
                    newData = newData.map((item) => {
                        const foundColorObj = filterChart?.colorsArr.find((colorObj) => colorObj.label === item.label);
                        if (foundColorObj) {
                            return {
                                ...item,
                                color: foundColorObj.color
                            };
                        } else {
                            return item;
                        }
                    });
                }


                setData(newData)
                setTable(modalData)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.planCards, props.load, filterChart, dashFilterChart])



    return (
        <div className='box' style={{ width: '100%', height: '100%', alignSelf: 'center', borderRadius: '5px', backgroundColor: 'transparent', display: 'flex', flexFlow: 'column', overflow: 'visible' }}>
            <Header
                handleSetSelected={(value) => {
                    setFilter({ ...filterChart, ...value })
                    props.handleSetFilter({ ...filterChart, ...value, id: props.id })
                }}
                filterConfig={{ filterItems: ['date', 'users', 'tags', 'tagsByText', 'unit', 'status'] }}
                tittle={translate("$__" + props.tittle)}
                icon={props.icon}
                styles={props.styles}
                datafilter={true}
                indicators={filterChart ? filterChart : null}
                handleModal={() => {
                    props.handleDatagridTable()
                }}
                table={true}
                vertical={true}
                handleRemoveObject={() => props.handleRemoveObject(props.id)}
                edit={props.edit}
                handleSetoption={(value) => {
                    JSON.stringify(value) !== JSON.stringify(filterChart) && setFilter({ ...value })
                    props.handleSetFilter({ ...filterChart, ...value, id: props.id })
                }}
            />
            <div style={{ borderBottom: '1px solid #D3D3D3', width: '100%' }}></div>
            <div style={{}} >
            </div>
            <div style={{ position: 'relative', flex: '1 1 auto', fontSize: '16px' }}>
                <div style={{ width: '100%', height: '100%', position: 'absolute', fontWeight: 'lighter', backgroundColor: '#f5f5f5' }}>
                    {(!props.load && Array.isArray(data)) ?
                        <ResponsiveBar
                            data={data}
                            colors={{ datum: 'data.color' }}
                            margin={{ top: 35, right: 40, bottom: 35, left: getLeftValue() }}
                            innerRadius={0}
                            padAngle={1.7}
                            cornerRadius={3}
                            layout={filterChart?.mode ? 'horizontal' : 'vertical'}
                            activeOuterRadiusOffset={8}
                            borderWidth={1}
                            padding={data.length === 1 ? 0.35 : 0.1}
                            tooltipFormat={x => {
                                return x
                            }}
                            axisLeft={filterChart?.mode ? {
                                format: (v) => getNames(v)
                            } : {
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                format: (v) => v % 1 === 0 ? v : ''
                            }}
                            axisBottom={filterChart?.mode ? {
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                format: (v) => v % 1 === 0 ? v : ''
                            } : {
                                format: (v) => getNames(v)
                            }}
                            theme={{
                                fontSize: '16px',
                                fontWeight: 'bold',
                                legend: false,
                                background: 'transparent',
                                axis: {
                                    "ticks": {
                                        "text": {
                                            fill: 'rgba(0, 0, 0, 0.50)',
                                            "fontSize": 14,
                                            //cor dos numeros
                                        },
                                    }
                                },
                            }}
                        />
                        : <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
                            <div style={{ alignSelf: 'center' }}>  <div className="loader"></div></div>
                        </div>}
                </div>
            </div>
            {props.filter.table ? <div style={{ maxHeight: '300px', overflow: 'scroll' }} >
                <Modal data={Object.values(table)} planCards={props.planCards} filterProps={filterChart} />
            </div> :
                <div style={{ height: '15%', maxHeight: '60px', display: 'flex', backgroundColor: 'white' }}>
                    <Legend
                        legend={Object.keys(similarColorsRef.current).length > 0
                            ? data.filter((item) => item.color && item.label).map((a, i) => {
                                return {
                                    key: i,
                                    color: a.color,
                                    label: a.label,
                                }
                            })
                            : keysLabel.current.map((a, i) => {
                                return {
                                    key: i,
                                    color: a.color,
                                    label: a.label,
                                }
                            })
                        }
                        customColors={filterChart?.colorsArr}
                    />
                </div>}
        </div >
    )
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default connect(null, mapDispatchToProps)(TaskDoneBar);