import React from "react";
import classNames from "classnames";

// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import BadgeIcon from "components/Icon/Badge";
import styles from "assets/jss/material-dashboard-pro-react/components/iconButtonStyle";

//FUNCTIONS
import { customApp } from "functions/"

const useStyles = makeStyles(styles);

const RegularButton = ((props) => {
    const classes = useStyles();
    const { text } = props;
    const btnClasses = classNames({
        [classes.button]: true,
        [classes.reverse]: props.reverse ? true : false,
    });

    const btnClassesText = classNames({
        [classes.buttonText]: true,
        [classes.reverse]: props.reverse ? true : false,
    });
    return (
        <div className={props.text ? btnClassesText : btnClasses} onClick={() => props.onClick ? props.onClick() : null}
            style={{
                ...props.style
            }}
        >
            <div
                className={classes.iconButton}
                style={{
                    color: props.iconColor ? customApp(props.iconColor) : customApp('color'),
                    backgroundColor: props.iconBackground ? customApp(props.iconBackground) : 'none',
                }}>{
                    props.icon ?
                        <BadgeIcon
                            className={props.iconBackground ? classes.icon : classes.onlyIcon}
                            size={props.size ? props.size : null}
                            icon={props.icon} color={props.iconColor}
                            title={props.title || null}
                            onClick={() => props.onClick ? props.onClick() : null}
                            style={{
                                ...props.style ? props.style : {}
                            }}
                        />
                        :
                        <BadgeIcon
                            className={props.iconBackground ? classes.icon : classes.onlyIcon}
                            icon={'stop_circle'}
                            color={props.iconColor}
                            title={props.title || null}
                            onClick={() => props.onClick ? props.onClick() : null}
                            style={{
                                ...props.style ? props.style : {}
                            }}
                        />
                }
            </div>
            {text &&
                <div className={classes.text} style={{ color: props.textColor ? customApp(props.textColor) : customApp('medium') }}>{text}</div>
            }
        </div>
    );
});

export default RegularButton;
