import React, { memo, useEffect } from "react"
import moment from "moment-timezone"
import ReactAudioPlayer from "react-audio-player"

//REDUX
import { connect, useSelector } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"

//COMPONENTS
import Card from "components/Card"
import CardTags from "components/Tags/Card"
import CustomButton from "components/Buttons/custom"
import HeaderWithIcon from "components/Header/card"
import Icon from "components/Icon"
import IconButton from "components/CustomButtons/IconButton"
import ReplyMessage from "./message"
import MoreVert from "components/MoreMenu/"
import SelectUsers from "components/User/select"
import TextField from "components/TextField"
import UserBadge from "components/Badge/user"

//@MATERIAL
import Avatar from "@material-ui/core/Avatar"
import AvatarGroup from "@material-ui/lab/AvatarGroup"
import Dialog from "@material-ui/core/Dialog"
import Tooltip from "@material-ui/core/Tooltip"
import { makeStyles } from "@material-ui/core/styles"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/timelineStyle";

//FUNCTIONS
import {
    translate,
    formatNumber,
    customApp,
    Highlighted,
    links,
    appAlert
} from "functions/"

import {
    interact,
    deleteMessage,
    forwardMessage,
    mentionedUsers
} from "functions/chat"

//IMAGES
import defaultAvatar from "assets/img/fundo-sonne.png"
import SVGIcon from "assets/icons/svg.png"

const useStyles = makeStyles(styles);


const Message = (props) => {
    const { storie } = props
    const classes = useStyles()
    const session = useSelector((state) => state.session);
    const db = useSelector((state) => state.db);
    const functions = useSelector((state) => state.functions);
    const timeline = useSelector((state) => state.timeline);
    const chat = useSelector((state) => state.chat);
    const { socket } = functions
    const [hideImage, setHideImage] = React.useState(false)
    const [overOptions, setOverOptions] = React.useState(false)
    const [cancelReason, setCancelReason] = React.useState(null)
    const [goCancel, setGoCancel] = React.useState(null)
    const [forward, setForward] = React.useState(false)
    const [saveLoading, setSaveLoading] = React.useState(false)
    const [forwardToId, setForwardToId] = React.useState(null)

    const mounted = React.useRef(true)

    React.useEffect(() => {
        mounted.current = true
        return () => {
            mounted.current = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const messageRef = React.createRef()
    const sizeRef = React.useRef(0)

    useEffect(() => {
        if (mounted.current)
            if (props.onResize && messageRef?.current?.clientHeight)
                if (sizeRef.current !== messageRef.current.clientHeight) {
                    sizeRef.current = messageRef.current.clientHeight
                    props.onResize(messageRef.current.clientHeight || 83)
                }
        // eslint-disable-next-line
    }, [messageRef])

    let timer = null

    if (storie.filesCount
        && storie.filesCount > 0
        && storie.user
        && storie.user._id
        && storie.user._id !== session._id
        && (
            !storie.files
            || (
                storie.files
                && storie.files.length < storie.filesCount
            )
        ))
        return (<React.Fragment></React.Fragment>)



    const overOptionsHandle = (mouse = false) => {
        if (mounted.current) {
            setOverOptions(true)
            if (mouse)
                clearTimeout(timer)
        }
    }
    const closeOptionsHandle = (time = 300) => {
        timer = setTimeout(() => {
            if (mounted.current)
                setOverOptions(false)
        }, time)
    }

    const reactThis = (react) => {
        const nodeId = props.storie.idRel ? props.storie.idRel : props.storie._parent ? props.storie._parent : chat.nodeId;

        let data = {
            chatId: reduxStore.getState().chat.id,
            react: react,
            user: session._id,
            db: props.db,
            nodeId: nodeId,
            message: props.storie._id
        }

        interact(props, data)
        socket.emit("data", {
            module: "timeline",
            method: "post",
            action: "interact"
        }, data)
    }


    if (!storie)
        return null

    let time = ""
    if (storie.type === "timer" && String(storie.ini) && String(storie.fin))
        time = storie.fin - storie.ini

    let urlPreview = null

    if (storie.urlPreview)
        urlPreview = JSON.parse(storie.urlPreview)

    let message = translate(mentionedUsers(storie.message, storie))

    let reaction
    if (storie._reactions && Object.keys(storie._reactions).length > 0)
        Object.keys(storie._reactions).forEach(re => {
            if (Object.keys(storie._reactions[re]).length > 0)
                Object.keys(storie._reactions[re]).forEach(us => {
                    if (us === session._id)
                        reaction = re
                })
        })

    let my = false
    if (props.storie && props.storie.user && props.storie.user._id === session._id)
        my = true

    const cancelConfirm = () => {
        appAlert({
            message: translate('$__confirmCancel', 1),
            variant: 'warning',
            persist: false,
            horizontal: 'right',
            confirm: () => {
                deleteMessage(props, {
                    id: storie._id,
                    idRel: storie.idRel,
                    deletedReason: cancelReason,
                    deleted: true
                })
                storie.deleted = true
                setGoCancel(false)
                setCancelReason(null)
            }
        })
    }
    const renderMessage = () => {
        if (storie.type === "comment" && storie.message) {
            return (
                <div>
                    {storie.urlPreview && urlPreview.img && urlPreview.title && urlPreview.contentSnippet ?
                        <GridContainer style={{ marginBottom: 15 }}>
                            {!hideImage && urlPreview.img &&
                                <GridItem
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    lg={3}
                                    xl={2}
                                >
                                    <img
                                        style={{
                                            width: "-webkit-fill-available"
                                        }}
                                        src={`${urlPreview.img}`}
                                        onError={(e) => {
                                            if (mounted.current)
                                                setHideImage(true)
                                        }}
                                        alt={urlPreview.title || urlPreview.link}
                                    />
                                </GridItem>
                            }
                            <GridItem
                                xs={12}
                                sm={6}
                                md={8}
                                lg={9}
                                xl={10}
                            >
                                <div style={{
                                    fontSize: "14px",
                                    color: customApp("menu"),
                                    fontWeight: "bold"
                                }}>{urlPreview.title}</div>
                                {urlPreview.site && <div
                                    style={{
                                        fontSize: "12px",
                                    }}
                                >{urlPreview.site}</div>}
                                {urlPreview.contentSnippet && <div
                                    style={{
                                        fontSize: "12px",
                                        color: "gray",
                                    }}>{urlPreview.contentSnippet}</div>}
                                <a style={{
                                    marginTop: "15px",
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                }
                                } href={urlPreview.link} target="_blank" rel="noopener noreferrer">{translate("$__openLink", "*")}</a>
                            </GridItem>
                        </GridContainer>
                        : <React.Fragment></React.Fragment>}
                    <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                    >
                        {Highlighted(links(message), props.searchText)}
                    </GridItem>
                    <div style={{ clear: "both" }}></div>
                </div>
            )
        } else if (storie.type === "comment" && !storie.message && storie.cardId !== props.nodeId && reduxStore.getState().db.cards[storie.cardId]) {
            return (
                <Card db="cards" data={reduxStore.getState().db.cards[storie.cardId]} Avatar expanded ignoreList />
            )
        }
        if (storie.type === "log") {
            let newData = {}
            if (storie.message === "$__stepChangeListPosition" && storie.newData) {
                // console.log(storie.newData)
                let positions = JSON.parse(storie.newData)
                return (
                    <div>
                        <div style={{ position: "relative", width: "100%", display: "flex" }}>
                            <Icon color="color" icon="history" style={{ marginRight: "10px" }} />
                            {storie.message.indexOf("$__") > -1 ? translate(storie.message, 1) : storie.message}{` `}
                        </div>
                        <ul
                            style={{
                                listStyle: "decimal"
                            }}
                        >
                            {positions.filter(a => reduxStore.getState().db.cards[a]).map(a => (
                                <li>{reduxStore.getState().db.cards[a].name}</li>
                            ))}

                        </ul>
                    </div>
                )
            }
            if (storie.message === "$__updated") {
                newData = JSON.parse(storie.newData)
                return (
                    <div>

                        <div style={{ position: "relative", width: "100%", display: "flex" }}>
                            <Icon color="color" icon="history" style={{ marginRight: "10px" }} />
                            {storie.message.indexOf("$__") > -1 ? translate(storie.message, 1) : storie.message}{` `}
                        </div>
                        {storie.newData &&
                            <ul style={{
                                position: "relative",
                                width: "98%"

                            }}>
                                {Object.keys(newData).map((a, i) => {
                                    if (a === "icon") {
                                        return (
                                            <li key={i} style={{ display: "flex" }}>
                                                {translate(`$__icon`, 1)}:<Icon icon={newData[a]} color={customApp("color")} size={"17px"} />
                                            </li>
                                        )
                                    } else
                                        if (a === "customColumns") {
                                            let columns = JSON.parse(newData[a])
                                            return (<li key={i}>{translate(`$__customColumns`, 1)}:
                                                <ul>
                                                    {Object.keys(columns).map((a, ai) =>
                                                        <li key={ai}>{translate(`$__${a}`)}{`=>`} <span style={{ color: customApp('menu'), fontWeight: "bold" }}>{translate(`${columns[a].name}`)}</span></li>
                                                    )}

                                                </ul>
                                            </li>)
                                        } else if (a === "customDashboard") {
                                            return (<li key={i}>{translate(`$__customDashboard`, 1)}: {translate(`$__updated`, 1)}</li>)
                                        } else if (
                                            a === "_id"
                                            || a === "id"
                                            || a === "client"
                                            || a === "planId"
                                            || a === "_parent"
                                            || a === "owner"
                                            || a === "image"
                                        ) {
                                        } else if (a === "status") {
                                            return (<li key={i}>{translate(`$__status`, 1)}: {translate(`$__${newData[a]}`, 1)}</li>)
                                        } else if (a === 'note') {
                                            return (<li key={i}>{translate(`$__note`, 1)}: <div>{Highlighted(links(newData[a]), props.searchText)}</div></li>)
                                        } else if (
                                            a === "kpi_type"
                                            || a === "priority"
                                            || a === "risk"
                                            || a === "complexity"
                                            || a === "impact"
                                        ) {
                                            return (<li key={i}>{translate(`$__${a}`, 1)}: {translate(`$__${newData[a]}`, 1)}</li>)
                                        } else if (
                                            a === "name" || a === 'description'
                                        ) {
                                            return (
                                                <li key={i}>{translate(`$__${a}`, 1)}: {newData[a]}</li>
                                            )
                                        } else if (
                                            a === "url"
                                        ) {
                                            return (
                                                <li key={i}>{"url"}:
                                                    <a href={newData[a]} target="blank"
                                                        style={{

                                                        }}
                                                    >
                                                        {Highlighted(links(newData[a]), props.searchText)}
                                                    </a>
                                                </li>
                                            )
                                        } else if (
                                            a !== "_tags"
                                            && (typeof newData[a] === "string")
                                            && (
                                                a !== "kpi_ini"
                                                && a !== "kpi_fin"
                                                && a !== "startDate"
                                                && a !== "endDate"
                                                && a !== "updated_at"
                                                && a !== "created_at"
                                                && a !== "content"
                                            )
                                        ) {
                                            return (
                                                <li key={i}>{translate(`$__${a}`, 1)}: {newData[a].indexOf("$__") > -1 ? translate(`$__${newData[a]}`, 1) : newData[a]}</li>
                                            )
                                        } else if (
                                            a === "kpi_ini"
                                            || a === "kpi_fin"
                                        ) {
                                            return (
                                                <li key={i}>{translate(`$__${a}`, 1)}: {formatNumber(newData[a], storie.kpi_type ? storie.kpi_type : "decimal")}</li>
                                            )
                                        } else if (
                                            a === "startDate"
                                            || a === "endDate"
                                            || a === "updated_at"
                                            || a === "created_at"
                                            || a === "completed_at"
                                        ) {
                                            return (
                                                <li key={i}>{translate(`$__${a}`, 1)}: {!newData[a] ? translate("$__removed") : moment(new Date(newData[a] * 1000)).tz("America/Sao_Paulo").format("DD/MM/YYYY HH:mm")}</li>
                                            )
                                        } else if (
                                            typeof newData[a] === "boolean"
                                        ) {
                                            return (
                                                <li key={i}>{translate(`$__${a}`, 1)}: {newData[a] ? translate(`$__enable`, 1) : translate(`$__disable`, 1)}</li>
                                            )
                                        } else if (a === "_tags") {
                                            return (<li key={i}>TAGS: <CardTags options={newData[a]} showOptions={true} /></li>)
                                        } else {
                                            return (<li key={i}>{translate(`$__${a}`, 1)}::UPDATED_VALUE</li>)
                                        }
                                    return (<li key={i}>{translate(`$__${a}`, 1)}::UPDATED_VALUE</li>)
                                }
                                )}
                                <div style={{ clear: "both" }}></div>
                            </ul>
                        }
                        <div style={{ clear: "both" }}></div>
                    </div>
                )
            } else {
                return (
                    <div style={{ position: "relative", width: "100%" }}>
                        <div style={{ position: "relative", width: "100%", display: "flex" }}>
                            <Icon color="color" icon="history" style={{ marginRight: "10px" }} />
                            {storie.approval && storie.message.indexOf("$__approved") > -1 ?
                                <Icon icon="verified" color={"green"} />
                                : storie.approval && storie.message.indexOf("$__rejected") > -1 ?
                                    <Icon icon="gpp_bad" color={"red"} />
                                    : <React.Fragment></React.Fragment>
                            }
                            <div style={{ wordBreak: "break-word" }}>
                                {Highlighted(links(message), props.searchText)}
                            </div>
                            <div style={{ clear: "both" }}></div>
                        </div>
                        {storie.cardId ?
                            <div
                                style={{
                                    position: "relative",
                                    maxWidth: "285px",
                                    ...props.boxStyle && props.boxStyle.maxWidth ? { maxWidth: props.boxStyle.maxWidth } : {},
                                    width: "100%",
                                    display: "block"
                                }}>
                                <Card
                                    db="cards"
                                    data={reduxStore.getState().db.cards[storie.cardId]}
                                    ignoreList
                                    onlyOption
                                    notGlobalExpanded
                                    noExpanding
                                    disabled
                                />
                            </div>
                            : <React.Fragment></React.Fragment>
                        }
                        <div style={{ clear: "both" }}></div>
                    </div>
                )
            }
        }
        if (storie.type === "timer") {
            // if (storie.message === "Dsf")
            //     console.log(storie)
            let diff = time
            let diffText = ``
            if (time < 60) {
                diffText = `${diff} ${translate("$__seconds", 1)}`
            } else if (time > 3600) {
                let hours = Math.trunc(diff / 3600)
                diff = diff - (hours * 3600)
                let minutes = Math.trunc(diff / 60) > 0 ? Math.trunc(diff / 60) : 0
                diff = diff - (minutes * 60)
                let seconds = diff > 0 ? diff : 0
                diffText = `${hours} hora${hours > 1 ? "s" : ""} ${minutes} ${translate("$__minutes", 1)} ${seconds} ${translate("$__seconds", 1)}`
            } else {
                let minutes = Math.trunc(diff / 60) > 0 ? Math.trunc(diff / 60) : 0
                diff = diff - (minutes * 60)
                let seconds = diff > 0 ? diff : 0
                diffText = `${minutes} ${translate("$__minutes", 1)} ${seconds} ${translate("$__seconds", 1)}`
            }
            if (goCancel)
                return (
                    <div>
                        <div style={{ position: "relative", width: "100%", marginTop: "33px" }}>
                            <TextField
                                label={translate('$__cancelReason', 1)}
                                variant={customApp('standard')}
                                name="cancelReason"
                                InputProps={{
                                    autoComplete: 'new-password',
                                    multiline: true,
                                    minRows: 3,
                                    onChange: (d) => {
                                        if (mounted.current)
                                            setCancelReason(d.target.value)
                                    },
                                }}
                                placeholder={translate('$__cancelReason_placeholder', 1)}
                            />
                        </div>
                        <div style={{ position: "absolute", right: "7px", top: "7px", display: 'flex' }}>
                            <CustomButton
                                transparent
                                title={translate(`$__cancel`, '*')}
                                color={customApp('lightgrey')}
                                icon={'cancel'}
                                onClick={() => {
                                    if (mounted.current) {
                                        setGoCancel(false)
                                        setCancelReason(null)
                                    }
                                }}
                                size={'25px'}
                            />
                            <CustomButton
                                transparent
                                title={translate(`$__save`, 1)}
                                color='green'
                                icon={'playlist_add_check'}
                                onClick={() => {
                                    if (mounted.current)
                                        cancelConfirm()
                                }}
                                size={'25px'}
                            />
                        </div>
                        <div style={{ clear: "both" }}></div>
                    </div>
                )
            return (
                <div>
                    <div
                        style={{
                            position: "relative",

                        }}
                    >
                        {storie && storie._toUser && Object.keys(storie._toUser).length > 0 &&
                            <div className={classes.timeInfo}>
                                <AvatarGroup
                                    max={300}
                                    style={{
                                        flexWrap: "wrap",
                                        display: "inline-flex",
                                        border: "none"
                                    }}
                                >
                                    {Object.keys(storie._toUser).map(selectedUserId =>
                                        <Tooltip
                                            key={selectedUserId}
                                            title={storie._toUser[selectedUserId].displayName ? storie._toUser[selectedUserId].displayName : storie._toUser[selectedUserId].name}
                                        >
                                            <Avatar
                                                alt={storie._toUser[selectedUserId].name}
                                                src={storie._toUser[selectedUserId].image || null}
                                                style={{ width: 27, height: 27, border: "none" }}>
                                                {storie._toUser[selectedUserId].name.substr(0, 1)}
                                            </Avatar>
                                        </Tooltip>
                                    )}
                                </AvatarGroup>
                            </div>
                        }
                        {storie.manualEntry ?
                            <div className={classes.timeInfo}>
                                <Icon color="color" icon="schedule" />
                                <div className="text">{moment(storie.ini * 1000).tz("America/Sao_Paulo").format("DD/MM/YYYY")}</div>
                            </div>
                            : <React.Fragment>
                                {parseInt(moment(storie.ini * 1000).tz("America/Sao_Paulo").format("YYYY")) > 2021 ?
                                    <div className={classes.timeInfo}>
                                        <Icon color="color" icon="schedule" />
                                        <div className="text">{moment(storie.ini * 1000).tz("America/Sao_Paulo").format("YYYY/MM/DD HH:mm:ss")}</div>
                                    </div>
                                    :
                                    <React.Fragment></React.Fragment>
                                }
                                {parseInt(moment(storie.fin * 1000).tz("America/Sao_Paulo").format("YYYY")) > 2021 ?
                                    <div className={classes.timeInfo}>
                                        <Icon color="color" icon="watch_later" />
                                        <div className="text">{moment(storie.fin * 1000).tz("America/Sao_Paulo").format("YYYY/MM/DD HH:mm:ss")}</div>
                                    </div>
                                    :
                                    <React.Fragment></React.Fragment>
                                }
                            </React.Fragment>
                        }

                        <div className={classes.timeInfo}>
                            <Icon color="color" icon="hourglass_full" />
                            <div className="text">
                                {diffText}
                            </div>
                        </div>
                        <div style={{ clear: 'both' }}></div>
                        {storie.deleted ? <div
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: "rgba(255,255,255,0.66)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontWeight: "bold",
                                color: customApp("menu")
                            }}
                        >
                            {translate("$__cancelled")}
                        </div> : <React.Fragment></React.Fragment>}
                    </div>
                    <div>{message}</div>
                    {storie.deleted && storie.deletedReason &&
                        <div style={{ color: "red" }}>{translate("$__cancelReason", 1)}: {storie.deletedReason}</div>
                    }
                    <div style={{ clear: "both" }}></div>
                    {
                        (
                            !storie.deleted &&
                            (
                                overOptions ||
                                (
                                    moment(storie.created_at).unix('x')
                                )
                            )
                        ) ?
                            <div style={{ position: "absolute", right: "7px", top: "7px" }}>
                                <MoreVert
                                    transparent
                                    options={[
                                        {
                                            name: "$__cancelThis",
                                            icon: "cancel",
                                            onClick: () => {
                                                setGoCancel(true)
                                            },
                                            show: "hover"
                                        },
                                    ]}
                                />
                                <div style={{ clear: "both" }}></div>
                            </div>
                            : <React.Fragment></React.Fragment>
                    }
                    <div style={{ clear: "both" }}></div>
                </div>
            )
        }
        if (storie.type === "kpi") {
            let balance = storie && storie.balance ? storie.balance : 0
            return (
                <div>
                    {storie.cardId ? <Card db="cards" data={reduxStore.getState().db.cards[storie.cardId]} ignoreList noOptions /> : <React.Fragment></React.Fragment>}
                    <div>{message}</div>
                    <div className={parseFloat(storie.value) > 0 ? classes.valuePositive : classes.valueNegative}>{formatNumber(storie.value, storie.kpi_type ? storie.kpi_type : "decimal")}</div>

                    {!props.stories &&
                        <div className={classes.lastValue}>{translate("$__balance", 1)}: {formatNumber(balance, storie.kpi_type ? storie.kpi_type : "decimal")}</div>
                    }
                    <div style={{ clear: "both" }}></div>
                </div>
            )
        }
    }


    if (!storie.filesCount && storie.message === "" && storie.cardId && storie.cardId !== props.nodeId && !reduxStore.getState().db.cards[storie.cardId])
        return <></>

    if (storie.deleted && storie.user._id !== session._id)
        return <></>



    const renderFile = (file) => {
        let fileName = file.name.split(".")
        let ext = fileName[fileName.length - 1]

        let img = `attachment`
        if (db.fileExtensions.filter(a => a.ext.indexOf(ext.toLowerCase()) > -1).length > 0) {
            img = db.fileExtensions.filter(a => a.ext.indexOf(ext.toLowerCase()) > -1)[0].icon
                || `https://fl.machen.ai/?arq=${file.file.replace('&', '||MCH-ECOM||')}&name=${file.name.replace('&', '||MCH-ECOM||')}`
        }

        let viewer = "img"
        if (db.fileExtensions.filter(a => a.ext.indexOf(ext.toLowerCase()) > -1).length > 0 && db.fileExtensions.filter(a => a.ext.indexOf(ext.toLowerCase()) > -1)[0].viewer)
            viewer = db.fileExtensions.filter(a => a.ext.indexOf(ext.toLowerCase()) > -1)[0].viewer

        return (
            <div key={file._id}
                style={{
                    position: "relative",
                    display: "flex",
                    marginBottom: "5px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    cursor: "pointer",
                    overflow: "hidden",
                    padding: "7px",
                    zIndex: "10 !important",
                    borderRadius: "15px",
                    alignItems: "center",
                    justifyContent: "center",
                    ...viewer === "audio" ? {
                        width: "350px",
                        height: "50px",
                    } : viewer === "movie" ? {
                        width: "350px",
                        height: "280px",
                    } : {
                        width: "150px",
                        height: "150px",
                    }
                }}
                onClick={(e) => {
                    if (props.openLightBox) {
                        props.openLightBox(file._id)
                    }
                    e.preventDefault()
                }}
                title={file.name}
            >
                {(viewer === "img"
                    ||
                    (
                        (
                            viewer !== "movie"
                            && viewer !== "audio"
                            && typeof img === "string"
                            && img.indexOf("blob:") === -1
                            && img.indexOf("svg") === -1
                        )
                        || (
                            viewer !== "movie"
                            && viewer !== "audio"
                            && !(viewer === "img" && img && img.indexOf("svg") > -1)
                        )
                    )) &&
                    <Tooltip
                        title={file.name}>
                        <div style={{
                            position: "absolute",
                            bottom: "7px",
                            left: "7px",
                            right: "7px",
                            padding: "3px",
                            background: "rgba(0,0,0,0.8)",
                            color: "#FFFFFF",
                            borderRadius: "0px 0px 15px 15px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}>{file.name}</div>
                    </Tooltip>
                }
                {/* IMAGES */}
                {(viewer === "img" && img === "attachment") &&
                    <Icon icon={img} size="50px" />
                }
                {(viewer === "img"
                    &&
                    (
                        typeof img === "string"
                        && img.indexOf("https") > -1
                        && img.indexOf("svg") === -1
                    )
                ) &&
                    <img
                        alt="altImage"
                        src={`${img}`}
                        style={
                            {
                                borderRadius: "15px",
                                boxShadow: `0 1px 2px 2px rgba(0,0,0,0.1)`,
                                maxWidth: "100%",
                                height: "100%",
                                maxHeight: "33vh"
                            }}
                        onError={(e) => {
                            // if (
                            //     storie.created_at
                            //     && typeof storie.created_at === "string"
                            //     && parseInt(storie.created_at) > moment().unix("X") - 60
                            // )
                            //     e.target.src = img
                        }
                        }
                    />
                }

                {(viewer !== "movie" && viewer !== "img" && typeof img === "string" && img.indexOf("blob:") === -1 && img.indexOf("svg") === -1) ?
                    <Icon icon={img} size="50px" />
                    : (viewer !== "movie" && viewer !== "img" && !(viewer === "img" && img && img.indexOf("svg") > -1)) ?
                        <img alt="MACHEN_IMAGE" src={img} style={{ width: "100%", height: "100%" }} />
                        : <React.Fragment></React.Fragment>
                }

                {/* IMAGES */}
                {(viewer === "img" && img && img.indexOf(".svg") > -1) ?
                    <img
                        alt="altImage"
                        src={SVGIcon}
                        style={
                            {
                                borderRadius: "15px",
                                boxShadow: `0 1px 2px 2px rgba(0,0,0,0.1)`,
                                maxWidth: "100%",
                                height: "100%",
                                maxHeight: "33vh"
                            }}
                    />
                    : <React.Fragment></React.Fragment>
                }

                {viewer === "movie"
                    &&
                    <video
                        style={{
                            width: "100%"
                        }}
                        controlsList="nodownload"
                        src={`https://machen.azureedge.net/${file.file}`} ></video>
                }
                {viewer === "audio"
                    &&
                    <ReactAudioPlayer
                        src={`https://machen.azureedge.net/${file.file}`}
                        // autoPlay
                        controls
                    />
                }
                <div style={{ clear: "both" }}></div>
            </div>
        )
    }

    return (
        <div
            style={{
                maxWidth: "calc(100% - 15px)",
                marginLeft: "7px",
                ...props.messageClick ? { cursor: "pointer" } : {},
                ...props.boxStyle ? props.boxStyle : {}
            }}
            onClick={props.messageClick ? (e) => {
                props.messageClick()
                e.preventDefault()
            } : null
            }
            ref={messageRef}
        >
            <div
                className="message"
                style={{
                    position: "relative",
                    maxWidth: "calc(88% - 15px)",
                    float: my ? "right" : "left",
                    ...my ? {
                        marginRight: "15px"
                    } : {
                        marginLeft: "15px"
                    },
                    width: "auto",
                    minWidth: "303px",
                    marginBottom: "20px"
                }}

                onMouseLeave={() => { closeOptionsHandle(1000) }}
                onMouseOver={() => {
                    if (!props.isReply)
                        overOptionsHandle(true)
                }}
                onClick={() => {
                    if (!props.isReply)
                        overOptionsHandle(true)
                }}
            >
                {(props.showUserName && !my) &&
                    <div style={{
                        position: "relative",
                        width: "100%",
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: customApp('menu'),
                    }}>
                        {(
                            !session.GlobalData.hideDeletedUsers
                            ||
                            (
                                session
                                && session.GlobalData
                                && session.GlobalData.hideDeletedUsers
                                && storie.user
                                && (
                                    !storie.user.deleted
                                    && (
                                        !reduxStore.getState().db.users[storie.user._id]
                                        || (
                                            reduxStore.getState().db.users[storie.user._id]
                                            && !reduxStore.getState().db.users[storie.user._id].deleted
                                        )
                                    )
                                )
                            )
                        ) && storie.user.name ? storie.user.name : translate("$__deletedUser")}
                    </div>
                }
                <div style={{
                    position: "relative",
                    float: my ? "right" : "left",
                    padding: "7px 15px",
                    width: "100%",
                    background: my ? "linear-gradient(33deg, rgba(237, 237, 237, 1), rgba(196, 196, 196, 1))" : "linear-gradient(33deg, rgba(250, 250, 250, 1), rgba(240,240,240, 1))",
                    borderRadius: "15px",
                    boxShadow: "rgb(0 0 0 / 5%) 0px 2px 2px 2px",
                    ...storie.approval ? {
                        border: `solid 4px ${storie.message.indexOf("$__approved") > -1 ? `green` : `red`}`
                    } : {}
                }}
                >

                    {(storie && storie.filesCount && storie && storie.user && storie.user._id === session._id && parseInt(storie.filesCount) > 0 && storie.files && Object.keys(storie.files).length === 0) ?
                        <React.Fragment>
                            <div style={{
                                position: "relative",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "lightgray",
                            }}>
                                {translate(`$__transferringFile${parseInt(storie.filesCount) > 1 ? `s` : ``}`)}
                            </div>
                            <div
                                className="files"
                                style={{
                                    position: "relative",
                                    maxWidth: "calc(100% - 15px)",
                                    width: "100%",
                                    display: "flex",
                                }}>
                                {
                                    parseInt(storie.created_at) > moment().unix('x') - 60
                                        ?
                                        <React.Fragment>
                                            {/* HOUVE UM PROBLEMA AO ENVIAR OS ARQUIVOS */}
                                        </React.Fragment>
                                        :
                                        <React.Fragment>

                                        </React.Fragment>
                                }
                            </div>
                        </React.Fragment>
                        :
                        <React.Fragment></React.Fragment>
                    }
                    {
                        storie.files && Object.keys(storie.files).length > 0 ?
                            <div className="files"
                                style={{
                                    position: "relative",
                                    maxWidth: "calc(100% - 15px)",
                                    float: my ? "right" : "left",
                                    ...my ? {
                                        marginRight: "15px"
                                    } : {
                                        marginLeft: "15px"
                                    },
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: my ? `flex-end` : `none`,
                                    flexWrap: "wrap"
                                }}>
                                {Object.keys(storie.files).map((id, i) => <div key={`${id}-${i}`}>{renderFile(storie.files[id])}</div>)}
                            </div>
                            : storie.files && storie.files.length > 0 ?
                                <div className="files"
                                    style={{
                                        position: "relative",
                                        maxWidth: "calc(100% - 15px)",
                                        float: my ? "right" : "left",
                                        ...my ? {
                                            marginRight: "15px"
                                        } : {
                                            marginLeft: "15px"
                                        },
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: my ? `flex-end` : `none`,
                                        flexWrap: "wrap"
                                    }}>
                                    {storie.files.map((ff, i) => renderFile(ff))}
                                </div>
                                : <React.Fragment></React.Fragment>
                    }
                    {(!props.isReply
                        && props.storie
                        && props.storie.reply
                        && timeline
                        && (
                            (
                                timeline[props.nodeId]
                                && timeline[props.nodeId][props.storie.reply]
                            ) ||
                            (
                                timeline[session._id]
                                && timeline[session._id][props.storie.reply]
                            )
                        )
                    ) &&
                        <div
                            style={{
                                position: "relative",
                                width: "calc(100% - 20px)",
                                opacity: 0.66,
                                display: "flex",
                                justifyContent: "center",
                                marginBottom: "7px"
                            }}>
                            <ReplyMessage
                                isReply={true}
                                storie={timeline[props.nodeId] && timeline[props.nodeId][props.storie.reply] ? timeline[props.nodeId][props.storie.reply] : timeline[session._id][props.storie.reply]}
                                db={props.db}
                                nodeId={props.nodeId}
                                openLightBox={(e) => {
                                    if (props.openLightBox)
                                        props.openLightBox(e)
                                }}
                                messageClick={() => {
                                    if (props.replyClick)
                                        props.replyClick()
                                }}
                            />
                        </div>
                    }
                    {(!storie.deleted || storie.type === 'timer') ?
                        renderMessage()
                        :
                        <div
                            style={{
                                color: 'lightGray',
                                height: 33,
                                padding: 7,
                            }}
                        >
                            {translate("$__removed", "*")}*
                        </div>
                    }
                </div>
                {
                    (
                        storie.user
                        && storie.user._id
                    ) &&
                    <div
                        style={{
                            position: "absolute",
                            ...my ? {
                                right: "-20px"
                            } : {
                                left: "-20px"
                            },
                            bottom: 0,
                        }}
                    >
                        <UserBadge
                            overlap="circular"
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            userId={storie.user._id}
                        >
                            <Avatar
                                {...storie.user._id && !reduxStore.getState().db.users[storie.user._id]?.deleted ?
                                    {
                                        alt: storie.user.displayName ? storie.user.displayName : storie.user.name,
                                        src: `${storie.user.image}`
                                    } : {
                                        alt: translate("$__deletedUser"),
                                        src: null,
                                    }
                                }
                                // alt={storie.user.displayName ? storie.user.displayName : storie.user.name}
                                // src={`${storie.user.image}`}
                                style={{
                                    width: "22px",
                                    height: "22px"
                                }} />
                        </UserBadge>
                    </div>
                }
                <div style={{
                    position: "absolute",
                    ...my ? {
                        left: "15px"
                    } : {
                        right: "15px"
                    },
                    bottom: "-26px",
                    color: "rgba(0,0,0,0.77)",
                    background: "linear-gradient(33deg, rgba(233, 233, 233, 1), rgba(211,211,211, 1))",
                    display: "flex",
                    padding: "1px 7px",
                    alignItems: "center",
                    borderRadius: "0 0 7px 7px",
                    fontSize: "10px",
                    width: "fit-content",

                }}
                    onMouseLeave={closeOptionsHandle}
                    onMouseOut={closeOptionsHandle}
                >


                    {!storie.deleted && storie._reactions && Object.keys(storie._reactions).length > 0 &&
                        <div style={{
                            display: "flex",
                            alignItems: "center"
                        }}>
                            {storie && storie._reactions.like && Object.keys(storie._reactions.like).length > 0 &&
                                <IconButton
                                    icon={"thumb_up"}
                                    iconColor={customApp("menu")}
                                    title={<React.Fragment>
                                        <div className={classes.users}>
                                            {Object.keys(storie._reactions.like)
                                                .sort((a, b) => {
                                                    if (
                                                        storie._reactions.like[a].reactDate
                                                        && String(storie._reactions.like[a].reactDate.low)
                                                        && storie._reactions.like[b].reactDate
                                                        && String(storie._reactions.like[b].reactDate.low)
                                                        && parseInt(storie._reactions.like[a].reactDate.low) < parseInt(storie._reactions.like[b].reactDate.low)
                                                    ) {
                                                        return -1
                                                    } else if (
                                                        storie._reactions.like[a].reactDate
                                                        && String(storie._reactions.like[a].reactDate.low)
                                                        && storie._reactions.like[b].reactDate
                                                        && String(storie._reactions.like[b].reactDate.low)
                                                        && parseInt(storie._reactions.like[a].reactDate.low) > parseInt(storie._reactions.like[b].reactDate.low)
                                                    ) {
                                                        return 1
                                                    } else {
                                                        return 0
                                                    }
                                                })
                                                .map((a, i) => {
                                                    return (
                                                        <div key={a} style={{ position: "relative", display: "flex", alignItems: "center", }}>
                                                            <Avatar alt={storie._reactions.like[a].name} src={storie._reactions.like[a].image || defaultAvatar} />
                                                            <span style={{ paddingLeft: "7px" }}>
                                                                {storie._reactions.like[a].displayName ? storie._reactions.like[a].displayName : storie._reactions.like[a].name}{storie._reactions.like[a].reactDate && storie._reactions.like[a].reactDate.low && ` (${moment(storie._reactions.like[a].reactDate.low * 1000).tz("America/Sao_Paulo").format("DD/MM/YYYY H:mm")})`}
                                                            </span>
                                                        </div>
                                                    )
                                                }
                                                )}
                                        </div>
                                    </React.Fragment>}
                                    style={{
                                        fontSize: "12px",
                                        margin: 0
                                    }}
                                />
                            }
                            {storie && storie._reactions && storie._reactions.nolike && Object.keys(storie._reactions.nolike).length > 0 &&
                                <IconButton
                                    icon={"thumb_down"}
                                    iconColor={customApp("medium")}
                                    style={{
                                        fontSize: "12px",
                                        margin: 0
                                    }}
                                    title={<React.Fragment>
                                        <div className={classes.users}>
                                            {Object.keys(storie._reactions.nolike)
                                                .sort((a, b) => {
                                                    if (
                                                        storie._reactions.nolike[a].reactDate
                                                        && String(storie._reactions.nolike[a].reactDate.low)
                                                        && storie._reactions.nolike[b].reactDate
                                                        && String(storie._reactions.nolike[b].reactDate.low)
                                                        && parseInt(storie._reactions.nolike[a].reactDate.low) < parseInt(storie._reactions.nolike[b].reactDate.low)
                                                    ) {
                                                        return -1
                                                    } else if (
                                                        storie._reactions.nolike[a].reactDate
                                                        && String(storie._reactions.nolike[a].reactDate.low)
                                                        && storie._reactions.nolike[b].reactDate
                                                        && String(storie._reactions.nolike[b].reactDate.low)
                                                        && parseInt(storie._reactions.nolike[a].reactDate.low) > parseInt(storie._reactions.nolike[b].reactDate.low)
                                                    ) {
                                                        return 1
                                                    } else {
                                                        return 0
                                                    }
                                                })
                                                .map((a, i) => {
                                                    return (
                                                        <div key={a} style={{ position: "relative", display: "flex", alignItems: "center", }}>
                                                            <Avatar alt={storie._reactions.nolike[a].name} src={storie._reactions.nolike[a].image || defaultAvatar} />
                                                            <span style={{ paddingLeft: "7px" }}>
                                                                {storie._reactions.nolike[a].displayName ? storie._reactions.nolike[a].displayName : storie._reactions.nolike[a].name}{storie._reactions.nolike[a].reactDate && storie._reactions.nolike[a].reactDate.low && ` (${moment(storie._reactions.nolike[a].reactDate.low * 1000).tz("America/Sao_Paulo").format("DD/MM/YYYY H:mm")})`}
                                                            </span>
                                                        </div>
                                                    )
                                                }
                                                )}
                                            <div style={{ clear: "both" }}></div>
                                        </div>
                                    </React.Fragment>}
                                />
                            }
                            {storie && storie._reactions && storie._reactions.love && Object.keys(storie._reactions.love).length > 0 &&
                                <IconButton
                                    icon={"favorite"}
                                    iconColor={customApp("medium")}
                                    style={{
                                        fontSize: "12px",
                                        margin: 0
                                    }}
                                    title={<React.Fragment>
                                        <div className={classes.users}>
                                            {Object.keys(storie._reactions.love)
                                                .sort((a, b) => {
                                                    if (
                                                        storie._reactions.love[a].reactDate
                                                        && String(storie._reactions.love[a].reactDate.low)
                                                        && storie._reactions.love[b].reactDate
                                                        && String(storie._reactions.love[b].reactDate.low)
                                                        && parseInt(storie._reactions.love[a].reactDate.low) < parseInt(storie._reactions.love[b].reactDate.low)
                                                    ) {
                                                        return -1
                                                    } else if (
                                                        storie._reactions.love[a].reactDate
                                                        && String(storie._reactions.love[a].reactDate.low)
                                                        && storie._reactions.love[b].reactDate
                                                        && String(storie._reactions.love[b].reactDate.low)
                                                        && parseInt(storie._reactions.love[a].reactDate.low) > parseInt(storie._reactions.love[b].reactDate.low)
                                                    ) {
                                                        return 1
                                                    } else {
                                                        return 0
                                                    }
                                                })
                                                .map((a, i) => {
                                                    return (
                                                        <div key={a} style={{ position: "relative", display: "flex", alignItems: "center", }}>
                                                            <Avatar alt={storie._reactions.love[a].name} src={storie._reactions.love[a].image || defaultAvatar} />
                                                            <span style={{ paddingLeft: "7px" }}>
                                                                {storie._reactions.love[a].displayName ? storie._reactions.love[a].displayName : storie._reactions.love[a].name}{storie._reactions.love[a].reactDate && storie._reactions.love[a].reactDate.low && ` (${moment(storie._reactions.love[a].reactDate.low * 1000).tz("America/Sao_Paulo").format("DD/MM/YYYY H:mm")})`}
                                                            </span>
                                                        </div>
                                                    )
                                                }
                                                )}
                                            <div style={{ clear: "both" }}></div>
                                        </div>
                                    </React.Fragment>}
                                />
                            }
                            {storie && storie._reactions && storie._reactions.approve && Object.keys(storie._reactions.approve).length > 0 &&
                                <IconButton
                                    icon="verified"
                                    iconColor={"green"}
                                    style={{
                                        fontSize: "12px",
                                        margin: 0
                                    }}
                                    title={<React.Fragment>
                                        <div className={classes.users}>
                                            {Object.keys(storie._reactions.approve)
                                                .sort((a, b) => {
                                                    if (
                                                        storie._reactions.approve[a].reactDate
                                                        && String(storie._reactions.approve[a].reactDate.low)
                                                        && storie._reactions.approve[b].reactDate
                                                        && String(storie._reactions.approve[b].reactDate.low)
                                                        && parseInt(storie._reactions.approve[a].reactDate.low) < parseInt(storie._reactions.approve[b].reactDate.low)
                                                    ) {
                                                        return -1
                                                    } else if (
                                                        storie._reactions.approve[a].reactDate
                                                        && String(storie._reactions.approve[a].reactDate.low)
                                                        && storie._reactions.approve[b].reactDate
                                                        && String(storie._reactions.approve[b].reactDate.low)
                                                        && parseInt(storie._reactions.approve[a].reactDate.low) > parseInt(storie._reactions.approve[b].reactDate.low)
                                                    ) {
                                                        return 1
                                                    } else {
                                                        return 0
                                                    }
                                                })
                                                .map((a, i) => {
                                                    return (
                                                        <div key={a} style={{ position: "relative", display: "flex", alignItems: "center", }}>
                                                            <Avatar alt={storie._reactions.approve[a].name} src={storie._reactions.approve[a].image || defaultAvatar} />
                                                            <span style={{ paddingLeft: "7px" }}>
                                                                {storie._reactions.approve[a].displayName ? storie._reactions.approve[a].displayName : storie._reactions.approve[a].name}{storie._reactions.approve[a].reactDate && storie._reactions.approve[a].reactDate.low && ` (${moment(storie._reactions.approve[a].reactDate.low * 1000).tz("America/Sao_Paulo").format("DD/MM/YYYY H:mm")})`}
                                                            </span>
                                                        </div>
                                                    )
                                                }
                                                )}
                                            <div style={{ clear: "both" }}></div>
                                        </div>
                                    </React.Fragment>}
                                />
                            }
                            {storie && storie._reactions && storie._reactions.attention && Object.keys(storie._reactions.attention).length > 0 &&
                                <IconButton
                                    icon="report_problem"
                                    iconColor={"red"}
                                    style={{
                                        fontSize: "12px",
                                        margin: 0
                                    }}
                                    title={<React.Fragment>
                                        <div className={classes.users}>
                                            {Object.keys(storie._reactions.attention)
                                                .sort((a, b) => {
                                                    if (
                                                        storie._reactions.attention[a].reactDate
                                                        && String(storie._reactions.attention[a].reactDate.low)
                                                        && storie._reactions.attention[b].reactDate
                                                        && String(storie._reactions.attention[b].reactDate.low)
                                                        && parseInt(storie._reactions.attention[a].reactDate.low) < parseInt(storie._reactions.attention[b].reactDate.low)
                                                    ) {
                                                        return -1
                                                    } else if (
                                                        storie._reactions.attention[a].reactDate
                                                        && String(storie._reactions.attention[a].reactDate.low)
                                                        && storie._reactions.attention[b].reactDate
                                                        && String(storie._reactions.attention[b].reactDate.low)
                                                        && parseInt(storie._reactions.attention[a].reactDate.low) > parseInt(storie._reactions.attention[b].reactDate.low)
                                                    ) {
                                                        return 1
                                                    } else {
                                                        return 0
                                                    }
                                                })
                                                .map((a, i) => {
                                                    return (
                                                        <div key={a} style={{ position: "relative", display: "flex", alignItems: "center", }}>
                                                            <Avatar alt={storie._reactions.attention[a].name} src={storie._reactions.attention[a].image || defaultAvatar} />
                                                            <span style={{ paddingLeft: "7px" }}>
                                                                {storie._reactions.attention[a].displayName ? storie._reactions.attention[a].displayName : storie._reactions.attention[a].name}{storie._reactions.attention[a].reactDate && storie._reactions.attention[a].reactDate.low && ` (${moment(storie._reactions.attention[a].reactDate.low * 1000).tz("America/Sao_Paulo").format("DD/MM/YYYY H:mm")})`}
                                                            </span>
                                                        </div>
                                                    )
                                                }
                                                )}
                                            <div style={{ clear: "both" }}></div>
                                        </div>
                                    </React.Fragment>
                                    }
                                />
                            }
                            <div style={{ clear: "both" }}></div>
                        </div>
                    }
                    {!props.isReply &&
                        <div style={{
                            position: "relative",
                            float: "left",
                            marginLeft: "7px",
                            marginRight: "7px",
                        }}>

                            {overOptions && !props.noReactions && !storie.deleted &&
                                <div
                                    style={{
                                        display: "flex",
                                        minWidth: "fit-content",
                                        justifyContent: "center",

                                    }}
                                    onMouseOver={(e) => {
                                        overOptionsHandle(true)
                                    }
                                    }
                                >
                                    <Icon
                                        icon={reaction && reaction === "like" ? "thumb_up" : "thumb_up_off_alt"}
                                        iconColor={reaction && reaction === "like" ? customApp("menu") : customApp("medium")}
                                        title={translate("$__like", 1)}
                                        size={"18px"}
                                        onClick={() => {
                                            reactThis("like")
                                        }}
                                        onMouseLeave={closeOptionsHandle}
                                    />
                                    <Icon
                                        icon={reaction && reaction === "nolike" ? "thumb_down" : "thumb_down_off_alt"}
                                        title={translate("$__notlike", 1)}
                                        iconColor={reaction && reaction === "nolike" ? customApp("menu") : customApp("medium")}
                                        size={"18px"}
                                        onClick={() => {
                                            reactThis("nolike")
                                        }}
                                        onMouseLeave={closeOptionsHandle}
                                    />
                                    <Icon
                                        icon={reaction && reaction === "love" ? "favorite" : "favorite_border"}
                                        title={translate("$__love", 1)}
                                        iconColor={reaction && reaction === "love" ? customApp("menu") : customApp("medium")}
                                        size={"18px"}
                                        onClick={() => {
                                            reactThis("love")
                                        }}
                                        onMouseLeave={closeOptionsHandle}
                                    />
                                    <Icon
                                        icon="verified"
                                        title={translate("$__approve", 1)}
                                        iconColor={reaction && reaction === "approve" ? "green" : customApp("medium")}
                                        size={"18px"}
                                        onClick={() => {
                                            reactThis("approve")
                                        }}
                                        onMouseLeave={closeOptionsHandle}
                                    />
                                    <Icon
                                        icon="report_problem"
                                        title={translate("$__attention", 1)}
                                        iconColor={reaction && reaction === "attention" ? "red" : customApp("medium")}
                                        size={"18px"}
                                        onClick={() => {
                                            reactThis("attention")
                                        }}
                                        onMouseLeave={closeOptionsHandle}
                                    />
                                    {storie && storie.user && storie.user._id === session._id &&
                                        <Icon
                                            icon="forward"
                                            title={translate("$__forwardThisMessage", 1)}
                                            iconColor={"deepskyblue"}
                                            size={"18px"}
                                            onClick={() => {
                                                setForward(true)
                                            }}
                                            onMouseLeave={closeOptionsHandle}
                                        />
                                    }
                                    <Icon
                                        icon="reply"
                                        title={translate("$__replyThisMessage", 1)}
                                        iconColor={"deepskyblue"}
                                        size={"18px"}
                                        onClick={() => {
                                            props.reduxFunction("ASYNC", "SET_CHATS", {
                                                ...reduxStore.getState().chats,
                                                drafts: {
                                                    ...reduxStore.getState().chats.drafts || {},
                                                    [props.nodeId]: {
                                                        ...reduxStore.getState().chats.drafts && reduxStore.getState().chats.drafts[props.nodeId] ? reduxStore.getState().chats.drafts[props.nodeId] : {},
                                                        reply: storie._id,
                                                    }
                                                }
                                            })
                                        }}
                                        onMouseLeave={closeOptionsHandle}
                                    />
                                    {
                                        (
                                            !storie.deleted &&
                                            storie.type === 'comment' &&
                                            storie.user &&
                                            storie.user._id &&
                                            storie.user._id === session._id &&
                                            parseInt(storie.created_at) > parseInt(moment().unix('x')) - 360
                                        ) ?
                                            <CustomButton
                                                id={`chat_message_delete`}
                                                title={translate(`$__delete`, "*")}
                                                color={'red'}
                                                icon={"delete_forever"}
                                                onClick={() => { cancelConfirm() }}
                                                size={"18px"}
                                                transparent
                                            />
                                            :
                                            <React.Fragment></React.Fragment>
                                    }
                                    <div style={{ clear: "both" }}></div>
                                </div>
                            }
                            <div style={{ clear: "both" }}></div>
                        </div>
                    }
                    <div style={{ clear: "both" }}></div>
                    {moment(new Date()).tz("America/Sao_Paulo").format("DD/MM/YYYY") === moment(storie.created_at * 1000).tz("America/Sao_Paulo").format("DD/MM/YYYY") ?
                        `${moment(storie.created_at * 1000).tz("America/Sao_Paulo").format("H:mm")}`
                        :
                        moment(new Date()).tz("America/Sao_Paulo").format("YYYY") === moment(storie.created_at * 1000).tz("America/Sao_Paulo").format("YYYY") ?
                            `${moment(storie.created_at * 1000).tz("America/Sao_Paulo").format("DD/MM H:mm")}`
                            :
                            `${moment(storie.created_at * 1000).tz("America/Sao_Paulo").format("DD/MM/YYYY H:mm")}`
                    }

                    {(
                        storie.readedBy
                        && Object.keys(storie.readedBy).length > 0)
                        &&
                        <Tooltip
                            title={(
                                <React.Fragment>
                                    <div className={classes.users}>
                                        {Object.keys(storie.readedBy)
                                            .sort((a, b) => {
                                                if (storie
                                                    && storie.readedBy[a]
                                                    && storie.readedBy[a].readedDate
                                                    && storie.readedBy[b]
                                                    && storie.readedBy[b].readedDate
                                                    && String(storie.readedBy[a].readedDate.low)
                                                    && String(storie.readedBy[b].readedDate.low)
                                                    && parseInt(storie.readedBy[a].readedDate.low) < parseInt(storie.readedBy[b].readedDate.low)
                                                ) {
                                                    return -1
                                                } else if (storie
                                                    && storie.readedBy[a]
                                                    && storie.readedBy[a].readedDate
                                                    && storie.readedBy[b]
                                                    && storie.readedBy[b].readedDate
                                                    && String(storie.readedBy[a].readedDate.low)
                                                    && String(storie.readedBy[b].readedDate.low)
                                                    && parseInt(storie.readedBy[a].readedDate.low) > parseInt(storie.readedBy[b].readedDate.low)
                                                ) {
                                                    return 1
                                                } else {
                                                    return 0
                                                }
                                            })
                                            .map((a, i) => (
                                                storie.user._id !== a && storie.readedBy && storie.readedBy[a] && storie.readedBy[a].name &&
                                                <div key={a} style={{ position: "relative", display: "flex", alignItems: "center", }}>
                                                    <Avatar alt={storie.readedBy[a].name} src={storie.readedBy[a].image || null} />
                                                    <span style={{ paddingLeft: "7px" }}>
                                                        {storie.readedBy[a].displayName ? storie.readedBy[a].displayName : storie.readedBy[a].name}{storie.readedBy[a].readedDate && storie.readedBy[a].readedDate.low && ` (${moment(storie.readedBy[a].readedDate.low * 1000).tz("America/Sao_Paulo").format("DD/MM/YYYY H:mm")})`}
                                                    </span>
                                                </div>
                                            ))}
                                        <div style={{ clear: "both" }}></div>
                                    </div>
                                </React.Fragment>
                            )
                            }
                        >
                            <div>
                                <Icon color={"#FFFFFF"} size="14px" style={{ marginLeft: "7px" }}>done_all</Icon>
                            </div>
                        </Tooltip>
                    }
                </div>
                <div style={{ clear: "both" }}></div>
            </div >
            {/* {console.log(storie)} */}
            {forward ?
                <Dialog
                    fullWidth={true}
                    // onClose={() => setEditor(false)} 
                    aria-labelledby="simple-dialog-title"
                    open={forward}
                >
                    <div className="forwardMessage">
                        <div style={{ position: "relative", marginLeft: "10px", marginTop: '5px', width: "calc(100% - 10px)" }}>
                            <HeaderWithIcon
                                title={translate("$__forwardMessage", 1)}
                                icon="forward"
                                onCancel={() => { setForward(false) }}
                            />
                        </div>
                        <div style={{ padding: 15 }}>
                            <SelectUsers
                                hideSelected
                                avatarSize="33px"
                                permission={props.permission}
                                noLimit
                                ignoreId={props.nodeId ? props.nodeId : props.storie.idRel ? props.storie.idRel : null}
                                nodeId={props.nodeId}
                                onSelect={(e) => {
                                    setForwardToId(e)
                                }}
                            /></div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", padding: "15px" }}>
                            <CustomButton
                                shadow
                                title={translate("$__cancel")}
                                text={translate("$__cancel")}
                                color="lightgrey"
                                icon={"close"}
                                size={"25px"}
                                onClick={() => {
                                    setForward(false)
                                    setForwardToId(null)
                                }}
                            />
                            {forwardToId &&
                                <div style={{ marginLeft: "15px" }}>
                                    <CustomButton
                                        shadow
                                        title={translate("$__forward")}
                                        text={translate("$__forward")}
                                        icon={"forward"}
                                        size={"25px"}
                                        load={saveLoading ? true : false}
                                        onClick={() => {
                                            setSaveLoading(true)
                                            forwardMessage(props, {
                                                ...storie,
                                                _forwardToId: forwardToId
                                            })


                                            setTimeout(() => {
                                                setForward(false)
                                                setForwardToId(null)
                                                props.reduxFunction("ASYNC", "SET_CHAT", {
                                                    ...reduxStore.getState().chat,
                                                    db: 'users',
                                                    nodeId: forwardToId[0],
                                                    loading: false,
                                                })
                                                setSaveLoading(false)
                                                // }
                                            }, 6000)
                                        }}
                                    />
                                    <div style={{ clear: "both" }}></div>
                                </div>
                            }
                            <div style={{ clear: "both" }}></div>
                        </div>
                        <div style={{ clear: "both" }}></div>
                    </div>
                </Dialog>
                : <React.Fragment></React.Fragment>
            }
            <div style={{ clear: "both" }}></div>
        </div >
    )
}
//REACT
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(
    null,
    mapDispatchToProps
)(memo(Message))