import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DateRange from '@material-ui/icons/DateRange';
import ErrorBoundary from "components/ErrorBoundary"

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";

//FUNCTIONS
import { translate } from "functions/";

const options = [
    '$__last7Days',
    '$__last15Days',
    '$__last30Days',
    '$__last60Days',
    '$__last90Days',
    '$__last180Days',
    '$__last365Days',
    '$__allPeriod',
];

const ITEM_HEIGHT = 48;

const mapStateToProps = (store) => ({
    store: {
        session: store.session
    }
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)


function PeriodFilter(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selected, setSelected] = React.useState('$__last7Days')
    const open = Boolean(anchorEl);

    React.useEffect(() => {
    }, [])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = async (option) => {
        setAnchorEl(null);
        setSelected(option)
        if (option && typeof option === 'string') {
            let subtractDays = String(option).replace('$__last', '')
            subtractDays = subtractDays.replace('Days', '')
            if (option === '$__allPeriod') {
                props.onSelect(500000)
            } else {
                props.onSelect(subtractDays)
            }
        }
    };

    return (
        <ErrorBoundary>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
                style={props.title ? {} : { ...props.style || props.showSelected ? props.style : {} }}
            >
                {props.title ? <div style={{ textWrap: "nowrap", fontSize: 10, color: "#CCCCCC", paddingRight: 5, paddingLeft: 5 }}>{`${props.title}:` || ``}</div> : <></>}<DateRange /><div style={{ textWrap: "nowrap", fontSize: 12, paddingRight: 5, paddingLeft: 5 }}>{props.showSelected && selected ? translate(selected, 1) : ``}</div>
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={() => {
                    handleClose()
                }}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                    },
                }}
            >
                {options.map((option) => (
                    <MenuItem
                        key={option}
                        selected={option === selected}
                        onClick={(e) => handleClose(option)}
                    >
                        {translate(option, 1)}
                    </MenuItem>
                ))}
            </Menu>
        </ErrorBoundary>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PeriodFilter)