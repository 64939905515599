import React, { useState } from 'react'

//REDUX
import reduxStore from "store/"

//FUNCTIONS
import { translate } from "functions/"

const AnswerForm = (props) => {
  const { db } = reduxStore.getState()
  const [values, setValues] = useState(props.values || {})
  const [column, setColumn] = useState(null)
  const [columns, setColumns] = useState([])

  React.useEffect(() => {
    let Columns = []
    if (reduxStore.getState().forms[props.formId].boardColumns)
      Object.keys(db.cards).filter(card => db.cards[card]._parent === props.boardId && db.cards[card].type === "step" && !db.cards[card].deleted).sort((cardA, cardB) => {
        let refA = `A${db.cards[cardA].step}`
        let refB = `A${db.cards[cardB].step}`
        if (refA > refB)
          return 1
        if (refA < refB)
          return -1
        return 0
      }).forEach((card, index) => {
        if (index === 0 && card)
          setColumn(card)
        Columns.push(card)
      })
    setColumns(Columns)
    // eslint-disable-next-line
  }, [props.boardId])

  React.useEffect(() => {
    let newValues = {}
    if (props.values && Object.keys(props.values).length > 0) {
      Object.keys(props.values).forEach(d => {
        newValues = {
          ...newValues,
          [d.substr(0, 1) !== "f" ? `f${d}` : d]: props.values[d]
        }
      })
      setValues(newValues)
    }
  }, [props.values])

  // React.useEffect(() => {
  //   if (props.resetValues)
  //     setValues({})
  // }, [props.resetValues])

  React.useEffect(() => {
    if (props.onChange)
      props.onChange(values, verifyForm())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  React.useEffect(() => {
    if (props.columnChange && column)
      props.columnChange(column)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [column])

  React.useLayoutEffect(() => {
    if (props.onChange)
      props.onChange(values, verifyForm())
    // eslint-disable-next-line
  }, [])

  let form = JSON.parse(reduxStore.getState().forms[props.formId].elements) || []
  if (form.length > 0)
    form.forEach((dd, di) => {
      if (dd.id.substr(0, 1) !== "f") form[di].id = `f${dd.id}`
    })

  const verifyForm = (e) => {
    let result
    form.forEach((element) => {
      if (
        element.required
        && (
          (
            !element.depends
            &&
            (
              !values[element.id]
              || (
                values[element.id]
                && typeof values[element.id] === 'undefined'
              )
            )
          )
          || (
            element.depends
            && element.dependsOn
            && (
              !values[element.id]
              &&
              (
                values[element.dependsOn]
                &&
                (
                  (
                    typeof element.dependsValue === "object"
                    && element.dependsValue.filter(a => values[element.dependsOn].indexOf(a) > -1).length > 0
                  )
                  ||
                  (
                    typeof element.dependsValue !== "object"
                    && values[element.dependsOn] === element.dependsValue
                  )
                )
              )
            )
          )
        )
      ) {
        result = `${translate("$_fillAllRequiredAsks")}`
      }
    })
    return result || null
  }
  const handleCheckboxChange = (fieldId, value) => {
    if (values[fieldId]) {
      if (values[fieldId].includes(value)) {
        setValues({ ...values, [fieldId]: values[fieldId].filter(item => item !== value) });
      } else {
        setValues({ ...values, [fieldId]: [...values[fieldId], value] });
      }
    } else {
      setValues({ ...values, [fieldId]: [value] });
    }
  };



  return (<div
    style={{
      width: "100%",
      ...props.style ? props.style : {}
    }}>
    {form?.map((element, index) => (
      ((!element.depends) ||
        (
          element.depends
          && (
            (
              Array.isArray(element.dependsValue)
              && JSON.stringify(values[element.dependsOn]) === JSON.stringify(element.dependsValue)
            )
            ||
            (
              !Array.isArray(element.dependsValue)
              && values[element.dependsOn] === element.dependsValue
            )
          )
        )
      ) ?
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '20px',
          alignItems: 'center',
          marginBottom: '10px',
          width: '100%',
        }}
          key={`item_${element.id}_${index}`}
        >
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}>
            <label htmlFor={element.id} style={{
              marginBottom: '5px',
            }}>{element.name}{element.required ? '*' : null}:</label>
            {
              element.type === 'radio' || element.type === 'checkbox' ?
                element?.items.map((item, index) => (
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: '5px',
                    margin: '3px 0px',
                  }}
                    key={`op_${element.id}_${item}`}
                    onClick={(e) => {
                      if (element.type === 'radio') {
                        setValues({ ...values, [element.id]: item })
                      }
                      if (element.type === 'checkbox') {
                        handleCheckboxChange(element.id, item)
                      }
                    }}
                  >
                    <div
                      style={{
                        pointerEvents: "none",
                      }}
                    >
                      <input
                        id={`op_${element.id}_${item}`}
                        name={`op_${element.id}`}
                        type={element.type}
                        required={element.required ? true : false}
                        {...element.type === 'radio' ? { checked: !(!values[element.id] || values[element.id] !== item) } : {}}
                        {...element.type === 'checkbox' ? { checked: values && values[element.id] && values[element.id].length > 0 && values[element.id].indexOf(item) > -1 ? true : false } : {}}
                        {...props.disabled ? { disabled: true } : {}}
                        onClick={(e) => {
                          if (element.type === 'radio') {
                            setValues({ ...values, [element.id]: item })
                          }
                          if (element.type === 'checkbox') {
                            handleCheckboxChange(element.id, item)
                          }
                        }}
                      />
                      <label htmlFor={`op_${element.id}_${item}`} style={{ color: '#aaa' }}>{item}</label>
                    </div>
                  </div>
                )) :
                element.type === 'textarea' ?
                  <textarea rows={4}
                    id={element.id}
                    required={element.required ? true : false}
                    style={{
                      borderRadius: '5px',
                      width: '100%',
                      height: 'auto',
                      resize: 'none',
                      padding: '10px 5px',
                      border: '1px solid rgb(150,150,150)',

                    }}
                    onChange={(e) => setValues({ ...values, [element.id]: e.target.value })}
                    value={values[element.id] || ""}
                    {...props.disabled ? { disabled: true } : {}}
                  ></textarea>
                  :
                  <input
                    type={element.type}
                    id={element.id}
                    required={element.required ? true : false}
                    style={{
                      borderRadius: '5px',
                      width: '100%',
                      height: 'auto',
                      resize: 'none',
                      padding: '10px 5px',
                      border: '1px solid rgb(150,150,150)',

                    }}
                    value={values[element.id] || ""}
                    onChange={(e) => setValues({ ...values, [element.id]: e.target.value })}
                    {...props.disabled ? { disabled: true } : {}}
                  />
            }
          </div>
        </div> : null))}
    {!props.ignoreSteps &&
      reduxStore.getState().forms[props.formId].boardColumns && columns && column && columns.length > 0 ?
      <div style={{ position: 'relative', width: "100%" }}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          width: "100%",
          fontWeight: "bold",
          marginBottom: 5,
          marginTop: 5
        }}>{translate("$__step", "*")}</div>
        {columns.map((card, index) =>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            width: "100%",
            gap: '5px',
            margin: '3px 0px',
          }}
            key={`col_${card}_${index}`}
            onClick={(e) => {
              setColumn(card)
            }}
          >
            <div
              style={{
                pointerEvents: "none",
              }}
            >
              <input
                id={`col_${card}_${index}`}
                name={`column`}
                type={'radio'}
                required={true}
                checked={column === card ? true : false}
                onClick={(e) => {
                  setColumn(card)
                }}
              />
              <label htmlFor={`col_${card}_${index}`} style={{ color: '#aaa' }}>{db.cards[card].name}</label>
            </div>
          </div>
        )}
      </div>
      : <>
      </>
    }
  </div>)
}

export default AnswerForm