import React, { useMemo, useState } from 'react'

// REDUX
import reduxStore from "store"

// COMPONENTES
import UserAddList from './UserAddList'
import Icon from 'components/Icon'

// @MATERIAL
import { FormControl, InputAdornment, MenuItem, Select, makeStyles, Checkbox, ListItemText, OutlinedInput } from '@material-ui/core'

// FUNCTIONS
import { translate, customApp } from 'functions'

const useStyles = makeStyles({
  formControl: {
    width: '200px',
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#fff',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
  },
  checkbox: {
    color: customApp('color'),
    '& .Mui-checked': {
      color: `${customApp('color')} !important`,
    }
  },
})

const ListUsers = ({ setSelectUser }) => {
  const styles = useStyles();
  const [edit, setEdit] = useState(false)
  const [filter, setFilter] = useState('All')
  const [searchValue, setSearchValue] = useState('')
  const [column, setColumn] = useState(null);
  const [direction, setDirection] = useState('asc');

  const { db } = reduxStore.getState();
  const { users } = db;

  // Gera os dados dos usuários com useMemo
  const dataUser = useMemo(() => {
    return Object.values(users).map(user => ({
      nomedeexibição: user?.name,
      nomecompleto: user?.fullName,
      email: user?.email,
      image: user?.image,
      _id: user?._id,
      "departamento/grupo": user?.groups,
    }));
  }, [users]);

  // Filtra os dados conforme o filtro e valor da busca
  const dataFilter = useMemo(() => {
    return dataUser.filter(user => {
      const matchesFilter = filter === 'All' || (user["departamento/grupo"] && user["departamento/grupo"].some(group => group.label === filter));
      const matchesSearch = Object.values(user).some(
        value => typeof value === 'string' && value.toLowerCase().includes(searchValue.toLowerCase())
      );
      return matchesFilter && matchesSearch;
    });
  }, [dataUser, filter, searchValue]);

  // Ordena os dados conforme a coluna e a direção selecionada
  const orderData = useMemo(() => {
    if (!column) return dataFilter;
    return [...dataFilter].sort((a, b) => {
      if (direction === 'asc') {
        return a[column] > b[column] ? 1 : -1;
      } else {
        return a[column] < b[column] ? 1 : -1;
      }
    });
  }, [dataFilter, column, direction]);

  // Mapeamento de colunas para tradução
  const columnsMap = {
    nomecompleto: translate("$__completeName"),
    "nomedeexibição": translate("$__showName"),
    email: translate("$__email"),
    "departamento/grupo": translate("$__department/group"),
    ramal: translate("$__branchLine"),
    observações: translate("$__observation"),
    celular: translate("$__phone")
  };

  const NameColumn = (name) => columnsMap[name] || name;

  const columns = ['nomecompleto', 'nomedeexibição', 'email', 'departamento/grupo'];
  const [selectedColumns, setSelectedColumns] = useState(['nomecompleto', 'nomedeexibição', 'email']);

  const orderColumn = (columnSelect) => {
    if (columnSelect === column) {
      setDirection(prev => prev === 'asc' ? 'desc' : 'asc');
    } else {
      setColumn(columnSelect);
      setDirection('asc');
    }
  };

  // Gera a lista única dos grupos/departamentos para o filtro
  const selectData = useMemo(() => {
    return [...new Set(dataUser.flatMap(user => user["departamento/grupo"]?.map(group => group?.label || [])))];
  }, [dataUser]);

  return (
    <div
      style={{
        width: "95%",
        position: "absolute",
        zIndex: "9",
        borderRadius: "5px",
        backgroundColor: "#eee",
        minHeight: '100vh',
        height: "auto",
        margin: "0px",
        fontFamily: "Soleto, Roboto, Helvetica, Arial, sans-serif",
      }}
    >
      <div
        style={{
          height: "100%",
          justifyContent: "center",
          margin: "0px",
          width: "100%",
          backgroundColor: "#eee",
        }}
      >
        <div
          style={{
            backgroundColor: "#eee",
            height: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "stretch",
            flexDirection: "column",
            gap: "5px",
            marginBottom: "30px",
            width: "100%",
          }}
        >
          <div
            style={{
              backgroundColor: "#eee",
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
              gap: "5px",
              marginBottom: "15px",
              width: "100%",
            }}
          >
            <div style={{
              display: 'flex',
              alignItems: "flex-end",
              flexDirection: "row",
            }}>
              <div style={{
                height: "100%",
                width: 'auto',
                display: 'flex',
                alignItems: 'flex-start',
                gap: '0px',
                flexDirection: 'column',
                marginRight: '25px',
                marginLeft: '10px',
              }}>
                <p style={{ margin: '5px 0' }}>{translate('$__search')}:</p>
                <FormControl className={styles.formControl} variant="standard" size='small'>
                  <OutlinedInput
                    id="search"
                    type="text"
                    placeholder={translate('$__search')}
                    onChange={(e) => setSearchValue(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <Icon>search</Icon>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
              <div
                style={{
                  height: "100%",
                  width: 'auto',
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: '0px',
                  flexDirection: 'column',
                  marginRight: '25px',
                }}
              >
                <p style={{ margin: '5px 0' }}>{translate("$__filterByDepartment")}:</p>
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 220 }} className={styles.formControl} size='small'>
                  <Select
                    defaultValue="All"
                    displayEmpty
                    onChange={(e) => setFilter(e.target.value)}
                  >
                    <MenuItem value="All">{translate('$__all')}</MenuItem>
                    {selectData.sort().map(departamento => (
                      <MenuItem value={departamento} key={departamento}>{departamento}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  height: "100%",
                  width: 'auto',
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: '0px',
                  flexDirection: 'column',
                  marginRight: '25px',
                }}
              >
                <p style={{ margin: '5px 0' }}>{translate("$__columnName")}:</p>
                <FormControl sx={{ m: 1, width: 300 }} className={styles.formControl} size='small'>
                  <Select
                    labelId="checkbox-label"
                    id="multiple-checkbox"
                    multiple
                    defaultValue={selectedColumns}
                    onChange={(e) => setSelectedColumns(e.target.value)}
                    input={<OutlinedInput />}
                    renderValue={(selected) => selected.map(NameColumn).join(', ')}
                  >
                    {columns.map(name => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={selectedColumns.indexOf(name) > -1} color="default" className={styles.checkbox} />
                        <ListItemText primary={NameColumn(name)} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div style={{
                backgroundColor: '#fff',
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: '5px',
                padding: '10.5px 14px'
              }}>
                {orderData?.length} {orderData?.length > 1 || orderData?.length === 0 ? translate('$__users') : translate('$__user')}
              </div>
            </div>
          </div>
          {orderData.length === 0 ? (
            <p>{translate('$__loading')}...</p>
          ) : (
            <UserAddList
              edit={edit}
              setSelectUser={setSelectUser}
              setEdit={setEdit}
              dataUser={orderData}
              columns={selectedColumns}
              orderData={orderData}
              orderColumn={orderColumn}
              column={column}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ListUsers;
