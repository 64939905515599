import React from "react"

//REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as reduxActions from 'store/actions'
import reduxStore from "store/"

//COMPONENTS
import Card from "components/Card"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import HeaderWithIcon from "components/Header/withIcon"
import CustomButton from "components/Buttons/custom"
import LinearProgress from "components/Progress/linear"

// @MATERIAL
import { withStyles } from "@material-ui/core/styles"

//DEPENDENCIES

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/views/search.js"

//FUNCTIONS
import { translate, customApp, getAppAccess } from "functions/"
import { loadCardsV2 } from "functions/loadData"
import { planAccountVerify } from "functions/cards"

function SearchComponent(props) {
  const [lastChange, setLastChange] = React.useState("")
  const [loader, setLoader] = React.useState(false)
  const [filteredItems, setfilteredItems] = React.useState([])
  const [searchCompleted, setSearchCompleted] = React.useState(false)
  const [searchDeleted, setSearchDeleted] = React.useState(false)
  const [cardsType, setCardsType] = React.useState('all')
  const { db, session } = reduxStore.getState()

  let AppAccess = getAppAccess()

  let hashs = window.location.hash.split('/')
  hashs[0] = hashs[0].replace('#', '')

  React.useEffect(() => {
    let timer = setTimeout(() => { }, 15000)
    if (props.text.length > 1 && props.text !== lastChange) {
      setLastChange(props.text)
      clearTimeout(timer)
      timer = setTimeout(() => searchCardsOnLine(), 500)
      setLoader(true)
    }
    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.text])

  React.useEffect(() => {
    let timer = setTimeout(() => { }, 15000)
    if (props.completed !== searchCompleted && props.text && props.text.length > 1) {
      setLoader(true)
      setSearchCompleted(!searchCompleted)
      setSearchDeleted(true)
      timer = setTimeout(() => searchCardsOnLine(), 500)
    }
    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.completed])

  React.useEffect(() => {
    let timer = setTimeout(() => { }, 15000)
    if (props.deleted !== searchDeleted && props.text && props.text.length > 1) {
      // setfilteredItems([])
      setLoader(true)
      setSearchDeleted(!searchDeleted)
      setSearchCompleted(true)
      timer = setTimeout(() => searchCardsOnLine(), 500)
    }
    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.deleted])

  const searchCardsOnLine = async () => {
    let lc = await loadCardsV2(props, {
      search: props.text,
      ignoreLoader: true,
      deleted: props.deleted,
      completed: props.completed,
      cardLoad: "search"
    })
    if (lc) {
      setTimeout(() => {
        searchCards(lc)
      }, 1000)
    }
  }

  const searchCards = async (result) => {
    let userData = reduxStore.getState().db.users[session._id] || {}
    let cards = {
      ...reduxStore.getState().db.cards,
      ...result
    }

    let searchResult = new Promise((resolve, reject) => {
      if (cards && Object.keys(cards).length > 0)
        resolve(Object.keys(cards).filter(card => {
          if (
            cards[card]
            && cards[card].type !== 'chatGroup'
            &&
            (
              props.text
              &&
              (
                (
                  cards[card].name
                  && props.text
                  && cards[card]
                  && cards[card].name
                  && cards[card].name.toLowerCase().indexOf(props.text.toLowerCase()) > -1
                )
                ||
                (
                  cards[card]
                  && props.text
                  && cards[card].description
                  && cards[card].description.toLowerCase().indexOf(props.text.toLowerCase()) > -1
                )
              )
            )
            && (
              (
                !cards[card].checklist
                ||
                (
                  cards[card].checklist
                  && cards[card].showCard
                )
              )
              &&
              (
                (
                  props.deleted
                  && cards[card].deleted
                )
                || (
                  !props.deleted
                  && !cards[card].deleted
                  && (
                    (
                      !props.completed
                      && cards[card]
                      && cards[card].status !== 'completed'
                    )
                    ||
                    (
                      props.completed
                      && cards[card]
                      && cards[card].status === 'completed'
                    )
                  )
                )
              )
              && (
                (
                  (
                    (
                      AppAccess.owner
                      || AppAccess.planAdmin
                    )
                    && (
                      cards[card].type !== 'plan'
                      || (
                        cards[card].type === 'plan'
                        && planAccountVerify(card)
                      )
                    )
                  )
                  ||
                  (
                    session._id
                    && cards[card]._users
                    && cards[card]._users[session._id]
                  )
                  ||
                  (
                    cards[card]
                    && cards[card]._groups
                    && Object.keys(cards[card]._groups).length > 0
                    && Object.keys(cards[card]._groups).filter(gr =>
                      userData
                      && userData.groups
                      && userData.groups.filter(xg => xg.value === gr).length > 0
                    )
                  )
                )

                &&
                (
                  !cards[card].private
                  ||
                  (
                    cards[card].private
                    && cards[card]._users
                    && cards[card]._users[session._id]
                  )
                )
              )
            )
          ) return true
          return false
        }
        )
        )
      resolve([])
    })

    let response = await searchResult.then(a => {
      return a
    })
    if (response) {
      setfilteredItems(response)
      setLoader(false)
      if (props.onFinish)
        props.onFinish(response.length)
    }
  }

  return (
    <div
      style={{ width: "100%" }}
    >
      <div style={{ positin: 'relative', width: '100%', minHeight: "100px" }}>
        {loader &&
          <GridContainer>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <LinearProgress />
              </div>
            </GridItem>
          </GridContainer>
        }
        <div
          style={{
            position: "relative",
            width: "100%",
            display: "flex"
          }}
        >
          <div
            style={{
              width: "177px",
              padding: 7,
              display: "flex",
              flexDirection: "column",
              marginRight: "solid 1px lightgray"
            }}
          >
            {filteredItems.length > 1 ?
              <div
                style={{
                  backgroundColor: cardsType === 'all' ? "rgba(0,0,0,0.08)" : "",
                  padding: "0 3px"
                }}
              >
                <CustomButton
                  title={translate(`$__allResults`, '*')}
                  text={translate(`$__allResults`, '*')}
                  color={cardsType === 'all' ? customApp('menu') : 'lightGray'}
                  icon={'manage_search'}
                  onClick={() => {
                    setCardsType('all')
                  }}
                  size={'17px'}
                  style={{ padding: "0px 3px !important" }}
                  transparent
                />
              </div>
              : <React.Fragment></React.Fragment>
            }
            {db.type.filter(tp =>
              filteredItems
                .filter(card =>
                  db.cards[card].type === tp.value).length > 0
            ).map((tp, i) => {
              return (
                <div
                  key={`${i}-${tp}`}
                  style={{
                    backgroundColor: cardsType === 'asset' ? "rgba(0,0,0,0.08)" : "",
                    padding: "0 3px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <CustomButton
                    title={translate(`${tp.label}`, '*')}
                    text={translate(`${tp.label}`, '*')}
                    color={cardsType === tp.value ? customApp('menu') : 'lightGray'}
                    icon={tp.icon}
                    onClick={() => {
                      setCardsType(tp.value)
                    }}
                    size={'17px'}
                    style={{ padding: "0px 3px !important" }}
                    transparent
                  />
                  <div style={{
                    backgroundColor: cardsType === tp.value ? customApp("menu", 1) : customApp("menu", 0.5),
                    fontSize: 9,
                    color: "#FFFFFF",
                    borderRadius: 11,
                    width: 22,
                    height: 22,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}>
                    {filteredItems.filter(card => db.cards[card].type === tp.value).length}
                  </div>
                </div>
              )
            })}
          </div>
          <div
            style={{
              width: "calc(100% - 177px)",
              padding: 7,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <GridContainer>
              {db.type.filter(tp =>
                (cardsType !== 'all'
                  && tp.value === cardsType
                )
                || (
                  cardsType === 'all'
                  &&
                  filteredItems
                    .filter(card =>
                      db.cards[card].type === tp.value).length > 0
                )
              ).map((tp, i) => {
                return (
                  <div key={`${tp}-${i}`}>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <HeaderWithIcon
                        title={translate(tp.label, 1)}
                        icon={tp.icon}
                        color={customApp('menu')}
                        style={{
                          padding: '7px'
                        }}
                      />
                      {filteredItems
                        .filter(card => db.cards[card].type === tp.value)
                        .sort((a, b) => {
                          if (db.cards[a].name < db.cards[b].name)
                            return -1
                          if (db.cards[a].name > db.cards[b].name)
                            return 1
                          return 0
                        })
                        .map((card, i) => {
                          return (
                            <GridItem
                              key={card}
                              md={12}
                              style={{ minWidth: '300px' }}
                            >
                              <Card cardId={card} db='cards' showConnectedIn searchText={props.text} expanded={false} onAction={() => { props.onAction() }} />
                            </GridItem>
                          )
                        })}
                    </GridItem>
                  </div>
                )
              })}
            </GridContainer>
          </div>
        </div>

      </div>
    </div >
  )
}

const mapStateToProps = (store, ownProps) => {
  return {
    cards: store.db.cards
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(reduxActions, dispatch)

//EXPORT
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SearchComponent))