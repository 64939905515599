//CORE
import React from "react"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"

//COMPONENTS
import CustomButton from "components/Buttons/custom"
import Files from "components/Files/list"
import Menu from "./menu"
import Timeline from "componentsV3/Timeline"
import Typing from "./Typing"
import UserBadge from "components/Badge/user"
import Message from "componentsV3/Timeline/message"

//@MATERIAL
import { withStyles } from "@material-ui/core/styles"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Avatar from "@material-ui/core/Avatar"

//FUNCTIONS
import {
    customApp,
    getDate,
    translate,
    appAlert,
} from "functions/"

import {
    loadUsers
} from "functions/users"

import {
    loadTimeline,
    timelineComment,
} from "functions/chat"

import NotificationAudio from "assets/sound/notification.mp3"
import MentionNotificationAudio from "assets/sound/mentionNotification.mp3"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/chat.js"
import ButtonViewOnline from "./ButtonViewOnline"

const audio = new Audio(NotificationAudio)
const audioTask = new Audio(MentionNotificationAudio)

function ChatIndex(props) {
    const { nodeId, chatOpen } = props
    const { socket } = reduxStore.getState().functions
    const [expanded, setExpanded] = React.useState(false)
    const [showFiles, setShowFiles] = React.useState(false)
    const [onLine, setOnLine] = React.useState(false)


    const mounted = React.useRef(true)
    const socketOn = React.useRef(false)

    const CHAT_STYLE = {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 3000,
        backgroundColor: "rgba(250,250,250,0.7)",
        backdropFilter: "blur(3px)",
    };

    const CONTAINER_STYLE = (isLargeScreen) => ({
        position: "fixed",
        top: isLargeScreen ? 77 : 50,
        left: isLargeScreen ? 77 : 7,
        right: isLargeScreen ? 77 : 7,
        bottom: isLargeScreen ? 77 : 7,
        zIndex: isLargeScreen ? 3 : 6000,
        boxShadow: "0px 0px 10px 3px rgba(0,0,0,0.2)",
        borderRadius: "15px",
        overflow: "hidden",
    });

    const HEADER_STYLE = {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        height: 40,
        background: customApp("ColumnTitleColor"),
        display: "flex",
        alignItems: "center",
    };

    React.useEffect(() => {
        mounted.current = true
        return () => {
            mounted.current = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Inicializa o ref para timelineTyping no escopo principal
    const timelineTyping = React.useRef({});

    React.useEffect(() => {
        const state = reduxStore.getState();
        const { session } = state;

        // Função auxiliar: verifica se um usuário deve ser carregado
        const shouldLoadUser = (data) =>
            data.db === "users" &&
            data.idRel === session._id &&
            data.comment &&
            data.comment.type === "comment" &&
            data.comment.user &&
            data.comment.user._id &&
            !state.db.users[data.comment.user._id];

        // Função auxiliar: verifica se deve reproduzir áudio de notificação
        const shouldPlayNotificationAudio = (data) =>
            state.chat.notifications &&
            (data.comment.idRel === session._id ||
                state.db.users[data.comment.idRel] ||
                (state.db.cards[data.comment.idRel] &&
                    state.db.cards[data.comment.idRel].type === "chatGroup" &&
                    state.db.cards[data.comment.idRel]._users[session._id])) &&
            state.db.users[data.comment.user._id] &&
            data.comment.type === "comment" &&
            data.comment.user._id !== session._id;

        // Gerenciar eventos de comentários na timeline
        const handleTimelineComment = async (data) => {
            if (
                data.db === "cards" &&
                data.comment.message.includes(session._id) &&
                data.comment.user._id !== session._id
            ) {
                audioTask.play();
            }

            if (shouldLoadUser(data)) {
                loadUsers(props, data.comment.user._id);
            }

            if (
                data.db === "users" ||
                (data.db !== "users" &&
                    data.comment.type === "comment" &&
                    state.db.cards[data.idRel]?.type === "chatGroup")
            ) {
                const lastDate =
                    data.filesCount &&
                        data.files &&
                        data.files.length === data.filesCount
                        ? parseInt(data.comment.created_at) - 30
                        : null;

                if (data.comment.user._id !== session._id) {
                    verifyMessages(lastDate);
                }
            }

            timelineComment(props, data);

            if (shouldPlayNotificationAudio(data)) {
                audio.play();

                const notification = new Notification(
                    `${data.comment.user.name} ${translate("$__sendedANewMessage", 1)}`,
                    {
                        body: data.comment.message || translate(`$__${data.comment.type}`, 1),
                    }
                );

                notification.onclick = (e) => {
                    e.preventDefault();
                    window.focus();
                    notification.close();
                };

                appAlert({
                    message: translate("$__newMessage", 1),
                    content: (
                        <div
                            style={{ display: "flex", cursor: "pointer" }}
                            onClick={(e) => {
                                openChat(data.comment.user._id, "users");
                                e.stopPropagation();
                            }}
                        >
                            <Message storie={data.comment} noReactions inverted />
                        </div>
                    ),
                    hideiconvariant: true,
                    variant: "info",
                    persist: false,
                    horizontal: "right",
                });
            }
        };

        // Gerenciar eventos de digitação na timeline

        const handleTimelineTyping = (data) => {
            if (timelineTyping.current[data.typingUser]) {
                clearTimeout(timelineTyping.current[data.typingUser]);
            }

            timelineTyping.current[data.typingUser] = setTimeout(() => {
                // Reset typing indicator after timeout
            }, 3000);
        };

        // Gerenciar eventos de exclusão na timeline
        const handleTimelineDeletion = (data) => {
            const idRel =
                state.timeline[data.idRel]?.[data.id] ? data.idRel : session._id;

            if (state.timeline[idRel]?.[data.id]) {
                props.reduxFunction("ASYNC", "SET_TIMELINE", {
                    ...state.timeline,
                    [idRel]: {
                        ...state.timeline[idRel],
                        [data.id]: {
                            ...state.timeline[idRel][data.id],
                            deleted: true,
                            ...(data.deletedReason && { deletedReason: data.deletedReason }),
                        },
                    },
                });
            }
        };

        // Inicializar listeners do socket
        const initializeSocketListeners = () => {
            socket.on("timeline.COMMENT", handleTimelineComment);
            socket.on("timeline.TYPING", handleTimelineTyping);
            socket.on("timeline.DEL", handleTimelineDeletion);

            socket.on("stream", (v) => {
                const streamVideo = document.getElementById("#streamVideo");
                if (streamVideo) {
                    streamVideo.attr("src", v);
                }
            });
        };

        // Verificar se o socket está conectado e inicializar
        if (!socketOn.current && socket?.connected) {
            socketOn.current = true;
            initializeSocketListeners();
        }

        // Cleanup dos listeners ao desmontar o componente
        return () => {
            if (socket && socket.connected) {
                socket.off("timeline.COMMENT", handleTimelineComment);
                socket.off("timeline.TYPING", handleTimelineTyping);
                socket.off("timeline.DEL", handleTimelineDeletion);
            }
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket]);

    const verifyMessages = (lastDate = null) => {
        loadTimeline(
            {
                ...props,
                fnIdentification: "Layouts/Index/loadData()-Users"
            },
            {
                ids: [reduxStore.getState().session._id],
                db: "users",
                type: "comment",
                newUpdates: true,
                lastDate
            }
        )
    }

    const openChat = async (nodeId, dbProps) => {
        props.reduxFunction("ASYNC", "SET_CHAT", {
            ...reduxStore.getState().chat,
            open: true,
            db: dbProps,
            nodeId: nodeId,
            loading: false,
        })
    }

    if (!chatOpen)
        return (<React.Fragment></React.Fragment>)

    const closeChat = (force = false) => {
        const state = reduxStore.getState();
        const isLightBoxOpen = state.lightBox?.open;
        const isSideModuleActive = state.sideModule?.id;
        const areToastsPresent = document.getElementsByClassName('Toastify__toast').length > 0;
        const areForwardMessagesPresent = document.getElementsByClassName('forwardMessage').length > 0;

        // Função auxiliar para verificar se o chat pode ser fechado
        const canCloseChat = () => !isLightBoxOpen && !isSideModuleActive;

        // Condição para fechar o chat
        if (force || canCloseChat()) {
            if (!areToastsPresent && !areForwardMessagesPresent) {
                props.reduxFunction("ASYNC", "SET_CHAT", {
                    ...state.chat,
                    open: false,
                });
            }
        }
    };


    if (!chatOpen)
        return (<React.Fragment></React.Fragment>)

    const chatDb = nodeId && reduxStore.getState().db.cards && reduxStore.getState().db.cards[nodeId] ? 'cards' : 'users'
    let chatData = reduxStore.getState().db[chatDb][nodeId] ? reduxStore.getState().db[chatDb][nodeId] : null


    const Sidebar = ({ expanded, setExpanded, setOnLine, onLine }) => (
        <div
            style={{
                position: "absolute",
                top: 40,
                left: 0,
                bottom: 0,
                backgroundColor: "rgba(246,246,246,1)",
                borderRight: `solid 4px ${customApp("color")}`,
                padding: "7px",
                width: expanded ? "311px" : "60px",
            }}
        >
            <Menu
                closeButton={() => setOnLine(false)}
                chat
                subMenuExpanded={(a) => {
                    setExpanded(a);
                    setOnLine(false);
                }}
                expanded={expanded}
                onLine={onLine}
            />
        </div>
    );

    const TimelineContainer = ({ expanded, showFiles, nodeId }) => (
        <div
            style={{
                position: "absolute",
                left: expanded ? 312 : 55,
                top: 40,
                right: showFiles && nodeId ? 333 : 0,
                bottom: 0,
                backgroundColor: "white",
            }}
        >
            {nodeId ? (
                <Timeline
                    types={["comment"]}
                    nodeId={nodeId}
                    db={reduxStore.getState().db.cards[nodeId] ? "cards" : "users"}
                    confirmRead={true}
                    hideCards
                    chat
                />
            ) : (
                <div>{translate("$__chats", 1)}</div>
            )}
        </div>
    );

    const FileSection = ({ nodeId }) => (
        <div
            style={{
                position: "absolute",
                top: 40,
                right: 0,
                bottom: 0,
                width: "333px",
                backgroundColor: "#f1f3f4",
                zIndex: 100,
                boxShadow: "-4px 0px 7px 3px rgba(0,0,0,0.1)",
            }}
        >
            <Files nodeId={nodeId} dark={false} />
        </div>
    );

    const ChatHeader = ({
        expanded,
        setExpanded,
        setOnLine,
        nodeId,
        chatData,
        setShowFiles,
        closeChat,
    }) => (
        <div style={HEADER_STYLE}>
            {/* Menu Button */}
            <div style={{ position: "absolute", left: 13 }}>
                <CustomButton
                    id="chat_menu"
                    title={translate("$__menu", "*")}
                    color={customApp("colorText")}
                    icon="menu"
                    onClick={() => {
                        setExpanded((a) => !a);
                        if (expanded) setOnLine(false);
                    }}
                    size="25px"
                    transparent
                />
            </div>

            {/* Add Group Button */}
            {expanded && (
                <div style={{ position: "absolute", left: 45, color: customApp("colorText") }}>
                    <CustomButton
                        id="addGroup"
                        title={translate("$__createChatGroup", "*")}
                        icon="add_circle_outline"
                        onClick={() => {
                            closeChat(true);
                            props.reduxFunction("ASYNC", "SET_MODULE", {
                                ...reduxStore.getState().sideModule,
                                id: "new",
                                activeModule: "cardEdit",
                                db: "cards",
                                data: { type: "chatGroup", status: "inProgress" },
                            });
                        }}
                        size="25px"
                        transparent
                        color={customApp("colorText")}
                    />
                </div>
            )}

            {/* View Online Button */}
            {expanded && (
                <div style={{ position: "absolute", left: 80 }}>
                    <ButtonViewOnline onClick={(e) => setOnLine(e)} />
                </div>
            )}

            {/* User Info */}
            <div
                style={{
                    position: "absolute",
                    left: !expanded ? 53 : 320,
                    right: 80,
                    color: customApp("colorText"),
                    fontSize: "12px",
                    fontWeight: "bold",
                }}
            >
                {nodeId && chatData ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <UserBadge userId={nodeId}>
                            <Avatar
                                alt={chatData.displayName || chatData.name}
                                src={chatData.image || null}
                                style={{ width: "30px", height: "30px" }}
                            />
                        </UserBadge>
                        <div style={{ fontSize: "14px" }}>
                            {chatData.name}{" "}
                            <span style={{ marginRight: 10, fontSize: 10, fontWeight: "normal" }}>
                                {reduxStore.getState().usersStatus?.users?.[nodeId]?.status === "away"
                                    ? `(${translate("$__lastSeeTime")}: ${getDate(
                                        reduxStore.getState().usersStatus.users[nodeId].updatedAt
                                    )})`
                                    : ""}
                            </span>
                        </div>
                        <Typing nodeId={nodeId} />
                    </div>
                ) : (
                    translate("$__chats", 1)
                )}
            </div>

            {/* Action Buttons */}
            <div style={{ position: "absolute", right: 5, display: "flex" }}>
                {/* File Button */}
                {nodeId && (
                    <CustomButton
                        id="chat_files"
                        title={translate("$__files", "*")}
                        color={customApp("colorText")}
                        icon="folder_special"
                        onClick={() => setShowFiles((prev) => !prev)}
                        size="25px"
                        transparent
                    />
                )}

                {/* Notifications Toggle */}
                <CustomButton
                    id="chat_settings"
                    title={translate(
                        reduxStore.getState().chat.notifications
                            ? "$__chatNotificationsActive"
                            : "$__chatNotificationsOff",
                        1
                    )}
                    color={customApp("colorText")}
                    icon={
                        reduxStore.getState().chat.notifications
                            ? "notifications_active"
                            : "notifications_off"
                    }
                    onClick={() => {
                        props.reduxFunction("ASYNC", "SET_CHAT", {
                            ...reduxStore.getState().chat,
                            notifications: !reduxStore.getState().chat.notifications,
                        });
                    }}
                    size="25px"
                    transparent
                />
                {reduxStore.getState().chat.nodeId && reduxStore.getState().db.cards[reduxStore.getState().chat.nodeId] ?
                    <CustomButton
                        id="chat_settings"
                        title={translate("$__settings", 1)}
                        color={customApp("colorText")}
                        icon={"settings"}
                        onClick={() => {
                            // return
                            props.reduxFunction("ASYNC", "SET_MODULE", {
                                ...reduxStore.getState().sideModule,
                                db: "cards",
                                id: reduxStore.getState().chat.nodeId,
                                module: "cardEdit",
                                activeModule: "cardEdit",
                                data: reduxStore.getState().db.cards[reduxStore.getState().chat.nodeId],
                            })
                        }}
                        size="25px"
                        transparent
                    /> : <></>}

                {/* Close Button */}
                <CustomButton
                    id="chat_close"
                    title={translate("$__close", "*")}
                    color={customApp("colorText")}
                    icon="close"
                    onClick={() => {
                        closeChat(true);
                        setOnLine(false);
                    }}
                    size="25px"
                    transparent
                />
            </div>
        </div>
    );

    return (
        <div style={CHAT_STYLE}>
            <ClickAwayListener onClickAway={closeChat}>
                <div style={CONTAINER_STYLE(window.innerWidth > 600)}>
                    {/* Header */}
                    <ChatHeader
                        expanded={expanded}
                        setExpanded={setExpanded}
                        setOnLine={setOnLine}
                        nodeId={nodeId}
                        chatData={chatData}
                        showFiles={showFiles}
                        setShowFiles={setShowFiles}
                        closeChat={closeChat}
                    />

                    {/* Sidebar */}
                    <Sidebar expanded={expanded} setExpanded={setExpanded} setOnLine={setOnLine} onLine={onLine} />

                    {/* Timeline */}
                    <TimelineContainer expanded={expanded} showFiles={showFiles} nodeId={nodeId} />

                    {/* File Section */}
                    {showFiles && nodeId && <FileSection nodeId={nodeId} />}
                </div>
            </ClickAwayListener>
        </div>
    );

}

const mapStateToProps = ({ chat }) => ({
    nodeId: chat.nodeId,
    chatOpen: chat.open,
    notifications: chat.notifications
})
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ChatIndex))