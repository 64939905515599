//CORE
import React from 'react';

//REDUX
import * as reduxActions from "store/actions";
import reduxStore from "store";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

//COMPONENTS
import Card from "components/Card";
import Icon from "components/Icon";


//@MATERIAL
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';

//FUNCTIONS
import { translate, customApp } from 'functions/';

function Favorites(props) {
    const { session, db } = reduxStore.getState()
    const { cards, users } = db
    const [open, setOpen] = React.useState(false);
    const [FavoriteCards, setFavoriteCards] = React.useState([])
    const anchorRef = React.useRef(null)

    React.useEffect(() => {
        iniFavorites()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cards, users])

    const iniFavorites = async () => {
        let newFavoriteCards = Object.keys(cards)
            .filter(fil => {
                let myGroups = []
                if (db.users[session._id] && db.users[session._id].groups && db.users[session._id].groups.length > 0)
                    db.users[session._id].groups.forEach(a => {
                        if (myGroups.indexOf(a.value) === -1)
                            myGroups.push(a.value)
                    })
                if (
                    cards[fil]
                    && cards[fil]._isFavorite
                    && !cards[fil].deleted
                    && !cards[fil].archived
                    && cards[fil].type !== "plan"
                    && cards[fil]._users
                    && (
                        cards[fil]._users[session._id]
                        || (
                            cards[fil]._groups
                            && Object.keys(cards[fil]._groups).filter(a => myGroups.indexOf(a) > -1).length > 0
                        )
                    )
                    && cards[fil].status !== "completed"
                    && (
                        !props.hidePrivate
                        || (
                            props.hidePrivate
                            && !cards[fil].private
                        )
                    )
                ) return true
                return false
            }
            )
        setFavoriteCards(newFavoriteCards)
    }



    if (FavoriteCards.length === 0)
        return (<React.Fragment></React.Fragment>)


    let favoriteCardsContent = FavoriteCards.map(c => {
        let a = cards[c]
        return (
            <div style={{ width: "calc(100%)", display: "flex", alignItems: "center", justifyContent: "center" }} key={a._id}>
                <Card db="cards" data={a} noShadow />
            </div>
        )
    })

    if (!props.button)
        return (<>{favoriteCardsContent}</>)

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <div id="notificationsBt"
            style={{
                zIndex: '100 !important'
            }}>
            <Button
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <Badge badgeContent={FavoriteCards.length} color="secondary" overlap="rectangular">
                    <Icon
                        icon={'favorite'}
                        color={'parent'}
                        title="$__favorites"
                    />
                </Badge>
            </Button>
            {FavoriteCards.length > 0 &&
                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                    style={{ width: '400px' }}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <div style={{
                                        height: 'auto',
                                        maxHeight: 'calc(90vh - 80px)',
                                        width: '100%',
                                        overflowY: 'auto'
                                    }}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                padding: '7px',
                                                fontSize: 15,
                                                color: customApp("menu"),
                                                fontWeight: "bold",
                                                paddingLeft: 10
                                            }}
                                        >
                                            {translate('$__favorites')}
                                        </div>
                                        {favoriteCardsContent}
                                    </div>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            }
        </div >
    )
}

const mapStateToProps = (store) => ({
    store
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Favorites)
