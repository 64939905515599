import React from "react";

//@MATERIAL
import { makeStyles } from "@material-ui/core/styles";

//REDUX
import history from "store/history";

//COMPONENTS
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter";
import Password from "components/Password";

// styles
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle";

//FUNCTIONS
import api from "api/"
import apims from "apims/"
import { translate } from "functions/";

const useStyles = makeStyles(styles);

export default function LoginPage(props) {
  const query = new URLSearchParams(window.location.search);
  const [user, setUser] = React.useState({ cPassword: null, password: null });
  const classes = useStyles();

  const tokenValidation = async () => {
    const res = await apims.post("/PasswordTokenValidation", {
      token: query.get('token')
    })
    if (!res.data.result)
      history.push('/')
  }

  React.useEffect(() => {
    tokenValidation()
    // eslint-disable-next-line
  }, [])



  if (!query.get('token') || query.get('token').length !== 36) {
    history.push('/')
  }


  const savePassword = async () => {
    try {
      const res = await api.post('/user/new-password', {
        token: query.get('token'),
        password: user.password,
      })
      if (res) {
        localStorage.setItem('TOKEN', res.data.token)
        if (props.callback) {
          setTimeout(() => {
            history.push('/login')
            // props.reduxFunction("ASYNC", "SET_DB", {
            //   ...reduxStore.getState().db,
            //   history: 'login'
            // });
          }, 3000)
        }
      }
    } catch (err) {
      props.callback({
        fn: 'alert',
        message: `${translate('$__invalidRequest', 1)} | ${translate('$__contactSupport', 1)}`,
        variant: 'warning',
        persist: false,
        horizontal: 'center',
        confirm: null
      })
    }
  }

  return (
    <div className={classes.container}>
      <GridContainer style={{ justifyContent: "center", overflow: "auto" }}>
        <GridItem xl={4} lg={4} md={4} sm={12} xs={12}>
          <form>
            <Card db="cards" login>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="primary"
                style={{
                  background: "linear-gradient(60deg, rgba(15,18,35,1), rgba(2,33,88,1))",
                  boxShadow:
                    "0 4px 20px 0 rgba(15,18,35,0.14), 0 7px 10px -5px rgba(15,18,35,0.4)"
                }}
              >
                <h4 className={classes.cardTitle}>{translate('$__createPassword', 1)}</h4>
              </CardHeader>
              <CardBody>
                <Password
                  onConfirmed={(v) => {
                    setUser({
                      ...user,
                      password: v
                    })
                  }} />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                {user && user.password &&
                  <Button style={{ color: 'rgba(15,18,35,1)' }} simple size="lg" block onClick={() => savePassword()}>
                    {translate('$__save', '*')}
                  </Button>
                }
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
