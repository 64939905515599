import React, { useEffect } from 'react'

//REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as reduxActions from 'store/actions'
import reduxStore from "store/"

//COMPONENTS
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import UiCard from "components/Card/Card"
import UiCardBody from "components/Card/CardBody"
import HeaderWithIcon from "components/Header/withIcon"
import Icon from "components/Icon"

//FUNCTIONS
import { translate, appAlert, nl2br } from "functions"
import { saveCard } from "functions/cards"

const Notes = (props) => {
    const [planNote, setPlanNote] = React.useState({ editor: false, text: '', height: 'auto' })
    const [planNoteSave, setPlanNoteSave] = React.useState(false)
    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace('#', '')
    const { db } = reduxStore.getState()

    const saveNote = async (clean) => {
        if (planNote.text || clean) {
            const request = await saveCard(props, false, {
                ...db.cards[hashs[0]],
                note: clean ? null : planNote.text
            });
            if (request) {
                appAlert({
                    message: translate('$__noteSaved', 1),
                    variant: null,
                    persist: false,
                    horizontal: 'right',
                    confirm: null
                })
            }
            setPlanNote(
                { editor: false, text: clean ? '' : planNote.text, height: 'auto' }
            )
        }
        setPlanNoteSave(false)
    }

    useEffect(() => {
        setPlanNote(
            {
                editor: false,
                text: reduxStore.getState().db.cards[hashs[0]]?.note ? reduxStore.getState().db.cards[hashs[0]].note : "", height: 'auto'
            }
        )
        // eslint-disable-next-line
    }, []);


    return (
        <div style={{ width: "100%", height: '100%' }}>
            <UiCard style={{ marginTop: '0', height: '100%' }}>
                {props.edit ? <div style={{ width: '100%', height: '48px', minHeight: "48px", display: 'flex', borderBottom: '1px solid #D3D3D3', backgroundColor: props.styles.backgroundColor ? props.styles.backgroundColor : 'white' }}>
                    <Icon size={24} style={{ alignSelf: 'center', marginLeft: '19.5px', color: props.styles.color ? props.styles.color : 'grey', marginTop: '2px' }}>note</Icon>
                    <p style={{ margin: '0px', marginLeft: '7px', alignSelf: 'center', fontWeight: 'bold', marginTop: '1px', color: props.styles.color ? props.styles.color : 'grey', fontSize: '16px' }}>{translate(`$__notes`, 1)}</p>
                    <div onMouseEnter={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0.12)' }}
                        onMouseLeave={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0)' }}>
                        <Icon onClick={() => props.handleRemoveObject(props.id)} style={{ height: '25px', color: props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)', cursor: 'pointer', position: 'absolute', right: '20px', top: '8px', border: '1px solid  #D3D3D3', padding: '14px' }}>
                            close
                        </Icon>
                    </div>
                    <div onMouseEnter={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0.12)' }}
                        onMouseLeave={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0)' }}>
                        <Icon className='drag-handle' style={{ height: '25px', cursor: 'move', color: props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)', position: 'absolute', right: '62px', top: '8px', border: '1px solid  #D3D3D3', padding: '14px' }}>
                            open_with
                        </Icon>
                    </div>
                </div> : <HeaderWithIcon
                    title={translate(`$__notes`, 1)}
                    icon={'note'}
                    color={props.styles.color ? props.styles.color : 'grey'}
                    style={{
                        background: props.styles.backgroundColor ? props.styles.backgroundColor : 'white',
                        padding: '12.4px',
                        height: "58px",
                        minHeight: "48px",
                        borderRadius: '5px 5px 0px 0px ',
                        fontWeight: 'normal',
                        borderBottom: '1px solid #D3D3D3'
                    }}
                    customButtons={[
                        ...planNote.text && !planNote.editor ? [
                            {
                                title: "$__cleanNote",
                                icon: "clear_all",
                                onClick: () => {
                                    saveNote(true)
                                },
                                color: props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)',
                            },
                            {
                                title: "$__edit",
                                icon: "edit",
                                onClick: () => {
                                    setPlanNote({
                                        ...planNote,
                                        editor: true,
                                    })
                                },
                                color: props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)',
                            }] : [],
                        planNoteSave &&
                        {
                            title: "$__saveNote",
                            icon: "save",
                            onClick: () => {
                                saveNote()
                            },
                            color: props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)',
                        },
                        planNote.editor &&
                        {
                            title: "$__cancel",
                            icon: "cancel",
                            onClick: () => {
                                setPlanNote({
                                    ...planNote,
                                    text: db.cards[hashs[0]].note,
                                    editor: false
                                })
                                setPlanNoteSave(false)
                            },
                            color: props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)',
                        },
                    ]}
                />}
                <UiCardBody style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
                    {(!planNote.text && !planNote.editor) ?
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            userSelect: 'none',
                            cursor: 'pointer',
                            height: '100%'
                        }}
                            onClick={
                                () => {
                                    setPlanNote({
                                        ...planNote,
                                        editor: true
                                    })
                                }
                            }
                        >
                            <div style={{ padding: '7px', border: 'dotted 1px rgba(0,0,0,0.2)', borderRadius: '7px' }}>
                                <Icon size={'33px'} color={"rgba(0,0,0,0.2)"}>add</Icon>
                            </div>
                            <div style={{ color: 'rgba(0,0,0,0.2)', fontSize: '22px', fontWeight: 'bold', marginLeft: '15px' }}>
                                {translate('$__addNote')}
                            </div>
                        </div>
                        : planNote.editor ?
                            <TextareaAutosize
                                style={{
                                    borderRadius: '7px',
                                    border: "0px",
                                    padding: '7px',
                                    fontFamily: '"Soleto", "Roboto", "Helvetica", "Arial", sans-serif',
                                    fontSize: '16px',
                                    width: "100%",
                                    overflow: "scroll",
                                    height: "100%",
                                    minWidth: 'calc(100% - 0px)',
                                    maxWidth: 'calc(100% - 0px)',
                                }}
                                placeholder={translate('$__planNotePlaceholder')}
                                onChange={(e) => {
                                    setPlanNoteSave(true)
                                    setPlanNote({
                                        ...planNote,
                                        text: e.target.value
                                    })
                                }}
                                onBlur={(e) => {
                                    if (e.target.value === '' || e.target.value === db.cards[hashs[0]].note) {
                                        setPlanNoteSave(false)
                                        setPlanNote({
                                            ...planNote,
                                            editor: false
                                        })
                                    }
                                }}
                                autoFocus
                                spellcheck={false}
                            >
                                {planNote.text}
                            </TextareaAutosize>
                            :
                            <div
                                style={{
                                    fontSize: '16px',
                                    width: '100%',
                                    height: "100%",
                                    overflow: "scroll",
                                    maxHeight: "90%"
                                }}
                                onClick={() => {
                                    setPlanNote({
                                        ...planNote,
                                        editor: true
                                    })
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: nl2br(planNote.text)
                                }}
                            >
                            </div>
                    }
                </UiCardBody>
            </UiCard>
        </div>
    )
}


const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default connect(null,
    mapDispatchToProps
)(Notes);
