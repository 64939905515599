import React, { useMemo, useState } from "react"
import moment from "moment-timezone"

// REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"
import history from "store/history"

// COMPONENTS
import CardTags from "components/Tags/Card"
import Icon from "components/Icon"
import UserBadge from "components/Badge/user"
import Card from "components/Card"

// @MATERIAL
import Tooltip from "@material-ui/core/Tooltip"
import Avatar from "@material-ui/core/Avatar"
import AvatarGroup from "@material-ui/lab/AvatarGroup"
import { withStyles } from "@material-ui/core/styles"
import styles from "assets/jss/material-dashboard-pro-react/components/sideModule.js"

// FUNCTIONS
import { translate, customApp, getDate } from "functions/"

function Component(props) {
    const { data } = props
    const { db, session } = reduxStore.getState()
    const { cards, dataType, status: dbStatus } = db
    const [imagePreview, setImagePreview] = useState(null)

    // Extrai as partes do hash (usando useMemo para evitar recomputação desnecessária)
    const hashParts = useMemo(() => {
        return window.location.hash.replace("#", "").split("/")
    }, [])

    // Cálculo de indicadores e status com base nos cards relacionados a data._id  
    const { computedStatus } = useMemo(() => {
        const indicators = {}
        const status = {}
        Object.keys(cards).forEach(key => {
            const card = cards[key]
            if (
                !card ||
                ["chatGroup", "link", "insight"].includes(card.type) ||
                !card._users ||
                Object.keys(card._users).length === 0 ||
                !card._users[data._id]
            ) {
                return
            }
            // Busca o item de dataType para o tipo do card
            const dtItem = dataType.find(dt => dt.name === card.type)
            if (dtItem) {
                if (card.status && card.status !== "completed") {
                    status[card.status] = (status[card.status] || 0) + 1
                    status.total = (status.total || 0) + 1
                    if (card.endDate) {
                        const cardEnd = moment(new Date(card.endDate * 1000))
                            .tz("America/Sao_Paulo")
                        const now = moment().tz("America/Sao_Paulo")
                        if (cardEnd.isSameOrBefore(now)) {
                            status.late = (status.late || 0) + 1
                        }
                    }
                } else {
                    status.completed = (status.completed || 0) + 1
                    status.total = (status.total || 0) + 1
                }
                indicators[card.type] = (indicators[card.type] || 0) + 1
            } else {
                indicators.swot = (indicators.swot || 0) + 1
            }
        })
        return { computedStatus: status, computedIndicators: indicators }
    }, [cards, data._id, dataType])

    // Função auxiliar para renderizar a imagem/Avatar
    const renderImage = () => {
        const borderStyle = `solid 2px ${customApp("color")}`
        if (data.image) {
            if (!imagePreview) {
                return (
                    <img
                        alt="MACHEN_IMAGE"
                        style={{
                            width: "70px",
                            height: "70px",
                            top: "-20px",
                            left: "-6px",
                            borderRadius: "50%",
                            border: borderStyle,
                            position: "absolute",
                            backgroundColor: customApp("color"),
                        }}
                        src={data.image}
                        onError={() => setImagePreview(null)}
                        onLoad={() => setImagePreview(data.image)}
                        width="100%"
                    />
                )
            } else {
                return (
                    <div
                        style={{
                            width: "50px",
                            height: "50px",
                            top: "-4px",
                            left: "-13px",
                            borderRadius: "50%",
                            border: borderStyle,
                            position: "absolute",
                            background: customApp("AvatarBackground"),
                            backgroundImage: `url("${imagePreview}")`,
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                        }}
                    />
                )
            }
        } else {
            return (
                <div
                    style={{
                        width: "50px",
                        height: "50px",
                        top: "-4px",
                        left: "-13px",
                        borderRadius: "50%",
                        border: borderStyle,
                        position: "absolute",
                        background: customApp("AvatarBackground"),
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {(!data.type || data.type === "user") ? (
                        <Icon size="16px" color="rgba(250,250,250,0.77)">
                            person
                        </Icon>
                    ) : (
                        <Icon size="16px" color="rgba(250,250,250,0.77)">
                            business
                        </Icon>
                    )}
                </div>
            )
        }
    }

    // Renderização em modo "expanded"
    if (props.expanded) {
        return (
            <div
                style={{
                    boxShadow: "0 0 3px 3px rgba(0,0,0,0.1)",
                    position: "relative",
                    marginLeft: "15px",
                    ...(props.onClick ? { cursor: "pointer" } : {}),
                }}
                onClick={props.onClick ? () => props.onClick() : null}
            >
                <div
                    style={{
                        backgroundColor: customApp("menu"),
                        height: "43px",
                        marginTop: "5px",
                        paddingLeft: "60px",
                        fontSize: "12px",
                        color: "#FFFFFF",
                        fontWeight: "bold",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        left: "15px",
                    }}
                >
                    {data.name}
                    {!props.resume && (
                        <div
                            style={{
                                float: "right",
                                display: "flex",
                                alignItems: "center",
                                marginRight: "7px",
                            }}
                        >
                            {!props.noActions && (
                                <div
                                    style={{
                                        float: "right",
                                        display: "flex",
                                        alignItems: "center",
                                        marginRight: "7px",
                                    }}
                                >
                                    <Icon
                                        color={customApp("color")}
                                        icon={"close"}
                                        onClick={() => {
                                            history.push(`c#tab/${hashParts[1]}`)
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
                {renderImage()}
            </div>
        )
    }

    // Renderização padrão (não expanded)
    return (
        <div
            style={{
                marginTop: "33px",
                width: props.style && props.style.width ? props.style.width : "calc(100% - 15px)",
                minHeight: "120px",
                backgroundColor: "#FFFFFF",
                boxShadow: "0 0 3px 3px rgba(0,0,0,0.1)",
                position: "relative",
                marginLeft: props.style && props.style.marginLeft ? props.style.marginLeft : "15px",
            }}
        >
            <div
                style={{
                    backgroundColor: customApp("menu"),
                    height: "43px",
                    paddingLeft: "95px",
                    fontSize: "12px",
                    color: "#FFFFFF",
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <div
                    style={{
                        maxWidth: "calc(100% - 10px)",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                    }}
                >
                    {data.name}
                </div>
                {!props.resume && (
                    <div
                        style={{
                            float: "right",
                            display: "flex",
                            alignItems: "center",
                            marginRight: "7px",
                        }}
                    >
                        {(!props.noActions &&
                            (!data.independentAccount ||
                                (data.independentAccount && session && session.matrixAdmin)) &&
                            (!data._parent ||
                                (data._parent &&
                                    reduxStore.getState().db.users[data._parent] &&
                                    !reduxStore.getState().db.users[data._parent].independentAccount))) && (
                                <div
                                    style={{
                                        float: "right",
                                        display: "flex",
                                        alignItems: "center",
                                        marginRight: "7px",
                                    }}
                                >
                                    <Icon
                                        color={customApp("color")}
                                        icon={"create"}
                                        onClick={() => {
                                            props.reduxFunction("ASYNC", "SET_MODULE", {
                                                ...db.sideModule,
                                                id: data._id,
                                                activeModule: "userEdit",
                                                db: "users",
                                                data: { type: "user", ...data },
                                            })
                                        }}
                                    />
                                </div>
                            )}
                        {!props.noActions && data.type !== "user" && (
                            <div
                                style={{
                                    float: "right",
                                    display: "flex",
                                    alignItems: "center",
                                    marginRight: "7px",
                                }}
                            >
                                <Icon
                                    color={customApp("color")}
                                    icon={"contacts"}
                                    onClick={() => {
                                        history.push(`c#tab/units/${data._id}`)
                                    }}
                                />
                            </div>
                        )}
                        {!props.noActions && data.type === "sic" && (
                            <div
                                style={{
                                    float: "right",
                                    display: "flex",
                                    alignItems: "center",
                                    marginRight: "7px",
                                }}
                            >
                                <Icon
                                    color={customApp("color")}
                                    icon={"badge"}
                                    onClick={() => {
                                        history.push(`c#tab/units/${data._id}`)
                                    }}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
            {(data.image && (!imagePreview || (data.image && data.image !== imagePreview))) ? (
                <img
                    alt="MACHEN_IMAGE"
                    style={{
                        width: "90px",
                        height: "90px",
                        top: "-24px",
                        left: "-6px",
                        borderRadius: "50%",
                        border: `solid 2px ${customApp("color")}`,
                        position: "absolute",
                        backgroundColor: customApp("color"),
                    }}
                    src={data.image}
                    onError={() => { }}
                    onLoad={() => setImagePreview(data.image)}
                    width="100%"
                />
            ) : data.image ? (
                <div
                    style={{
                        width: "90px",
                        height: "90px",
                        top: "-24px",
                        left: "-6px",
                        borderRadius: "50%",
                        border: `solid 2px ${customApp("color")}`,
                        position: "absolute",
                        background: customApp("AvatarBackground"),
                        backgroundImage: `url("${imagePreview}")`,
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                    }}
                />
            ) : (
                <div
                    style={{
                        width: "90px",
                        height: "90px",
                        top: "-24px",
                        left: "-6px",
                        borderRadius: "50%",
                        border: `solid 2px ${customApp("color")}`,
                        position: "absolute",
                        background: customApp("AvatarBackground"),
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {(!data.type || data.type === "user") ? (
                        <Icon size="33px" color="rgba(250,250,250,0.77)">
                            person
                        </Icon>
                    ) : (
                        <Icon size="33px" color="rgba(250,250,250,0.77)">
                            business
                        </Icon>
                    )}
                </div>
            )}
            {props.children ? (
                props.children
            ) : (
                <>
                    {data?._access?.enabled && data.type === "user" ? (
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                fontSize: 12,
                                color: customApp("medium"),
                                padding: "3px 7px",
                            }}
                        >
                            {data?._access?.admin ? (
                                <>
                                    <Icon color={customApp("medium")} size={"12px"}>
                                        supervisor_account
                                    </Icon>
                                    <span>{translate("$__supervisorAccount")}</span>
                                </>
                            ) : (
                                <>
                                    <Icon color={customApp("medium")} size={"12px"}>
                                        supervised_user_circle
                                    </Icon>
                                    <span>{translate("$__guest")}</span>
                                </>
                            )}
                        </div>
                    ) : data.type === "user" && data._access && !data._access.enabled ? (
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                color: "red",
                                fontSize: 10,
                            }}
                        >
                            {translate("$__notEnabled")}
                        </div>
                    ) : null}
                    {data.groups && data.groups.length > 0 ? (
                        <div
                            style={{
                                position: "relative",
                                width: "100%",
                                fontSize: "12px",
                                color: customApp("mediumText"),
                                paddingLeft: "94px",
                                paddingRight: "15px",
                                marginTop: "15px",
                            }}
                        >
                            {data.groups
                                .filter(a => a.value)
                                .sort((a, b) => (a.label < b.label ? -1 : 1))
                                .map((item, i) => `${i > 0 ? " / " : ""}${item.label}`)}
                        </div>
                    ) : data.type === "user" ? (
                        <div
                            style={{
                                position: "relative",
                                width: "100%",
                                fontSize: "12px",
                                color: customApp("mediumText"),
                                paddingLeft: "94px",
                                marginTop: "15px",
                            }}
                        >
                            {translate(`$__doesntBelongToAGroup`)}
                        </div>
                    ) : null}
                    {data._lastAccess ? (
                        <div
                            style={{
                                position: "relative",
                                width: "100%",
                                fontSize: 10,
                                color: customApp("menu"),
                                marginTop: 15,
                                textAlign: "right",
                                paddingRight: 5,
                            }}
                        >
                            {translate(`$__lastAccess`)}:{getDate(data._lastAccess)}
                        </div>
                    ) : null}
                    {!props.noShowUsers && data.type !== "user" && (
                        <div style={{ marginTop: "11px", width: "100%", padding: "15px" }}>
                            <ClientInfo id={data._id} {...props} />
                        </div>
                    )}
                    {data.type === "user" && computedStatus && computedStatus.total > 0 && (
                        <div
                            style={{
                                display: "flex",
                                width: "100%",
                                paddingLeft: "15px",
                                paddingRight: "15px",
                                flexFlow: "wrap",
                                paddingTop: 15,
                                paddingBottom: 15,
                            }}
                        >
                            <fieldset style={{ padding: 7, width: "calc(100% - 14px)" }}>
                                <legend style={{ color: "lightgray", fontSize: 12 }}>
                                    {translate("$__openActivities")}
                                </legend>
                                <h3>
                                    <small>
                                        {translate("$__total", 1)}: {computedStatus.total}
                                    </small>
                                </h3>
                                {dbStatus
                                    .filter(st => st.value !== "completed")
                                    .map((st, index) => (
                                        <div
                                            key={`${index}_${st.value}`}
                                            style={{
                                                color: "black",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                height: "30px",
                                                alignItems: "center",
                                                width: "100%",
                                            }}
                                        >
                                            <div style={{ display: "flex" }}>
                                                <Icon color={st.color}>{st.icon}</Icon>
                                                <span style={{ marginLeft: "15px" }}>
                                                    {translate(st.label, 1)}
                                                </span>
                                            </div>
                                            <div>{computedStatus[st.value] || 0}</div>
                                        </div>
                                    ))}
                                <div
                                    style={{
                                        color: "black",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        height: "30px",
                                        alignItems: "center",
                                        width: "100%",
                                    }}
                                >
                                    <div style={{ display: "flex" }}>
                                        <Icon color={"red"}>assignment_late</Icon>
                                        <span style={{ marginLeft: "15px" }}>
                                            {translate("$__late", 1)}
                                        </span>
                                    </div>
                                    <div>{computedStatus.late || 0}</div>
                                </div>
                            </fieldset>
                        </div>
                    )}
                    {!props.resume && <CardTags options={data.tags} showOptions={false} />}
                    {props.resume && (
                        <div>
                            <UserBadge
                                overlap="circular"
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                userId={data._id}
                            >
                                <Icon size="27px">chat</Icon>
                            </UserBadge>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

const ClientInfo = (props) => {
    const { cards, users } = reduxStore.getState().db
    const plans = useMemo(() => {
        const cardsArray = Object.keys(cards)
            .map(key => cards[key])
            .filter(
                card =>
                    card &&
                    card.client === props.id &&
                    card.type === "plan" &&
                    !card.deleted &&
                    !card.archived &&
                    !card.isTemplate &&
                    (
                        (!props.completed && card.status !== "completed") ||
                        (props.completed && card.status === "completed")
                    )
            )
        return cardsArray.sort((a, b) => {
            if (a.name < b.name) return -1
            if (a.name > b.name) return 1
            return 0
        })
    }, [cards, props.id, props.completed])

    return (
        <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
            {Object.values(users).filter(u => u && !u.deleted && u._parent === props.id).length > 0 && (
                <>
                    <div style={{ width: "100%" }}>{translate("$__users", 1)}:</div>
                    <AvatarGroup max={12}>
                        {Object.values(users)
                            .filter(u => u && !u.deleted && u._parent === props.id)
                            .map(user => (
                                <Tooltip title={user.name} key={user._id}>
                                    <Avatar alt={user.name} src={user.image || null} style={{ width: "23px", height: "23px" }}>
                                        {user.name ? user.name.substr(0, 1) : ""}
                                    </Avatar>
                                </Tooltip>
                            ))}
                    </AvatarGroup>
                </>
            )}
            {plans.length > 0 && (
                <div style={{ width: "100%" }}>
                    {plans.map((plan, i) => (
                        <div key={`${plan._id}-${i}`}>
                            <Card data={plan} db="cards" Avatar={false} expanded={false} ignoreList notGlobalExpanded />
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

const mapStateToProps = (store) => ({
    usersStatus: store.usersStatus,
    users: store.db.users,
})
const mapDispatchToProps = (dispatch) => bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component))