//CORE
import React, { useCallback, useMemo, useState } from "react";

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import reduxStore from "store/";
import history from "store/history";

//COMPONENTS
import Icon from "components/Icon";
import ChatCountIcon from "components/Chat/countIcon";
import SubMenu from "./subMenu";
import ErrorBoundary from "components/ErrorBoundary";

//@MATERIAL
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

//IMAGES
import Bright from "assets/icons/BrigthAndConnected";

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/sidebarInteractive.js";

//FUNCTIONS
import { translate, customApp } from "functions/";

import packageJson from "./../../../package.json";
require("dotenv").config();

function Component(props) {
    const { classes } = props;
    const [viewModule, setViewModule] = useState(null);

    // Propriedade para itens bloqueados (ex.: ["StrategyAndPlans", "library"])
    const blockedItems = props.blockedMenuItems || [];

    // Memoriza os valores do hash da URL
    const hashs = useMemo(() => {
        const parts = window.location.hash.split("/");
        parts[0] = parts[0].replace("#", "");
        return parts;
    }, []);

    // Cores e estilos comuns
    const menuButtonColor = customApp("menuButtonColor");
    const menuColor = customApp("menuColor");
    const commonIconStyle = { marginBottom: "0px", marginRight: "7px" };

    // Função auxiliar para determinar a classe ativa com base no pathname
    const getItemClass = (activePath) =>
        window.location.pathname === activePath ? classes.itemButtomActive : classes.itemButtom;

    // Encapsula a lógica de navegação e definição de módulos
    const openModule = useCallback(
        async (route, vm) => {
            if (props.subMenuExpanded) {
                props.setSubMenuExpanded(false);
            }
            // Se a rota for diferente da atual ou o módulo for "chat", navega
            if (route && (window.location.pathname !== route || vm === viewModule)) {
                history.push(route);
            }
            if (vm === "chat") {
                props.reduxFunction("ASYNC", "SET_CHAT", {
                    ...reduxStore.getState().chat,
                    open: true,
                });
            } else {
                setViewModule(vm);
                props.reduxFunction("ASYNC", "SET_CHAT", {
                    ...reduxStore.getState().chat,
                    open: false,
                });
            }
        },
        [props, viewModule]
    );

    // Componente auxiliar para renderizar itens do menu
    const renderMenuItem = (menuId, route, vm, icon, label, activePath) => {
        const isBlocked = blockedItems.includes(vm);
        const itemClass = getItemClass(activePath);
        const handleClick = () => {
            if (!isBlocked) {
                openModule(route, vm);
            }
        };

        const content = (
            <div
                className={itemClass}
                onClick={handleClick}
                style={{
                    color: menuButtonColor,
                    cursor: isBlocked ? "default" : "pointer",
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    width: "100%",
                    textDecoration: isBlocked ? 'underlined' : 'none',
                    justifyContent: "space-between",
                    fontSize: "11px",
                }}
            >
                <div style={{
                    whiteSpace: "nowrap",
                    display: "flex"
                }}>
                    <Icon
                        icon={icon}
                        badgeColor="primary"
                        color="parent"
                        badgeVisible
                        size="21px"
                        style={commonIconStyle}
                    />
                    {label}
                </div>
                {isBlocked && (
                    <Icon
                        icon="lock"
                        color="inherit"
                        size="16px"
                        style={{ marginLeft: "4px", float: "right" }}
                    />
                )}
                {isBlocked && (
                    <div style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0,0,0,0.5)"
                    }}>

                    </div>
                )}

            </div>
        );

        return (
            <div className={classes.item} id={`sideBar_button_${menuId}`} style={{ width: "100%" }}>
                {isBlocked ? (
                    <Tooltip title="Para acessar essa opção, faça upgrade do plano">
                        <div style={{ width: "100%" }}>{content}</div>
                    </Tooltip>
                ) : (
                    content
                )}
            </div>
        );
    };

    // Renderiza item do Chat (função especial, pois o comportamento é de toggle)
    const renderChatItem = () => {
        const isBlocked = blockedItems.includes("chat");
        const chatOpen = reduxStore.getState().chat.open;
        const itemClass = chatOpen ? classes.itemButtomActive : classes.itemButtom;
        const handleClick = () => {
            if (!isBlocked) {
                props.reduxFunction("ASYNC", "SET_CHAT", {
                    ...reduxStore.getState().chat,
                    open: !chatOpen,
                });
            }
        };

        const content = (
            <div
                className={itemClass}
                onClick={handleClick}
                style={{
                    color: menuButtonColor,
                    cursor: isBlocked ? "default" : "pointer",
                }}
            >
                <ChatCountIcon>
                    <Icon
                        icon="chat"
                        color="parent"
                        title="$__chat"
                        size="21px"
                        style={commonIconStyle}
                    />
                </ChatCountIcon>
                {translate("$__chat", "*")}
                {isBlocked && (
                    <Icon
                        icon="lock"
                        color="inherit"
                        size="16px"
                        style={{ marginLeft: "4px" }}
                    />
                )}
            </div>
        );

        return (
            <div className={classes.item} id="sideBar_button_chat">
                {isBlocked ? (
                    <Tooltip title="Para acessar essa opção, faça upgrade do plano">
                        <div>{content}</div>
                    </Tooltip>
                ) : (
                    content
                )}
            </div>
        );
    };

    // Renderiza item de Support (abre link externo)
    const renderSupportItem = () => {
        const isBlocked = blockedItems.includes("support");
        const handleClick = () => {
            if (!isBlocked) {
                window.open("https://support.machen.ai", "_blank");
            }
        };

        const content = (
            <div
                className={classes.itemButtom}
                onClick={handleClick}
                style={{
                    color: menuButtonColor,
                    cursor: isBlocked ? "default" : "pointer",
                }}
            >
                <Icon
                    icon="help_outline"
                    badgeColor="primary"
                    color="parent"
                    badgeVisible
                    title={translate("$__support")}
                    size="21px"
                    style={commonIconStyle}
                />
                {translate("$__support", "*")}
                {isBlocked && (
                    <Icon
                        icon="lock"
                        color="inherit"
                        size="16px"
                        style={{ marginLeft: "4px" }}
                    />
                )}
            </div>
        );

        return (
            <div className={classes.item} id="sideBar_button_support">
                {isBlocked ? (
                    <Tooltip title="Para acessar essa opção, faça upgrade do plano">
                        <div>{content}</div>
                    </Tooltip>
                ) : (
                    content
                )}
            </div>
        );
    };

    return (
        <ErrorBoundary info={{ path: "components/Sidebar/Index" }}>
            <div className={classes.root}>
                <div
                    className={classes.bar}
                    id="sideBar"
                    style={{ background: menuColor }}
                >
                    {/* Área do logo */}
                    <div
                        className={classes.logoMini}
                        id="sideBar_logoMini"
                        style={{
                            width: "100%",
                            justifyContent: "space-between",
                            display: "flex",
                            cursor: "pointer",
                            color: menuButtonColor,
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                backgroundImage: `url("${customApp("customLogo")}")`,
                                width: "calc(100% - 70px)",
                                height: "auto",
                                marginLeft: "15px",
                                minHeight: "min-content",
                                cursor: "pointer",
                                paddingTop: "33%",
                                backgroundPosition: "center center",
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                            }}
                            onClick={() => openModule("/d#tab/resume", "dashboard")}
                        />
                        <div
                            style={{ padding: "7px", marginRight: "7px", cursor: "pointer" }}
                            onClick={props.menuExpand}
                            id="sideBar_button_hideMenu"
                        >
                            <Icon icon="menu" color="parent" title="$__hideMenu" size="22px" />
                        </div>
                    </div>

                    {/* Itens do menu */}
                    <div
                        style={{
                            position: "absolute",
                            top: "100px",
                            bottom: "0px",
                            overflowX: "auto",
                            width: "100%",
                        }}
                    >
                        {renderMenuItem(
                            "dashboard",
                            "/d#tab/resume",
                            "dashboard",
                            "dashboard",
                            translate("$__dashboard", "*"),
                            "/d"
                        )}
                        {renderMenuItem(
                            "brightAndConnected",
                            "/bright-and-connected#tab/home",
                            "bright-and-connected",
                            Bright,
                            "BRIGHT & CONNECTED",
                            "/bright-and-connected"
                        )}
                        {renderMenuItem(
                            "StrategyAndPlans",
                            "/sp#tab/home",
                            "StrategyAndPlans",
                            "view_comfy",
                            translate("$__StrategyAndPlans", "*"),
                            "/sp"
                        )}
                        {renderMenuItem(
                            "myTasks",
                            "/t#tab/myTasks",
                            "myTasks",
                            "task",
                            translate("$__activities", "*"),
                            "/t"
                        )}
                        {renderMenuItem(
                            "library",
                            "/library",
                            "library",
                            "folder",
                            translate("$__library", "*"),
                            "/library"
                        )}
                        {renderMenuItem(
                            "tags",
                            "/tags",
                            "tags",
                            "turned_in",
                            translate("$__tags", "*"),
                            "/tags"
                        )}
                        {renderMenuItem(
                            "calendar",
                            "/calendar",
                            "calendar",
                            "event",
                            translate("$__calendar", "*"),
                            "/calendar"
                        )}
                        <div className={classes.separator} />
                        {renderChatItem()}
                        {renderSupportItem()}
                    </div>

                    {/* SubMenu */}
                    {props.subMenu && (
                        <div
                            className={
                                props.subMenuExpanded ? classes.moduleExpanded : classes.module
                            }
                            id="sideBar_subMenu"
                            style={{
                                background: customApp("subMenuColor"),
                                borderRight: `4px solid ${customApp("color")}`,
                            }}
                        >
                            <SubMenu
                                hashs={hashs}
                                subMenuExpanded={(a) => props.setSubMenuExpanded(a)}
                                expanded={props.subMenuExpanded}
                            />
                        </div>
                    )}

                    {/* Versão */}
                    <div
                        style={{
                            position: "absolute",
                            bottom: 0,
                            left: 15,
                            color: "#c0b283",
                            fontSize: "10px",
                        }}
                    >
                        V:{packageJson.version}
                    </div>
                </div>
            </div>
        </ErrorBoundary>
    );
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(reduxActions, dispatch);

export default connect(null, mapDispatchToProps)(
    withStyles(styles)(Component)
);