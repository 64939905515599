import React from "react"


//REDUX
import * as reduxActions from "store/actions"
import reduxStore from "store"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

//COMPONENTS
import Icon from "components/Icon"
import TextField from "components/TextField"

//@MATERIAL
import Avatar from "@material-ui/core/Avatar"
import AvatarGroup from "@material-ui/lab/AvatarGroup"
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Dialog from '@material-ui/core/Dialog'
import Tooltip from "@material-ui/core/Tooltip"
import { makeStyles } from '@material-ui/core/styles'
import InputAdornment from "@material-ui/core/InputAdornment"

//FUNCTIONS
import {
    getUserList,
    getUserAccess
} from "functions/cards"

import {
    translate,
    customApp,
    textColor,
} from "functions/"

import {
    getAdminList
} from "functions/users"


const useStyles = makeStyles((props) => ({
    iconButtonStyleTransaparent: {
        margin: 0,
        '&:hover': {
            backgroundColor: 'rgba(250,250,250,0.9)'
        }
    },
    iconButtonStyleWhite: {
        margin: 0,
        backgroundColor: '#FFFFFF',
        '&:hover': {
            backgroundColor: 'rgba(250,250,250,0.9)'
        }
    },
}))

function SelectUsers(props) {
    const classes = useStyles()
    const anchorRef = React.useRef(null)
    const [open, setOpen] = React.useState(false)
    const { data } = props
    const { db, session, sideModule } = reduxStore.getState()
    const [selectedUser, setSelectedUser] = React.useState(props.selected ? props.selected : [])
    const [searchValue, setSearchValue] = React.useState("")
    const [showInternal, setShowInternal] = React.useState(props.selectedShow && String(props.selectedShow?.internal) ? props.selectedShow?.internal : true)
    const [showExternal, setShowExternal] = React.useState(props.selectedShow && String(props.selectedShow?.external) ? props.selectedShow?.external : false)

    const mounted = React.useRef()

    React.useEffect(() => {
        mounted.current = true
        return () => {
            mounted.current = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (props.selected && JSON.stringify(props.selected) !== JSON.stringify(selectedUser))
            if (mounted.current)
                setSelectedUser(props.selected)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selected])


    const userList = props.admins
        ? getAdminList(data
            && data._id
            ? data._id
            : null,
            null,
            {
                onlyCard: data
                    && data._id
                    ? true
                    : false
            })
        : getUserList(data
            && data._id
            ? data._id
            : null,
            {
                onlyCard:
                    data
                        && data._id
                        ? true
                        : false,
                all: true,
                allPlataform: props.allPlataform || false
            })


    let CardAccess = sideModule && sideModule.id ? getUserAccess(sideModule.id) : 0

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }
        if (mounted.current)
            setOpen(false)
    }

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault()
            if (mounted.current)
                setOpen(false)
        }
    }

    const selectUser = async (id) => {
        let selectedUsers = Array.from(selectedUser)
        if (props.multiple) {
            if (selectedUsers.filter(i => i === id).length === 0) {
                selectedUsers.push(id)
            } else {
                selectedUsers.splice(selectedUsers.indexOf(id), 1)
            }
        } else {
            selectedUsers = [id]
        }
        if (mounted.current)
            setSelectedUser(selectedUsers)
        if (props.onSelect)
            props.onSelect(selectedUsers)
        if (!props.multiple) {
            if (mounted.current)
                setOpen(false)
        }
    }

    const UserMenuItem = ({ pr, selected }) => {
        let userPermission = true

        let u = userList[pr]
        if (u)
            return (
                <MenuItem
                    autoFocus={false}
                    onClick={userPermission ? (e) => {
                        selectUser(pr)
                        e.preventDefault()
                        e.stopPropagation()
                    } : null}
                    className={"ignoreClickAway"}
                >
                    <div

                        style={{
                            position: 'relative',
                            width: '100%',
                            display: "flex",
                            alignItems: "center",
                            cursor: 'pointer',
                            pointerEvents: 'none'
                        }}
                    >
                        <Avatar alt={u.name || null} src={u.image || null} style={{
                            ...props.avatarSize ? { width: props.avatarSize, height: props.avatarSize } : {}
                        }}>
                            {u && u.name ? u.name.substr(0, 1) : ''}
                        </Avatar>
                        <div style={{ marginLeft: '15px', color: !userPermission ? "lightgray" : "black" }}>

                            <div style={{ position: "relative", width: "100%", float: "left" }} className={"ignoreClickAway"}>
                                {u.name || u.name}

                            </div>
                            <div style={{ position: "relative", width: "100%", float: "left", display: "flex", alignItems: "center" }}>
                                {u._parent &&
                                    db.users[u._parent] &&
                                    db.users[u._parent].image ?
                                    <div
                                        style={{
                                            position: "relative",
                                            bottom: -4,
                                            right: -7,
                                            width: 17,
                                            height: 17,
                                            borderRadius: "50%",
                                            backgroundImage: `url(${db.users[u._parent].image})`,
                                            backgroundSize: "cover",
                                            backgroundRepeat: "no-repeat"
                                        }}></div>
                                    : <React.Fragment></React.Fragment>}
                                {u._parent &&
                                    db.users[u._parent] &&
                                    db.users[u._parent].name ?
                                    <div style={{ position: "relative", float: "left", fontSize: 11, color: "lightgray", marginLeft: 7 }}>
                                        {db.users[u._parent].name}
                                    </div>
                                    : <React.Fragment></React.Fragment>}
                            </div>
                            {selected ?
                                <div style={{
                                    position: "absolute",
                                    right: 0,
                                    top: 0,
                                    bottom: 0,
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display: "flex"
                                }}>
                                    {selectedUser.indexOf(u._id) === 0 ?
                                        <div><Icon icon="admin_panel_settings" color={"lightgray"} /></div>
                                        : <React.Fragment></React.Fragment>}
                                    <Icon icon="check" color={"green"} />
                                </div>
                                : <React.Fragment></React.Fragment>
                            }
                        </div>
                    </div>
                </MenuItem>
            )
        return (<React.Fragment></React.Fragment>)
    }


    return (
        <div>
            <Button
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                aria-label="menu"
                onClick={(e) => {
                    if (
                        !data
                        ||
                        (
                            props.permission
                        )
                        ||
                        (
                            data && userList && userList[session._id] && userList[session._id].access && parseInt(userList[session._id].access) > 2
                        )
                        ||
                        (
                            CardAccess > 3
                        )
                    ) {
                        setOpen(true)
                    }
                }}
                className={!props.transparent ? classes.iconButtonStyleWhite : classes.iconButtonStyleTransaparent}
            >
                {(selectedUser && selectedUser.length === 0) ?
                    <span style={{ fontSize: "12px", whiteSpace: "nowrap" }}>{translate(props.text || "$__selectUser")}</span>
                    :
                    <div>
                        <AvatarGroup
                            max={!props.noLimit ? 4 : 300}
                            style={{
                                flexWrap: "wrap",   
                                display: "inline-flex"
                            }}
                        >
                            {selectedUser?.filter(uid => uid && reduxStore.getState().db.users[uid])?.map(selectedUserId =>
                                <Tooltip
                                    key={selectedUserId}
                                    title={reduxStore.getState().db.users[selectedUserId] && reduxStore.getState().db.users[selectedUserId].displayName ? reduxStore.getState().db.users[selectedUserId].displayName : reduxStore.getState().db.users[selectedUserId]?.name || ""}
                                >
                                    <div
                                        style={{ display: "flex", alignItems: "center" }}
                                    >
                                        <Avatar
                                            alt={reduxStore.getState().db.users[selectedUserId].name || ""}
                                            src={reduxStore.getState().db.users[selectedUserId].image || null}
                                            style={{ width: props && props.avatarSize ? props && props.avatarSize : 'none', height: props && props.avatarSize ? props.avatarSize : 'none' }}>
                                            {reduxStore.getState().db.users[selectedUserId]?.name?.substr(0, 1) || ""}
                                        </Avatar>
                                        {!props.multiple &&
                                            <span style={{ marginLeft: 15 }}>
                                                {reduxStore.getState().db.users[selectedUserId].displayName ? reduxStore.getState().db.users[selectedUserId].displayName : reduxStore.getState().db.users[selectedUserId]?.name || ""}
                                            </span>
                                        }
                                    </div>
                                </Tooltip>
                            )}
                            {props.multiple &&
                                <Tooltip
                                    title={translate(props.text || "$__selectUser")}
                                >
                                    <Avatar
                                        style={{ width: props && props.avatarSize ? props && props.avatarSize : 'none', height: props && props.avatarSize ? props.avatarSize : 'none' }}
                                        alt={'ADD'}
                                        src={null}>
                                        +
                                    </Avatar>
                                </Tooltip>
                            }
                        </AvatarGroup>
                    </div>
                }
            </Button>
            <Dialog
                open={open}
            >
                <div style={{
                    position: 'relative',
                    width: '100%',
                    display: "flex",
                    justifyContent: "space-between"
                }}>
                    <ClickAwayListener
                        onClickAway={handleClose}
                    >
                        <div
                            style={{
                                position: "relative",
                            }}
                        >
                            <div style={{
                                width: "100%",
                                padding: "4px 15px",
                                fontSize: "16px",
                                color: "lightGray",
                                position: "relative",
                            }}>
                                <TextField
                                    id={`quickUser_Search`}
                                    label={translate('$__searchByName', 1)}
                                    variant={customApp('fieldVariant')}
                                    name={'searchValue'}
                                    InputProps={{
                                        autoComplete: 'new-password',
                                        endAdornment: (
                                            <InputAdornment
                                                position="end"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setSearchValue("")
                                                    setOpen(false)
                                                }}>
                                                <Icon icon='close' />
                                            </InputAdornment>
                                        ),
                                        onChange: (d) => {
                                            setSearchValue(d.target.value)
                                        },
                                    }}
                                    value={searchValue}
                                    placeholder={translate('$__searchByName', 1)}
                                    autoFocus
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    // justifyContent: "space-between",
                                    padding: "0px 15px",
                                    fontSize: 12,
                                    marginTop: -5
                                }}
                            >
                                <div style={{
                                    border: `solid 1px ${showInternal ? '#585858' : `lightGray`}`,
                                    padding: "3px 7px",
                                    cursor: "pointer",
                                    borderRadius: 10,
                                    backgroundColor: `${showInternal ? '#585858' : `lightGray`}`,
                                    color: textColor(showInternal ? '#585858' : `#d3d3d3`, true)
                                }}
                                    onClick={() => {
                                        setShowInternal(prev => !prev)
                                    }}>
                                    {translate("$__showInternal")}
                                </div>
                                <div style={{
                                    border: `solid 1px ${showExternal ? '#585858' : `lightGray`}`,
                                    padding: "3px 7px",
                                    cursor: "pointer",
                                    marginLeft: 7,
                                    borderRadius: 10,
                                    backgroundColor: `${showExternal ? '#585858' : `lightGray`}`,
                                    color: textColor(showExternal ? '#585858' : `#d3d3d3`, true)
                                }}
                                    onClick={() => {
                                        setShowExternal(prev => !prev)
                                    }}>
                                    {translate("$__showExternals")}
                                </div>
                            </div>
                            <div style={{
                                width: "100%",
                                padding: "4px 15px",
                                fontSize: "16px",
                                color: "lightGray",
                                position: "relative",
                                maxHeight: 250,
                                overflowY: "auto"
                            }}>
                                <MenuList
                                    id="menu-list-grow"
                                    autoFocusItem={false}
                                    autoFocus={false}
                                    onKeyDown={handleListKeyDown}
                                >
                                    {Object.keys(userList).filter(a => {
                                        if (!userList[a].deleted
                                            && (searchValue === ""
                                                ||
                                                (
                                                    searchValue
                                                    && userList[a].name
                                                    && userList[a].name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
                                                )
                                            )
                                            && (
                                                (
                                                    !showExternal
                                                    && userList[a].type !== "external"
                                                )
                                                ||
                                                (
                                                    showExternal
                                                    && userList[a].type === "external"
                                                )
                                                ||
                                                (
                                                    !showInternal
                                                    && userList[a].type !== "user"
                                                )
                                                ||
                                                (
                                                    showInternal
                                                    && userList[a].type === "user"
                                                )
                                            )
                                        ) return true
                                        return false
                                    }
                                    ).sort((a, b) => {
                                        if (userList[a].name < userList[b].name)
                                            return -1
                                        else if (userList[a].name > userList[b].name)
                                            return 1
                                        return 0
                                    })
                                        .filter(pr => userList && userList[pr] && userList[pr]._id)
                                        .map(pr =>
                                            <UserMenuItem pr={pr} key={pr} selected={selectedUser.indexOf(userList[pr]._id) === -1 ? false : true} />
                                        )}
                                </MenuList>
                            </div>
                        </div>
                    </ClickAwayListener>
                </div>
            </Dialog>
        </div>
    )
}
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(null, mapDispatchToProps)(SelectUsers)