import React, { useState, useMemo, useCallback } from "react"
import moment from "moment"

// REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"

// COMPONENTS
import Autocomplete from "components/AutoComplete"
import DataPicker from "components/DataPicker"
import SideModuleCloseAndSave from "components/SideModule/closeAndSave"
import Switch from "components/Switch"
import TextField from "components/TextField"

// @MATERIAL
import { withStyles } from "@material-ui/core/styles"

// STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/sideModule-Users.js"

// FUNCTIONS
import { customApp, appAlert, translate, getAppAccess } from "functions/"
import { save } from "../../functions/users.js"

function Component(props) {
    const { classes } = props
    const { sideModule, db } = reduxStore.getState()

    // Define valores padrão para as áreas da plataforma
    const defaultFeatures = {
        dashboard: true,
        brightAndConnected: true,
        StrategyAndPlans: true,
        myTasks: true,
        library: true,
        tags: true,
        chat: true,
        calendar: true
    }

    // Mescla os dados iniciais com os defaults (caso as features não estejam definidas)
    const initialData = {
        ...sideModule.data,
        features: { ...defaultFeatures, ...(sideModule.data?.features || {}) }
    }

    const [data, setData] = useState(initialData)
    const [iniData, setIniData] = useState(initialData)

    // Verifica se houve alterações
    const reqSave = useMemo(
        () => JSON.stringify(data) !== JSON.stringify(iniData),
        [data, iniData]
    )

    // Atualiza os dados e também envia para o redux
    const changeData = useCallback(
        (d) => {
            const newData = { ...data, [d.name]: d.value }
            if (!d.value && newData[d.name]) delete newData[d.name]

            props.reduxFunction("ASYNC", "SET_MODULE", {
                ...sideModule,
                data: { ...sideModule.data, [d.name]: d.value }
            })
            setData(newData)
        },
        [data, sideModule, props]
    )

    const close = useCallback(
        (force = false) => {
            if (reqSave && !force) {
                appAlert({
                    message: translate("$__confirmCloseWithUnsavedData", 1),
                    variant: "warning",
                    persist: false,
                    horizontal: "right",
                    confirm: () => props.reduxFunction("ASYNC", "CLEAR_MODULE")
                })
            } else {
                props.reduxFunction("ASYNC", "CLEAR_MODULE")
            }
        },
        [reqSave, props]
    )

    const saveUser = useCallback(
        async (cl) => {
            const req = await save(props)
            if (req) {
                appAlert({
                    message: translate("$__userDataSaved", 1),
                    variant: null,
                    persist: false,
                    horizontal: "right",
                    confirm: null
                })
                setIniData(data)
                if (cl) close(true)
            }
        },
        [data, props, close]
    )

    const AppAccess = getAppAccess()
    const permission =
        AppAccess &&
        AppAccess.user &&
        (AppAccess.userAdmin || AppAccess.userEdit)

    // Lista de áreas configuráveis da plataforma
    const configurableAreas = useMemo(
        () => [
            { key: "dashboard", label: translate("$__dashboard", 1) },
            { key: "brightAndConnected", label: translate("$__brightAndConnected", 1) },
            { key: "StrategyAndPlans", label: translate("$__strategyAndPlans", 1) },
            { key: "myTasks", label: translate("$__myTasks", 1) },
            { key: "library", label: translate("$__library", 1) },
            { key: "tags", label: translate("$__tags", 1) },
            { key: "chat", label: translate("$__chat", 1) },
            { key: "calendar", label: translate("$__calendar", 1) }
        ],
        []
    )

    // Alterna (toggle) cada área da plataforma
    const toggleFeature = useCallback(
        (key) => {
            const current = data.features || {}
            const newFeatures = { ...current, [key]: !current[key] }
            console.log(newFeatures)
            changeData({ name: "features", value: newFeatures })
        },
        [data, changeData]
    )

    return (
        <div>
            <SideModuleCloseAndSave
                save={reqSave ? () => saveUser() : null}
                saveClose={reqSave ? () => saveUser(true) : null}
                close={() => close(true)}
            />
            <div
                style={{
                    position: "relative",
                    width: "100%",
                    textAlign: "center",
                    padding: "15px"
                }}
            >
                {/* Limite do número de usuários */}
                <fieldset>
                    <legend>{translate("$__limitNumberOfUsers", 1)}</legend>
                    <div>
                        {translate("$__limitAdminUsersExplain", 1)}
                        <TextField
                            label={translate("$__numberOfUsers")}
                            variant="outlined"
                            name="customAdmins"
                            type="number"
                            min={0}
                            max={99999}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                type: "number",
                                onChange: (e) => {
                                    const val = Number(e.target.value)
                                    changeData({ name: "customAdmins", value: val === 0 ? null : val })
                                }
                            }}
                            value={data.customAdmins || 0}
                        />
                    </div>
                </fieldset>

                {/* Configuração de Conta Independente */}
                {reduxStore.getState().session.isResale && (
                    <fieldset style={{ marginBottom: "14px" }}>
                        {reduxStore.getState().db.users[data._id].independentAccount ? (
                            <>
                                <div style={{ color: "gray", fontSize: 11, width: "100%" }}>
                                    {translate("$__thisIsAIndependentAccountExplain", 1)}
                                </div>
                                <ul
                                    style={{
                                        color: "gray",
                                        fontSize: 12,
                                        width: "100%",
                                        textAlign: "initial"
                                    }}
                                >
                                    {data.plan && (
                                        <li>
                                            {translate("$__signaturePlan", 1)}:{" "}
                                            {translate(
                                                db.plans.find(
                                                    (a) => String(a.value) === String(data.plan)
                                                ).label,
                                                1
                                            )}
                                        </li>
                                    )}
                                    {data.expireAccountDate && (
                                        <li>
                                            {translate("$__expireAccountDate", 1)}:{" "}
                                            {moment(data.expireAccountDate * 1000).format("DD/MM/YYYY")}
                                        </li>
                                    )}
                                </ul>
                            </>
                        ) : (
                            <>
                                <div className={classes.switch}>
                                    <Switch
                                        checked={!!data.independentAccount}
                                        onChange={() => {
                                            if (permission)
                                                changeData({
                                                    name: "independentAccount",
                                                    value: !data.independentAccount
                                                })
                                        }}
                                        name="independentAccount"
                                        inputProps={{ "aria-label": "secondary checkbox" }}
                                    />
                                    {translate("$__turnIntoIndependentAccount", 1)}
                                </div>
                                <div style={{ color: "gray", fontSize: 11 }}>
                                    {translate("$__turnIntoIndependentAccountExplain", 1)}
                                </div>
                            </>
                        )}
                    </fieldset>
                )}

                {/* Seleção do Plano (caso aplicável) */}
                {((!iniData.independentAccount && data.plan) || !data.plan) &&
                    data.independentAccount && (
                        <fieldset style={{ marginBottom: "14px" }}>
                            <Autocomplete
                                id="plan-autocomplete"
                                options={db.plans}
                                getOptionLabel={(option) => translate(option.label, 1)}
                                style={{ width: "100%" }}
                                name="plans"
                                disableClearable
                                value={
                                    data && data.plan && db.plans
                                        ? db.plans.find(
                                            (a) => String(a.value) === String(data.plan)
                                        ) || db.plans[0]
                                        : db.plans[0]
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={translate("$__demoPlanSignature", 1)}
                                        variant={customApp("fieldVariant")}
                                    />
                                )}
                                onChange={(e, v) => {
                                    if (permission)
                                        changeData({ name: "plan", value: v.value })
                                }}
                            />
                        </fieldset>
                    )}

                {/* Data para Expiração de Conta (Data Picker) */}
                {data.independentAccount &&
                    data.plan &&
                    data.plan !== "p0" &&
                    !iniData.expireAccountDate && (
                        <DataPicker
                            placeholder={translate("$__dueDate")}
                            clearButtonPosition="flex-end"
                            onChangeStartDate={(d) => {
                                changeData({
                                    name: "expireAccountDate",
                                    value: String(moment(d).unix("X"))
                                })
                            }}
                            maxDate={new Date(moment().add("days", 30).unix("X") * 1000)}
                            startDate={
                                data.expireAccountDate
                                    ? new Date(data.expireAccountDate * 1000)
                                    : null
                            }
                        />
                    )}

                {/* NOVA CONFIGURAÇÃO: Áreas da Plataforma */}
                <fieldset style={{ marginTop: "15px" }}>
                    <legend>{translate("$__platformFeatures", 1)}</legend>
                    <div
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "center"
                        }}
                    >
                        {configurableAreas.map((area) => (
                            <div
                                key={area.key}
                                style={{
                                    margin: "5px",
                                    display: "flex",
                                    alignItems: "center"
                                }}
                            >
                                <Switch
                                    checked={
                                        data.features ? data.features[area.key] : defaultFeatures[area.key]
                                    }
                                    onChange={() => toggleFeature(area.key)}
                                    name={area.key}
                                    disabled={!permission}
                                />
                                <span style={{ marginLeft: "5px" }}>{area.label}</span>
                            </div>
                        ))}
                    </div>
                </fieldset>

                {/* NOVA CONFIGURAÇÃO: Data de Expiração da Plataforma */}
                {/* <fieldset style={{ marginTop: "15px" }}>
                    <legend>{translate("$__platformExpiration", 1)}</legend>
                    <TextField
                        label={translate("$__platformExpiration", 1)}
                        variant={customApp("fieldVariant")}
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        value={data.platformExpiration || ""}
                        onChange={(e) =>
                            changeData({
                                name: "platformExpiration",
                                value: e.target.value
                            })
                        }
                    />
                </fieldset> */}
            </div>
        </div>
    )
}

const mapStateToProps = () => ({})
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component))
