import React, { useMemo } from "react";

// REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";

// COMPONENTS
import CardsLoader from "components/Card/loader";

// DEPENDENCIES
import Resume from "./resume";
import DayTasks from "./daytasks";

// COMPONENTS
import ErrorBoundary from "components/ErrorBoundary";

function Dashboard() {
  // Extract hash values
  const hashs = useMemo(() => {
    const parts = window.location.hash.split("/");
    return parts.map((part, index) => (index === 0 ? part.replace("#", "") : part));
    // eslint-disable-next-line
  }, [window.location.hash]);

  // Render content based on hash
  const renderContent = () => {
    if (hashs[0] === "tab") {
      switch (hashs[1]) {
        case "resume":
          return (
            <ErrorBoundary>
              <Resume />
            </ErrorBoundary>
          );
        case "dayTasks":
          return (
            <ErrorBoundary>
              <DayTasks />
            </ErrorBoundary>
          );
        default:
          return null;
      }
    }
    return null;
  };

  return (
    <div style={{ minHeight: "100vh" }}>
      {/* Loader */}
      <CardsLoader />

      {/* Dynamic Content */}
      {renderContent()}
    </div>
  );
}

const mapStateToProps = (store) => ({
  history: store.db.history,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(reduxActions, dispatch);

// EXPORT
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);