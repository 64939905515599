import React, { useEffect, useState } from 'react'
import { v4 as uuid } from "uuid"

//COMPONENTS
import TextField from "components/TextField"
import Icon from "components/Icon"

//REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as reduxActions from 'store/actions'
import reduxStore from "store/"

//@MATERIAL
import InputAdornment from "@material-ui/core/InputAdornment"

import { translate, customApp, appAlert } from "functions"

import {
  ClickAwayListener,
  Switch,
  FormControlLabel,
  Input,
  Select,
  MenuItem,
  makeStyles,
  FormControl,
  InputLabel,
} from '@material-ui/core'

import api from "api"

const useStyles = makeStyles({
  formControl: {
    color: 'rgb(150,150,150)',
    width: '100%',
    padding: '0',
    fontFamily: '"Soleto", "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '300',
    fontSize: '14px',
    '& label': {
      marginTop: '5px',
    },
    '& .Mui-focused': {
      color: 'rgb(150,150,150)',
    },
    '& +:after': {
      border: '1px solid rgb(192,178,131)',
    },
    '& +:before': {
      borderBottom: '1px solid rgb(192,178,131)',
    },
  },
  switch: {
    '& + .MuiSwitch-track': {
      backgroundColor: 'rgb(158, 158, 158)',
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: 'rgb(125, 125, 125)',
    },
    '& .Mui-checked': {
      '& + .MuiSwitch-track': {
        backgroundColor: 'rgb(192, 178, 131)',
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: 'rgb(192, 178, 131)',
      },
      '& .MuiSvgIcon-root': {
        backgroundColor: "rgb(192, 178, 131)",
        borderRadius: "50%",
        width: '20px',
        height: '20px',
        color: "rgb(192, 178, 131)",
      }
    },
    '& .Mui-disabled': {
      '& + .MuiSwitch-track': {
        opacity: 0.5,
      },
      '& .MuiSvgIcon-root': {
        backgroundColor: "rgb(192, 178, 131)",
        borderRadius: "50%",
        width: '14px !important',
        height: '14px !important',
        padding: '3px',
        color: '#ffffffb0 !important',
        transform: 'translate(0, 0, 0, 0.2em)',
      }
    }
  },
  labelSwitch: {
    marginLeft: '15px'
  },
})
const SwitchCheck = ({ label, icon, defaultChecked, ...props }) => {
  const [check, setCheck] = useState(defaultChecked);
  const colorSwitch = useStyles();

  return (
    <FormControlLabel control={
      <Switch className={colorSwitch.switch}
        color="default"
        checked={check}
        onChange={(event) => { setCheck(event.target.checked) }}
        {...props}
      />
    } label={label} />
  )
}

const CreateForm = (props) => {
  const styles = useStyles();

  const [formId, setFormId] = useState(props?.data?._id || uuid())
  const [titleForm, setTitleForm] = useState('');
  const [boardColumns, setBoardColumns] = useState(false);
  const [msg, setMsg] = useState('');
  const [elements, setElements] = useState([]);
  const [divHover, setDivHover] = useState({});
  const [editField, setEditField] = useState({ type: 'text' })

  useEffect(() => {
    setFormId(props?.data?._id || uuid())
    setTitleForm(props.data?.name || "")
    setElements(props.data?.elements ? JSON.parse(props.data.elements) : [])
    setBoardColumns(props.data?.boardColumns || false)
  }, [props.data])

  function saveField() {
    let id = editField.id || `f${Math.random().toString(36).slice(2).substr(0, 4)}${Math.floor(Date.now() * Math.random()).toString(36).substr(0, 4)}`
    let dataToSave = { ...editField, id }
    if (!dataToSave.type)
      dataToSave.type = "text"
    if (dataToSave?.items?.length > 0) {
      dataToSave.items = Array.from(dataToSave.items.filter(a => a !== "" && a !== undefined && a !== null)) || null
    }
    if (
      (dataToSave.type === "checkbox" || dataToSave.type === "radio")
      && dataToSave.items.filter(a => a !== "" && a !== undefined && a !== null).length === 0
    ) {
      // setMsg('Você deve criar uma lista de opções para este tipo de campo');
      setMsg(translate("$__formWarningOptionsListRequire"))
      setTimeout(() => {
        setMsg('');
      }, 4000);
      return
    } else if (!dataToSave.type || !dataToSave.name) {
      // setMsg('Preencha o nome do campo e o tipo!');
      setMsg(translate("$__formWarningNameAndType"))
      setTimeout(() => {
        setMsg('');
      }, 4000);
      return
    } else if (elements.filter(a => a.name === editField.name && a.id !== editField.id).length > 0) {
      setMsg('O título para o campo já foi definido para outro campo, defina outro nome!');
      setMsg(translate("$__formWarningFieldNameUsed"))
      setTimeout(() => {
        setMsg('');
      }, 4000);
      return
    } else {
      if (elements.filter(a => a.id === id).length === 0) {
        setElements([...elements, dataToSave])
      } else {
        let newElements = Array.from(elements)
        newElements = Array.from(newElements.map(a => {
          if (a.id === editField.id)
            a = dataToSave
          return a
        }))
        setElements([...newElements])
      }
    }
    setEditField({})
  }
  function removeElement(element) {
    appAlert({
      message: translate("$__confirmDeleteField", 1),
      variant: "warning",
      persist: false,
      horizontal: "right",
      confirm: () => {
        setElements(elements.filter(item => item.id !== element));
      }
    })
  }

  function moveElement(from, to) {
    const updatedElements = [...elements];
    const element = updatedElements.splice(from, 1)[0];
    updatedElements.splice(to, 0, element);
    setElements(updatedElements);
  }

  function move(Arr, from, to) {
    const updatedElements = [...Arr];
    const element = updatedElements.splice(from, 1)[0];
    updatedElements.splice(to, 0, element);
    return updatedElements
  }

  const saveForm = async (close) => {
    api.post("forms/add", {
      _id: formId,
      name: titleForm,
      boardColumns: boardColumns,
      elements: JSON.stringify(elements)
    })
    props.reduxFunction("ASYNC", "SET_FORMS", {
      ...reduxStore.getState().forms,
      [formId]: {
        _id: formId,
        name: titleForm,
        boardColumns: boardColumns,
        elements: JSON.stringify(elements)
      }
    })
    if (props.onClose && close)
      props.onClose()

  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        // closeChat()
      }}
    >
      <div
        style={{
          position: "fixed",
          // paddingBottom: 100,
          ...window.innerWidth > 600 ? {
            top: 77,
            left: 77,
            right: 77,
            bottom: 77,
          } : {
            top: 50,
            left: 7,
            right: 7,
            bottom: 7,
          },
          ...window.innerWidth > 600 ? {
            zIndex: 3,

          } : {
            zIndex: 6000,
          },
          boxShadow: "0px 0px 10px 3px rgba(0,0,0,0.2)",
          borderRadius: "15px",
          overflow: 'hidden',
          backgroundColor: "#FFFFFF",
          padding: 0
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              // height: "100%",
              justifyContent: "flex-end",
              margin: "0px",
              // width: "100%"
            }}
          >
            <div
              // item
              // xs={8}
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: "30%",
                backgroundColor: "#fafbfc",
                overflowY: 'scroll',
                // height: '100% ',
                // paddingBottom: 100
              }}
            >
              <h1 style={{ margin: '20px 15px' }}>{titleForm}</h1>
              <div id="form" style={{
                padding: '10px 15px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
                gap: '5px',
                paddingBottom: '50px',
              }}>
                {elements?.map((element, index) => {
                  return (
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '20px',
                      alignItems: 'center',
                      marginBottom: '10px',
                      width: '100%',
                    }}
                      onMouseOver={() => setDivHover({ ...divHover, [element.id]: true })}
                      onMouseLeave={() => setDivHover({ ...divHover, [element.id]: false })}
                      key={element.id}
                    >
                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                      }}>
                        <label htmlFor={element.id} style={{
                          marginBottom: '5px', display: 'flex'
                        }}>{element.name}{element.required ? '*' : ''}:</label>
                        <div style={{
                          display: "flex",
                          alignItems: "center"
                        }}
                        >
                          {
                            (element.type === 'radio' || element.type === 'checkbox') ?
                              <div style={{
                                flexDirection: "column",
                                width: "100%"
                              }}>
                                {element?.items.map((item, index) => (
                                  <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    gap: '5px',
                                    margin: '3px 0px'
                                  }}
                                    key={`ITEM_${element.id}_${index}`}
                                  >
                                    <input type={element.type} id={item} name={element.id} required={element.required ? true : false} />
                                    <label htmlFor={item} style={{ color: '#aaa' }}>{item}</label>
                                  </div>
                                ))}</div> :
                              element.type === 'textarea' ?
                                <textarea rows={4} required={element.required ? true : false} id={element.id} style={{
                                  borderRadius: '5px',
                                  width: '100%',
                                  height: 'auto',
                                  resize: 'none',
                                  padding: '10px 5px',
                                  border: '1px solid rgb(150,150,150)',
                                  fontFamily: '"Soleto", "Roboto", "Helvetica", "Arial", sans-serif',
                                }}></textarea> :
                                <input type={element.type} required={element.required ? true : false} id={element.id} style={{
                                  borderRadius: '5px',
                                  width: '100%',
                                  height: 'auto',
                                  resize: 'none',
                                  padding: '10px 5px',
                                  border: '1px solid rgb(150,150,150)',
                                  fontFamily: '"Soleto", "Roboto", "Helvetica", "Arial", sans-serif',
                                }} />
                          }
                          <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '10px',
                            alignItems: 'center',
                            color: 'rgb(171 171 171)',
                          }}>
                            <div style={{
                              display: 'flex',
                              alignItems: "center"
                            }}>
                              {divHover[element.id] ?
                                <>
                                  {
                                    index === 0 ? null :
                                      <div style={{ padding: 7, cursor: 'pointer' }}
                                        onClick={() => moveElement(index, index - 1)}
                                      >
                                        <Icon style={{
                                          cursor: 'pointer',
                                          backgroundColor: '#eee',
                                          borderRadius: '50%',
                                        }} >arrow_drop_up</Icon>
                                      </div>
                                  }
                                  {
                                    index === 0 ? null :
                                      <div style={{ padding: 7, cursor: 'pointer' }}
                                        onClick={() => moveElement(index, index - 1)}
                                      >
                                        <Icon style={{
                                          cursor: 'pointer',
                                          backgroundColor: '#eee',
                                          borderRadius: '50%',
                                        }} >arrow_drop_up</Icon>
                                      </div>
                                  }
                                  {
                                    index === elements.length - 1 ? null :
                                      <div style={{ padding: 7, cursor: 'pointer' }}
                                        onClick={() => moveElement(index, index + 1)}
                                      >
                                        <Icon style={{
                                          cursor: 'pointer',
                                          backgroundColor: '#eee',
                                          borderRadius: '50%',
                                        }} >arrow_drop_down</Icon>
                                      </div>
                                  }
                                  <div style={{ padding: 7, cursor: 'pointer' }} onClick={() => removeElement(element.id)}><Icon >delete</Icon></div>
                                  <div style={{ padding: 7, cursor: 'pointer' }} onClick={() => {
                                    let newElement = element
                                    if (newElement.type === 'radio' || newElement.type === "checkbox") {
                                      newElement.items.push("")
                                    }
                                    setEditField(newElement)
                                  }}><Icon >edit</Icon></div>
                                </> : <></>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
                )}
              </div>
            </div>
            <div
              style={{
                backgroundColor: "#fafbfc",
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                left: "70%",
                overflowY: 'scroll',
                // minHeight: "100vh",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                paddingBottom: 100,

              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  borderLeft: "3px solid rgb(192,178,131)",
                }}
              >
                <div
                  style={{
                    height: "50px",
                    minHeight: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "calc(100% - 0px)",
                    marginTop: "-4px",
                  }}
                >
                  <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                    <Icon style={{ margin: "0px 10px" }}>edit_note</Icon>
                    <div
                      style={{
                        width: "100%",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                        color: "rgb(1, 30, 65)",
                      }}
                    >
                      {translate("$__createForm")}
                    </div>
                  </div>
                  <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: '5px',
                  }}>
                    {
                      elements?.length > 0 && titleForm ? <><div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '25px',
                        width: '25px',
                        fontSize: '25px',
                        color: 'rgb(192, 178, 131)',
                        opacity: '1',
                        cursor: 'pointer',
                      }}
                        onClick={() => {
                          saveForm()
                        }}
                      >
                        <Icon>save</Icon>
                      </div>
                        <div style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '25px',
                          width: '25px',
                          fontSize: '25px',
                          color: 'green',
                          opacity: '1',
                          cursor: 'pointer',
                        }}
                          onClick={() => {
                            saveForm(true)
                          }}
                        >
                          <Icon>playlist_add_check</Icon>
                        </div></> : null
                    }
                    <div style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '25px',
                      width: '25px',
                      fontSize: '25px',
                      color: 'lightgrey',
                      opacity: '1',
                      cursor: 'pointer',
                    }}
                      onClick={props.onClose ? () => props.onClose() : null}
                    >
                      <Icon>cancel</Icon>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    right: "0px",
                    padding: "4px 25px",
                    position: "relative",
                    backgroundColor: "rgb(192,178,131)",
                    marginBottom: "10px",
                    alignItems: 'center',
                    color: '#fff',
                  }}
                >
                </div>
                <div
                  style={{
                    maxHeight: "75vh",
                    overflowY: "auto",
                    padding: '0px 15px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'stretch',
                    flexDirection: 'column',
                    gap: '10px'
                  }}
                >
                  <div>
                    <FormControl className={styles.formControl} variant="standard">
                      <InputLabel htmlFor="titleForm" className={styles.formControl}>{translate('$__formName')}</InputLabel>
                      <Input
                        id="titleForm"
                        type={'text'}
                        onChange={(e) => setTitleForm(e.target.value)}
                        value={titleForm}
                      />
                      <SwitchCheck
                        label={translate("$__showBoardColumns")}
                        defaultChecked
                        onClick={() => {
                          setBoardColumns(!boardColumns)
                        }}
                        checked={boardColumns}
                      />
                    </FormControl>
                    <fieldset style={{
                      borderRadius: 10,
                      padding: 7,
                      marginTop: 33
                    }}>
                      <legend>{translate('$__field')}</legend>
                      <form id="adicionar" onSubmit={(e) => { e.preventDefault(); }}>
                        <FormControl className={styles.formControl} variant="standard">
                          <InputLabel htmlFor="title" className={styles.formControl}>{translate("$__title")}</InputLabel>
                          <Input
                            id="title"
                            type={'text'}
                            onChange={(e) => {
                              setEditField(({ ...editField, name: e.target.value }))
                            }}
                            value={editField.name || ""}
                          />
                        </FormControl>
                        <div>
                          <FormControl variant="standard" fullWidth className={styles.formControl}>
                            <InputLabel id="typeField" className={styles.formControl}>{translate("$__type")}</InputLabel>
                            <Select
                              value={editField.type || "text"}
                              onChange={(e) => {
                                setEditField(prev => ({
                                  ...prev,
                                  type: e.target.value,
                                  ...(e.target.value === "radio" || e.target.value === "checkbox") ? {
                                    items: editField.items || [""]
                                  } : {}
                                }))
                              }}
                              labelId="typeField"
                              id="typeField"
                              label="Tipo de campo"
                            >
                              <MenuItem value={'text'}>{translate("$__shortText")}</MenuItem>
                              <MenuItem value={'textarea'}>{translate("$__longText")}</MenuItem>
                              <MenuItem value={'radio'}>{translate("$__selection")}</MenuItem>
                              <MenuItem value={'checkbox'}>{translate("$__multiSelection")}</MenuItem>
                              <MenuItem value={'date'}>{translate("$__date")}</MenuItem>
                              <MenuItem value={'number'}>{translate("$__number")}</MenuItem>
                              {/* <MenuItem value={'file'}>{translate("$__file")}</MenuItem> */}
                            </Select>
                          </FormControl>
                          {
                            editField.type === 'radio' || editField.type === 'checkbox' ?
                              <>
                                <fieldset>
                                  <legend htmlFor="legendRadio" >{translate("$__options")}</legend>
                                  {editField?.items?.map((item, index) => (
                                    <div style={{ marginBotton: 15, width: "100%", clear: "both", position: "relative" }} key={index}>
                                      <TextField
                                        id={`sideModule_user_fullName`}
                                        label={`${translate('$__option', 1)} ${index + 1}`}
                                        variant={customApp('fieldVariant')}
                                        name={`option${item + 1}`}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        InputProps={{
                                          autoComplete: 'new-password',
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              {index > 0 ?
                                                <>
                                                  <Icon icon='arrow_drop_up' color={"gray"} onClick={() => {

                                                    let newItems = move(editField.items, index, index - 1)
                                                    setEditField({
                                                      ...editField,
                                                      items: newItems
                                                    })
                                                  }} />
                                                </>
                                                : <>
                                                </>}
                                              {index < editField.items.length - 1 ?
                                                <>
                                                  <Icon icon='arrow_drop_down' color={"gray"} onClick={() => {

                                                    let newItems = move(editField.items, index, index + 1)
                                                    setEditField({
                                                      ...editField,
                                                      items: newItems
                                                    })
                                                  }} />
                                                </>
                                                : <>
                                                </>}
                                              <Icon icon='delete' color={"red"} onClick={() => {
                                                let newItems = Array.from(editField.items)
                                                if (newItems.length === 1) {
                                                  newItems[0] = ""
                                                } else {
                                                  newItems.splice(index, 1)
                                                }
                                                setEditField({
                                                  ...editField,
                                                  items: newItems
                                                })

                                              }} />
                                            </InputAdornment>
                                          ),
                                          onChange: (d) => {
                                            let newItems = Array.from(editField.items)
                                            newItems[index] = d.target.value
                                            if (newItems.filter(a => a === "").length === 0)
                                              newItems.push("")
                                            setEditField({
                                              ...editField,
                                              items: newItems
                                            })
                                          },
                                        }}
                                        value={editField.items[index]}
                                        placeholder={translate('$__optionText', 1)}
                                        autoFocus={index === 0}
                                      />
                                    </div>
                                  ))}

                                </fieldset>
                              </>
                              : <></>}
                          <div style={{ width: '100%' }}>
                            <SwitchCheck
                              label={translate("$__required")}
                              defaultChecked
                              onClick={() => {
                                setEditField(({ ...editField, required: !editField.required ? true : false }))
                              }}
                              checked={editField?.required ? true : false}
                            />
                            {elements.length > 0 ?
                              <SwitchCheck label={translate("$__dependsOnAnotherAnswew")}
                                onClick={() => {
                                  setEditField(({ ...editField, depends: !editField.depends ? true : false }))
                                }}
                                checked={editField?.depends ? true : false}
                              />
                              : <></>}
                            {
                              editField?.depends ?
                                <div>
                                  {elements.map((element) => (
                                    <div style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'flex-start',
                                      alignItems: 'flex-start',
                                      gap: '5px',
                                      margin: '3px 0px'
                                    }}
                                      key={element.id}
                                    >
                                      <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start',
                                        gap: '5px',
                                        margin: '3px 0px'
                                      }}>
                                        <input
                                          type="radio"
                                          id={`R_${element.id}`}
                                          name='dependsOn'
                                          {...editField && editField.dependsOn && String(editField.dependsOn) === String(element.id) ? { defaultChecked: true } : {}}
                                          onClick={() => {
                                            setEditField(({ ...editField, dependsOn: element.id }))
                                          }}
                                        />
                                        <label htmlFor={`R_${element.id}`}>{element.name}</label>
                                      </div>
                                      {
                                        editField && editField.dependsOn && editField.dependsOn === element.id ? <>
                                          {
                                            element.type === 'radio' || element.type === 'checkbox' ?
                                              element?.items.map((item, index) => (
                                                <div style={{
                                                  display: 'flex',
                                                  flexDirection: 'row',
                                                  justifyContent: 'flex-start',
                                                  alignItems: 'flex-start',
                                                  gap: '5px',
                                                  margin: '3px 15px'
                                                }}
                                                  key={`ITEM_${element.id}_${index}`}
                                                // onClick={(e) => {
                                                //   if (element.type === 'radio') {
                                                //     setValues({ ...values, [element.id]: item })
                                                //   }
                                                //   if (element.type === 'checkbox') {
                                                //     handleCheckboxChange(element.id, item)
                                                //   }
                                                // }}
                                                >
                                                  <div
                                                  // style={{
                                                  //   pointerEvents: "none",
                                                  // }}
                                                  >
                                                    <input
                                                      type={element.type}
                                                      id={`R_${item}`}
                                                      name={element.id}
                                                      {...element.type === "checkbox"
                                                        && (
                                                          index === 0
                                                          && (
                                                            !editField.dependsValue
                                                            || (
                                                              editField.dependsValue
                                                              && editField.dependsValue.length === 0
                                                            )
                                                            || (
                                                              editField.dependsValue
                                                              && editField.dependsValue.length === 1
                                                              && editField.dependsValue.indexOf(item) > -1
                                                            )
                                                          )
                                                        ) ? { defaultChecked: true } : {}}
                                                      {...element.type === 'radio' ? { checked: editField && editField.dependsValue && editField.dependsValue === item ? true : false } : {}}
                                                      // {...element.type === 'checkbox' ? { checked: values && values[element.id] && values[element.id].length > 0 && values[element.id].indexOf(item) > -1 ? true : false } : {}}
                                                      // value={ }
                                                      onClick={(e) => {
                                                        let value = item
                                                        if (element.type === "checkbox") {
                                                          if (editField && (
                                                            !editField.dependsValue
                                                            || (editField.dependsValue
                                                              && editField.dependsValue.indexOf(item) === -1
                                                            )
                                                          )) {
                                                            value = Array.from(editField.dependsValue || [])
                                                            value.push(item)
                                                          } else if (editField && editField.dependsValue && editField.dependsValue.indexOf(item) > -1) {
                                                            value = Array.from(editField.dependsValue)
                                                            value.splice(editField.dependsValue.indexOf(item), 1)
                                                          }
                                                        }
                                                        setEditField(({ ...editField, dependsValue: value }))
                                                      }} />
                                                    <label htmlFor={`R_${item}`} style={{ color: '#aaa' }}>{item}</label>
                                                  </div>
                                                </div>
                                              )) :
                                              element.type === 'textarea' ?
                                                <textarea rows={4}
                                                  onChange={(e) => setEditField(({ ...editField, dependsValue: e.target.value }))}
                                                  id={element.id}
                                                  style={{
                                                    borderRadius: '5px',
                                                    width: '100%',
                                                    height: 'auto',
                                                    resize: 'none',
                                                    padding: '10px 5px',
                                                    border: '1px solid rgb(150,150,150)',
                                                    fontFamily: '"Soleto", "Roboto", "Helvetica", "Arial", sans-serif',
                                                  }}></textarea> :
                                                <input type={element.type}
                                                  onChange={(e) => setEditField(({ ...editField, dependsValue: e.target.value }))}
                                                  id={element.id}
                                                  style={{
                                                    borderRadius: '5px',
                                                    width: '100%',
                                                    height: 'auto',
                                                    resize: 'none',
                                                    padding: '10px 5px',
                                                    border: '1px solid rgb(150,150,150)',
                                                    fontFamily: '"Soleto", "Roboto", "Helvetica", "Arial", sans-serif',
                                                  }} />
                                          }
                                        </> : null}
                                    </div>))}
                                  {/* {editField && editField.dependsOn ?
                                    <FormControl variant="standard" fullWidth className={styles.formControl}>
                                      <InputLabel id="conditionToDependency" className={styles.formControl}>Condição</InputLabel>
                                      <Select
                                        value={editField.dependsCondition || "equal"} onChange={(e) => setEditField(({ ...editField, dependsCondition: e.target.value }))}
                                        labelId="conditionToDependency"
                                        id="conditionToDependency"
                                        label="conditionDependecy"
                                      >
                                        {(elements.filter(a => a.id === editField.dependsOn)[0].type === "radio" || elements.filter(a => a.id === editField.dependsOn)[0].type === "date" || elements.filter(a => a.id === editField.dependsOn)[0].type === "number" || elements.filter(a => a.id === editField.dependsOn)[0].type === "checkbox") &&
                                          [
                                            <MenuItem value={'equal'}>{translate("$__equal")}</MenuItem>,
                                            <MenuItem value={'different'}>{translate("$__different")}</MenuItem>
                                          ]
                                        }
                                        {(elements.filter(a => a.id === editField.dependsOn)[0].type === "date" || elements.filter(a => a.id === editField.dependsOn)[0].type === "number") &&
                                          [
                                            <MenuItem value={'biggerThan'}>{translate("$__biggerThen")}</MenuItem>,
                                            <MenuItem value={'lessThan'}>{translate("$__lessThan")}</MenuItem>
                                          ]
                                        }
                                        {(elements.filter(a => a.id === editField.dependsOn)[0].type === "checkbox") &&
                                          [
                                            <MenuItem value={'contain'}>{translate("$__contain")}</MenuItem>,
                                          ]
                                        }
                                      </Select>
                                    </FormControl>
                                    : <></>} */}
                                </div> : null
                            }
                            <button onClick={() => { saveField() }} style={{
                              padding: '7px 30px',
                              border: 'rgb(1, 30, 65) 1px solid',
                              background: 'linear-gradient(90deg, rgb(1, 30, 65) 0%, rgb(0, 49, 112) 100%)',
                              color: 'rgb(192,178,131)',
                              borderRadius: '4px',
                              fontWeight: '600',
                              margin: '10px 0',
                              width: '100%',
                              cursor: 'pointer',
                            }}>
                              {editField.id ? translate("$__save") : translate("$__add")}
                            </button>
                          </div>
                        </div>
                      </form>
                    </fieldset>
                  </div>
                  <p style={{
                    color: 'red',
                    fontSize: '0.8rem',
                    textAlign: 'center'
                  }}>{msg}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ClickAwayListener>
  );
}

//REACT
const mapDispatchToProps = dispatch =>
  bindActionCreators(reduxActions, dispatch);

export default connect(
  null,
  mapDispatchToProps
)(CreateForm)