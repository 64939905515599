import React from 'react';
import Icon from "@material-ui/core/Icon";
import HtmlTooltip from "components/Tooltip/html";
import { translate, customApp } from "functions/";

export default function IconComponent(props) {
    // Removendo usePropagation para não ser espalhado no elemento DOM
    const {
        title,
        icon,
        children,
        className = "",
        static: isStatic,
        size = "22px",
        color,
        disabled,
        onClick,
        style = {},
        usePropagation, // removido do ...rest
        ...rest
    } = props;

    let nRest = { ...rest }
    if (nRest.iconColor)
        delete nRest.iconColor

    if (nRest.badgeColor)
        delete nRest.badgeColor

    if (nRest.badgeVisible)
        delete nRest.badgeVisible

    const tooltipTitle = title ? translate(title, 1) : "";
    const computedColor = color || customApp("medium");

    // Estilos comuns para o ícone
    const commonStyle = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: size,
        width: size,
        fontSize: size,
        color: computedColor,
        opacity: disabled ? "0.5" : "1",
        cursor: onClick ? "pointer" : "",
        ...style,
    };

    // Manipulador unificado para onClick
    const handleClick = (e) => {
        if (onClick) {
            onClick();
            e.preventDefault();
            e.stopPropagation();
        }
    };

    // Se o ícone (ou children) for uma string, renderiza o componente Icon do Material‑UI
    if (typeof icon === "string" || typeof children === "string") {
        const iconStyle = {
            position: isStatic ? "static" : "",
            ...commonStyle,
        };

        return (
            <HtmlTooltip title={tooltipTitle}>
                <Icon
                    className={className}
                    style={iconStyle}
                    onClick={onClick ? handleClick : null}
                    {...nRest}
                >
                    {icon || children}
                </Icon>
            </HtmlTooltip>
        );
    }

    // Caso contrário, assume que "icon" é um componente e o renderiza
    return (
        <HtmlTooltip title={tooltipTitle}>
            {React.createElement(icon, {
                className,
                style: { margin: "auto", ...commonStyle },
                onClick: onClick ? handleClick : null,
                ...nRest,
            })}
        </HtmlTooltip>
    );
}
