// MODULES
import React from "react";

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import reduxStore from "store/";

// COMPONENTS
import { VariableSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import IconButton from "components/CustomButtons/IconButton";

//DEPENDENCIES
import Message from "./message"

// STYLES
import styles from "./Messages.css";

//FUNCTIONS
import {
    loadTimeline,
    chatReaded,
} from "functions/chat";

import { countUnReadsMessages } from "functionsV3/chat"

const VirtualListMessages = (props) => {
    const { messages: ReqMessages, type, nodeId } = props
    const { socket } = reduxStore.getState().functions

    const [messages, setMessages] = React.useState([])
    const unReadeds = countUnReadsMessages(nodeId)

    // References
    const mounted = React.useRef(true)
    const listRef = React.createRef()
    const loadRef = React.useRef({
        autoScrolling: true
    })
    const rowHeights = React.useRef({})
    const messagesList = React.useRef()

    React.useEffect(() => {
        if (JSON.stringify(ReqMessages) !== JSON.stringify(messages)) {
            setMessages(ReqMessages)
        }
        // eslint-disable-next-line 
    }, [ReqMessages])

    React.useEffect(() => {
        loadRef.current = {
            ...loadRef.current,
            autoScrolling: true
        }
        if (listRef.current)
            scrollToBottom(true)
        clearMessages()
        return () => {
            // mounted.current = false
            rowHeights.current = {}
            loadRef.current = {
                autoScrolling: true
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nodeId, type])



    React.useEffect(() => {
        if (parseInt(unReadeds) > 0 && mounted.current && loadRef.current.autoScrolling) {
            clearMessages()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nodeId, type])

    React.useEffect(() => {
        if (mounted.current && listRef.current)
            scrollToBottom()
        // eslint-disable-next-line 
    }, [messages])

    React.useLayoutEffect(() => {
        if (mounted.current && listRef) {
            scrollToBottom(true)
        }
        // eslint-disable-next-line 
    }, [])


    React.useEffect(() => {
        if (messages.length > 0 && mounted.current) {
            if (!loadRef.current?.mounted || (loadRef?.current?.messagesLength && messages.length > loadRef.current.messagesLength)) {
                loadRef.current = {
                    ...loadRef.current,
                    loading: false,
                    messagesLength: messages.length,
                };
                if (!loadRef.current.mounted) {
                    loadRef.current = {
                        ...loadRef.current,
                        mounted: true,
                    };
                }
            }
        }
        if (parseInt(unReadeds) > 0 && mounted.current && loadRef.current.autoScrolling) {
            clearMessages()
        }
        // eslint-disable-next-line 
    }, [messages]);

    const clearMessages = async () => {
        chatReaded(props, {
            readedBy: reduxStore.getState().session._id,
            id: nodeId,
            db: props.db,
            type: type
        })

        socket.emit("data", {
            module: "chat",
            method: "put",
            action: "read"
        }, {
            readedBy: reduxStore.getState().session._id,
            id: nodeId,
            db: props.db,
            type: type
        })


        if (reduxStore.getState().chats.unreadeds[nodeId]) {
            props.reduxFunction("ASYNC", "SET_CHATS", {
                ...reduxStore.getState().chats,
                unreadeds: {
                    ...reduxStore.getState().chats.unreadeds,
                    [nodeId]: 0
                }
            })
        }
        loadRef.current = {
            ...loadRef.current,
            cleanMessages: false
        }

    }

    const loadMore = async () => {
        const timelineDb = reduxStore.getState().db.users[nodeId] ? `users` : `cards`
        let lt = await loadTimeline(
            {
                ...props,
                fnIdentification: "Timeline/Index/scrollFunction()"
            },
            {
                ids: [nodeId],
                db: timelineDb,
                type: "comment",
                limit: true,
                skip: messages.length
            })

        if (lt) {
            scrollToBottom();
            let newRef = {
                reqMessages: false
            };

            if (lt.total < 20)
                newRef = { ...newRef, timelineEnd: true };
            loadRef.current = {
                ...loadRef.current,
                ...newRef,
                reqMessages: false
            };
        }
    }



    function getRowHeight(index) {
        const data = messages[index]
        return rowHeights.current[data._id] + 11 || 83;
    }

    function setRowHeight(index, size) {
        rowHeights.current = { ...rowHeights.current, [index]: size };
        if (listRef?.current?.resetAfterIndex) {
            listRef.current.resetAfterIndex(0);
        }
    }


    function Row({ index, style }) {
        const data = messages[index]

        if (
            !loadRef.current.lastMessage || (
                loadRef
                && loadRef.current
                && loadRef.current.lastMessage
                && loadRef.current.lastMessage < data.created_at
            )) {
            loadRef.current = {
                ...loadRef.current,
                lastMessage: data.created_at
            }
        }


        return (
            <div style={style}>
                <Message
                    storie={data}
                    openLightBox={(e) => {
                        if (mounted.current)
                            props.openLightBox(e)
                    }}
                    hideCards={props.hideCards ? true : false}
                    db={props.db}
                    nodeId={nodeId}
                    showUserName={reduxStore.getState().db?.cards[nodeId] ? true : false}
                    onResize={(size) => {
                        setRowHeight(data._id, size);
                    }}
                />
            </div>
        );
    }

    const scrollToBottom = (force) => {
        let item = messages.length - 1
        let position = "end"

        let currentLength = loadRef.current.length || 0
        let visibleStopIndex = loadRef?.current?.visibleStartIndex || 20

        if (force && listRef && listRef.current) {
            position = "end"
            listRef.current.scrollToItem(messages.length, position)
            return
        }

        if (listRef && listRef.current) {
            if (!loadRef.current.autoScrolling) {
                position = "start"
                item = (messages.length - currentLength) + visibleStopIndex + 1
                listRef.current.scrollToItem(item < 20 ? 20 : item, position)
            } else {
                position = "end"
                listRef.current.scrollToItem(ReqMessages.length, position)
            }
            return
        }
    }

    const messagesHeight = () => {
        let mh = 0
        if (rowHeights.current && Object.keys(rowHeights.current).length)
            Object.keys(rowHeights.current).forEach(a => {
                mh = mh + rowHeights.current[a] + 11
            })
        return mh
    }

    return (
        <div style={styles.messagesContainer}
            ref={messagesList}
        >
            <AutoSizer
                style={styles.messagesContainer}
            >
                {({ height, width }) => (
                    <List
                        className="List"
                        style={{ paddingBottom: 66 }}
                        height={height}
                        itemCount={messages.length}
                        itemSize={getRowHeight}
                        ref={listRef}
                        width={width}
                        onItemsRendered={(e) => {
                            loadRef.current = {
                                ...loadRef.current,
                                ...e,
                                length: messages.length,
                            }
                        }}
                        overscanCount={33}
                        onScroll={(e) => {
                            let messageSpace = messagesList?.current?.offsetHeight || 0
                            if (mounted.current) {
                                let newLoadRef = {
                                    ...loadRef.current,
                                    // autoScrolling: false
                                }

                                if (e.scrollDirection === "backward") {
                                    if (e.scrollOffset + messageSpace <= (messagesHeight() - 300))
                                        newLoadRef = {
                                            ...newLoadRef,
                                            autoScrolling: false
                                        }
                                    if (!loadRef.current.reqMessages && !loadRef.current.timelineEnd && e.scrollOffset < 100) {
                                        newLoadRef = {
                                            ...newLoadRef,
                                            reqMessages: true
                                        }
                                        loadMore()
                                    }
                                }
                                if (e.scrollOffset + messageSpace > (messagesHeight() - 300)) {
                                    newLoadRef = {
                                        ...newLoadRef,
                                        autoScrolling: true
                                    }
                                    if (unReadeds && !loadRef.current.cleanMessages) {
                                        newLoadRef = {
                                            ...newLoadRef,
                                            cleanMessages: true
                                        }
                                        clearMessages()
                                    }
                                }
                                loadRef.current = newLoadRef
                            }
                        }}
                    >
                        {Row}

                    </List>
                )}
            </AutoSizer>
            {(parseInt(unReadeds) > 0 && !loadRef.current.autoScrolling) ?
                <div
                    style={{
                        position: 'absolute',
                        bottom: '33px',
                        right: '15px',
                        width: '33px',
                        height: '33px',
                    }}
                >
                    <IconButton
                        icon="arrow_downward"
                        iconColor={'red'}
                        size={'33px'}
                        onClick={() => {
                            scrollToBottom(true)
                        }}
                    />
                </div>
                : <React.Fragment></React.Fragment>
            }
        </div>
    )
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default connect(
    null,
    mapDispatchToProps
)(VirtualListMessages)